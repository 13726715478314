import type ProfilingMetadataValues from "../../../../types/ProflierungMetadataValues";
import { metadataTargetFieldMapping, targetFieldLabels } from "../../helpers";
import type {
	DefaultAutoFixTargetFields,
	MetadataRule,
	ValidationTargetField,
} from "../../types";

const requiredFields: (keyof ProfilingMetadataValues)[] = [
	"standard",
	"nameKurz",
	"beschreibung",
	"version",
	"kennung",
	"statusFassung",
	"nameLang",
	"herausgeber",
];

const characterFields: (keyof ProfilingMetadataValues)[] = ["herausgeber"];

// We can't use global regexes here, as they are stateful. When we call `.test`
// on a global regex, it saves the last index where it matched:
// (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/lastIndex#description)
// When we call `.test` again, we look from that last index and not from the
// start of the string, which might lead us to missing some invalid chars at
// the start. To avoid this, we only test against non-global regexes and
// convert them to single-use global regexes when we run the replacement in
// the tofix function
const invalidCharRegex = /([^A-Z|a-z|0-9|:|\-|Ä|ä|Ö|ö|Ü|ü|ß|ẞ])/;
const invalidNameRegex = /([^A-Z|a-z|0-9|:|\-|Ä|ä|Ö|ö|Ü|ü|ß|ẞ|\s])/;
const invalidVersionCharRegex = /([^A-Z|a-z|0-9|.|:|\-|Ä|ä|Ö|ö|Ü|ü|ß|ẞ])/;

const toGlobalRegex = (regex: RegExp) => new RegExp(regex, "g");

const createInvalidCharMessage = (label: string) =>
	`In "${label}" sind nur die Sonderzeichen "-" ` +
	`und ":" zulässig. Bitte verwenden Sie "-" ausschließlich zur ` +
	`Worttrennung (bspw. Luftfahrt-Bundesamt) und ":" Abgrenzung von ` +
	`Namensraumbestandteilen (bspw. bund:itzbund). Leerzeichen sind nicht zulässig.`;

const createInvalidNameMessage = (label: string) =>
	`In "${label}" sind nur die Sonderzeichen "-" ` +
	`und ":" zulässig. Bitte verwenden Sie "-" ausschließlich zur ` +
	`Worttrennung (bspw. Luftfahrt-Bundesamt) und ":" Abgrenzung von ` +
	`Namensraumbestandteilen (bspw. bund:itzbund).`;

const createInvalidVersionCharMessage = (label: string) =>
	`In "${label}" sind nur die Sonderzeichen ".", "-" ` +
	`und ":" zulässig. Bitte verwenden Sie "-" ausschließlich zur ` +
	`Worttrennung (bspw. Luftfahrt-Bundesamt) und ":" Abgrenzung von ` +
	`Namensraumbestandteilen (bspw. bund:itzbund). Leerzeichen sind nicht zulässig.`;

function createIsRequiredRule<K extends keyof ProfilingMetadataValues>(
	key: K,
): MetadataRule {
	const targetField = metadataTargetFieldMapping[key] as ValidationTargetField;
	return {
		id: `metadata-field-is-required-${key}`,
		target: [key],
		targetField,
		isValid({ metadata }) {
			const value = metadata.get(key);
			return !!value;
		},
		message: `"${targetFieldLabels[targetField]}" ist ein Pflichtfeld. Bitte geben Sie einen Wert ein.`,
	};
}

function createHasNoInvalidCharsRule<K extends keyof ProfilingMetadataValues>(
	key: K,
	invalidRegex: RegExp,
	createMessage: (label: string) => string,
): MetadataRule<DefaultAutoFixTargetFields> {
	const targetField = metadataTargetFieldMapping[
		key
	] as DefaultAutoFixTargetFields;
	return {
		id: `metadata-field-has-invalid-characters-${key}`,
		target: [key],
		targetField,
		isValid({ metadata }) {
			const value = metadata.get(key);
			if (!value) return true;
			return !invalidRegex.test(value);
		},
		message: createMessage(targetFieldLabels[targetField]),
		autoFix: (invalidValue) => () =>
			invalidValue.replace(toGlobalRegex(invalidRegex), "-"),
		autoFixDescription: "Ungültige Zeichen ersetzen",
	};
}

const metadataRules: MetadataRule[] = [
	...requiredFields.map((key) => createIsRequiredRule(key)),
	...characterFields.map((key) =>
		createHasNoInvalidCharsRule(
			key,
			invalidCharRegex,
			createInvalidCharMessage,
		),
	),
	createHasNoInvalidCharsRule(
		"version",
		invalidVersionCharRegex,
		createInvalidVersionCharMessage,
	),
	createHasNoInvalidCharsRule(
		"nameKurz",
		invalidNameRegex,
		createInvalidNameMessage,
	),
];

export default metadataRules;
