import { useEffect, useState } from "react";
import type {
	ValidationQueryActorRef,
	ValidationQueryContext,
	ValidationStates,
} from "./validation.query.machine";
import { useAppSystem, useSelectFromSystem } from "../../../hooks";
import {
	createValidationCommandId,
	createValidationQueryId,
} from "./validation.machine";
import {
	assertIsActor,
	useActorRefSelector,
} from "../../../../../utils/machines";
import type { ValidationCommandActorRef } from "./validation.command.machine";
import validationCommandMachine from "./validation.command.machine";
import type { ProjektId } from "../schemas";
import { useActiveProjectId } from "../../navigation/hooks";

export function useValidationQueryRef(
	projektId: ProjektId | null,
): ValidationQueryActorRef | null {
	return useSelectFromSystem((system) => {
		if (!projektId) return null;
		return system.get(createValidationQueryId(projektId)) || null;
	});
}

export function useValidationCommandRef(
	projektId: ProjektId | null,
): ValidationCommandActorRef | null {
	const system = useAppSystem();
	if (!projektId) return null;
	const validationCommandActor = system.get(
		createValidationCommandId(projektId),
	);
	if (!validationCommandActor) return null;
	assertIsActor(validationCommandActor, validationCommandMachine);
	return validationCommandActor;
}

export function useValidationContext(
	projektId: ProjektId | null,
): ValidationQueryContext | null {
	const validationQueryRef = useValidationQueryRef(projektId);
	return useActorRefSelector(
		validationQueryRef,
		(snapshot) => snapshot.context,
	);
}

export function useValidationSelector<T>(
	selector: (context: ValidationQueryContext) => T,
): T | null {
	const projektId = useActiveProjectId();
	const validationQueryRef = useValidationQueryRef(projektId);
	return useActorRefSelector(validationQueryRef, (snapshot) =>
		selector(snapshot.context),
	);
}

export function useValidationStatus(projektId: ProjektId | null) {
	const validationCommandRef = useValidationCommandRef(projektId);
	const [state, setState] = useState<ValidationStates | null>(null);
	useEffect(() => {
		const subscription = validationCommandRef?.subscribe((snapshot) => {
			setState(snapshot.value === "Validating" ? "Validating" : "Idle");
		});
		return subscription?.unsubscribe;
	}, [validationCommandRef]);
	return state;
}

export function useValidationErrors(projektId: ProjektId | null) {
	const context = useValidationContext(projektId);
	return context ? context.validationErrors : null;
}

export function useMetadataValidationErrors(projektId: ProjektId | null) {
	const validationErrors = useValidationErrors(projektId);
	return validationErrors ? validationErrors.results : null;
}
