import { cloneElement, useEffect, useMemo } from "react";
import type { ImmutableMap } from "@xoev/immutable-map";
import type { CodeListValues } from "../../../../types/InfoNodeValues";
import { extractFromList } from "../../../../utils/lists";
import { runFixer } from "../../../Validation/helpers";
import type { FixerFn, ValidationTargetField } from "../../../Validation/types";
import type {
	CreateComponentsProps,
	FieldRendererInterface,
	FieldRendererDefinition,
	FieldRendererKeys,
} from "./types";
import {
	QNamePathSchema,
	type LiteNode,
	isLiteDatatype,
	LiteBausteinType,
	isLiteEigenschaft,
} from "../../../AppActor/actors/modellierungModel/schemas";
import type { MessageProfileValues } from "../../../EditorState/types";
import { useStateSelector } from "../../../EditorState";
import {
	selectRestrictionByName,
	selectStandard,
} from "../../../EditorState/selectors";
import { useAppSelector } from "../../../../redux/hooks";
import {
	selectModellContainer,
	selectQName,
	selectReferencedDatatype,
} from "../../../../redux/treeSlice";
import type { Nullish } from "../../../../utils/types";
import type { LiteModellContainer } from "../../../AppActor/actors/modellierungModel/types";
import type { RootState } from "../../../../redux/store";
import { selectDatatypeOrReferenceFromModell } from "../../../AppActor/actors/modellierungModel/selectors";
import useCodelistRequest from "./renderers/CodeListRestrictionRenderer/CodeListComboBox/useCodelistRequest";
import { selectSuggestedCodelist } from "../../../../redux/codelistSlice";

export type NutzungsArt = "1" | "2" | "3" | "4";

export interface CodelisteInfo {
	kennung: string | undefined;
	version: string | undefined;
	nutzungsArt: string | undefined;
}

export function extractDefinitions(
	fields: FieldRendererDefinition[],
	keys: FieldRendererKeys[],
): [matches: FieldRendererDefinition[], rest: FieldRendererDefinition[]] {
	const tuple = extractFromList(fields, (field) => keys.includes(field.name));
	return tuple;
}

export function createComponents(
	renderers: FieldRendererInterface[],
	fields: FieldRendererDefinition[],
	props: CreateComponentsProps,
): JSX.Element[] {
	let restFields = fields;
	const elements: JSX.Element[] = [];
	for (const { keys, render } of renderers) {
		const [matchedFields, rest] = extractDefinitions(restFields, keys);
		restFields = rest;
		if (matchedFields.length > 0) {
			const onFix =
				props.onFix &&
				((fixer: FixerFn<ValidationTargetField>) => {
					matchedFields.forEach((field) => {
						runFixer(fixer, field.name, (fixedValue) =>
							props.onFix?.(fixedValue, field.name),
						);
					});
				});
			const fieldElement = render(matchedFields, { ...props, onFix });
			// Add a key here. ES-Lint React can't quite reach deep enough into our
			// code to identify that each item in `elements` is a react element that
			// needs a key, so we need to think of this ourselves
			elements.push(cloneElement(fieldElement, { key: keys[0] }));
		}
	}
	return elements;
}

export function getCodeListIconKey(
	field: keyof CodeListValues,
	nutzungsArt?: string,
) {
	if (nutzungsArt === "1" || nutzungsArt === "2") {
		return "nutzungsart12";
	}
	if (nutzungsArt === "3" && field === "kennung") {
		return "nutzungsart3";
	}
	if (nutzungsArt === "4" && field === "kennung") {
		return "nutzungsart4";
	}
	return null;
}

function getNutzungsArt(
	kennung: string | undefined,
	version: string | undefined,
): NutzungsArt {
	if (kennung && version) return "2";
	if (kennung) return "3";
	return "4";
}

const emptyCodeliste: CodelisteInfo = {
	kennung: undefined,
	version: undefined,
	nutzungsArt: undefined,
};

function getCodelisteInfo(node: LiteNode): CodelisteInfo {
	if (isLiteDatatype(node) && node.typ === LiteBausteinType.CodeDatentyp) {
		const { kennung, version } = node;
		return {
			kennung,
			version,
			nutzungsArt: getNutzungsArt(kennung, version),
		};
	}
	return emptyCodeliste;
}

export function selectCodelistInfoFromModell(
	modell: LiteModellContainer,
	node: Nullish<LiteNode>,
) {
	const codelisteNode = selectDatatypeOrReferenceFromModell(modell, node);
	if (!codelisteNode) return emptyCodeliste;
	return getCodelisteInfo(codelisteNode);
}
export const selectCodelistInfo =
	(standard: Nullish<string>, node: Nullish<LiteNode>) =>
	(state: RootState) => {
		const modell = selectModellContainer(standard)(state);
		if (!modell || !node) return emptyCodeliste;
		return selectCodelistInfoFromModell(modell, node);
	};

export function useCodeliste(node: LiteNode): CodelisteInfo {
	const standard = useStateSelector(selectStandard());
	return useAppSelector(selectCodelistInfo(standard, node));
}

export function usePresetValues(node: LiteNode) {
	return useMemo(() => {
		if (isLiteEigenschaft(node)) {
			return { fixedWert: node.fixedWert, defaultWert: node.defaultWert };
		}
		return { fixedWert: undefined, defaultWert: undefined };
	}, [node]);
}

export function useReferenceProfile(
	profile: Nullish<ImmutableMap<Partial<MessageProfileValues>>>,
	activeNode: LiteNode,
): ImmutableMap<Partial<MessageProfileValues>> | null {
	const standard = useStateSelector(selectStandard());
	const datatype = useAppSelector(
		selectReferencedDatatype(standard, activeNode),
	);
	const qname = useAppSelector(selectQName(standard, datatype));
	const restriction = useStateSelector(
		selectRestrictionByName(
			profile?.getIn(["datentyp", "datentyp"]),
			profile?.getIn(["datentyp", "name"]),
		),
	);
	return (
		(datatype &&
			restriction?.getIn(["profile", QNamePathSchema.parse(qname)])) ||
		null
	);
}

export function useAvailableInXRepository(
	kennung: Nullish<string>,
	version: Nullish<string>,
) {
	const { request } = useCodelistRequest();
	useEffect(() => {
		request({ kennung, version });
	}, [kennung, request, version]);
	return !!useAppSelector(selectSuggestedCodelist(kennung, version));
}
