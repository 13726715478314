import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";
import type {
	LiteId,
	LiteNode,
	LiteNodeType,
} from "../../../../AppActor/actors/modellierungModel/schemas";
import { useIsProjektDisplayOnly } from "../../../../AppActor/actors/project/hooks";
import type { DisplayRendererProps, EditRendererProps } from "../types";
import {
	getLiteNodeKind,
	type LiteKind,
} from "../../../../AppActor/actors/modellierungModel/LiteKind";
import type { StandardProjekt } from "../../../../AppActor/actors/project/types";

type NodeByType<TNodeType extends LiteNodeType> = Extract<
	LiteNode,
	{ liteType: TNodeType }
>;

function useRendererMode() {
	const isDisplayOnly = useIsProjektDisplayOnly();
	return isDisplayOnly ? "displayComponent" : "editComponent";
}

const RendererContext = createContext<{
	activeNode: LiteNode;
	activePath: LiteId[];
	isReadOnly: boolean;
	projekt: StandardProjekt;
}>(null as never);

export function RendererProvider({
	activeNode,
	activePath,
	isReadOnly,
	children,
	projekt,
}: {
	activeNode: LiteNode;
	activePath: LiteId[];
	isReadOnly: boolean;
	children: ReactNode;
	projekt: StandardProjekt;
}): JSX.Element {
	const ctx = useMemo(
		() => ({
			activeNode,
			activePath,
			isReadOnly,
			projekt,
		}),
		[activeNode, activePath, isReadOnly, projekt],
	);

	return (
		<RendererContext.Provider value={ctx}>{children}</RendererContext.Provider>
	);
}

function toList<T>(listOrItem: T | T[]): T[] {
	return Array.isArray(listOrItem) ? listOrItem : [listOrItem];
}

/**
 * Renders an Edit or Display component depending on useRendererMode() result
 * @param displayComponent Component to display field
 * @param editComponent Component to edit field
 * @param showForType Types that this component will be rendered for
 * @param showForKind Kinds that this component will be rendered for
 * @returns JSX.Element
 */
export default function Renderer<
	TNodeType extends LiteNodeType = LiteNodeType,
>({
	showForType,
	showForKind,
	...props
}: {
	displayComponent: (
		props: DisplayRendererProps<NodeByType<TNodeType>>,
	) => JSX.Element;
	editComponent: (
		props: EditRendererProps<NodeByType<TNodeType>>,
	) => JSX.Element;
	showForType?: TNodeType | TNodeType[];
	showForKind?: LiteKind | LiteKind[];
}) {
	const { activeNode, activePath, isReadOnly, projekt } =
		useContext(RendererContext);
	const rendererMode = useRendererMode();
	const renderProps = useMemo(
		() => ({
			editComponent: {
				activeNode,
				activePath,
				isReadOnly,
				projekt,
			},
			displayComponent: { activeNode, activePath },
		}),
		[activeNode, activePath, isReadOnly, projekt],
	);
	if (
		showForType &&
		!toList<LiteNodeType>(showForType).includes(activeNode.liteType)
	) {
		return <></>;
	}
	if (
		showForKind &&
		!toList(showForKind).includes(getLiteNodeKind(activeNode))
	) {
		return <></>;
	}
	const Component = props[rendererMode];
	return (
		<Component
			{...(renderProps[rendererMode] as EditRendererProps<
				NodeByType<TNodeType>
			>)}
		/>
	);
}
