import type { Context } from "react";
import { useCallback, useContext, useEffect } from "react";
import { useConst } from "../../hooks";
import { createDeferred } from "../../utils/promise";
import type { SessionContextValue } from "../Session/types";

/**
 * Returns a function, that returns a promise, that resolves once the session
 * cookie has been set
 */
function useWaitForSession(
	sessionContext: Context<SessionContextValue>,
): () => Promise<void> {
	const { session } = useContext(sessionContext);
	const hasSession = !!session;
	const resolvable = useConst(() => createDeferred<void>());
	const waitForSession = useCallback(() => {
		return resolvable.promise;
	}, [resolvable]);

	useEffect(() => {
		if (hasSession) {
			resolvable.resolve();
		}
	}, [resolvable, hasSession]);

	return waitForSession;
}

export default useWaitForSession;
