import classNames from "classnames";
import type { ReactNode } from "react";
import { useContext, createContext } from "react";
import CacheProvider from "../CacheProvider";
import ProfilingPageGuard from "../../AtomicComponents/Organisms/ProfilierungPageGuard";
import "./EditorPage.scss";

export enum EditorSection {
	Metadata = "metadata",
	Datatypes = "datatypes",
	MessageStructures = "messageStructures",
	Documentation = "documentation",
}

const EditorPageContext = createContext<EditorSection>(null as never);

export function useEditorSection(): EditorSection {
	return useContext(EditorPageContext);
}

const mainPaths = [
	"/profilierung/projekt",
	"/profilierung/datentypen",
	"/profilierung/nachrichtenstrukturen",
	"/profilierung/dokumentation",
];

function EditorPage({
	children,
	className,
	section,
	...props
}: {
	[k: `data-${string}`]: string;
	children: ReactNode;
	section: EditorSection;
	className?: string;
}): JSX.Element {
	return (
		<EditorPageContext.Provider value={section}>
			<CacheProvider mainPaths={mainPaths} section={section}>
				<ProfilingPageGuard
					{...props}
					className={classNames("editor-page", className)}
					data-section={section}
				>
					{children}
				</ProfilingPageGuard>
			</CacheProvider>
		</EditorPageContext.Provider>
	);
}

export default EditorPage;
