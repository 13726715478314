import { IconButton, Tooltip } from "@mui/material";
import CopyIcon from "@mui/icons-material/ContentCopy";
import CopySuccessIcon from "@mui/icons-material/TaskOutlined";
import { useEffect, useState } from "react";
import classNames from "classnames";
import type { NodeElementProps } from "../../../../../Tree";
import Tree from "../../../../../Tree";
import type { MarkupNode } from "../../../types";
import InteractiveNode from "../../../../../InteractiveNode";
import { getNodeIndex, getNodeName, getNodeValue } from "../helpers";
import { unknownErrorToString } from "../../../../../../utils/error";
import useIsMountedRef from "../../../../../../hooks/useIsMountedRef";
import AlertPopup from "../../../../../AlertPopup";
import "./TreeRendererNode.scss";

const SHOW_COPY_SUCCESS_TIMEOUT = 2000;
const MAX_GENERATED_NAME_LENGTH = 50;

function generateNodeName(node: MarkupNode) {
	return JSON.stringify(node)
		.slice(0, MAX_GENERATED_NAME_LENGTH)
		.padEnd(MAX_GENERATED_NAME_LENGTH + 1, "…");
}

function TreeRendererNode({
	node,
	nodeProps,
	level,
	maxDigits,
}: {
	node: MarkupNode;
	nodeProps: NodeElementProps;
	level: number;
	maxDigits?: number;
}): JSX.Element {
	const isMountedRef = useIsMountedRef();
	const [error, setError] = useState<null | string>(null);
	const [isSuccess, setIsSuccess] = useState(false);

	const nodeName = getNodeName(node);
	const nodeValue = getNodeValue(node);
	const nodeIndex = getNodeIndex(node);

	// Show the success icon for a few seconds and then show the original icon
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (isSuccess) {
			const timeout = setTimeout(() => {
				if (!isMountedRef.current) return;
				setIsSuccess(false);
			}, SHOW_COPY_SUCCESS_TIMEOUT);
			return () => clearTimeout(timeout);
		}
	}, [isMountedRef, isSuccess]);

	const handleCopy = async () => {
		if (!nodeValue) return;
		try {
			await navigator.clipboard.writeText(nodeValue);
			if (!isMountedRef.current) return;
			setIsSuccess(true);
		} catch (e) {
			if (!isMountedRef.current) return;
			setError(unknownErrorToString(e));
		}
	};

	const handleErrorClose = () => {
		setError(null);
	};

	return (
		<>
			{error !== null && (
				<AlertPopup
					id="tree-renderer-node-copy-value-error"
					onClose={handleErrorClose}
					isOpen
				>
					Beim Kopieren ist ein Fehler aufgetreten. Bitte versuchen Sie es
					erneut.
					<br />
					<br />
					Originale Fehlermeldung:
					<br />
					{error}
				</AlertPopup>
			)}
			<InteractiveNode
				{...nodeProps}
				data-testid="tree-renderer-node"
				data-node-name={nodeName}
				data-node-level={level}
				className="tree-renderer-node"
				style={{ ["--_tree-node-level" as string]: level }}
			>
				<Tree.NodeToggle />
				<div className="tree-renderer-node__node-index">
					{maxDigits && nodeIndex?.padStart(maxDigits, "0")}
				</div>
				<span className="tree-renderer-node__node-label">
					{/*
						If the node provides a name use that. This should always be the
						case, as we add a name to every child in `getChildren`. Fall
						back to a generated name if neccessary
					*/}
					{nodeName || generateNodeName(node)}
				</span>
				{nodeValue && (
					<div className="tree-renderer-node__leaf-value">
						<span className="tree-renderer-node__leaf-label">{nodeValue}</span>
						<Tooltip title="Wert kopieren">
							<IconButton
								className="tree-renderer-node__copy-button"
								size="small"
								disableRipple
								onClick={handleCopy}
							>
								<div className="tree-renderer-node__icon-wrapper">
									<CopyIcon
										className={classNames(
											"tree-renderer-node__icon",
											!isSuccess && "tree-renderer-node__icon--active",
										)}
										fontSize="inherit"
									/>
								</div>
								<div className="tree-renderer-node__icon-wrapper">
									<CopySuccessIcon
										className={classNames(
											"tree-renderer-node__icon",
											isSuccess && "tree-renderer-node__icon--active",
										)}
										fontSize="inherit"
									/>
								</div>
							</IconButton>
						</Tooltip>
					</div>
				)}
			</InteractiveNode>
		</>
	);
}

export default TreeRendererNode;
