import type { ReactNode } from "react";
import { useMemo } from "react";
import { useEventHandler } from "../../hooks";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import type { RequestNodeOptions } from "../../redux/apiSlice";
import { useLazyGetTreeQuery } from "../../redux/apiSlice";
import { useAppDispatch } from "../../redux/hooks";
import { setTree } from "../../redux/treeSlice";
import { toRequestError, toRequestStatus } from "../../utils/requestFormatters";
import RequestErrorNotification from "../RequestErrorNotification";
import TreeStructureContext from "./TreeStructureContext";
import type { TreeStructureContextType } from "./types";

export function useTreeRequest(): TreeStructureContextType {
	const [trigger, { isError, isFetching, isLoading, isSuccess, error }] =
		useLazyGetTreeQuery();
	const dispatch = useAppDispatch();
	const treeStatus = toRequestStatus({
		isError,
		isFetching,
		isLoading,
		isSuccess,
	});
	const treeError = useMemo(() => toRequestError(error), [error]);
	const isMountedRef = useIsMountedRef();

	const requestTree = useEventHandler(({ standard }: RequestNodeOptions) => {
		return trigger({ standard })
			.unwrap()
			.then((rawModel) => {
				if (!isMountedRef.current) return;
				dispatch(setTree({ standard, rawModel }));
			});
	});

	return useMemo(
		() => ({ requestTree, treeStatus, treeError }),
		[requestTree, treeError, treeStatus],
	);
}

export default function TreeStructureProvider({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const ctx = useTreeRequest();
	return (
		<TreeStructureContext.Provider value={ctx}>
			<RequestErrorNotification
				id="tree-request-error"
				error={ctx.treeError}
				status={ctx.treeStatus}
			>
				Fehler beim Abrufen der Datentypen und Nachrichtenstrukturen
			</RequestErrorNotification>
			{children}
		</TreeStructureContext.Provider>
	);
}
