import type { SyntheticEvent } from "react";
import { useCallback, useMemo, useEffect, useState } from "react";
import { useGetStandardsQuery } from "../../../../redux/apiSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectActiveStandard } from "../../../../redux/standardsSlice";
import { useStateSelector } from "../../../EditorState";
import { selectStandard } from "../../../EditorState/selectors";
import { setGenerationConfiguraton } from "../../../../redux/configuration/configurationSlice";
import { GenerationBase } from "../../../../redux/configuration/types";
import type { StandardType } from "../../../../types/ProfilierungHome";

function createStandardsList(
	availableStandards?: StandardType[],
	projectStandard?: string,
	explorationStandard?: StandardType | null,
) {
	const standardsList = availableStandards?.map((standard) => ({
		label: `${standard.nameKurz} ${standard.version}`,
		standard,
	}));

	if (explorationStandard && standardsList) {
		const explorationIndex = standardsList.findIndex(
			(elem) => elem.standard.kennung === explorationStandard.kennung,
		);
		if (explorationIndex && explorationIndex !== -1) {
			const explorationStandardElement = standardsList[explorationIndex];
			standardsList.splice(explorationIndex, 1);
			standardsList.unshift(explorationStandardElement);
		}
	}
	const projectStandardElement = availableStandards?.find(
		(standard) => standard.kennung === projectStandard,
	);

	if (projectStandardElement && standardsList) {
		const element = {
			label: `Geöffnete Profilierung: ${projectStandardElement.nameKurz} ${projectStandardElement.version}`,
			standard: projectStandardElement,
		};
		standardsList.unshift(element);
	}

	return standardsList;
}

function useGenerationBaseSelect() {
	const dispatch = useAppDispatch();
	const projectStandard = useStateSelector(selectStandard());
	const explorationStandard = useAppSelector(selectActiveStandard());
	const { data: availableStandards } = useGetStandardsQuery();
	const standardsList = useMemo(
		() =>
			createStandardsList(
				availableStandards,
				projectStandard,
				explorationStandard,
			),
		[projectStandard, explorationStandard, availableStandards],
	);

	/* We need to hold the selected option in its own state since we can't rely on
		 the configured standard only. The same standard can be in the list twice when
		 a project is active.
	*/
	const [selectedOption, setSelectedOption] = useState(
		standardsList?.[0] ?? null,
	);

	const setConfig = useCallback(
		(option: { label: string; standard: StandardType }) => {
			const basis =
				projectStandard && option === standardsList?.[0]
					? GenerationBase.ActiveProfile
					: GenerationBase.Standard;
			dispatch(
				setGenerationConfiguraton({
					configuration: {
						standard: option.standard,
						basis,
					},
				}),
			);
		},
		[dispatch, projectStandard, standardsList],
	);

	const handleStandardChange = (
		event: SyntheticEvent,
		value: {
			label: string;
			standard: StandardType;
		} | null,
	) => {
		if (value && value.standard) {
			setSelectedOption(value);
			setConfig(value);
		}
	};

	useEffect(() => {
		if (selectedOption) {
			setConfig(selectedOption);
		}
	}, [selectedOption, setConfig]);

	return {
		selectedOption,
		standardsList,
		handleStandardChange,
	};
}

export default useGenerationBaseSelect;
