import { TableRow } from "@mui/material";
import classNames from "classnames";
import type { ReactNode } from "react";
import type { ExtendProps, PropsOf } from "../../../../utils/types";
import "./DatatypeTableRow.scss";

type DatatypeTableRowProps = ExtendProps<
	PropsOf<typeof TableRow>,
	{ children: ReactNode; className?: string }
>;

export default function DatatypeTableRow({
	children,
	className,
	...props
}: DatatypeTableRowProps): JSX.Element {
	return (
		<TableRow
			className={classNames("datatype-table-row", className)}
			{...props}
		>
			{children}
		</TableRow>
	);
}
