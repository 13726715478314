import type { ImmutableMap } from "@xoev/immutable-map";
import type { QName } from "../../components/AppActor/actors/modellierungModel/schemas";
import type { StandardType } from "../../types/ProfilierungHome";

export enum GenerationBase {
	Standard = "STANDARD",
	ActiveProfile = "AKTIVES_PROFIL",
}

export interface DocumentationConfiguration {
	nurProfilierteElemente?: boolean;
	nurVorhandeneElemente?: boolean;
	nachrichten?: QName[];
}

export interface GenerationConfiguration {
	optionaleElementeGenerieren: boolean;
	rekursionsTiefe: number;
	basis?: GenerationBase;
	standard?: StandardType;
	nachrichten?: QName[];
}

export interface Configuration {
	dokumentation?: ImmutableMap<DocumentationConfiguration>;
	generierung?: ImmutableMap<GenerationConfiguration>;
}

export type ConfigurationState = {
	dokumentation: DocumentationConfiguration;
	generierung: GenerationConfiguration;
};
