import { createSvgIcon } from "@mui/material";

const PlusIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12 5V19M5 12H19"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>,
	"Plus",
);

export default PlusIcon;
