import type { ImmutableMap } from "@xoev/immutable-map";
import type { CodelistStateResult } from "../../../../redux/codelistSlice";
import { CodelistResultStatus } from "../../../../redux/codelistSlice";
import {
	subSelectCodelistIdentifier,
	subSelectCodelistValues,
	subSelectDatatype,
	subSelectDatatypeRestrictionProfile,
	subSelectHasCodelistValueOverlap,
	subSelectRestrictionId,
} from "../../../EditorState/subSelectors";
import {
	RestrictionIdSchema,
	type MessageProfileValues,
} from "../../../EditorState/types";
import type { ProfileRule } from "../../types";
import { Severity, ValidationTargetField } from "../../types";
import { addTargetField } from "../helpers";
import type { LiteNode } from "../../../AppActor/actors/modellierungModel/schemas";
import { QNameSchema } from "../../../AppActor/actors/modellierungModel/schemas";
import { selectCodelistInfoFromModell } from "../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/fieldRendererHelpers";
import type { LiteModellContainer } from "../../../AppActor/actors/modellierungModel/types";

const getKennung = (
	modell: LiteModellContainer,
	profile: ImmutableMap<Partial<MessageProfileValues>>,
	standardNode: LiteNode,
) => {
	return (
		selectCodelistInfoFromModell(modell, standardNode).kennung ||
		subSelectCodelistIdentifier(profile)
	);
};

const filterValues = (
	modell: LiteModellContainer,
	profile: ImmutableMap<Partial<MessageProfileValues>>,
	standardNode: LiteNode,
	filterFn: (value: string) => boolean | undefined,
) => {
	const kennung = getKennung(modell, profile, standardNode);
	const profiledValues = subSelectCodelistValues(profile);
	if (kennung && profiledValues) {
		return profiledValues.filter(filterFn);
	}
	return [];
};

const getValuesNotInLists = (
	modell: LiteModellContainer,
	profile: ImmutableMap<Partial<MessageProfileValues>>,
	standardNode: LiteNode,
	codelist: CodelistStateResult,
) => {
	const kennung = getKennung(modell, profile, standardNode);

	if (kennung) {
		const { suggestedList, otherLists } = codelist.data || {};
		return filterValues(
			modell,
			profile,
			standardNode,
			(value) =>
				!suggestedList?.includes(value) && !otherLists?.includes(value),
		)
			.map((value) => `"${value}"`)
			.join(", ");
	}
	return "";
};

const getValuesInOtherList = (
	modell: LiteModellContainer,
	profile: ImmutableMap<Partial<MessageProfileValues>>,
	standardNode: LiteNode,
	codelist: CodelistStateResult,
) => {
	const kennung = getKennung(modell, profile, standardNode);

	if (kennung) {
		const { suggestedList, otherLists } = codelist.data || {};
		return filterValues(
			modell,
			profile,
			standardNode,
			(value) => !suggestedList?.includes(value) && otherLists?.includes(value),
		)
			.map((value) => `"${value}"`)
			.join(", ");
	}
	return "";
};

const codelistValueRules = addTargetField<
	ValidationTargetField.CodelistValues,
	ProfileRule<ValidationTargetField.CodelistValues>
>(ValidationTargetField.CodelistValues, [
	{
		id: "codelist-values-are-suggested-or-other",
		target: ["konfiguration"],
		isValid({ standardNode, profile, codelist, modell }) {
			const kennung = getKennung(modell, profile, standardNode);

			const profiledValues = subSelectCodelistValues(profile);
			if (
				kennung &&
				codelist.status === CodelistResultStatus.Existing &&
				!!profiledValues
			) {
				const { suggestedList, otherLists } = codelist.data || {};
				return (
					filterValues(
						modell,
						profile,
						standardNode,
						(value) =>
							suggestedList?.includes(value) || otherLists?.includes(value),
					).length === profiledValues.length
				);
			}
			return true;
		},
		message: ({ profile, standardNode, codelist, modell }) =>
			`Die Werte ${getValuesNotInLists(
				modell,
				profile,
				standardNode,
				codelist,
			)} befinden sich nicht in der Codeliste "${getKennung(
				modell,
				profile,
				standardNode,
			)}"`,
		severity: Severity.Error,
	},
	{
		id: "codelist-values-are-suggested-and-not-other",
		target: ["konfiguration"],
		isValid({ standardNode, profile, codelist, modell }) {
			const kennung = getKennung(modell, profile, standardNode);
			const profiledValues = subSelectCodelistValues(profile);
			if (
				kennung &&
				codelist.status === CodelistResultStatus.Existing &&
				!!profiledValues
			) {
				const { suggestedList, otherLists } = codelist.data || {};
				return (
					filterValues(
						modell,
						profile,
						standardNode,
						(value) =>
							suggestedList?.includes(value) || !otherLists?.includes(value),
					).length === profiledValues.length
				);
			}
			return true;
		},
		message: ({ profile, standardNode, codelist, modell }) =>
			`Die Werte ${getValuesInOtherList(
				modell,
				profile,
				standardNode,
				codelist,
			)} befinden sich nicht in der empfohlenen Codelistenspalte`,
		severity: Severity.Info,
	},
	{
		id: "codelist-values-are-empty-when-dt-ref-has-values",
		target: ["konfiguration"],
		isValid({ profile, datatypes }) {
			const dtRef = subSelectDatatype(profile);
			const datatypeId = dtRef?.get("datentyp") ?? QNameSchema.parse("");
			const dtName = dtRef?.get("name") ?? "";
			const restrictionId =
				subSelectRestrictionId(datatypes, datatypeId, dtName) ??
				RestrictionIdSchema.parse("");
			if (!dtRef) return true;
			const restrictionProfile = subSelectDatatypeRestrictionProfile(
				datatypes,
				datatypeId,
				restrictionId,
			);
			return !subSelectHasCodelistValueOverlap(profile, restrictionProfile);
		},
		message:
			"Im Profil des Nachrichtenelements UND in der Einschränkung des " +
			"Datentyps sind Codelistenwerte festgelegt. Die Werte aus dem Profil " +
			"des Nachrichtenelements werden ignoriert.",
		severity: Severity.Warning,
		autoFixDescription: "Werte aus dem Profil des Nachrichtenelements löschen",
		autoFix: () => () => [],
	},
]);

const codelistMetadataRules = addTargetField<
	ValidationTargetField.CodelistIdentifier,
	ProfileRule<ValidationTargetField.CodelistIdentifier>
>(ValidationTargetField.CodelistIdentifier, [
	{
		id: "codelist-exists-in-xrepository",
		target: ["konfiguration"],
		isValid({ standardNode, profile, codelist, modell }) {
			const kennung = getKennung(modell, profile, standardNode);
			if (kennung) {
				return codelist.status === CodelistResultStatus.Existing;
			}
			return true;
		},
		message: ({ profile, standardNode, modell }) =>
			`Die Codeliste mit Kennung ${getKennung(
				modell,
				profile,
				standardNode,
			)} ist nicht im XRepository verfügbar`,
		severity: Severity.Warning,
	},
	{
		id: "codelist-has-identifier-if-values-are-profiled",
		target: ["konfiguration"],
		isValid({ standardNode, profile, modell }) {
			const kennung = getKennung(modell, profile, standardNode);
			const profiledValues = subSelectCodelistValues(profile);
			if (profiledValues) {
				return !!kennung;
			}
			return true;
		},
		message: "Werte wurden ohne zugehörige Kennung profiliert",
		severity: Severity.Warning,
	},
]);

const codelistRules = [...codelistValueRules, ...codelistMetadataRules];

export default codelistRules;
