import type { FileError } from "react-dropzone";
import { Alert, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RetryIcon from "@mui/icons-material/Replay";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { GlobalSnackbarItem } from "../../../../components/GlobalSnackbar";

function ErrorNotification({
	id,
	children,
	rejectedError,
	onRetry,
	onClose,
}: {
	id: string;
	children: ReactNode;
	rejectedError: FileError[];
	onRetry?: () => void;
	onClose?: () => void;
}): JSX.Element {
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		if (rejectedError.length > 0) {
			setShowError(true);
		} else {
			setShowError(false);
		}
	}, [rejectedError]);

	const handleErrorClose = () => {
		if (onClose) {
			onClose();
		}
		setShowError(false);
	};

	return showError ? (
		<GlobalSnackbarItem id={id}>
			<Alert
				severity="error"
				action={
					<>
						{onRetry && (
							<Tooltip title="Erneut versuchen">
								<IconButton
									color="error"
									size="small"
									aria-label="Erneut versuchen"
									onClick={onRetry}
								>
									<RetryIcon fontSize="small" fill="currentColor" />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title="Schließen">
							<IconButton
								color="error"
								size="small"
								aria-label="Schließen"
								onClick={handleErrorClose}
							>
								<CloseIcon fontSize="small" fill="currentColor" />
							</IconButton>
						</Tooltip>
					</>
				}
			>
				{children}
				{rejectedError.at(0)?.message && <>: {rejectedError.at(0)?.message}</>}
			</Alert>
		</GlobalSnackbarItem>
	) : (
		<></>
	);
}

export default ErrorNotification;
