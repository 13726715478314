import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
	selectFilterValue,
	setFilter,
	UiFilterGroup,
} from "../../../../redux/uiSlice";
import TypeFilter from "../TypeFilter";
import FilterMenu from "../../../../AtomicComponents/Organisms/ProfilierungFilterMenu";
import ProfiledOnlyFilter from "../../../../AtomicComponents/Organisms/ProfilierungProfiledOnlyFilter";

const FilterGroup = UiFilterGroup.DatatypeList as const;

function ListFilter(): JSX.Element {
	const dispatch = useAppDispatch();

	const showProfiledOnly = useAppSelector(
		selectFilterValue(FilterGroup, "showProfiledOnly"),
	);

	const handleFilterModeChange = () => {
		dispatch(setFilter(FilterGroup, { showProfiledOnly: !showProfiledOnly }));
	};

	return (
		<FilterMenu filterGroup={FilterGroup}>
			<ProfiledOnlyFilter
				checked={showProfiledOnly}
				onChange={handleFilterModeChange}
			/>
			<TypeFilter filterGroup={FilterGroup} />
		</FilterMenu>
	);
}

export default ListFilter;
