import { setup } from "xstate";

const MACHINE_ID = "ProfilierungHome";

const profilingHomeMachine = setup({
	types: {
		// typegen currently not supported in v5
		// eslint-disable-next-line @typescript-eslint/consistent-type-imports
		// typegen:  {} as import("./ProfilierungHome.machine.typegen").Typegen0,
		context: {} as {
			callbacks: {
				shouldOpenWarning: () => boolean;
				hasActiveProject: () => boolean;
			};
		},
		events: {} as
			| { type: "OPEN_LOAD_DIALOG" }
			| { type: "OPEN_NEW_PROJECT_DIALOG" }
			| { type: "CLOSE_DIALOG" }
			| { type: "CONTINUE_WITHOUT_SAVING" }
			| { type: "DELETE_PROJECT_DIALOG" },
		input: {} as {
			shouldOpenWarning: () => boolean;
			hasActiveProject: () => boolean;
		},
	},
	guards: {
		shouldOpenWarning: ({ context }) => context.callbacks.shouldOpenWarning(),
		hasActiveProject: ({ context }) => context.callbacks.hasActiveProject(),
	},
}).createMachine({
	id: MACHINE_ID,
	initial: "Closed",
	context: ({ input }) => ({
		callbacks: { ...input },
	}),
	on: {
		CLOSE_DIALOG: {
			target: ".Closed",
		},
	},
	states: {
		Closed: {
			on: {
				OPEN_LOAD_DIALOG: [
					{
						target: "WarningOpen.LoadingWarningOpen",
						guard: "shouldOpenWarning",
					},
					{
						target: "LoadDialogOpen",
					},
				],
				OPEN_NEW_PROJECT_DIALOG: [
					{
						target: "WarningOpen.NewProjectWarningOpen",
						guard: "shouldOpenWarning",
					},
					{
						target: "NewProjectDialogOpen",
					},
				],
				DELETE_PROJECT_DIALOG: {
					target: "DeleteDialogOpen",
					guard: "hasActiveProject",
				},
			},
		},
		LoadDialogOpen: {},
		NewProjectDialogOpen: {},
		WarningOpen: {
			initial: "LoadingWarningOpen",
			states: {
				LoadingWarningOpen: {
					on: {
						CONTINUE_WITHOUT_SAVING: {
							target: "#ProfilierungHome.LoadDialogOpen",
						},
					},
				},
				NewProjectWarningOpen: {
					on: {
						CONTINUE_WITHOUT_SAVING: {
							target: "#ProfilierungHome.NewProjectDialogOpen",
						},
					},
				},
			},
		},
		DeleteDialogOpen: {},
	},
});

export default profilingHomeMachine;
