import classNames from "classnames";
import type {
	DetailedHTMLProps,
	HTMLAttributes,
	LabelHTMLAttributes,
	ReactNode,
} from "react";
import type { ExtendProps } from "../../../utils/types";
import "./EditFormFieldLabel.scss";

type DivProps = DetailedHTMLProps<
	HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;
type LabelProps = DetailedHTMLProps<
	LabelHTMLAttributes<HTMLLabelElement>,
	HTMLLabelElement
>;

type EditFormFieldLabelProps = ExtendProps<
	LabelProps,
	{
		htmlFor: string;
		text: ReactNode;
		children: ReactNode;
		className?: string;
		isRequired?: boolean;
		labelTextProps?: Partial<DivProps>;
		labelTextClassName?: string;
	}
>;

function EditFormFieldLabel({
	children,
	htmlFor,
	text,
	className,
	isRequired,
	labelTextProps,
	labelTextClassName,
	...props
}: EditFormFieldLabelProps): JSX.Element {
	return (
		<label
			htmlFor={htmlFor}
			className={classNames("edit-form-field-label", className)}
			{...props}
		>
			<div
				{...(labelTextProps || {})}
				className={classNames(
					"edit-form-field-label__text",
					labelTextClassName,
				)}
			>
				{text}
				{/*
				  Add the star here, instead of in css, so we can prevent screen
				  readers from reading it
				*/}
				{isRequired && <span aria-hidden>*</span>}
			</div>
			{children}
		</label>
	);
}

export default EditFormFieldLabel;
