import { createStateReducer } from "@xoev/state-container";
import type { ImmutableMap } from "@xoev/immutable-map";
import createImmutableMap from "@xoev/immutable-map";
import { mapRefIds } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/refIds";
import {
	deleteProfile,
	getProfile,
	cleanPropertyReferencesInProfile,
	setDatatype,
	editPropertyInProfile,
	adjustPropertyRefs,
	fixupProfileMap,
	fixupDatatypeMap,
	fixupDatatypeRestrictionReferences,
	diffSelection,
	deleteChildProfiles,
} from "./reducerHelpers";
import {
	selectProperties,
	selectDatatypeEntry,
	selectRestrictionProfile,
	selectRestrictionSelectionModel,
	selectVisibleDocs,
} from "./selectors";
import { subSelectHasZeroCardinality } from "./subSelectors";
import type {
	EditorReducerMap,
	MessageProfileValues,
	ProfilingNameType,
	StateProfileMap,
} from "./types";

const reorder = (
	list: (string | number)[],
	startIndex: number,
	endIndex: number,
) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

const updateProfile = (
	name: ProfilingNameType,
	value: string,
	profile?: ImmutableMap<Partial<MessageProfileValues>> | null,
) => {
	const newProfile = profile ?? createImmutableMap();
	switch (name) {
		case "limit":
		case "istRekursionsStart":
			return newProfile.setIn(["rekursion", "limit"], value);
		case "beschreibungRekursion":
			return newProfile.setIn(["rekursion", "beschreibung"], value);
		case "kennung":
		case "version":
			return newProfile.setIn(["konfiguration", "codeliste", name], value);
		case "fixedWert":
		case "defaultWert":
		case "beispielWert":
		case "beispielWertNachricht":
			return newProfile.setIn(["konfiguration", name], value);
		default:
			return newProfile.set(name as keyof MessageProfileValues, value);
	}
};
// Define a sub-reducer for every patch type here, that takes the current state
// and the applied patches and creates a new version of the state
export const stateReducerMap: EditorReducerMap = {
	setContainerState(_state, patch) {
		return { ...patch.payload };
	},
	setMetadataValue(state, patch) {
		let currentState = state;
		for (const entry of Object.entries(patch.payload)) {
			const [key, value] = entry as [keyof typeof patch.payload, string];
			currentState = currentState.setIn(["metadaten", key], value);
		}
		return currentState;
	},
	setMetadata(state, patch) {
		return state.set("metadaten", patch.payload);
	},
	setPartialProfile(state, patch) {
		const { id, values } = patch.payload;
		let currentState = state;
		for (const entry of Object.entries(values)) {
			const [key, value] = entry as [keyof typeof values, string];
			currentState = currentState.setIn(["profile", id, key], value);
		}
		return currentState;
	},
	setValueForProfile(state, patch) {
		const { id, name, value } = patch.payload;
		const currentProfile = state.getIn(["profile", id]);
		const newProfile = updateProfile(name, value, currentProfile);
		const shouldDeleteChildren =
			newProfile?.has("datentyp") || subSelectHasZeroCardinality(newProfile);
		const nextProfileMap = shouldDeleteChildren
			? deleteChildProfiles(state.get("profile").set(id, newProfile), id)
			: state.get("profile").set(id, newProfile);
		return state.set("profile", fixupProfileMap(nextProfileMap));
	},
	setProfile(state, patch) {
		const { id, profile } = patch.payload;
		const hasProfileZeroCardinality = subSelectHasZeroCardinality(profile);
		const nextProfileMap =
			profile.get("datentyp") || hasProfileZeroCardinality
				? deleteChildProfiles(state.get("profile").set(id, profile), id)
				: state.get("profile").set(id, profile);
		return state.set("profile", fixupProfileMap(nextProfileMap));
	},
	deleteProfile(state, patch) {
		const { id } = patch.payload;
		return state.deleteIn(["profile", id]);
	},
	deletePartialProfile(state, patch) {
		const { id, keys } = patch.payload;
		let profile = state.getIn(["profile", id]) || createImmutableMap();
		for (const key of keys) {
			profile = profile.delete(key);
		}
		return stateReducerMap.setProfile(state, {
			meta: patch.meta,
			type: "setProfile",
			payload: { id, profile },
		});
	},
	setDocumentation(state, patch) {
		const { documentation, name } = patch.payload;
		return state.setIn(["dokumentation", name], documentation);
	},
	changeDocumentTitle(state, patch) {
		const { oldTitle, newTitle } = patch.payload;
		const currentDoc = state
			.get("dokumentation")
			.get(oldTitle)
			?.set("name", newTitle);
		if (!currentDoc) return state;
		return state
			.setIn(["dokumentation", newTitle], currentDoc)
			.deleteIn(["dokumentation", oldTitle]);
	},
	deleteDocument(state, patch) {
		const { docTitle } = patch.payload;
		return state.deleteIn(["dokumentation", docTitle]);
	},
	reorderDocuments(state, patch) {
		const { fromIndex, toIndex } = patch.payload;
		const documentTitles = state
			.get("dokumentation")
			.filter((doc) => !doc.get("versteckt"))
			.sortBy((doc) => doc.get("index"))
			.keySeq()
			.toArray();
		const reordered = reorder(documentTitles, fromIndex, toIndex);
		let currentState = state;
		for (const [index, docTitle] of reordered.entries()) {
			currentState = currentState.setIn(
				["dokumentation", docTitle, "index"],
				index,
			);
		}
		return currentState;
	},
	addVisibleDocument(state, patch) {
		const { docTitle } = patch.payload;
		const index = selectVisibleDocs()(state).size;
		return state
			.setIn(["dokumentation", docTitle, "name"], docTitle)
			.setIn(["dokumentation", docTitle, "index"], index)
			.setIn(["dokumentation", docTitle, "inhalt"], `= ${docTitle}`)
			.setIn(["dokumentation", docTitle, "generiert"], false);
	},
	setDatatype(state, patch) {
		const { datatype, id } = patch.payload;
		let nextState = state.setIn(["datentypen", id], datatype);
		// Delete dangling references and adjust datatype names in references
		nextState = fixupDatatypeRestrictionReferences(state, nextState, id);
		const nextDatatypeMap = fixupDatatypeMap(nextState.get("datentypen"));
		const nextProfileMap = fixupProfileMap(nextState.get("profile"));
		return nextState
			.set("profile", nextProfileMap)
			.set("datentypen", nextDatatypeMap);
	},
	setRestrictionProfile(state, { meta, payload }) {
		const { datatypeId, restrictionId, restrictionProfileId, profile } =
			payload;
		const datatypeEntry = selectDatatypeEntry(datatypeId)(state);
		const newDatatype = (datatypeEntry || createImmutableMap()).setIn(
			["restrictions", restrictionId, "profile", restrictionProfileId],
			profile,
		);
		const profileMap = newDatatype.getIn([
			"restrictions",
			restrictionId,
			"profile",
		]);
		const hasProfileZeroCardinality = subSelectHasZeroCardinality(profile);
		const transformedDatatype =
			profile.get("datentyp") || hasProfileZeroCardinality
				? newDatatype.setIn(
						["restrictions", restrictionId, "profile"],
						deleteChildProfiles(
							profileMap as StateProfileMap,
							restrictionProfileId,
						),
				  )
				: newDatatype;

		return stateReducerMap.setDatatype(state, {
			meta,
			type: "setDatatype",
			payload: { id: datatypeId, datatype: transformedDatatype },
		});
	},
	setValueForRestrictionProfile(state, { meta, payload }) {
		const { datatypeId, restrictionId, restrictionProfileId, name, value } =
			payload;
		const datatypeEntry = selectDatatypeEntry(datatypeId)(state);

		const currentProfile = selectRestrictionProfile(
			datatypeId,
			restrictionId,
			restrictionProfileId,
		)(state);

		const newProfile = updateProfile(name, value, currentProfile);
		const newDatatype = (datatypeEntry || createImmutableMap()).setIn(
			["restrictions", restrictionId, "profile", restrictionProfileId],
			newProfile,
		);
		const profileMap = newDatatype.getIn([
			"restrictions",
			restrictionId,
			"profile",
		]);
		const hasProfileZeroCardinality = subSelectHasZeroCardinality(newProfile);
		const transformedDatatype =
			newProfile.get("datentyp") || hasProfileZeroCardinality
				? newDatatype.setIn(
						["restrictions", restrictionId, "profile"],
						deleteChildProfiles(
							profileMap as StateProfileMap,
							restrictionProfileId,
						),
				  )
				: newDatatype;

		return stateReducerMap.setDatatype(state, {
			meta,
			type: "setDatatype",
			payload: { id: datatypeId, datatype: transformedDatatype },
		});
	},
	deletePartialRestrictionProfile(state, patch) {
		const { datatypeId, restrictionId, restrictionProfileId, keys } =
			patch.payload;
		let profile =
			selectRestrictionProfile(
				datatypeId,
				restrictionId,
				restrictionProfileId,
			)(state) || createImmutableMap();
		for (const key of keys) {
			profile = profile.delete(key);
		}
		return stateReducerMap.setRestrictionProfile(state, {
			meta: patch.meta,
			type: "setRestrictionProfile",
			payload: { datatypeId, profile, restrictionId, restrictionProfileId },
		});
	},
	deleteRestrictionProfile(state, patch) {
		const { datatypeId, restrictionId, restrictionProfileId } = patch.payload;
		return state.deleteIn([
			"datentypen",
			datatypeId,
			"restrictions",
			restrictionId,
			"profile",
			restrictionProfileId,
		]);
	},
	changeRestrictionSelection(state, patch) {
		const { restrictionId, selection: nextSelection } = patch.payload;
		const prevSelection = selectRestrictionSelectionModel(restrictionId)(state);
		const { added, deleted } = diffSelection(prevSelection, nextSelection);

		let nextState = state;

		mapRefIds(added).forEach((item) => {
			const profile = getProfile(nextState, item);
			nextState = setDatatype(nextState, profile, item, restrictionId);
		});
		mapRefIds(deleted).forEach((item) => {
			nextState = deleteProfile(nextState, item);
		});

		const nextDatatypeMap = fixupDatatypeMap(nextState.get("datentypen"));
		const nextProfileMap = fixupProfileMap(nextState.get("profile"));
		return nextState
			.set("profile", nextProfileMap)
			.set("datentypen", nextDatatypeMap);
	},
	addProperty(state, patch) {
		const currentProps = selectProperties()(state) || [];
		return state.setIn(
			["konfiguration", "profilierung", "eigenschaften"],
			[...currentProps, patch.payload],
		);
	},
	deleteProperty(state, patch) {
		const { nameTechnisch } = patch.payload;
		const nextProps = (selectProperties()(state) || []).filter(
			(props) => props.nameTechnisch !== nameTechnisch,
		);
		let nextState = state;
		if (nextProps.length === 0) {
			nextState = state.deleteIn([
				"konfiguration",
				"profilierung",
				"eigenschaften",
			]);
		} else {
			nextState = state.setIn(
				["konfiguration", "profilierung", "eigenschaften"],
				nextProps,
			);
		}

		return adjustPropertyRefs({
			state: nextState,
			nameTechnisch,
			patchMeta: patch.meta,
			reducerMap: stateReducerMap,
			adjustProfile: (profile) =>
				cleanPropertyReferencesInProfile(nameTechnisch, profile),
		});
	},
	editProperty(state, patch) {
		const { nameTechnisch, values } = patch.payload;
		const currentProps = selectProperties()(state) || [];
		const nextProps = currentProps.map((prop) =>
			prop.nameTechnisch === nameTechnisch ? values : prop,
		);
		const nextState = state.setIn(
			["konfiguration", "profilierung", "eigenschaften"],
			nextProps,
		);
		// If we didn't rename the property, we can leave it at this. Otherwise,
		// we'll need to change the property key in all profiles, that reference
		// the changed property
		if (nameTechnisch === values.nameTechnisch) return nextState;

		return adjustPropertyRefs({
			state: nextState,
			nameTechnisch,
			patchMeta: patch.meta,
			reducerMap: stateReducerMap,
			adjustProfile: (profile) =>
				editPropertyInProfile(nameTechnisch, values, profile),
		});
	},
};

const editorStateReducer = createStateReducer(stateReducerMap);

export default editorStateReducer;
