import classNames from "classnames";
import type { ReactNode } from "react";
import type { ExtendProps, PropsOf } from "../../utils/types";
import { TableContainer } from "../ui";
import "./SelectionTableContainer.scss";

type SelectionTableContainerProps = ExtendProps<
	PropsOf<typeof TableContainer>,
	{ children: ReactNode; className?: string }
>;

export default function SelectionTableContainer({
	children,
	className,
	...props
}: SelectionTableContainerProps): JSX.Element {
	return (
		<TableContainer
			className={classNames("selection-table-container", className)}
			headerSurface="emphasized"
			{...props}
		>
			{children}
		</TableContainer>
	);
}
