const umlautReplacements: { [char: string]: string | undefined } = {
	ä: "ae",
	ö: "oe",
	ü: "ue",
	Ä: "Ae",
	Ö: "Oe",
	Ü: "Ue",
	ß: "ss",
};
const replacements: { [char: string]: string | undefined } = {
	...umlautReplacements,
	" ": "-",
};

const defaultTransform = (char: string) => char;

// eslint-disable-next-line import/prefer-default-export
export const normalizeString = (
	str = "",
	transformChar = defaultTransform,
): string => {
	let normalized = "";
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < str.length; i++) {
		const char = str.charAt(i);
		normalized += transformChar(replacements[char] || char);
	}
	return normalized;
};

/* TODO: Delete me (wird scheinbar nicht benutzt) */
// const validCharsRegex = /^[0-9a-zA-ZÄäÖöÜüß\-.]$/;
// const whitespaceRegex = /^\s$/;

// const parseStates = {
// 	INITIAL: "INITIAL",
// 	WHITESPACE: "WHITESPACE",
// 	VALID_CHAR: "VALID_CHAR",
// };
// export const normalizeKennungFragment = (str: string): string => {
// 	// Keep track of what type of character came before
// 	let state = parseStates.INITIAL;
// 	let normalized = "";

// 	[...str].forEach((char) => {
// 		// Valid characters are letters, '-' and '.'.
// 		// Umlauts will be replaced later.
// 		if (validCharsRegex.test(char)) {
// 			// If the previous character was whitespace insert a '-' now.
// 			// Adding the '-' here, instead of when encountering a whitespace char,
// 			// ensures that only one '-' will be added when a sequence of spaces or
// 			// invalid chars preceded the valid char.
// 			if (state === parseStates.WHITESPACE) {
// 				normalized += "-";
// 			}

// 			state = parseStates.VALID_CHAR;
// 			// Add the lower cased letter and replace possible umlaut.
// 			normalized += (umlautReplacements[char] || char).toLowerCase();
// 		} else if (
// 			// When a space or invalid char is at the begining of the string skip it.
// 			state !== parseStates.INITIAL &&
// 			// Else, if we encounter a white space char, set the state accordingly.
// 			// It will be replaced, when we hit the next valid character.
// 			// This way, we automatically skip multiple spaces.
// 			whitespaceRegex.test(char)
// 		) {
// 			state = parseStates.WHITESPACE;
// 		}
// 		// Skip invalid characters...
// 	});

// 	return normalized;
// };
