import { useSelector } from "@xstate/react";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import { getFromSystem } from "../../../../utils/machines";
import type { NotificationQueryRef } from "./notification.query.machine";
import { useSelectFromSystem } from "../../hooks";

export function getNotificationQueryFromSystem(
	system: AnyActorSystem,
): NotificationQueryRef {
	return getFromSystem(system, "notification:query");
}

export function useNotificationQueryRef(): NotificationQueryRef {
	return useSelectFromSystem((system) =>
		getNotificationQueryFromSystem(system),
	);
}

export function useAlerts() {
	const actorRef = useNotificationQueryRef();
	return useSelector(actorRef, ({ context }) => context.alerts);
}
