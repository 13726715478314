import EditFormField from "../../../../../EditFormField";
import type { FieldRendererComponentProps, ValueFields } from "../../types";
import ProfilingValidationDisplay from "../../../../../../AtomicComponents/Organisms/ProfilierungValidationDisplay";
import { ValidationTargetField } from "../../../../../Validation/types";
import { usePresetValues } from "../../fieldRendererHelpers";
import "./ValuesRenderer.scss";

const targetMapping = {
	beispielWert: ValidationTargetField.ExampleValue,
	defaultWert: ValidationTargetField.DefaultValue,
	fixedWert: ValidationTargetField.FixedValue,
} as const;

function ValuesRenderer({
	fields,
	activeNode,
	profile,
	label,
	onBlur,
	onFix,
}: FieldRendererComponentProps<ValueFields>): JSX.Element {
	const [definition] = fields;

	const { fixedWert } = usePresetValues(activeNode);

	const value =
		(profile?.get("konfiguration")?.get(definition.name) as string) || "";

	return (
		<div className="values-renderer">
			<EditFormField
				definition={{
					...definition,
					label,
					readOnly:
						definition.readOnly ||
						!!fixedWert ||
						(!!profile?.get("konfiguration")?.get("fixedWert") &&
							definition.name !== "fixedWert"),
				}}
				onBlur={onBlur}
				value={value}
				error={
					<ProfilingValidationDisplay
						targetField={targetMapping[definition.name]}
						nodeId={activeNode.id}
						value={value}
						onFix={onFix}
					/>
				}
			/>
		</div>
	);
}

export default ValuesRenderer;
