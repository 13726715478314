import { useCallback, useEffect } from "react";
import { useModellierungDatatypesAndGlobals } from "../../../../AppActor/actors/modellierungModel/hooks";
import type {
	LiteBaustein,
	LiteEigenschaft,
	LiteId,
} from "../../../../AppActor/actors/modellierungModel/schemas";
import {
	isLiteBaustein,
	isLiteDatatype,
	isLiteGlobaleEigenschaft,
	isLitePaket,
	isLiteSchema,
	LiteBausteinType,
} from "../../../../AppActor/actors/modellierungModel/schemas";
import type { LiteDatatypeEntry } from "../../../../AppActor/actors/modellierungModel/selectors";
import { selectNodeFromModell } from "../../../../AppActor/actors/modellierungModel/selectors";
import { useSendStoreEvent } from "../../../../AppActor/EventStore/hooks";
import type { EditRendererProps } from "../types";
import type { RefererKeys } from "./shared/helpers";
import {
	getReferersConfiguration,
	useReferencingEntries,
} from "./shared/helpers";
import ReferersDropdown from "./shared/ReferersDropdown";

export default function ReferersEdit({
	activeNode,
	isReadOnly,
	projekt,
}: EditRendererProps<LiteEigenschaft | LiteBaustein>): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();
	const getTypes = () => {
		if (activeNode.parent) {
			const parent = selectNodeFromModell(projekt.modell, activeNode.parent);
			if (parent && (isLitePaket(parent) || isLiteSchema(parent))) {
				return [LiteBausteinType.Datentyp];
			}
		}

		return [
			LiteBausteinType.Datentyp,
			LiteBausteinType.GlobaleEigenschaft,
			LiteBausteinType.CodeDatentyp,
		];
	};
	const datatypeOptions = useModellierungDatatypesAndGlobals(getTypes());
	const { currentReferer, currentKey } = useReferencingEntries(activeNode);

	const nodeId = activeNode.id;

	const { label, clearText, placeholder } = getReferersConfiguration(
		currentKey ?? "datentyp",
	);

	const sendChangeReference = useCallback(
		(ref: LiteId | null, targetKey: RefererKeys) => {
			sendStoreEvent({
				type: "MODELLIERUNG.MODELL.APPLY",
				payload: {
					projektId: projekt.id,
					patch: {
						type: "changeReference",
						payload: {
							ref,
							nodeId,
							targetKey,
						},
					},
				},
			});
		},
		[nodeId, projekt.id, sendStoreEvent],
	);

	const handleDropDownSelect = (
		_e: unknown,
		refererValue: LiteDatatypeEntry | null,
	) => {
		const getTargetKey = () => {
			if (refererValue?.id) {
				const targetNode = selectNodeFromModell(
					projekt.modell,
					refererValue.id,
				);
				if (targetNode && isLiteGlobaleEigenschaft(targetNode)) {
					return "referenz";
				}
			}
			if (isLiteDatatype(activeNode)) {
				return "basisDatentyp";
			}
			return activeNode.children.length > 0 ? "basisDatentyp" : "datentyp";
		};
		sendChangeReference(refererValue?.id ?? null, getTargetKey());
	};
	useEffect(() => {
		if (isLiteDatatype(activeNode)) {
			return;
		}
		/* Moves the Datentyp or Basisdatentyp to the other one */
		if (activeNode?.children.length > 0 && activeNode.datentyp) {
			sendChangeReference(activeNode.datentyp, "basisDatentyp");
		} else if (activeNode?.children.length === 0 && activeNode.basisDatentyp) {
			sendChangeReference(activeNode.basisDatentyp, "datentyp");
		}
	}, [
		activeNode,
		activeNode.basisDatentyp,
		activeNode?.children.length,
		activeNode.datentyp,
		sendChangeReference,
	]);

	const isDataAnonymous = () => {
		if (isLiteBaustein(activeNode) && isLiteDatatype(activeNode)) {
			return !activeNode.basisDatentyp;
		}
		return !activeNode.datentyp && !activeNode.basisDatentyp;
	};
	return (
		<ReferersDropdown
			onChange={handleDropDownSelect}
			clearTextHint={clearText}
			placeholderText={placeholder}
			labelText={label}
			value={currentReferer}
			activeNode={activeNode}
			isReadOnly={isReadOnly}
			isDataAnonymous={isDataAnonymous()}
			dropdownOptions={datatypeOptions}
		/>
	);
}
