import classNames from "classnames";
import React from "react";
import type { FooterProps } from "./types";
import styles from "./Footer.module.scss";
import ItPlanungsratLogo from "./ItPlanungsratLogo";

export default function Footer({
	children,
	className,
	...props
}: FooterProps): JSX.Element {
	return (
		<footer className={classNames(styles["xoev-footer"], className)} {...props}>
			<nav className={styles["xoev-footer-navigation"]}>{children}</nav>
			<a
				className={styles["xoev-footer-link"]}
				href="https://www.xoev.de"
				target="_blank"
				rel="noreferrer"
			>
				&copy; Koordinierungsstelle für IT-Standards
			</a>
			<div className={styles["xoev-footer-logo"]}>
				<ItPlanungsratLogo />
			</div>
		</footer>
	);
}
