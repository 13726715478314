import fileDownload from "js-file-download";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/FileDownload";
import DownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { useEffect } from "react";
import classNames from "classnames";
import { useResource } from "../../../Api";
import { createSaveData } from "../../../EditorData/helpers";
import { useStateContainerContext } from "../../../EditorState";
import type { PostRequestOptions } from "../../../Api/types";
import { RequestStatus, RequestMethods } from "../../../Api/types";
import type SavedProfileData from "../../../../types/SavedProfileData";
import useDelayedValue from "../../../../hooks/useDelayedValue";
import { getFileNameFromHeaders } from "../../../../utils/requests";
import { useAppSelector } from "../../../../redux/hooks";
import { selectConfiguration } from "../../../../redux/configuration/configurationSlice";
import "./DownloadPdfButton.scss";

const FALLBACK_FILENAME = "dokumentation.pdf";
const SUCCESS_DELAY = 3000;

function DownloadPdfButton(): JSX.Element {
	const {
		request,
		data: pdf,
		headers,
		status,
	} = useResource<Blob, RequestMethods.Post, SavedProfileData>();
	const { getState } = useStateContainerContext();
	const configuration = useAppSelector(selectConfiguration());

	const handleSave = () => {
		const data = createSaveData({ state: getState().value, configuration });

		const options: PostRequestOptions<SavedProfileData> = {
			method: RequestMethods.Post,
			requireSession: true,
			data,
			headers: {
				Accept: "application/pdf",
				"Content-Type": "application/json",
			},
		};

		request(`/v1/projekt/${data.metadaten.kennung}`, options);
	};

	useEffect(() => {
		if (pdf) {
			const filename = getFileNameFromHeaders(headers, FALLBACK_FILENAME);
			fileDownload(pdf, filename, "application/pdf");
		}
	}, [pdf, headers]);

	const isLoading = status === RequestStatus.Loading;
	const isSuccess = status === RequestStatus.Success;
	const delayedIsSuccess = useDelayedValue(isSuccess, SUCCESS_DELAY);
	const showSuccessIcon = isSuccess && !delayedIsSuccess;

	return (
		<div className="download-pdf-button">
			<Tooltip
				title={
					isLoading
						? "Dokumentation wird heruntergeladen, dies kann eine Weile dauern"
						: "Dokumentation als Pdf exportieren"
				}
			>
				{isLoading ? (
					<CircularProgress size="2rem" data-testid="save-spinner" />
				) : (
					<IconButton
						className="download-pdf-button__button"
						onClick={handleSave}
					>
						<div
							className={classNames(
								"download-pdf-button__icon-wrapper",
								showSuccessIcon && "download-pdf-button__icon-wrapper--active",
							)}
						>
							<DownloadDoneIcon />
						</div>
						<div
							className={classNames(
								"download-pdf-button__icon-wrapper",
								!showSuccessIcon && "download-pdf-button__icon-wrapper--active",
							)}
						>
							<DownloadIcon />
						</div>
					</IconButton>
				)}
			</Tooltip>
		</div>
	);
}

export default DownloadPdfButton;
