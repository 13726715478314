import type { ActorRefFrom } from "xstate";
import { assign, setup, stopChild } from "xstate";
import { assertEvent } from "../../../../utils/machines";
import type { EventTranslationMap } from "../../EventStore/helpers";
import { translateStoreEvents } from "../../EventStore/helpers";
import type { ProjectActorRef } from "../project/project.machine";
import projectMachine from "../project/project.machine";
import type { ProjektMeta } from "../project/types";
import type { ProjektId } from "../modellierungModel/schemas";

// eslint-disable-next-line no-use-before-define
export type ProjectsQueryActorRef = ActorRefFrom<typeof projectsQueryMachine>;
export type ProjectsQueryContext = {
	projects: { [projektId: ProjektId]: ProjectActorRef };
};
export type ProjectsQueryEvent =
	| { type: "ADD_EXISTING_PROJECT"; projektMeta: ProjektMeta }
	| { type: "CLOSE_PROJECT"; projektId: ProjektId };

const eventMap: EventTranslationMap<ProjectsQueryEvent> = {
	"PROJECT.CLOSE": ({ payload }) => ({
		type: "CLOSE_PROJECT",
		projektId: payload.projektId,
	}),
	"PROJECT.SPAWN": ({ payload }) => ({
		type: "ADD_EXISTING_PROJECT",
		projektMeta: payload.projektMeta,
	}),
};

const projectsQueryMachine = setup({
	types: {
		events: {} as ProjectsQueryEvent,
		context: {} as ProjectsQueryContext,
	},
	actors: {
		translateEvents: translateStoreEvents<ProjectsQueryEvent>(eventMap, {
			replayEventLog: true,
		}),
	},
	actions: {
		addExistingProject: assign({
			projects: ({ context, spawn, event }) => {
				assertEvent(event, "ADD_EXISTING_PROJECT");
				const { projektMeta } = event;
				const newProject = spawn(projectMachine, {
					systemId: projektMeta.projektId,
					input: projektMeta,
				});
				return { ...context.projects, [projektMeta.projektId]: newProject };
			},
		}),
		closeProject: assign({
			projects: ({ context, event }) => {
				assertEvent(event, "CLOSE_PROJECT");
				const { projektId } = event;
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { [projektId]: _omit, ...projects } = context.projects;
				return projects;
			},
		}),
		stopActor: stopChild(({ context, event }) => {
			assertEvent(event, "CLOSE_PROJECT");
			return context.projects[event.projektId];
		}),
	},
}).createMachine({
	id: "projects:query",
	context: {
		projects: {},
	},
	invoke: { src: "translateEvents" },
	on: {
		CLOSE_PROJECT: {
			actions: ["stopActor", "closeProject"],
		},
		ADD_EXISTING_PROJECT: {
			actions: ["addExistingProject"],
		},
	},
});

export default projectsQueryMachine;
