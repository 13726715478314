import classNames from "classnames";
import type { FormEventHandler } from "react";
import { useMemo } from "react";
import { useEventHandler } from "../../../hooks";
import { normalizeUmlauts } from "../../../utils/misc";
import MetadataFormContext from "./MetadataFormContext";
import type {
	MetadataFormChangeHandler,
	MetadataFormProps,
	MetadataFormWriteHandler,
} from "./types";
import MetadataFormFields from "./MetadataFormFields";
import "./MetadataForm.scss";

const AUTO_REPLACE_KEYS = ["nameKurz", "herausgeber", "version"];

function createValue(name: string, value: string) {
	if (AUTO_REPLACE_KEYS.includes(name)) return normalizeUmlauts(value);
	return value;
}

function MetadataForm({
	values,
	onWrite,
	onChange,
	className,
	disabled = false,
	children,
	...props
}: MetadataFormProps): JSX.Element {
	const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
	};

	const handleChange: MetadataFormChangeHandler = useEventHandler((e) => {
		onChange?.(e);
	});

	const handleWrite: MetadataFormWriteHandler = useEventHandler(
		(name, value) => {
			const nextValue = createValue(name, value);
			onWrite(name, nextValue);
		},
	);

	const ctx = useMemo(
		() => ({ handleWrite, disabled, values, handleChange }),
		[disabled, handleWrite, handleChange, values],
	);

	return (
		<MetadataFormContext.Provider value={ctx}>
			<form
				data-testid="metadata-form"
				className={classNames("metadata-form", className)}
				onSubmit={handleSubmit}
				aria-label="Metadaten zum Projekt"
				{...props}
			>
				{children}
			</form>
		</MetadataFormContext.Provider>
	);
}

MetadataForm.Fields = MetadataFormFields;

export default MetadataForm;
