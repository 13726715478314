import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import type { Seq } from "immutable";
import { Link } from "react-router-dom";
import { encodeXPath } from "../../utils/url";
import { InvalidRefTypeError } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/refIds";
import { RefType } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/types";
import type { ProfileRef } from "../EditorState/types";

function createKey(ref: ProfileRef) {
	return ref.type === RefType.Datatype
		? `${ref.restrictionId}-${ref.profileId}`
		: ref.profileId;
}
function createLabel(ref: ProfileRef) {
	if (ref.type === RefType.MessageElement) {
		return ref.profileId;
	}
	if (ref.type === RefType.Datatype) {
		return `${ref.restrictionName || ref.restrictionId} (${ref.datatypeId}) → ${
			ref.profileId
		}`;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	throw new InvalidRefTypeError((ref as any).type);
}
function createLinkPath(ref: ProfileRef) {
	if (ref.type === RefType.MessageElement) {
		return `/profilierung/nachrichtenstrukturen/${encodeXPath(ref.profileId)}`;
	}
	if (ref.type === RefType.Datatype) {
		return `/profilierung/datentypen/${encodeXPath(ref.datatypeId)}/profil/${
			ref.restrictionId
		}/${encodeXPath(ref.profileId)}`;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	throw new InvalidRefTypeError((ref as any).type);
}

export default function RefTable({
	refs,
}: {
	refs: Seq.Indexed<ProfileRef>;
}): JSX.Element {
	return (
		<TableContainer sx={{ margin: "1rem 0", maxHeight: "25rem" }}>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell>Referenziert in</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{refs.map((ref) => (
						<TableRow key={createKey(ref)}>
							<TableCell data-testid="referenced-attribute">
								<Link
									data-testid="ref-table-link"
									data-ref-type={ref.type}
									data-ref-profile={ref.profileId}
									data-restriction={
										ref.type === RefType.Datatype
											? ref.restrictionName
											: undefined
									}
									data-datatype={
										ref.type === RefType.Datatype ? ref.datatypeId : undefined
									}
									to={createLinkPath(ref)}
								>
									{createLabel(ref)}
								</Link>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
