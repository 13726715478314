import { Tooltip } from "@mui/material";
import NodeIcon from "../../NodeIcon";
import { VisuallyHidden } from "../../ui";
import "./ChoiceIcon.scss";
import GruppenartAuswahlIcon from "../../../resources/icons/GruppenartAuswahlIcon";

/* TODO: add to translations */
const ChoiceIcon = (): JSX.Element => {
	return (
		<Tooltip title="Das Element enthält eine Auswahl">
			<NodeIcon className="choice-icon" data-testid="choice-icon">
				<VisuallyHidden>Information: Das Element eine Auswahl</VisuallyHidden>
				<GruppenartAuswahlIcon />
			</NodeIcon>
		</Tooltip>
	);
};

export default ChoiceIcon;
