import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import type { SnapshotFrom } from "xstate";
import type {
	ModellierungSearchQueryActorRef,
	ModellierungSearchQueryContext,
} from "./modellierungSearch.query.machine";
import { useSelectFromSystem } from "../../../hooks";
import { useActiveProjectId } from "../../navigation/hooks";
import {
	getFromSystem,
	useActorRefSelector,
} from "../../../../../utils/machines";
import type { LiteSearchTrie } from "./helpers";
import { EMPTY_TRIE, createQueryId } from "./helpers";
import type { LiteId, ProjektId } from "../schemas";

export function getSearchQueryRef(
	system: AnyActorSystem,
	projektId: ProjektId | null | undefined,
): ModellierungSearchQueryActorRef | null {
	if (!projektId) return null;
	return getFromSystem(system, createQueryId(projektId));
}

export function useSearchQueryRef(
	projektId: ProjektId | null | undefined,
): ModellierungSearchQueryActorRef | null {
	return useSelectFromSystem((system) => getSearchQueryRef(system, projektId));
}

export function useSearchSnapshotSelectorById<T>(
	projektId: ProjektId | null | undefined,
	selector: (snapshot: SnapshotFrom<ModellierungSearchQueryActorRef>) => T,
): T | null {
	const ref = useSearchQueryRef(projektId);
	return useActorRefSelector(ref, selector);
}

export function useSearchSnapshotSelector<T>(
	selector: (snapshot: SnapshotFrom<ModellierungSearchQueryActorRef>) => T,
): T | null {
	const id = useActiveProjectId();
	return useSearchSnapshotSelectorById(id, selector);
}

export function useSearchSelectorById<T>(
	projektId: ProjektId | null | undefined,
	selector: (context: ModellierungSearchQueryContext) => T,
): T | null {
	return useSearchSnapshotSelectorById(projektId, ({ context }) =>
		selector(context),
	);
}

export function useSearchSelector<T>(
	selector: (context: ModellierungSearchQueryContext) => T,
): T | null {
	const id = useActiveProjectId();
	return useSearchSelectorById(id, selector);
}

export function useIsSearchLoading(): boolean {
	return (
		useSearchSnapshotSelector((snapshot) =>
			snapshot.matches({ Searching: "Loading" }),
		) || false
	);
}

export function useIsSearchActive(): boolean {
	return (
		useSearchSnapshotSelector((snapshot) => snapshot.matches("Searching")) ||
		false
	);
}

export function useActiveSearchPath(): LiteId[] | null {
	return (
		useSearchSelector((context) => context.result.paths.at(context.index)) ||
		null
	);
}

export function useSearchTrie(): LiteSearchTrie {
	return useSearchSelector((context) => context.trie) || EMPTY_TRIE;
}

export function useLatestSearchTerm(): string {
	return useSearchSelector((context) => context.term) || "";
}
