import { useState } from "react";
import UploadDialog from "../UploadDialog";
import UploadButton from "../UploadButton";

const UploadProject = (): JSX.Element => {
	const [isUploadOpen, setIsUploadOpen] = useState(false);

	const handleUploadClick = () => {
		setIsUploadOpen(true);
	};
	return (
		<div className="startview-project">
			<UploadDialog isOpen={isUploadOpen} setIsOpen={setIsUploadOpen} />
			<UploadButton onClick={handleUploadClick} />
		</div>
	);
};

export default UploadProject;
