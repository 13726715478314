import type { ExpansionTree } from "../../../Tree";
import { closePath, createExpansionState, openPath } from "../../../Tree";
import {
	createSelectDirectChildrenFromModell,
	createSelectNodesByPathFromModell,
	selectNodeFromModell,
	selectRootModellFromModell,
} from "../modellierungModel/selectors";
import {
	isLiteEigenschaft,
	LiteIdListSchema,
} from "../modellierungModel/schemas";
import type { ProjektId, LiteId } from "../modellierungModel/schemas";
import { memoize } from "../../../../utils/memoization";
import { joinIdSegments } from "../../../../utils/xoev";
import type { LiteModellContainer } from "../modellierungModel/types";

export interface TreePathVariants {
	modellPath: LiteId[];
	basePath: LiteId[];
	fullPath: LiteId[];
	fullBausteinPath: LiteId[];
	bausteinPath: LiteId[];
	structurePath: LiteId[];
}

export interface LiteNodeLocator {
	expansionState: ExpansionTree<LiteId>;
}

export const EMPTY_EXPANSION_STATE = createExpansionState<LiteId>([]);

export const createQueryId = (projektId: ProjektId) =>
	`treeState:query:${projektId}`;
export const createCommandId = (projektId: ProjektId) =>
	`treeState:command:${projektId}`;

export function openNode(view: LiteNodeLocator, path: LiteId[]) {
	const expansionState = openPath(view.expansionState, path);
	return { ...view, expansionState };
}

export function closeNode(view: LiteNodeLocator, path: LiteId[]) {
	const expansionState = closePath(view.expansionState, path);
	return { ...view, expansionState };
}

/**
 * Extract the structure tree path from the full path, which starts at the root
 * model and leads all the way to the target node. The structure path is the
 * slice of that path, that is displayed in the structure tree.
 */
export function getStructureTreePath(
	modell: LiteModellContainer,
	fullPath: LiteId[],
) {
	const nodePath = createSelectNodesByPathFromModell(modell)(fullPath);
	if (!nodePath) return [];
	// The structure view shows all child nodes of baustein nodes, including the
	// baustein node itself. Baustein nodes only have eigenschaften nodes as
	// children. The split between the full tree path and the structure tree path
	// is at the last baustein node, that is followed by an eigenschaft node.
	// However, paket nodes or baustein nodes without child eigenschaft nodes may
	// exist, so we have also found the split, should  no next node exist
	const parentIndex = nodePath.findIndex((node, index) => {
		// Eigenschaften nodes cannot be root nodes in the structure tree
		if (isLiteEigenschaft(node)) return false;
		// If there is no next node, we have found the split
		const nextNode = nodePath[index + 1];
		if (!nextNode) return true;
		// If there is a next node, we have found the split, should the next node
		// be an eigenschaft node
		return isLiteEigenschaft(nextNode);
	});
	return fullPath.slice(parentIndex);
}

/**
 * Extract the baustein tree path from the full path, which starts at the root
 * model and leads all the way to the target node. The baustein path is the
 * slice of that path, that is displayed in the baustein tree.
 */
export function getBausteinTreePath(basePath: LiteId[], fullPath: LiteId[]) {
	return fullPath.slice(basePath.length);
}

export function getFullBausteinTreePath(
	basePath: LiteId[],
	fullPath: LiteId[],
	structurePath: LiteId[],
) {
	const bausteinAndStructurePath = fullPath.slice(basePath.length);
	const splitIndex = bausteinAndStructurePath.findIndex(
		(segment) => segment === structurePath[0],
	);
	return bausteinAndStructurePath.slice(0, splitIndex + 1);
}

/**
 * If the `modellPath` is empty or does not point to existing nodes, fall back
 * to a path pointing to the first node in the visible tree
 */
function normalizeModellPath(
	modell: LiteModellContainer,
	modellPath: LiteId[],
) {
	const filteredModellPath = modellPath.filter(
		(id) => !!selectNodeFromModell(modell, id),
	);
	if (filteredModellPath.length === 0) {
		const rootNode = selectRootModellFromModell(modell);
		const firstChild =
			createSelectDirectChildrenFromModell(modell)(rootNode).at(0);
		if (firstChild) {
			return [firstChild.id];
		}
	}
	return modellPath;
}

export const getTreePathVariants = memoize(
	(modell: LiteModellContainer, modellPath: LiteId[]): TreePathVariants => {
		const filteredModellPath = normalizeModellPath(modell, modellPath);
		const basePath = [modell.rootModelId];
		const fullPath = [...basePath, ...filteredModellPath].filter(
			(nodeId) => !!selectNodeFromModell(modell, nodeId),
		);
		const structurePath = getStructureTreePath(modell, fullPath);
		const bausteinPath = getFullBausteinTreePath(
			basePath,
			fullPath,
			structurePath,
		);
		const fullBausteinPath = [...basePath, ...bausteinPath];
		return {
			modellPath: LiteIdListSchema.parse(filteredModellPath),
			basePath,
			fullPath,
			fullBausteinPath,
			bausteinPath,
			structurePath,
		};
	},
	{
		getCacheKeys: (projekt, modellPath) => [
			projekt,
			joinIdSegments(modellPath),
		],
		cacheSize: 64,
	},
);
