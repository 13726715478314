import { useMemo } from "react";
import { useMatch, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { selectModellContainer, selectRootNode } from "../../redux/treeSlice";
import { decodeXPath } from "../../utils/url";
import { useStateSelector } from "../EditorState";
import { selectStandard } from "../EditorState/selectors";
import type { TreeNodeType } from "../../types/ProfilierungHome";
import useTree from "../../hooks/useProfilierungTree";
import {
	parseQNamePath,
	QNamePathSchema,
} from "../AppActor/actors/modellierungModel/schemas";
import type { LiteId } from "../AppActor/actors/modellierungModel/schemas";
import {
	selectIdPathFromQNamePathFromModell,
	selectNodeFromModell,
	selectQNameFromModell,
} from "../AppActor/actors/modellierungModel/selectors";
import type { Nullish } from "../../utils/types";

export interface ActiveNodeInfo {
	activePath: LiteId[];
	activeNode: TreeNodeType;
	didFindMatch: boolean;
	isFallback: boolean;
}

export function useActiveNodeBase(
	xpathParam: string,
	treeRoot: Nullish<LiteId>,
	standard: Nullish<string>,
): ActiveNodeInfo {
	const modell = useAppSelector(selectModellContainer(standard));
	const { tree, isFallback } = useTree(standard, treeRoot);
	const value = useMemo(() => {
		const qnamePath = parseQNamePath(
			QNamePathSchema.parse(decodeXPath(xpathParam)),
		);
		const path = selectIdPathFromQNamePathFromModell(modell, qnamePath);
		const nodeId = path.at(-1);
		const node = nodeId && modell ? selectNodeFromModell(modell, nodeId) : null;
		const activeNode = node || tree;
		const treeQNamePath = modell ? [selectQNameFromModell(modell, tree)] : [];
		const treePath = selectIdPathFromQNamePathFromModell(modell, treeQNamePath);
		const activePath = node ? path : treePath;
		return { activePath, activeNode, didFindMatch: !!node, isFallback };
	}, [isFallback, modell, tree, xpathParam]);
	return value;
}

export function useActiveNode(): ActiveNodeInfo {
	const { "*": xpathParam = "" } = useParams();
	const standard = useStateSelector(selectStandard());
	const root = useAppSelector(selectRootNode(standard));
	return useActiveNodeBase(xpathParam, root?.id, standard);
}

// Cut off the path before the first baustein, so we always start at the root
// of the tree
function createActiveSubtreePath(
	rootId: Nullish<LiteId>,
	activePath: LiteId[],
) {
	const i = activePath.findIndex((id) => rootId === id);
	if (i === -1) return activePath;
	return activePath.slice(i);
}

export function useActiveSubtreeNode(
	rootId: Nullish<LiteId>,
	pathParam: string,
): ActiveNodeInfo {
	const standard = useStateSelector(selectStandard());
	const activeNodeState = useActiveNodeBase(pathParam, rootId, standard);
	return useMemo(
		() => ({
			...activeNodeState,
			activePath: createActiveSubtreePath(rootId, activeNodeState.activePath),
		}),
		[activeNodeState, rootId],
	);
}

export function useActiveStandardNode(
	rootId: LiteId | undefined,
): ActiveNodeInfo {
	const match = useMatch(
		"/profilierung/datentypen/:parent/standard/:pathParam",
	);
	const { pathParam = "" } = match?.params || {};
	return useActiveSubtreeNode(rootId, pathParam);
}
