import { useCallback, useEffect, useRef, useState } from "react";
import type { GridColDef, GridRowModel } from "@mui/x-data-grid";
import { DataGrid, deDE } from "@mui/x-data-grid";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import BorderTopIcon from "@mui/icons-material/BorderTop";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableContainer } from "../../../../components/ui";
import RequestErrorNotification from "../../../../components/RequestErrorNotification";
import useStartView from "../../hooks/useStartView";
import { RequestStatus } from "../../../../components/Api";
import AdocRenderer from "../../../../components/AdocRenderer";
import HomeSubMenu from "../../../../components/Menu/HomeSubMenu";
import type { ApiProjekt } from "../../../../components/AppActor/actors/modellierungModel/schemas";
import { ProjektType } from "../../../../components/AppActor/actors/modellierungModel/schemas";
import {
	useEventStoreRef,
	useSendStoreEvent,
} from "../../../../components/AppActor/EventStore/hooks";
import { useProjectList } from "../../../../components/AppActor/actors/projects/hooks";
import {
	useDeleteProjectMutation,
	useGetProjekteQuery,
} from "../../../../redux/apiSlice";
import { markup } from "../../../../resources/textConstants/markup.json";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import "./StartView.scss";
import CustomGridToolbarContainer from "../CustomGridToolbarContainer";
import CustomGridFilterPanel from "../CustomGridFilterPanel";
import StandardIcon from "../../../../resources/icons/StandardIcon";
import ProfilIcon from "../../../../resources/icons/ProfilIcon";
import { ModellierungIcon } from "../../../../resources/iconMaps/icons";

/* TODO: create icon map */
const renderTypIcon = (typ: ProjektType) => {
	switch (typ) {
		case ProjektType.Modellierung:
			return <ModellierungIcon sx={{ fill: "#FF7923" }} />;
		// TODO Which icon belongs to Genericode? Not used at the moment.
		case ProjektType.Genericode:
			return <BorderTopIcon />;
		case ProjektType.Profilierung:
			return <ProfilIcon sx={{ fill: "#FFC300" }} />;
		case ProjektType.Standard:
			return <StandardIcon sx={{ fill: "#FF7923" }} />;
		default:
			return typ;
	}
};

const RowMenu = ({ row }: { row: GridRowModel<ApiProjekt> }) => {
	const iconButton = useRef<HTMLButtonElement>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	// const [duplicateProject, { isLoading: isDuplicating }] =
	// 	useDuplicateProjectMutation();
	const [deleteProject, { isLoading: isDeleting }] = useDeleteProjectMutation();
	const { refetch } = useGetProjekteQuery();
	const isStandard = row.typ === ProjektType.Standard;
	const send = useSendStoreEvent();
	const projects = useProjectList();
	const [confirmOpen, setConfirmOpen] = useState(false);

	// const handleDuplicate = useCallback(async () => {
	// 	await duplicateProject({
	// 		id: row.id,
	// 		nameKurz: row.nameKurz,
	// 		version: row.version,
	// 		kennung: row.kennung,
	// 		typ: ProjektType.Modellierung,
	// 	});
	// 	await refetch();
	// }, [duplicateProject, row, refetch]);

	const promptDeletion = useCallback(() => {
		setConfirmOpen(true);
		setMenuOpen(false);
	}, [setConfirmOpen]);

	const handleDelete = useCallback(async () => {
		const projectTab = projects.find((project) => project.data.dbId === row.id);

		if (projectTab) {
			send({
				type: "PROJECT.CLOSE",
				payload: { projektId: projectTab.projektId },
			});
		}

		await deleteProject(row.id);
		await refetch();
	}, [deleteProject, row, refetch, projects, send]);

	const closeMenu = useCallback(() => {
		setMenuOpen(false);
	}, [setMenuOpen]);

	return (
		<div data-role="row-context-menu-button">
			<IconButton ref={iconButton} onClick={() => setMenuOpen(!menuOpen)}>
				<MoreVertIcon />
			</IconButton>
			<Menu anchorEl={iconButton.current} open={menuOpen} onClose={closeMenu}>
				{/* <MenuItem onClick={handleDuplicate} disabled={isDuplicating}>
					Als neues Projekt kopieren
				</MenuItem> */}
				<MenuItem
					style={{ backgroundColor: "var(--red-0)", color: "var(--red-4)" }}
					onClick={promptDeletion}
					disabled={isDeleting || isStandard}
				>
					Projekt löschen
				</MenuItem>
			</Menu>
			<ConfirmDialog
				isOpen={confirmOpen}
				title={`Sind Sie sicher, dass Sie das Projekt "${row.nameKurz}" löschen möchten?`}
				description="Dieser Vorgang kann nicht rückgängig gemacht werden."
				onAccept={handleDelete}
				onDismiss={() => setConfirmOpen(false)}
			/>
		</div>
	);
};

const columns: GridColDef<ApiProjekt>[] = [
	{
		field: "typ",
		headerName: "Typ",
		renderCell: (field) => {
			return (
				<Tooltip title={field.value}>{renderTypIcon(field.value)}</Tooltip>
			);
		},
	},
	{
		field: "nameKurz",
		headerName: "Standard",
		flex: 1,
	},
	{
		field: "version",
		headerName: "Version",
		width: 500,
	},
	{
		field: "menu",
		headerName: "Menü",
		width: 150,
		editable: true,
		renderCell: ({ row }) => {
			return <RowMenu row={row} />;
		},
		filterable: false,
	},
];

export default function StartView(): JSX.Element {
	const eventStore = useEventStoreRef();
	const {
		rows,
		requestError,
		status,
		pageSize,
		handlePageSizeChange,
		handleSelectProject,
	} = useStartView();

	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		const sub = eventStore.subscribe((snapshot) => {
			const event = snapshot.context.eventLog.last(null)?.event;
			if (
				event &&
				(event.type === "PROJECT.OPEN_EXISTING.SUCCESS" ||
					event.type === "PROJECT.OPEN_EXISTING.FAILURE")
			) {
				setLoading(false);
			}
		});
		return sub.unsubscribe;
	}, [eventStore]);

	return (
		<>
			<HomeSubMenu />
			<main id="main" className="start-view">
				<RequestErrorNotification
					id="request-all-standards-error"
					error={requestError}
					status={status}
				>
					Beim Abrufen der Liste von Standards ist ein Fehler aufgetreten. Bitte
					versuchen Sie es erneut.
					<br />
					<br />
					Sollte der Fehler weiterhin auftreten wenden Sie sich an die
					Administrator:innen.
				</RequestErrorNotification>
				<AdocRenderer
					className="start-view__adoc"
					markup={markup.pages.start.introduction}
				/>
				<TableContainer className="start-view__table">
					<DataGrid
						loading={status === RequestStatus.Loading || isLoading}
						columns={columns}
						rows={rows}
						rowsPerPageOptions={[10, 25, 50]}
						pageSize={pageSize}
						onPageSizeChange={handlePageSizeChange}
						pagination
						localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
						components={{
							Toolbar: CustomGridToolbarContainer,
							FilterPanel: CustomGridFilterPanel,
						}}
						onRowClick={(row, event) => {
							const menuWrappers = document.querySelectorAll(
								"[data-role=row-context-menu-button]",
							);
							const isMenuButton = Array.from(menuWrappers).some(
								(menuWrapper) =>
									menuWrapper.contains(event.nativeEvent.target as Node),
							);

							if (!isMenuButton) {
								const project = rows.find((r) => r.id === row.id);

								if (project) {
									setLoading(true);
									handleSelectProject(project);
								}
							}
						}}
						disableColumnSelector
						disableDensitySelector
						sx={{
							// disable cell selection style
							".MuiDataGrid-cell:focus": {
								outline: "none",
							},
							// pointer cursor on ALL rows
							"& .MuiDataGrid-row:hover": {
								cursor: "pointer",
							},
						}}
						autoHeight
					/>
				</TableContainer>
			</main>
		</>
	);
}
