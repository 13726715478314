import { Tooltip } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { placeholder } from "../../resources/textConstants";
import {
	selectIsDatatypeFromModell,
	selectNodesFromModell,
} from "../../redux/treeSlice";
import { encodeXPath } from "../../utils/url";
import { stringifyRefId } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/refIds";
import type {
	SpecificRefItem,
	LinkSegment,
	CellParams,
	RefItem,
} from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/types";
import { RefType } from "../DatatypesView/DetailsView/RestrictionView/RestrictionEditView/RestrictionEditForm/types";
import { GridCellExpand } from "../ui/mui";
import type { Nullish } from "../../utils/types";
import type { LiteModellContainer } from "../AppActor/actors/modellierungModel/types";
import {
	isLiteBaustein,
	parseQNamePath,
	type LiteId,
	joinQNamePath,
} from "../AppActor/actors/modellierungModel/schemas";
import { filterFalsy } from "../../utils/lists";
import {
	selectNodeFromModell,
	selectQNameFromModell,
	selectQNamePathFromModell,
} from "../AppActor/actors/modellierungModel/selectors";
import { AssertionError } from "../../utils/error";

export const createGetType =
	(standard: Nullish<string>, modell: Nullish<LiteModellContainer>) =>
	(rootId: Nullish<LiteId>) => {
		return selectIsDatatypeFromModell(modell, standard, rootId)
			? RefType.Datatype
			: RefType.MessageElement;
	};

export function getLinkSegments(
	row: SpecificRefItem,
	basePath: string | ((row: SpecificRefItem) => string),
) {
	const base = typeof basePath === "function" ? basePath(row) : basePath;
	const mappedSegments: LinkSegment[] = parseQNamePath(row.qnamePath).map(
		(segment, i, segments) => {
			// The path always consists of all previous paths, separated by a "/",
			// followed by the own segment. The `end` parameter to slice is exclusive,
			// so if we want to have an array that contains all elements, including the
			// nth element, we need to call `.slice(0, n + 1)`
			const path = joinQNamePath(segments.slice(0, i + 1));
			return {
				segment,
				label: segment,
				path,
				to: `${base}/${encodeXPath(path)}`,
			};
		},
	);
	if (row.type === RefType.Datatype) {
		if (row.restrictionId) {
			const title = `Einschränkung von ${row.rootQName}`;
			mappedSegments[0].label = (
				<Tooltip title={title}>
					<span>{row.restrictionName}</span>
				</Tooltip>
			);
		} else {
			mappedSegments[0].to = `${base}/${encodeXPath(
				mappedSegments[0].segment,
			)}`;
		}
	}
	return mappedSegments;
}

export function generateCellLinks(
	item: SpecificRefItem,
	{
		renderLeadingSlash = true,
		basePath,
	}: {
		renderLeadingSlash?: boolean;
		basePath: string | ((row: SpecificRefItem) => string);
	} = { basePath: "" },
) {
	const linkSegments = getLinkSegments(item, basePath);
	return linkSegments.map(({ segment, label, path, to }, i) => (
		<Fragment key={path}>
			{(renderLeadingSlash || i > 0) && " / "}
			<Link data-testid="segment-link" data-segment={segment} to={to}>
				{label}
			</Link>
		</Fragment>
	));
}

export function createBaseRefs(
	modell: LiteModellContainer,
	usedBy: LiteId[][] | undefined,
	getType: (rootId: Nullish<LiteId>) => RefType,
): RefItem[] {
	if (!usedBy || !getType) return [];
	return usedBy.map((idPath) => {
		const hierachy = filterFalsy(selectNodesFromModell(modell, idPath));
		const rootBaustein = hierachy.find((node) => isLiteBaustein(node));
		AssertionError.notNullish(
			rootBaustein,
			"There must be a root baustein node",
		);
		const rootQName = selectQNameFromModell(modell, rootBaustein);
		const type = getType(rootBaustein.id);
		const nodeId = idPath.at(-1);
		const node = nodeId && selectNodeFromModell(modell, nodeId);
		AssertionError.notNullish(node, "There must be a node");
		const elementName = node.name || placeholder.anonymousStructure;
		const qnamePath = selectQNamePathFromModell(modell, idPath);
		return {
			id: stringifyRefId({
				type: type as RefType.MessageElement,
				qnamePath,
				idPath,
			}),
			qnamePath,
			idPath,
			rootQName,
			elementName,
			type,
		};
	});
}

export const createRenderElementname =
	(basePath: string | ((row: SpecificRefItem) => string)) =>
	// eslint-disable-next-line react/display-name
	(params: CellParams) => {
		const { row, colDef } = params;
		const fullSegment = getLinkSegments(row, basePath).pop();
		if (!fullSegment) return null;
		const { to } = fullSegment;
		const label = row.elementName;
		const link = (
			<Link
				to={to}
				data-testid="element-name-link"
				data-node-qname={row.qnamePath}
			>
				{label}
			</Link>
		);
		return <GridCellExpand value={link} width={colDef.computedWidth} />;
	};

export const createRenderSyntaxpfad =
	(basePath: string | ((row: SpecificRefItem) => string)) =>
	// eslint-disable-next-line react/display-name
	(params: CellParams) => {
		const { row, colDef } = params;
		const cellLinks = generateCellLinks(row, { basePath });
		return <GridCellExpand value={cellLinks} width={colDef.computedWidth} />;
	};
