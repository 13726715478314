import EditorNavBar from "../../../../components/EditorNavBar";
import { useProjectBreadcrumb } from "../../../../components/EditorNavBar/NavBarBreadcrumbs/breadCrumbs";
import NavBarBreadcrumbs from "../../../../components/EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";

const ProfilierungHomeNavBar = (): JSX.Element => {
	const projectBreadcrumb = useProjectBreadcrumb();
	const breadcrumbs = [projectBreadcrumb];
	return (
		<EditorNavBar data-testid="profiling-home-navbar">
			<NavBarBreadcrumbs items={breadcrumbs} />
		</EditorNavBar>
	);
};

export default ProfilierungHomeNavBar;
