import type { ReactNode } from "react";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import WarningIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import type ProfilingMetadataValues from "../../types/ProflierungMetadataValues";
import { objectFromEntries } from "../../utils/object";
import type {
	AutoFixValue,
	DefaultAutoFixTargetFields,
	FixerFn,
	MetadataValidationResult,
	ProfileValidationResult,
	RestrictionProfileValidationResult,
	RestrictionValidationResult,
	ValidationResultBase,
	ValidationRunResult,
} from "./types";
import {
	SkipAutoFixSymbol,
	ValidationTargetField,
	ValidationResultGroup,
	Severity,
} from "./types";
import "./helpers.scss";

export const emptyValidationResults: ValidationRunResult = objectFromEntries(
	Object.values(ValidationResultGroup).map((key) => [key, []]),
);

const severityPrecedence = [Severity.Error, Severity.Warning, Severity.Info];
const severityCodes: { [K in Severity]: number } = objectFromEntries(
	severityPrecedence.map((s, i) => [s, severityPrecedence.length - (i + 1)]),
);
const maxSeverityCode = severityPrecedence.length - 1;

/**
 * Get the code with the highest severity
 *
 * @param severities Any number of severity values
 * @returns The most severe code or `null` if the input is empty
 */
export function getMaxSeverity(severities: Severity[]): Severity | null {
	let maxSeverity: Severity | null = null;
	for (const severity of severities) {
		const currentCode = severityCodes[severity];
		if (currentCode === maxSeverityCode) return severity;
		if (!maxSeverity || severityCodes[maxSeverity] < currentCode) {
			maxSeverity = severity;
		}
	}
	return maxSeverity;
}

/**
 * Get the severities of a list of validation results.
 *
 * @param validationResults The list of validation results
 * @returns The severities of the results as a list
 */
export function getSeverities<
	TargetField extends ValidationTargetField = ValidationTargetField,
>(validationResults: ValidationResultBase<TargetField>[]): Severity[] {
	return validationResults.map((result) => result.severity);
}

/**
 * Get the maximum severity of a list of validation results.
 *
 * @param validationResults The list of validation results
 * @returns The maximum severity, found in the result list
 */
export function getMaxSeverityFromResults<
	TargetField extends ValidationTargetField = ValidationTargetField,
>(validationResults: ValidationResultBase<TargetField>[]): Severity | null {
	return getMaxSeverity(getSeverities(validationResults));
}

export enum SeveritySortingDirection {
	Ascending = "ascending",
	Descending = "descending",
}
const directions: {
	[K in SeveritySortingDirection]: Severity[];
} = {
	[SeveritySortingDirection.Ascending]: [
		Severity.Info,
		Severity.Warning,
		Severity.Error,
	],
	[SeveritySortingDirection.Descending]: [
		Severity.Error,
		Severity.Warning,
		Severity.Info,
	],
};
/**
 * Sort items containing a `severity` property. By default, it sorts in
 * descending direction, meaning from `Error` to `Info`
 *
 * @param items The list of items you want to sort
 * @param direction The order to sort the list by. Default is `Descending`
 * @returns The sorted list
 */
export function sortBySeverity<T extends { severity: Severity }>(
	items: T[],
	direction: SeveritySortingDirection = SeveritySortingDirection.Descending,
): T[] {
	const sortingTemplate: {
		[K in Severity]: T[];
	} = {
		[Severity.Error]: [],
		[Severity.Warning]: [],
		[Severity.Info]: [],
	};
	for (const item of items) {
		sortingTemplate[item.severity].push(item);
	}
	return directions[direction].flatMap((severity) => sortingTemplate[severity]);
}

export function isProfileValidationResult<
	TargetField extends ValidationTargetField = ValidationTargetField,
>(
	result: ValidationResultBase<TargetField>,
): result is ProfileValidationResult<TargetField> {
	return result.type === ValidationResultGroup.MessageProfiles;
}
export function isRestrictionProfileValidationResult<
	TargetField extends ValidationTargetField = ValidationTargetField,
>(
	result: ValidationResultBase<TargetField>,
): result is RestrictionProfileValidationResult<TargetField> {
	return result.type === ValidationResultGroup.RestrictionProfiles;
}
export function isRestrictionValidationResult<
	TargetField extends ValidationTargetField = ValidationTargetField,
>(
	result: ValidationResultBase<TargetField>,
): result is RestrictionValidationResult<TargetField> {
	return result.type === ValidationResultGroup.Restrictions;
}
export function isMetadataValidationResult<
	TargetField extends ValidationTargetField = ValidationTargetField,
>(
	result: ValidationResultBase<TargetField>,
): result is MetadataValidationResult<TargetField> {
	return result.type === ValidationResultGroup.Metadata;
}
/* TODO: add to translations */

export const targetFieldLabels: { [K in ValidationTargetField]: string } = {
	[ValidationTargetField.Cardinality]: "Kardinalität",
	[ValidationTargetField.DefaultValue]: "Standard-Wert",
	[ValidationTargetField.ExampleValue]: "Beispiel-Wert",
	[ValidationTargetField.ExampleValueMessage]: "Wert für Beispielnachrichten",
	[ValidationTargetField.FixedValue]: "Fix-Wert",
	[ValidationTargetField.RecursionLimit]: "Rekursionstiefe",
	[ValidationTargetField.RestrictionName]: "Name der Einschränkung",
	[ValidationTargetField.Standard]: "Standard",
	[ValidationTargetField.NameShort]: "Name (kurz)",
	[ValidationTargetField.NameLong]: "Name (lang)",
	[ValidationTargetField.Description]: "Beschreibung",
	[ValidationTargetField.Version]: "Version",
	// TODO: ValidationTargetField.Kennung & ValidationTargetField.CodelistIdentifier
	// are identical. Untangle the collision
	[ValidationTargetField.Kennung]: "Kennung",
	[ValidationTargetField.DraftStatus]: "Status Fassung",
	[ValidationTargetField.Publisher]: "Herausgeber",
	[ValidationTargetField.Property]: "Eigenschaften",
	[ValidationTargetField.CodelistValues]: "Codelistenwerte",
	// [ValidationTargetField.CodelistIdentifier]: "Codelistenkennung",
};

export const metadataTargetFieldMapping: {
	[K in keyof ProfilingMetadataValues]?: DefaultAutoFixTargetFields;
} = {
	standard: ValidationTargetField.Standard,
	nameKurz: ValidationTargetField.NameShort,
	nameLang: ValidationTargetField.NameLong,
	beschreibung: ValidationTargetField.Description,
	version: ValidationTargetField.Version,
	kennung: ValidationTargetField.Kennung,
	statusFassung: ValidationTargetField.DraftStatus,
	herausgeber: ValidationTargetField.Publisher,
};

type SeverityRecord<T> = { [K in Severity]: T };

export const validationIcons: SeverityRecord<ReactNode> = {
	[Severity.Error]: (
		<ErrorIcon aria-hidden className="severity-icon severity-icon--error" />
	),
	[Severity.Warning]: (
		<WarningIcon aria-hidden className="severity-icon severity-icon--warning" />
	),
	[Severity.Info]: (
		<InfoIcon aria-hidden className="severity-icon severity-icon--info" />
	),
};
export const validationSuccessIcon = (
	<CheckIcon className="severity-icon severity-icon--check" />
);
export const validationSeverityShortNames: SeverityRecord<string> = {
	[Severity.Error]: "Fehler",
	[Severity.Warning]: "Warnung",
	[Severity.Info]: "Info",
};
export const validationSeverityNames: SeverityRecord<string> = {
	[Severity.Error]: "Validierungsfehler",
	[Severity.Warning]: "Validierungswarnung",
	[Severity.Info]: "Validierungsinformation",
};
export const validationLabels: SeverityRecord<string> = {
	[Severity.Error]: "Es liegen Validierungsfehler vor.",
	[Severity.Warning]: "Es liegen Warnungen bei der Validierung vor.",
	[Severity.Info]: "Es liegen Informationen bei der Validierung vor.",
};
export const validationSaveProjectNotes: SeverityRecord<string> = {
	[Severity.Error]: `${
		validationLabels[Severity.Error]
	} Das Speichern ist daher nicht möglich.`,
	[Severity.Warning]:
		`${validationLabels[Severity.Warning]} Es wird empfohlen, diese ` +
		"zu beheben. Das Speichern ist dennoch weiterhin möglich.",
	[Severity.Info]:
		`${validationLabels[Severity.Info]} Das Speichern ist ` +
		"weiterhin möglich.",
};

/**
 * Run an auto fix function. Auto fix functions may be skiped when they apply
 * to multiple target fields, so that the fix is only executed for the correct
 * target field. This function wraps the skiping logic, so we don't need to do
 * the same checks over and over again.
 *
 * @param fixer The fix function to run
 * @param fieldName The field that the fix is executed on
 * @param cb The callback used, to communicate the fixed value back to the
 * caller. It may not be called at all, should the fix be skiped
 */
export function runFixer<TargetField extends ValidationTargetField>(
	fixer: FixerFn<TargetField>,
	fieldName: string,
	cb: (fixedValue: AutoFixValue<TargetField>) => void,
) {
	// Pass a unique symbol to the fix function, that, when returned indicates
	// that the fix should be skiped
	const fixedValue = fixer(fieldName, { skip: SkipAutoFixSymbol });
	if (fixedValue !== SkipAutoFixSymbol) {
		cb(fixedValue);
	}
}
