import type { SvgIconComponent } from "@mui/icons-material";
import {
	LiteDatentypenKind,
	LiteKind,
} from "../../components/AppActor/actors/modellierungModel/LiteKind";
import PaketIcon from "../icons/PaketIcon";
import SchemaPaketIcon from "../icons/SchemaPaketIcon";
import NachrichtIcon from "../icons/NachrichtIcon";
import GlobalesAttributIcon from "../icons/GlobalesAttributIcon";
import CodeDatentypIcon from "../icons/CodeDatentypIcon";
import EinfacherDatentypIcon from "../icons/EinfacherDatentypIcon";
import StandardIcon from "../icons/StandardIcon";
import GlobalesElementIcon from "../icons/GlobalesElementIcon";

import StandardInEntwicklungIcon from "../icons/StandardInEntwicklungIcon";
import ElementIcon from "../icons/ElementIcon";
import AttributIcon from "../icons/AttributIcon";
import UnknownBausteinIcon from "../icons/MuiQuestionMarkIcon";
import KomplexerDatentypIcon from "../icons/KomplexerDatentypIcon";

/* Name Mapping */
const GlobaleEigenschaftIcon = GlobalesElementIcon;
const ModellierungIcon = StandardInEntwicklungIcon;
const EigenschaftIcon = ElementIcon;

export const kindIconMap: {
	[K in LiteKind | LiteDatentypenKind]: SvgIconComponent;
} = {
	[LiteKind.Model]: StandardIcon,
	[LiteKind.Paket]: PaketIcon,
	[LiteKind.SchemaPaket]: SchemaPaketIcon,
	[LiteKind.CodeDatentyp]: CodeDatentypIcon,
	[LiteKind.Nachricht]: NachrichtIcon,
	[LiteKind.Datentyp]: EinfacherDatentypIcon,
	[LiteKind.GlobaleEigenschaft]: GlobaleEigenschaftIcon,
	[LiteKind.UnknownBaustein]: UnknownBausteinIcon, // TODO: Gegen KoSIT-Icon austauschen, wenn vorhanden
	[LiteKind.Attribut]: AttributIcon,
	[LiteKind.Eigenschaft]: EigenschaftIcon,
	[LiteDatentypenKind.EinfacherDatentyp]: EinfacherDatentypIcon,
	[LiteDatentypenKind.KomplexerDatentyp]: KomplexerDatentypIcon,
};

export {
	GlobaleEigenschaftIcon,
	EinfacherDatentypIcon,
	SchemaPaketIcon,
	PaketIcon,
	NachrichtIcon,
	GlobalesAttributIcon,
	EigenschaftIcon,
	StandardIcon,
	ModellierungIcon,
};
