import { Link, useMatch } from "react-router-dom";
import { MenuItem, Header } from "@xoev/ui";
import XoevLogo from "./XoevLogo";
import SkipLink from "../SkipLink";
import MenuHome from "./MenuHome";
import ProjectTabs from "../Projects/ProjectTabs";
import TopMenu from "./TopMenu";
// import LoginButton from "./LoginButton";
import styles from "./TopMenu.module.scss";
import ProfilInEntwicklungIcon from "../../resources/icons/ProfilInEntwicklungIcon";

const Menu = (): JSX.Element => {
	const profilingMatch = useMatch("/profilierung/*");
	const isProfilingActive = !!profilingMatch;

	return (
		<>
			<SkipLink label="Navigation überspringen" target="#main" />
			<Header>
				<TopMenu>
					<MenuItem>
						<Link to="/barrierefreiheit" data-testid="barrierefreiheit-link">
							Barrierefreiheit
						</Link>
					</MenuItem>
					{/* <MenuItem>
						<Link to="/nachrichten" data-testid="messages-link">
							Nachrichten validieren
						</Link>
					</MenuItem> */}
					<MenuItem>
						<Link
							to="https://docs.xoev.de/xoevsuite/%C3%BCbersicht"
							target="_blank"
							data-testid="info-area-link"
							className={styles["xoev-external-link"]}
						>
							Zur Hilfe
						</Link>
					</MenuItem>
					<MenuItem>
						{/* <button
							type="button"
							aria-label="Login"
							className={styles["xoev-login-button"]}
						>
							<LoginButton />
						</button> */}
					</MenuItem>
				</TopMenu>
				<Header.Nav data-testid="main-navigation">
					<Header.NavList>
						<li>
							<Header.Logo label="XÖV-Suite Homepage" as={Link} to="/">
								<XoevLogo />
							</Header.Logo>
						</li>
						<MenuHome
							to="/"
							isActive={({ pathname }) =>
								[
									"/",
									"/index",
									"/impressum",
									"/datenschutzerklaerung",
									"/barrierefreiheit",
								].includes(pathname)
							}
							label="Startseite"
						/>
						<MenuItem>
							<MenuItem.Link
								as={Link}
								to="/profilierung"
								isActive={isProfilingActive}
								data-testid="profiling-home-link"
							>
								<ProfilInEntwicklungIcon
									sx={{ fill: "#FFC300", marginRight: "8px" }}
								/>
								Profilierung
							</MenuItem.Link>
						</MenuItem>
						<ProjectTabs />
					</Header.NavList>
				</Header.Nav>
			</Header>
		</>
	);
};

export default Menu;
