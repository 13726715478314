import { useAppSelector } from "../redux/hooks";
import { selectModellContainer } from "../redux/treeSlice";
import { memoizeOnce } from "../utils/memoization";
import { useStateSelector } from "../components/EditorState";
import { selectStandard } from "../components/EditorState/selectors";
import type { StateProfileMap } from "../components/EditorState/types";
import {
	parseQNamePath,
	type LiteId,
	type QNamePath,
} from "../components/AppActor/actors/modellierungModel/schemas";
import { selectIdPathFromQNamePathFromModell } from "../components/AppActor/actors/modellierungModel/selectors";
import type { LiteModellContainer } from "../components/AppActor/actors/modellierungModel/types";

// We want to avoid doing this work more often than necessarey. We don't need
// to create a potentially huge set of ids for every node that want's to check
// if its id is in that set, when we can lazily create it once per state update
const createAffectedByProfile = memoizeOnce(
	(
		profiles: StateProfileMap,
		getHierachyIds: (profileQNamePath: QNamePath) => LiteId[] | null,
		// We just need the next argument, to construct a cache key
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		_modell: LiteModellContainer | null,
	) => {
		const profileIdIter = profiles.keySeq().cacheResult();
		const affectedIds = new Set<LiteId>();
		for (const profileQNamePath of profileIdIter) {
			const parentIds = getHierachyIds(profileQNamePath) || [];
			for (const parentId of parentIds) {
				affectedIds.add(parentId);
			}
		}
		return affectedIds;
	},
	(profiles, _getParentIds, modell) => [profiles, modell],
);

export default function useIdsAffectedByProfile(
	profiles: StateProfileMap | undefined,
): Set<string> {
	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));
	if (!profiles) return new Set<string>();
	const affectedIds = createAffectedByProfile(
		profiles,
		(profileQNamePath) =>
			modell
				? selectIdPathFromQNamePathFromModell(
						modell,
						parseQNamePath(profileQNamePath),
				  )
				: [],
		modell,
	);
	return affectedIds;
}
