import { createSvgIcon } from "@mui/material";

const NachrichtIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.43557 6.50612C3.70833 6.19439 4.18215 6.16281 4.49388 6.43557L11.8354 12.8594C11.9296 12.9419 12.0704 12.9419 12.1646 12.8594L19.5061 6.43557C19.8179 6.16281 20.2917 6.19439 20.5644 6.50612C20.8372 6.81785 20.8056 7.29167 20.4939 7.56443L13.1524 13.9882C12.4926 14.5656 11.5074 14.5656 10.8476 13.9882L3.50612 7.56443C3.19439 7.29167 3.16281 6.81785 3.43557 6.50612Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.25 7C2.25 6.0335 3.0335 5.25 4 5.25H20C20.9665 5.25 21.75 6.0335 21.75 7V17C21.75 17.9665 20.9665 18.75 20 18.75H4C3.0335 18.75 2.25 17.9665 2.25 17V7ZM4 6.75C3.86193 6.75 3.75 6.86193 3.75 7V17C3.75 17.1381 3.86193 17.25 4 17.25H20C20.1381 17.25 20.25 17.1381 20.25 17V7C20.25 6.86193 20.1381 6.75 20 6.75H4Z"
		/>
	</svg>,
	"Nachricht",
);

export default NachrichtIcon;
