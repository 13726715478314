import { createSvgIcon } from "@mui/material";

const CodelisteIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M6.5 6C5.94772 6 5.5 6.44772 5.5 7C5.5 7.55228 5.94772 8 6.5 8H17.5C18.0523 8 18.5 7.55228 18.5 7C18.5 6.44772 18.0523 6 17.5 6H6.5Z" />
		<path d="M7 14C7.82843 14 8.5 13.3284 8.5 12.5C8.5 11.6716 7.82843 11 7 11C6.17157 11 5.5 11.6716 5.5 12.5C5.5 13.3284 6.17157 14 7 14Z" />
		<path d="M13.5 12.5C13.5 13.3284 12.8284 14 12 14C11.1716 14 10.5 13.3284 10.5 12.5C10.5 11.6716 11.1716 11 12 11C12.8284 11 13.5 11.6716 13.5 12.5Z" />
		<path d="M13.5 17.5C13.5 18.3284 12.8284 19 12 19C11.1716 19 10.5 18.3284 10.5 17.5C10.5 16.6716 11.1716 16 12 16C12.8284 16 13.5 16.6716 13.5 17.5Z" />
		<path d="M17 14C17.8284 14 18.5 13.3284 18.5 12.5C18.5 11.6716 17.8284 11 17 11C16.1716 11 15.5 11.6716 15.5 12.5C15.5 13.3284 16.1716 14 17 14Z" />
		<path d="M18.5 17.5C18.5 18.3284 17.8284 19 17 19C16.1716 19 15.5 18.3284 15.5 17.5C15.5 16.6716 16.1716 16 17 16C17.8284 16 18.5 16.6716 18.5 17.5Z" />
		<path d="M8.5 17.5C8.5 18.3284 7.82843 19 7 19C6.17157 19 5.5 18.3284 5.5 17.5C5.5 16.6716 6.17157 16 7 16C7.82843 16 8.5 16.6716 8.5 17.5Z" />
	</svg>,
	"Codeliste",
);

export default CodelisteIcon;
