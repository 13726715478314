import { useCallback, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import PopoverMenuContext from "./PopoverMenuContext";
import { useConst, useSyncedRef, useSyncedState } from "../../../hooks";
import { createHtmlId } from "../../../utils/misc";
import PopoverMenuButton from "./PopoverMenuButton";
import PopoverMenuContent from "./PopoverMenuContent";
import type { PopoverMenuProps } from "./types";
import "./PopoverMenu.scss";

const PopoverMenu = ({
	className,
	activeClassName,
	isOpen: isOpenProp,
	children,
	onOpen,
	onClose,
	position = "bottom left",
	...props
}: PopoverMenuProps): JSX.Element => {
	const buttonId = useConst(createHtmlId);
	const contentId = useConst(createHtmlId);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const contentRef = useRef<HTMLElement | null>(null);
	const [isOpen, setIsOpen] = useSyncedState(!!isOpenProp);
	const onOpenRef = useSyncedRef(onOpen);
	const onCloseRef = useSyncedRef(onClose);

	useEffect(() => {
		if (isOpen) {
			onOpenRef.current?.();
		} else {
			onCloseRef.current?.();
		}
	}, [isOpen, onCloseRef, onOpenRef]);

	const isElementInMenu = useCallback(
		(elem: Node | null) =>
			!!(
				containerRef.current?.contains(elem) ||
				buttonRef.current?.contains(elem) ||
				contentRef.current?.contains(elem)
			),
		[],
	);

	// Each handler is pretty much self contained, and not very complex in itself
	// so adding the complexities does not really reflect the actual complexity
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		let tabDown = false;

		const clickHandler = (e: MouseEvent) => {
			if (!isElementInMenu(e.target as Node)) {
				e.preventDefault();
				e.stopPropagation();
				setIsOpen(false);
			}
		};

		const focusinHandler = (e: FocusEvent) => {
			// Close the menu when focus moves out of it
			if (!isElementInMenu(e.target as Node)) {
				setIsOpen(false);

				// When the menu closes because the menu loses focus after
				// tabbing away, focus the button, so we return to the
				// expected tab order.
				// Don't do this when losing focus after a click! This way
				// clicking a second select, when one is open will not work
				// properly
				if (tabDown) {
					buttonRef.current?.focus();
				}
			}
		};

		const keydownHandler = (e: KeyboardEvent) => {
			if (isElementInMenu(e.target as Node)) {
				if (e.key === "Escape") {
					e.stopPropagation();
					e.preventDefault();
					buttonRef.current?.focus();
					setIsOpen(false);
				}
				// Remember if tab key was pressed, so we know if a focusin
				// event was caused by moving focus with the keyboard
				if (e.key === "Tab") {
					tabDown = true;
				}
			}
		};
		const keyupHandler = (e: KeyboardEvent) => {
			if (e.key === "Tab") {
				tabDown = false;
			}
		};

		if (isOpen) {
			document.addEventListener("click", clickHandler);
			document.addEventListener("focusin", focusinHandler);
			document.addEventListener("keydown", keydownHandler);
		}
		// The keyup event might be triggered after the menu is closed
		document.addEventListener("keyup", keyupHandler);

		return () => {
			if (isOpen) {
				document.removeEventListener("click", clickHandler);
				document.removeEventListener("focusin", focusinHandler);
				document.removeEventListener("keydown", keydownHandler);
			}
			document.removeEventListener("keyup", keyupHandler);
		};
	}, [isOpen, setIsOpen, isElementInMenu]);

	const ctx = useMemo(
		() => ({
			isOpen,
			setIsOpen,
			buttonId,
			contentId,
			containerRef,
			buttonRef,
			contentRef,
			isElementInMenu,
			position,
		}),
		[
			isOpen,
			setIsOpen,
			buttonId,
			contentId,
			containerRef,
			buttonRef,
			contentRef,
			isElementInMenu,
			position,
		],
	);

	return (
		<PopoverMenuContext.Provider value={ctx}>
			<div
				ref={containerRef}
				className={classNames(
					"xui-popover-menu",
					className,
					isOpen && "xui-popover-menu--open",
					isOpen && activeClassName,
				)}
				tabIndex={-1}
				data-testid="popover-menu"
				data-is-open={isOpen}
				// `props` is specifically typed to include only `div` props, so we
				// know it's safe to forward them to the underlying div
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			>
				{children}
			</div>
		</PopoverMenuContext.Provider>
	);
};

PopoverMenu.Button = PopoverMenuButton;
PopoverMenu.Content = PopoverMenuContent;

export default PopoverMenu;
