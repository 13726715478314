import { createSvgIcon } from "@mui/material";

const CodeDatentypIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.25 4C2.25 3.0335 3.0335 2.25 4 2.25H14C14.9665 2.25 15.75 3.0335 15.75 4V6.84C15.75 7.25421 15.4142 7.59 15 7.59C14.5858 7.59 14.25 7.25421 14.25 6.84V4C14.25 3.86193 14.1381 3.75 14 3.75H4C3.86193 3.75 3.75 3.86193 3.75 4V14C3.75 14.1381 3.86193 14.25 4 14.25H6.5C6.91421 14.25 7.25 14.5858 7.25 15C7.25 15.4142 6.91421 15.75 6.5 15.75H4C3.0335 15.75 2.25 14.9665 2.25 14V4Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.25 12C11.25 11.5858 11.5858 11.25 12 11.25H20.5C20.9142 11.25 21.25 11.5858 21.25 12C21.25 12.4142 20.9142 12.75 20.5 12.75H12C11.5858 12.75 11.25 12.4142 11.25 12Z"
		/>
		<path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" />
		<path d="M16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17Z" />
		<path d="M16 21C16.5523 21 17 20.5523 17 20C17 19.4477 16.5523 19 16 19C15.4477 19 15 19.4477 15 20C15 20.5523 15.4477 21 16 21Z" />
		<path d="M21 16C21 16.5523 20.5523 17 20 17C19.4477 17 19 16.5523 19 16C19 15.4477 19.4477 15 20 15C20.5523 15 21 15.4477 21 16Z" />
		<path d="M20 21C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19C19.4477 19 19 19.4477 19 20C19 20.5523 19.4477 21 20 21Z" />
		<path d="M12 21C12.5523 21 13 20.5523 13 20C13 19.4477 12.5523 19 12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21Z" />
	</svg>,
	"CodeDatentyp",
);

export default CodeDatentypIcon;
