import { useEffect } from "react";
import { useStableNavigate } from "../../../../hooks";
import { encodeXPath } from "../../../../utils/url";
import EditorMain from "../../../EditorMain";
import { LRSplit } from "../../../ui";
import useActiveDataType from "../../useActiveDataType";
import useActiveRestriction from "../../useActiveRestriction";
import RestrictionEditView from "./RestrictionEditView";
import RestrictionList from "./RestrictionList";

function RestrictionView(): JSX.Element {
	const { activeDataType, didFindMatch } = useActiveDataType();
	const activeRestriction = useActiveRestriction();
	const navigate = useStableNavigate();

	useEffect(() => {
		// When we're on a path with an existing datatype, but a non existing
		// restriction, we might have undone the creation of the restriction or
		// have reloaded the page without saving the change. In that case we'd end
		// up on a blank page, so we go back to the datatype view
		if (activeDataType && didFindMatch && !activeRestriction) {
			navigate(`/profilierung/datentypen/${encodeXPath(activeDataType.id)}`, {
				replace: true,
			});
		}
	}, [activeDataType, activeRestriction, didFindMatch, navigate]);

	if (!activeDataType || !activeRestriction) return <></>;

	return (
		<EditorMain>
			<LRSplit>
				<LRSplit.Left>
					<RestrictionList />
				</LRSplit.Left>
				<LRSplit.Right>
					<RestrictionEditView />
				</LRSplit.Right>
			</LRSplit>
		</EditorMain>
	);
}

export default RestrictionView;
