import { useSendStoreEvent } from "../../../../../AppActor/EventStore/hooks";
import {
	CARDINILITIES,
	DEFAULT_CARDINALITY,
} from "../../../../../AppActor/actors/modellierungModel/helpers";
import type { LiteEigenschaft } from "../../../../../AppActor/actors/modellierungModel/schemas";
import EditFormFieldLite from "../../../../../EditFormFieldLite";
import type { EditRendererProps } from "../../types";

const cardinalityOptions = CARDINILITIES.map((option) => ({
	value: option,
	label: option,
}));

export default function KardinalitaetEdit({
	activeNode,
	isReadOnly,
	projekt,
}: EditRendererProps<LiteEigenschaft>): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();

	const handleSelectChange = (e: {
		target: { name?: string; value: string };
	}) => {
		const nodeId = activeNode.id;
		sendStoreEvent({
			type: "MODELLIERUNG.MODELL.APPLY",
			payload: {
				projektId: projekt.id,
				patch: {
					type: "changeDetailsValue",
					payload: { name: "multiplizitaet", value: e.target.value, nodeId },
				},
			},
		});
	};

	return (
		<EditFormFieldLite
			definition={{
				readOnly: isReadOnly,
				name: "multiplizitaet",
				label: "Kardinalität",
				type: "select",
				options: cardinalityOptions,
			}}
			value={activeNode.multiplizitaet || DEFAULT_CARDINALITY}
			onChange={handleSelectChange}
			inputProps={{
				"data-testid": "details-field",
				"data-field-name": "multiplizitaet",
			}}
		/>
	);
}
