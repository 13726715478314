import { filterFalsy } from "../../../utils/lists";
import {
	useModellierungProjekt,
	useProjektBaseSegment,
} from "../../AppActor/actors/modellierungModel/hooks";
import type { BreadcrumbItem } from "../../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import useActiveMetadataSection, {
	MetadataSection,
} from "../useActiveMetadataSection";
import NavBarBreadcrumbs from "../../EditorNavBar/NavBarBreadcrumbs/NavBarBreadcrumbs";
import useProjectBreadcrumb from "../../EditorNavBar/NavBarBreadcrumbs/breadCrumbsLite";
import TabNavBar from "../../TabNavBar";

const MetadataNavBarLite = (): JSX.Element => {
	const activeProject = useModellierungProjekt();
	const baseSegment = useProjektBaseSegment();
	const section = useActiveMetadataSection();
	const projectBreadcrumb = useProjectBreadcrumb(activeProject);

	const breadCrumbs: BreadcrumbItem[] = filterFalsy([
		projectBreadcrumb,
		section === MetadataSection.Standard && {
			key: "__METADATA_STANDARD__",
			content: "Metadaten",
			to: `${baseSegment}/metadaten/standard`,
		},
		section === MetadataSection.Version && {
			key: "__METADATA_VERSION__",
			content: "Metadaten",
			to: `${baseSegment}/metadaten/version`,
		},
	]);

	return (
		<TabNavBar>
			<NavBarBreadcrumbs items={breadCrumbs} />
		</TabNavBar>
	);
};

export default MetadataNavBarLite;
