interface IdCounter {
	(): string;
	reset: () => void;
}

/* TODO: also exists in @xoev/ui/src/util  */
export const createIdCounter = (): IdCounter => {
	let id = 0;
	// eslint-disable-next-line no-plusplus
	const nextId = () => `${id++}`;
	// This will only be used in tests, so we can reset the id counter
	// before every test
	nextId.reset = () => {
		id = 0;
	};
	return nextId;
};

export const createLabelledCounter = (
	label: string,
	delim = "-",
): IdCounter => {
	const nextId = createIdCounter();
	const nextLabelledId = () => `${label}${delim}${nextId()}`;
	nextLabelledId.reset = nextId.reset;
	return nextLabelledId;
};

const umlautReplacements: Record<string, string | undefined> = {
	ä: "ae",
	Ä: "Ae",
	ö: "oe",
	Ö: "Oe",
	ü: "ue",
	Ü: "Ue",
	ß: "ss",
	ẞ: "Ss",
};
export const normalizeUmlauts = (input: string): string => {
	let out = "";
	for (const char of input) {
		out += umlautReplacements[char] || char;
	}
	return out;
};

export function normalizeIdentifier(input: string | undefined): string {
	return normalizeUmlauts(
		(input || "")
			.trim()
			.toLowerCase()
			.replace(/(\s+|_)/g, "-"),
	);
}

export function generateKennung(inputValues: {
	standard?: string;
	herausgeber?: string;
	nameKurz?: string;
	version?: string;
}): string {
	const normalizedPublisher = normalizeIdentifier(inputValues.herausgeber);
	const normalizedProject = normalizeIdentifier(inputValues.nameKurz);
	const normalizedVersion = normalizeIdentifier(inputValues.version);
	const standard = inputValues.standard || "standard-kennung";
	return (
		`${standard}#compliant#urn:` +
		`xoev-de:${normalizedPublisher}:profil:${normalizedProject}_` +
		`${normalizedVersion}`
	);
}

// DOM ids need to be prefixed, because some browsers expect ids to
// begin with a character. The string returned from the counter would
// however begin with a number.
export const createHtmlId = createLabelledCounter("html-id");
