import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import type { ContextFrom, EventObject } from "xstate";
import { fromCallback } from "xstate";
import { getFromSystem, sendInSystem } from "../../../utils/machines";
import type {
	EventStoreActorRef,
	EventStoreEntry,
	EventStoreEntryMeta,
	EventStoreLog,
} from "./eventStore.machine";
import type { StoreEvent } from "./StoreEvent";
import type eventStoreMachine from "./eventStore.machine";

export function getEventStoreFromSystem(
	system: AnyActorSystem,
): EventStoreActorRef {
	return getFromSystem(system, "eventStore");
}

export function sendToEventStore(system: AnyActorSystem, event: StoreEvent) {
	return sendInSystem<typeof eventStoreMachine>(system, "eventStore", {
		type: "APPEND_EVENT",
		event,
	});
}

export const EVENT_STORE_UPDATE = "EVENT_STORE_UPDATE";
export type EventStoreUpdateEvent = {
	type: typeof EVENT_STORE_UPDATE;
	eventLog: EventStoreLog;
	update: EventStoreEntry | null;
};

function createEventStoreUpdateEvent(
	context: ContextFrom<typeof eventStoreMachine>,
): EventStoreUpdateEvent {
	const { eventLog } = context;
	return { type: EVENT_STORE_UPDATE, eventLog, update: eventLog.last(null) };
}

export const subscribeToEventStoreActor = fromCallback(
	({ system, sendBack }) => {
		const eventStore = getEventStoreFromSystem(system);
		sendBack(createEventStoreUpdateEvent(eventStore.getSnapshot().context));
		const sub = eventStore.subscribe(({ context }) => {
			sendBack(createEventStoreUpdateEvent(context));
		});
		return () => {
			sub.unsubscribe();
		};
	},
);

export type EventTranslationMap<TEvent> = {
	[EventType in StoreEvent["type"]]?: (
		storeEvent: Extract<StoreEvent, { type: EventType }>,
		eventMeta: EventStoreEntryMeta,
	) => TEvent;
};

function forwardEvent<TOut>(
	translationMap: EventTranslationMap<TOut>,
	send: (event: TOut) => unknown,
	event: StoreEvent,
	meta: EventStoreEntryMeta,
) {
	const translatedEvent = translationMap[event.type]?.(event as never, meta);
	if (!translatedEvent) return;
	send(translatedEvent);
}

export function translateStoreEvents<TEvent extends EventObject>(
	translationMap: EventTranslationMap<TEvent>,
	options?: { replayEventLog?: boolean },
) {
	return fromCallback<TEvent>(({ system, sendBack }) => {
		const eventStore = getEventStoreFromSystem(system);
		if (options?.replayEventLog) {
			const { eventLog } = eventStore.getSnapshot().context;
			eventLog.forEach(({ event, meta }) => {
				forwardEvent(translationMap, sendBack, event, meta);
			});
		}
		const sub = eventStore.subscribe(({ context }) => {
			const entry = context.eventLog.last(null);
			if (!entry) return;
			forwardEvent(translationMap, sendBack, entry.event, entry.meta);
		});
		return () => {
			sub.unsubscribe();
		};
	});
}

export function mapStoreEvents(
	translationMap: EventTranslationMap<StoreEvent>,
) {
	return fromCallback<StoreEvent>(({ system }) => {
		const eventStore = getEventStoreFromSystem(system);
		const send = (e: StoreEvent) => sendToEventStore(system, e);
		const sub = eventStore.subscribe(({ context }) => {
			const entry = context.eventLog.last(null);
			if (!entry) return;
			forwardEvent(translationMap, send, entry.event, entry.meta);
		});
		return () => {
			sub.unsubscribe();
		};
	});
}

export function isStoreEvent<
	TStoreEvent extends StoreEvent,
	Type extends TStoreEvent["type"],
>(ev: unknown, type: Type): ev is Extract<TStoreEvent, { type: Type }> {
	return !(ev && typeof ev === "object" && "type" in ev && ev.type !== type);
}

export function assertStoreEvent<
	TStoreEvent extends StoreEvent,
	Type extends TStoreEvent["type"],
>(ev: unknown, type: Type): asserts ev is Extract<TStoreEvent, { type: Type }> {
	if (!isStoreEvent(ev, type)) {
		throw new Error("Unexpected event type.");
	}
}
