import {
	selectDatatypesFromModell,
	selectQNameFromModell,
} from "../../AppActor/actors/modellierungModel/selectors";
import type { LiteModellContainer } from "../../AppActor/actors/modellierungModel/types";
import type { DatatypeMap } from "../../EditorState/types";
import { NotADatatypeError } from "../../../utils/profilingHelpers";
import restrictionRules from "../rules/datatypes/restrictionRules";
import type {
	Validator,
	RestrictionRule,
	RestrictionValidationContext,
	RestrictionValidationResult,
	ValidationTargetField,
} from "../types";
import { ValidationResultGroup, Severity } from "../types";
import { generateRestrictionEntries } from "./helpers";

function createRestrictionValidationResult(
	failedRule: RestrictionRule,
	validationContext: RestrictionValidationContext,
): RestrictionValidationResult<ValidationTargetField> {
	const validationMsg =
		typeof failedRule.message === "function"
			? failedRule.message(validationContext)
			: failedRule.message;
	const { restrictionId, datatypeId } = validationContext;
	// Create an id that uniquely identifies the result
	const resultId = `${failedRule.id}#${datatypeId}#${restrictionId}`;
	const restrictionName = validationContext.restriction.get("name");
	return {
		targetField: failedRule.targetField,
		type: ValidationResultGroup.Restrictions,
		id: resultId,
		// By default we'll assume that the severity is an error
		severity: failedRule.severity || Severity.Error,
		message: validationMsg,
		ruleId: failedRule.id,
		datatypeId,
		restrictionId,
		restrictionName,
		elementName: restrictionName,
		elementNamePath: [restrictionName],
		autoFix: failedRule.autoFix,
		autoFixDescription: failedRule.autoFixDescription,
		target: failedRule.target,
	};
}

/**
 * Validate restrictions of datatypes entered by the user.
 *
 * @param datatypes The datatypes, containing the restrictions, that should be
 * validated
 * @param getStandardDatatype A getter for the datatypes of the standard. We
 * need these datatypes to compare the profiles to the data in the standard
 */
// Reduce cognitive complexity, when the function needs to be modified next
// eslint-disable-next-line sonarjs/cognitive-complexity
export default async function* validateRestrictions({
	modell,
	datatypes,
}: {
	modell: LiteModellContainer;
	datatypes: DatatypeMap;
}): Validator<RestrictionValidationResult<ValidationTargetField>> {
	const datatypeList = selectDatatypesFromModell(modell);
	// Iterate through each datatype, so we can check all user entered data
	const restrictionGenerator = generateRestrictionEntries(datatypes);
	for (const [datatypeQName, restrictions] of restrictionGenerator) {
		const datatype = datatypeList.find((dt) => {
			NotADatatypeError.assert(dt);
			return selectQNameFromModell(modell, dt) === datatypeQName;
		});
		if (!datatype) continue;
		NotADatatypeError.assert(datatype);
		for (const [restrictionId, restriction] of restrictions) {
			for (const rule of restrictionRules) {
				// Skip the validation if the profile does not contain at least one of
				// the target keys
				if (rule.target && !rule.target.some((key) => restriction.has(key))) {
					continue;
				}
				const context: RestrictionValidationContext = {
					datatypeId: datatype.id,
					restrictionId,
					standardDatatype: datatype,
					restriction,
				};
				if (!rule.isValid(context)) {
					// Yield the validation result here, so we can display it to the user
					yield createRestrictionValidationResult(rule, context);
				} else {
					// Yield nothing here, so we know that we've advanced the validation by
					// one check
					yield null;
				}
			}
		}
	}
	yield null;
}
