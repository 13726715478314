import { createSvgIcon } from "@mui/material";

const GruppenartAlleIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5 4C10.5 4.82843 11.1716 5.5 12 5.5C12.8284 5.5 13.5 4.82843 13.5 4C13.5 3.17157 12.8284 2.5 12 2.5C11.1716 2.5 10.5 3.17157 10.5 4ZM9 4C9 5.65685 10.3431 7 12 7C13.6569 7 15 5.65685 15 4C15 2.34315 13.6569 1 12 1C10.3431 1 9 2.34315 9 4Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5 12C10.5 12.8284 11.1716 13.5 12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12ZM9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5 20C10.5 20.8284 11.1716 21.5 12 21.5C12.8284 21.5 13.5 20.8284 13.5 20C13.5 19.1716 12.8284 18.5 12 18.5C11.1716 18.5 10.5 19.1716 10.5 20ZM9 20C9 21.6569 10.3431 23 12 23C13.6569 23 15 21.6569 15 20C15 18.3431 13.6569 17 12 17C10.3431 17 9 18.3431 9 20Z"
		/>
	</svg>,
	"GruppenartAlle",
);

export default GruppenartAlleIcon;
