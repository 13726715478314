import { createSvgIcon } from "@mui/material";

const PaketIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.25 7C2.25 6.0335 3.0335 5.25 4 5.25H7.7768C8.19045 5.25 8.59072 5.39653 8.90661 5.66358L10.9189 7.36483C10.9641 7.40298 11.0213 7.42391 11.0803 7.42391H20C20.9665 7.42391 21.75 8.20742 21.75 9.17391V17.5C21.75 18.4665 20.9665 19.25 20 19.25H4C3.0335 19.25 2.25 18.4665 2.25 17.5V7ZM4 6.75C3.86193 6.75 3.75 6.86193 3.75 7V17.5C3.75 17.6381 3.86193 17.75 4 17.75H20C20.1381 17.75 20.25 17.6381 20.25 17.5V9.17391C20.25 9.03584 20.1381 8.92391 20 8.92391H11.0803C10.6667 8.92391 10.2664 8.77739 9.95053 8.51033L7.9382 6.80908C7.89307 6.77093 7.83589 6.75 7.7768 6.75H4Z"
		/>
	</svg>,
	"Paket",
);

export default PaketIcon;
