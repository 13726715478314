import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import type { StandardType } from "../types/ProfilierungHome";

export type StandardsState = {
	activeStandard?: StandardType | null;
};

const initialState: StandardsState = {};

const standardsSlice = createSlice({
	name: "standards",
	initialState,
	reducers: {
		setActiveStandard(
			state,
			action: PayloadAction<{
				standard?: StandardType | null;
			}>,
		) {
			const { standard } = action.payload;
			return {
				...state,
				activeStandard: standard,
			};
		},
	},
});

export const { setActiveStandard } = standardsSlice.actions;

export const selectActiveStandard = () => (state: RootState) =>
	state.standards.activeStandard;

export default standardsSlice.reducer;
