import type { ImmutableMap } from "@xoev/immutable-map";
import createImmutableMap from "@xoev/immutable-map";
import { useMemo } from "react";
import EditFormField from "../../../../../EditFormField";
import useActiveDataType from "../../../../useActiveDataType";
import useActiveRestriction from "../../../../useActiveRestriction";
import {
	selectDatatypeEntry,
	selectStandard,
} from "../../../../../EditorState/selectors";
import {
	useApplyPatch,
	useEditorPatchCreators,
	useStateSelector,
} from "../../../../../EditorState";
import type {
	MessageProfileValues,
	RestrictionProfileValues,
} from "../../../../../EditorState/types";
import {
	ValidationResultGroup,
	ValidationTargetField,
} from "../../../../../Validation/types";
import ValidationDisplay from "../../../../../Validation/ValidationDisplay";
import { fields as allFields } from "../../../../../InfoNodeEditView/InfoNodeEditForm/infoNodeFormHelpers";
import FormFieldRenderer from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/FormFieldRenderer";
import type { CodeListValues } from "../../../../../../types/InfoNodeValues";
import type { FieldRendererKeys } from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/types";
import { useAppSelector } from "../../../../../../redux/hooks";
import {
	selectDefinitionPath,
	selectIsCodeliste,
	selectQName,
} from "../../../../../../redux/treeSlice";
import type { LiteNode } from "../../../../../AppActor/actors/modellierungModel/schemas";
import {
	QNamePathSchema,
	isLiteEigenschaft,
} from "../../../../../AppActor/actors/modellierungModel/schemas";
import { AssertionError } from "../../../../../../utils/error";
import "./RestrictionEditForm.scss";

const CODELIST_FIELD_NAMES: ReadonlyArray<FieldRendererKeys> = [
	"nutzungsArt",
	"version",
	"kennung",
	"codeliste",
];
const MULTIPLIZITAET_NAMES = ["lowerBound", "upperBound"];
const FIELD_NAMES: ReadonlyArray<FieldRendererKeys> = [
	"beschreibung",
	"umsetzungshinweis",
];

const emptyFields: typeof allFields = [];
const codelistFields = allFields.filter((field) =>
	CODELIST_FIELD_NAMES.includes(field.name),
);
const createFields = (node: LiteNode) =>
	allFields.filter(
		(field) =>
			FIELD_NAMES.includes(field.name) ||
			(isLiteEigenschaft(node) && MULTIPLIZITAET_NAMES.includes(field.name)),
	);

const RestrictionEditForm = (): JSX.Element => {
	const standard = useStateSelector(selectStandard());
	const { activeDataType: optionalActiveDataType } = useActiveDataType();
	// We check for the existance of the activeDataType in the parent component
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const activeNode = optionalActiveDataType!;
	const activeQName = useAppSelector(selectQName(standard, activeNode));
	AssertionError.notNullish(
		activeQName,
		"`activeQName` in <RestrictionEditForm />",
	);
	const qnamePath = useMemo(
		() => QNamePathSchema.parse(activeQName),
		[activeQName],
	);
	// For datatypes, the definition path is also the path in the tree
	const activePath = useAppSelector(
		selectDefinitionPath(standard, activeNode.id),
	);
	// We're checking if activeRestriction exists in `RestrictionView`
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const activeRestriction = useActiveRestriction()!;
	const isCodeliste = useAppSelector(selectIsCodeliste(standard, activeNode));
	const fields = useMemo(() => createFields(activeNode), [activeNode]);

	const applyPatch = useApplyPatch();
	const { setDatatype } = useEditorPatchCreators();
	const values = useStateSelector(selectDatatypeEntry(activeQName));
	const restrictionValues =
		values?.get("restrictions")?.get(activeRestriction.get("id")) ?? null;

	const editableRestrictionValues = createImmutableMap({
		beschreibung: restrictionValues?.get("beschreibung"),
		umsetzungshinweis: restrictionValues?.get("umsetzungshinweis"),
	}) as ImmutableMap<Partial<MessageProfileValues>>;

	const profile = restrictionValues?.get("profile")?.get(qnamePath);

	const handleBlur = (name: string, value: string) => {
		const nextDatatype = (values || createImmutableMap()).setIn(
			[
				"restrictions",
				activeRestriction.get("id"),
				name as keyof RestrictionProfileValues,
			],
			value as string,
		);
		applyPatch(setDatatype({ datatype: nextDatatype, id: activeQName }));
	};

	const handleCodelistBlur = (name: string, value: string) => {
		const newProfile = (profile || createImmutableMap()).setIn(
			[
				"konfiguration",
				"codeliste",
				name as keyof Omit<
					CodeListValues,
					"availableInXrepository" | "nutzungsArt"
				>,
			],
			value as string,
		);
		const nextDatatype = (values || createImmutableMap()).setIn(
			["restrictions", activeRestriction.get("id"), "profile", qnamePath],
			newProfile,
		);
		applyPatch(setDatatype({ datatype: nextDatatype, id: activeQName }));
	};
	return (
		<div
			className="restriction-editform"
			data-testid="restriction-editform"
			data-restriction-name={restrictionValues?.get("name") || ""}
		>
			<EditFormField
				className="restriction-editform__restriction-name"
				inputProps={{ "data-testid": "restriction-name" }}
				value={restrictionValues?.get("name") || ""}
				definition={{
					name: "name",
					label: "Name",
				}}
				onBlur={handleBlur}
				error={
					<ValidationDisplay
						resultGroup={ValidationResultGroup.Restrictions}
						locator={{
							targetField: ValidationTargetField.RestrictionName,
							datatypeId: activeNode.id,
							restrictionId: activeRestriction.get("id"),
						}}
					/>
				}
			/>

			<FormFieldRenderer
				fields={fields}
				profile={editableRestrictionValues}
				onBlur={handleBlur}
				activeNode={activeNode}
				activePath={activePath}
			/>
			<FormFieldRenderer
				fields={isCodeliste ? codelistFields : emptyFields}
				profile={profile}
				onBlur={handleCodelistBlur}
				definition={{
					readOnly: true,
				}}
				activeNode={activeNode}
				activePath={activePath}
			/>
		</div>
	);
};

export default RestrictionEditForm;
