import { createSvgIcon } from "@mui/material";

const SchemaPaketIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.25 4C2.25 3.0335 3.0335 2.25 4 2.25H7.16962C7.62086 2.25 8.05467 2.4243 8.38046 2.73652L10.086 4.37097C10.1325 4.41558 10.1945 4.44048 10.2589 4.44048H18C18.9665 4.44048 19.75 5.22398 19.75 6.19048V10.9286C19.75 11.3428 19.4142 11.6786 19 11.6786C18.5858 11.6786 18.25 11.3428 18.25 10.9286V6.19048C18.25 6.05241 18.1381 5.94048 18 5.94048H10.2589C9.80771 5.94048 9.37391 5.76617 9.04812 5.45396L7.3426 3.8195C7.29606 3.7749 7.23409 3.75 7.16962 3.75H4C3.86193 3.75 3.75 3.86193 3.75 4V13.5C3.75 13.6381 3.86193 13.75 4 13.75H7.42857C7.84279 13.75 8.17857 14.0858 8.17857 14.5C8.17857 14.9142 7.84279 15.25 7.42857 15.25H4C3.0335 15.25 2.25 14.4665 2.25 13.5V4Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.6197 14.5775C13.853 14.9197 13.7647 15.3863 13.4225 15.6197L10.5274 17.5936C10.3895 17.6876 10.3807 17.8879 10.5099 17.9936L13.4749 20.4195C13.7955 20.6818 13.8428 21.1543 13.5805 21.4749C13.3182 21.7955 12.8457 21.8428 12.5251 21.5805L9.56009 19.1546C8.65553 18.4145 8.71676 17.0126 9.68242 16.3542L12.5775 14.3803C12.9197 14.147 13.3863 14.2353 13.6197 14.5775Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.3803 21.4225C17.147 21.0803 17.2353 20.6137 17.5775 20.3803L20.4726 18.4064C20.6105 18.3124 20.6193 18.1121 20.4901 18.0064L17.5251 15.5805C17.2045 15.3182 17.1572 14.8457 17.4195 14.5251C17.6818 14.2045 18.1543 14.1572 18.4749 14.4195L21.4399 16.8454C22.3445 17.5855 22.2832 18.9874 21.3176 19.6458L18.4225 21.6197C18.0803 21.853 17.6137 21.7647 17.3803 21.4225Z"
		/>
	</svg>,
	"SchemaPaket",
);

export default SchemaPaketIcon;
