import classNames from "classnames";
import type {
	DetailedHTMLProps,
	ForwardedRef,
	HTMLAttributes,
	ReactNode,
} from "react";
import { forwardRef } from "react";
import type { ExtendProps } from "../../utils/types";
import "./NodeIcon.scss";

type SpanProps = DetailedHTMLProps<
	HTMLAttributes<HTMLSpanElement>,
	HTMLSpanElement
>;
type NodeIconProps = ExtendProps<
	SpanProps,
	{
		children: ReactNode;
		className?: string;
	}
>;

const NodeIcon = forwardRef(
	(
		{ children, className, ...props }: NodeIconProps,
		ref: ForwardedRef<HTMLSpanElement>,
	) => {
		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<span {...props} ref={ref} className={classNames("node-icon", className)}>
				{children}
			</span>
		);
	},
);

NodeIcon.displayName = "NodeIcon";

export default NodeIcon;
