import EditorContent from "../EditorContent";
import EditorMain from "../EditorMain";
import EditorPage, { EditorSection } from "../EditorPage";
import ProfilingEditForm from "../../AtomicComponents/Organisms/ProfilierungEditForm/ProfilierungEditForm";
import ProfilingNodeValidationProvider from "../../AtomicComponents/Organisms/ProfilierungNodeValidationProvider";
import { LRSplit } from "../ui";
import MessageProfilingList from "./MessageProfilingList";
import MessageProfilingNavBar from "./MessageProfilingNavBar";

const MessageProfilingView = (): JSX.Element => {
	return (
		<EditorPage
			data-testid="message-profiling-view"
			section={EditorSection.MessageStructures}
		>
			<MessageProfilingNavBar />
			<EditorMain>
				<LRSplit>
					<LRSplit.Left>
						<ProfilingNodeValidationProvider>
							<MessageProfilingList />
						</ProfilingNodeValidationProvider>
					</LRSplit.Left>
					<LRSplit.Right>
						<EditorContent padTop={false}>
							<ProfilingEditForm />
						</EditorContent>
					</LRSplit.Right>
				</LRSplit>
			</EditorMain>
		</EditorPage>
	);
};

export default MessageProfilingView;
