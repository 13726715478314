import { UnreachableError } from "../../../../../utils/error";
import WorkerBridge from "../../../../WorkerBridge/WorkerBridge";
import type { AnyActionMap } from "../../../../WorkerBridge/types";
import type {
	StoreEvent,
	ModellierungModelEvent,
} from "../../../EventStore/StoreEvent";
import type {
	EventStoreEntryMeta,
	EventStoreLog,
} from "../../../EventStore/eventStore.machine";
import type { ProjektMeta } from "../../project/types";
import type { ProjektId, RawLiteModel } from "../schemas";
import connectModellWorkerBridge from "./connectModellWorkerBridge";
import type {
	ModellEventLogEntry,
	ModellActionMap,
	ModellHandlerMap,
} from "./types";

function isModellEvent(event: StoreEvent): event is ModellierungModelEvent {
	return (
		event.type === "MODELLIERUNG.MODELL.APPLY" ||
		event.type === "MODELLIERUNG.MODELL.UNDO" ||
		event.type === "MODELLIERUNG.MODELL.REDO"
	);
}

export default class ModellWorkerBridge<TActionMap extends AnyActionMap> {
	bridge: WorkerBridge<TActionMap & ModellActionMap>;

	#projektId: ProjektId;

	/**
	 * Create a ModellWorkerBridge for a given projekt from the event log. The
	 * workers state will automatically be initialized with all modell events
	 * from the log.
	 */
	static async fromEventLog<TActionMap extends AnyActionMap>(
		projektId: ProjektId,
		eventLog: EventStoreLog,
		createWorker: () => Worker,
		poolSize?: number,
	): Promise<ModellWorkerBridge<TActionMap>> {
		const modellEntry = eventLog.findLast(
			({ event }) =>
				event.type === "PROJECT.OPEN_EXISTING.SUCCESS" &&
				event.payload.projektMeta.projektId === projektId,
		);
		if (!modellEntry) {
			throw new Error(`No modell could be found for project "${projektId}"`);
		}
		const { event } = modellEntry;
		if (event.type !== "PROJECT.OPEN_EXISTING.SUCCESS") {
			throw new UnreachableError(
				`Type "${event.type}" was asserted to be "PROJECT.OPEN_EXISTING.SUCCESS" in \`eventLog.findLast\``,
			);
		}
		const { rawModel, projektMeta } = event.payload;
		const modellEvents = eventLog
			.filter(
				(entry) =>
					isModellEvent(entry.event) &&
					entry.event.payload.projektId === projektId,
			)
			.map((entry) => entry as ModellEventLogEntry)
			.toArray();
		const searchWorker = new ModellWorkerBridge<TActionMap>(
			projektId,
			createWorker,
			poolSize,
		);
		await searchWorker.init(projektMeta, rawModel, modellEvents);
		return searchWorker;
	}

	constructor(
		projektId: ProjektId,
		createWorker: () => Worker,
		poolSize?: number,
	) {
		this.#projektId = projektId;
		this.bridge = new WorkerBridge<TActionMap & ModellActionMap>(
			createWorker,
			poolSize,
		);
	}

	async init(
		projektMeta: ProjektMeta,
		rawModel: RawLiteModel,
		modellEvents: ModellEventLogEntry[],
	) {
		return this.bridge.enqueue("init", {
			...projektMeta,
			projektId: this.#projektId,
			rawModel,
			modellEvents,
		});
	}

	forward(event: ModellierungModelEvent, meta: EventStoreEntryMeta) {
		return this.bridge.enqueue("sendModellEvent", { event, meta });
	}

	terminate() {
		this.bridge.terminate();
	}

	static connectWorker<TActionMap extends AnyActionMap>(
		handlerMap: ModellHandlerMap<TActionMap>,
	) {
		connectModellWorkerBridge(handlerMap);
	}
}
