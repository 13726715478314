import { v4 as uuid } from "uuid";
import classNames from "classnames";
import type {
	ValidationResult,
	ValidationTargetField,
} from "../AppActor/actors/modellierungModel/validation/validators/types";
import { validationSeverityShortNames } from "../Validation/helpers";

function ValidationFormatterLite<
	TargetField extends ValidationTargetField = ValidationTargetField,
>({ results }: { results: ValidationResult<TargetField>[] }): JSX.Element {
	return (
		<ul className="validation-formatter">
			{results.map((result) => (
				<li
					key={`${result.id}-${uuid()}`}
					className={classNames(
						"validation-formatter__item",
						`validation-formatter__item--severity-${result.severity}`,
					)}
					data-rule-id={result.ruleId}
				>
					<span>
						<span className="validation-formatter__label">
							{validationSeverityShortNames[result.severity]}:{" "}
						</span>
						<span className="validation-formatter__value">
							{result.message}
						</span>
					</span>
				</li>
			))}
		</ul>
	);
}

export default ValidationFormatterLite;
