import { z } from "zod";
import type { AnyZodObject } from "zod";
import type { ImmutableMap } from "@xoev/immutable-map";
import type { FieldDefinition } from "../EditFormFieldLite";
import { ValidationTargetField } from "../AppActor/actors/modellierungModel/validation/validators/types";
import EditFormFieldLite from "../EditFormFieldLite";
import ValidationDisplayLite from "../ValidationDisplayLite";
import { isValidUrl } from "./helper";
import "./EditForm.scss";

type EditFormProps<TSchema extends AnyZodObject> = {
	schema: TSchema;
	values: ImmutableMap<z.infer<TSchema>>;
	fields: FieldDefinition<z.infer<TSchema>>[];
	onWrite: <K extends keyof z.infer<TSchema>>(
		name: K,
		value: z.infer<TSchema>[K],
	) => void;
};
const ValidationTargetFieldSchema = z.nativeEnum(ValidationTargetField);

export default function EditForm<TSchema extends AnyZodObject>({
	fields,
	onWrite,
	schema,
	values,
}: EditFormProps<TSchema>) {
	const handleWrite = (name: string, value: string) => {
		const key = schema.keyof().parse(name);
		onWrite(key, schema.shape[key].parse(value));
	};

	return (
		<form className="edit-form">
			{fields.map((fieldDefinition) => (
				<div key={fieldDefinition.name.toString()}>
					{isValidUrl(values.get(fieldDefinition.name) || "") &&
					fieldDefinition.readOnly ? (
						<div className="edit-form__input--linked">
							<a
								href={values.get(fieldDefinition.name)}
								rel="noreferrer"
								target="_blank"
							>
								{values.get(fieldDefinition.name)}
							</a>
						</div>
					) : (
						<EditFormFieldLite
							definition={{
								...fieldDefinition,
								name: fieldDefinition.name.toString(),
								readOnly: fieldDefinition.readOnly ?? false,
							}}
							value={values.get(fieldDefinition.name) ?? ""}
							onBlur={handleWrite}
							inputProps={{
								"data-is-visible-field": fieldDefinition.isHidden
									? "false"
									: "true",
								"data-field-name": fieldDefinition.name,
							}}
							error={
								fieldDefinition.name && (
									<ValidationDisplayLite
										locator={{
											targetField: ValidationTargetFieldSchema.parse(
												fieldDefinition.name,
											),
										}}
									/>
								)
							}
						/>
					)}
				</div>
			))}
		</form>
	);
}
