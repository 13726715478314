import classNames from "classnames";
import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import type { ExtendProps } from "../../utils/types";
import SystemBannerContext from "./SystemBannerContext";
import { useConst } from "../../hooks";
import "./SystemBanner.scss";

type DivProps = DetailedHTMLProps<
	HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;

function createRootElement() {
	const root = document.createElement("div");
	root.classList.add("system-banner__banner-root");
	return root;
}

function SystemBanner({
	children,
	className,
	heightProp,
	...props
}: ExtendProps<
	DivProps,
	{
		children: ReactNode;
		className?: string;
		heightProp: `--${string}`;
	}
>): JSX.Element {
	const { outletRef } = useContext(SystemBannerContext);
	const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
	const [bannerRef, setBannerRef] = useState<HTMLDivElement | null>(null);

	const root = useConst(createRootElement);

	useEffect(() => {
		outletRef?.append(root);
		return () => {
			outletRef?.removeChild(root);
		};
	}, [outletRef, root]);

	useEffect(() => {
		const refs = [wrapperRef, bannerRef];
		refs.forEach((ref) => {
			if (!ref) return;
			ref.style.setProperty("--system-banner-height", `var(${heightProp})`);
		});
	}, [bannerRef, heightProp, wrapperRef]);

	return (
		<div className="system-banner__wrapper" ref={setWrapperRef}>
			{outletRef && (
				<>
					<div className="system-banner__spacer" />
					{createPortal(
						<div
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...props}
							className={classNames("system-banner", className)}
							ref={setBannerRef}
						>
							{children}
						</div>,
						root,
					)}
				</>
			)}
		</div>
	);
}

export default SystemBanner;
