import { useContext } from "react";
import type ProfilingMetadataValues from "../../../types/ProflierungMetadataValues";
import EditFormField from "../../EditFormField";
import { metadataTargetFieldMapping, runFixer } from "../../Validation/helpers";
import type {
	DefaultAutoFixTargetFields,
	FixerFn,
} from "../../Validation/types";
import { ValidationResultGroup } from "../../Validation/types";
import ValidationDisplay from "../../Validation/ValidationDisplay";
import MetadataFormContext from "./MetadataFormContext";
import type { MetadataFormFieldsProps } from "./types";
import StandardSelect from "./StandardSelect/StandardSelect";

export default function MetadataFormFields({
	fields,
	isSelectModal,
}: MetadataFormFieldsProps): JSX.Element {
	const { disabled, handleChange, handleWrite, values } =
		useContext(MetadataFormContext);

	const createFixHandler =
		<FieldName extends keyof ProfilingMetadataValues>(fieldName: FieldName) =>
		(fixer: FixerFn<DefaultAutoFixTargetFields>) => {
			runFixer(fixer, fieldName, (value) => {
				handleWrite(fieldName, value);
			});
		};

	return (
		<>
			{fields.map((definition) => (
				<div key={definition.name}>
					{definition.name === "standard" && isSelectModal ? (
						<StandardSelect definition={definition} />
					) : (
						<EditFormField
							definition={{
								...definition,
								readOnly: definition.readOnly || disabled,
							}}
							value={values.get(definition.name) || ""}
							onBlur={handleWrite}
							onChange={handleChange}
							inputProps={{
								"data-testid": "metadata-field",
								"data-is-visible-field": definition.isHidden ? "false" : "true",
								"data-field-name": definition.name,
							}}
							error={
								metadataTargetFieldMapping[definition.name] && (
									<ValidationDisplay
										resultGroup={ValidationResultGroup.Metadata}
										locator={{
											targetField: metadataTargetFieldMapping[definition.name],
										}}
										value={values.get(definition.name) || ""}
										onFix={createFixHandler(definition.name)}
									/>
								)
							}
						/>
					)}
				</div>
			))}
		</>
	);
}
