import type { ExtendProps, PropsOf } from "../../../utils/types";
import type DatatypeNode from "./DatatypeNode";
import type EditorSideBar from "../../EditorSideBar";
import type { TreeNodeType } from "../../../types/ProfilierungHome";

export type BaseDatatypesListProps = ExtendProps<
	PropsOf<typeof EditorSideBar.List>,
	{
		activeDataType: TreeNodeType | null;
		getNodeProps?: (
			datatype: TreeNodeType,
		) => Partial<PropsOf<typeof DatatypeNode>>;
		getUrl: (node: TreeNodeType) => string;
		datatypes: TreeNodeType[] | null;
		isLoading?: boolean;
	}
>;

export type DatatypeFilterPredicate = (datatype: TreeNodeType) => boolean;

export enum ListDisplayState {
	ShowFallback = "ShowFallback",
	ShowLoading = "ShowLoading",
	ShowList = "ShowList",
}
