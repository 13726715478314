import type { BRAND } from "zod";
import { walkTreeEntries } from "../../../Tree/treeHelpers";
import type { StandardProjekt } from "../project/types";
import {
	createNewNode,
	getInsertHierachy,
	insertNewNodeIntoNodes,
	listNodeReferences,
	safeUpdateNodes,
	safeUpdatePaketConfig,
} from "./helpers";
import type { LiteModel, LiteNode, LiteNodeKey } from "./schemas";
import {
	LiteNodeType,
	isLiteBaustein,
	isLiteDatatype,
	isLiteEigenschaft,
	isLiteGlobaleEigenschaft,
	isLiteModel,
} from "./schemas";
import { createSelectDirectChildren, createSelectNode } from "./selectors";
import { EditableFieldNameSchema } from "./types";
import type {
	LiteBausteinMap,
	ModellierungPatch,
	ModellierungModelPatchType,
	LiteEigenschaftMap,
	LiteProjectNodes,
} from "./types";

export type ExtractPatch<TPatchType extends ModellierungModelPatchType> =
	Extract<ModellierungPatch, { type: TPatchType }>;

function getRoot(projekt: StandardProjekt): LiteModel {
	const { rootModelId } = projekt.modell;
	const root = createSelectNode(projekt)(rootModelId);
	if (!root || !isLiteModel(root)) {
		throw new Error(
			`Could not find root node "${rootModelId}" in project ` +
				`"${projekt.name}" id: "${projekt.id}"`,
		);
	}
	return root;
}

function removeSubtree(
	projekt: StandardProjekt,
	nodes: LiteProjectNodes,
	node: LiteNode,
) {
	let nextNodes = nodes;
	// Loop through the nodes subtree and delete all nodes
	const entries = walkTreeEntries(
		[node],
		// Don't follow datatype references while looping through the subtree
		createSelectDirectChildren(projekt),
	);
	for (const [, child] of entries) {
		nextNodes = nextNodes.deleteIn([child.liteType, child.id]);
	}
	return nextNodes;
}

function removeReferencesToDatatype(nodes: LiteProjectNodes, node: LiteNode) {
	if (!isLiteDatatype(node)) return nodes;
	const nextEigenschaften = nodes
		.get(LiteNodeType.Eigenschaft)
		.map((eigenschaft) => {
			if (eigenschaft?.datentyp && eigenschaft.datentyp === node.id) {
				return { ...eigenschaft, datentyp: null };
			}
			return eigenschaft;
		});
	return nodes.set(
		LiteNodeType.Eigenschaft,
		nextEigenschaften as LiteEigenschaftMap,
	);
}

function removeExtensionsOfDatatype(nodes: LiteProjectNodes, node: LiteNode) {
	if (!isLiteDatatype(node)) return nodes;
	const nextBausteine = nodes.get(LiteNodeType.Baustein).map((baustein) => {
		if (baustein?.basisDatentyp && baustein.basisDatentyp === node.id) {
			return { ...baustein, basisDatentyp: null };
		}
		return baustein;
	});
	return nodes.set(LiteNodeType.Baustein, nextBausteine as LiteBausteinMap);
}

function removeReferences(
	projekt: StandardProjekt,
	nodes: LiteProjectNodes,
	node: LiteNode,
) {
	const references = listNodeReferences(projekt, node);
	let nextNodes = nodes;
	for (const ref of references) {
		nextNodes = removeReferencesToDatatype(nextNodes, ref.node);
		nextNodes = removeExtensionsOfDatatype(nextNodes, ref.node);
	}
	return nextNodes;
}

function removeFromParent(
	nodes: LiteProjectNodes,
	node: LiteNode,
	parentNode: LiteNode | null,
) {
	// Get the parent node and remove the removed node's id from the parents
	// children list
	if (!parentNode) return nodes;
	const newParentNode = {
		...parentNode,
		children: parentNode.children.filter((childId) => childId !== node.id),
	};
	return nodes.setIn([parentNode.liteType, parentNode.id], newParentNode);
}

function removeMultipleFromParent(
	nodes: LiteProjectNodes,
	nodesToRemove: Array<string & BRAND<"LiteId">>,
	parentNode: LiteNode | null,
) {
	if (!parentNode) return nodes;
	const newParentNode = {
		...parentNode,
		children: parentNode.children.filter(
			(childId) => !nodesToRemove.includes(childId),
		),
	};
	return nodes.setIn([parentNode.liteType, parentNode.id], newParentNode);
}

function applyPatchToModel(
	subtree: keyof LiteModel,
	projekt: StandardProjekt,
	name: string,
	value: string,
) {
	const { nodes, rootModelId } = projekt.modell;
	const root = getRoot(projekt);
	const newSubtree = { ...(root[subtree] as object), [name]: value };
	const nextRoot: LiteModel = {
		...root,
		[subtree]: newSubtree,
	};
	const nextNodes = nodes.setIn([LiteNodeType.Model, rootModelId], nextRoot);
	return {
		...projekt,
		modell: {
			...projekt.modell,
			nodes: nextNodes,
		},
	};
}

const modellierungPatchMap: {
	[TPatchType in ModellierungModelPatchType]: (
		projekt: StandardProjekt,
		patch: ExtractPatch<TPatchType>,
	) => StandardProjekt;
} = {
	addNode: (projekt, patch) => {
		const { kind, position, insertPath, newNodeId } = patch.payload;
		const { parent } = getInsertHierachy(projekt, insertPath, position);
		const newNode = createNewNode(kind, newNodeId, parent.id);
		return {
			...projekt,
			modell: {
				...projekt.modell,
				nodes: insertNewNodeIntoNodes(projekt, insertPath, newNode, position),
			},
		};
	},
	deleteNode: (projekt, patch) => {
		const { deletePath: path } = patch.payload;
		const nodeId = path.at(-1);
		const selectNode = createSelectNode(projekt);
		const node = nodeId && selectNode(nodeId);
		if (!node) {
			throw new Error(
				`Could not find node "${nodeId}" in project ` +
					`"${projekt.name}" id: "${projekt.id}"`,
			);
		}
		const parentNodeId = path.at(-2);
		const parentNode = parentNodeId && selectNode(parentNodeId);

		let nextNodes = projekt.modell.nodes;
		nextNodes = removeSubtree(projekt, nextNodes, node);
		nextNodes = removeReferences(projekt, nextNodes, node);
		nextNodes = removeFromParent(nextNodes, node, parentNode || null);
		return { ...projekt, modell: { ...projekt.modell, nodes: nextNodes } };
	},
	changeDetailsValue: (projekt, patch) => {
		const { name, nodeId, value } = patch.payload;
		EditableFieldNameSchema.parse(name);
		const nodes = safeUpdateNodes(projekt, nodeId, name, value);
		return { ...projekt, modell: { ...projekt.modell, nodes } };
	},

	changeReference: (projekt, patch) => {
		const { nodeId, ref, targetKey } = patch.payload;
		const selectNode = createSelectNode(projekt);
		const activeNode = nodeId && selectNode(nodeId);
		let { nodes } = projekt.modell;
		const refererNode = ref && selectNode(ref);

		// Remove Children for LiteGlobaleEigenschaft
		if (refererNode && isLiteGlobaleEigenschaft(refererNode)) {
			// remove name
			nodes = safeUpdateNodes(
				{ ...projekt, modell: { ...projekt.modell, nodes } },
				nodeId,
				"name",
				"",
			);
			if (activeNode && activeNode.children.length > 0) {
				nodes = removeMultipleFromParent(
					nodes,
					activeNode.children,
					activeNode,
				);
			}
		}

		/* Null all other basisDatentyp datentyp and referenz entries from active node */
		if (
			activeNode &&
			(isLiteBaustein(activeNode) || isLiteEigenschaft(activeNode))
		) {
			const removeKeys: LiteNodeKey[] = [
				"basisDatentyp",
				"datentyp",
				"referenz",
			];
			removeKeys.forEach((key) => {
				nodes = safeUpdateNodes(
					{ ...projekt, modell: { ...projekt.modell, nodes } },
					nodeId,
					key,
					null,
				);
			});
		}
		nodes = safeUpdateNodes(
			{ ...projekt, modell: { ...projekt.modell, nodes } },
			nodeId,
			targetKey,
			ref,
		);

		return { ...projekt, modell: { ...projekt.modell, nodes } };
	},

	changePaketConfig: (projekt, patch) => {
		const { name, nodeId, value } = patch.payload;
		const nodes = safeUpdatePaketConfig(projekt, nodeId, name, value);
		return { ...projekt, modell: { ...projekt.modell, nodes } };
	},
	changeConfigurationValue: (projekt, patch) => {
		const { name, value } = patch.payload;
		return applyPatchToModel("konfiguration", projekt, name, value);
	},
	changeMetadataStandardValue: (projekt, patch) => {
		const { name, value } = patch.payload;
		return applyPatchToModel("metadatenStandard", projekt, name, value);
	},
	changeMetadataVersionValue: (projekt, patch) => {
		const { name, value } = patch.payload;
		return applyPatchToModel("metadatenVersion", projekt, name, value);
	},
};

export default modellierungPatchMap;
