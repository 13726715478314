import { placeholder } from "../../../../../resources/textConstants";
import type {
	LiteId,
	LiteNode,
} from "../../../../AppActor/actors/modellierungModel/schemas";
import { LiteNodeType } from "../../../../AppActor/actors/modellierungModel/schemas";

import {
	CommonMetadataDisplay,
	CommonMetadataEdit,
} from "../shared/CommonMetadata";
import { SchemaPaketDisplay, SchemaPaketEdit } from "../paket/SchemaPaket";
import {
	KardinalitaetDisplay,
	KardinalitaetEdit,
} from "../eigenschaft/Kardinalitaet";
import Renderer, { RendererProvider } from "../Renderer";
import { CodelisteDisplay, CodelisteEdit } from "../eigenschaft/Codeliste";
import { DatentypTableDisplay, DatentypTableEdit } from "../baustein/Datentyp";
import {
	PresetValuesDisplay,
	PresetValuesEdit,
} from "../eigenschaft/PresetValues";
import { LiteKind } from "../../../../AppActor/actors/modellierungModel/LiteKind";
import ReferersDisplay from "../referers/ReferersDisplay";
import ReferersEdit from "../referers/ReferersEdit";
import type { StandardProjekt } from "../../../../AppActor/actors/project/types";

export default function DetailsRenderer({
	activeNode,
	activePath,
	isReadOnly,
	projekt,
}: {
	activeNode: LiteNode;
	activePath: LiteId[];
	isReadOnly: boolean;
	projekt: StandardProjekt;
}): JSX.Element {
	return (
		<RendererProvider
			activeNode={activeNode}
			activePath={activePath}
			isReadOnly={isReadOnly}
			projekt={projekt}
		>
			<div
				data-testid="lite-node-details"
				data-projekt-id={projekt.id}
				data-node-id={activeNode.id}
				data-lite-type={activeNode.liteType}
				data-node-name={activeNode.name || placeholder.anonymousStructure}
				data-readonly={isReadOnly}
			>
				<Renderer
					showForType={[LiteNodeType.Baustein, LiteNodeType.Eigenschaft]}
					displayComponent={ReferersDisplay}
					editComponent={ReferersEdit}
				/>

				<Renderer
					displayComponent={CommonMetadataDisplay}
					editComponent={CommonMetadataEdit}
				/>
				<Renderer
					showForType={LiteNodeType.Paket}
					showForKind={LiteKind.SchemaPaket}
					displayComponent={SchemaPaketDisplay}
					editComponent={SchemaPaketEdit}
				/>
				<Renderer
					showForType={LiteNodeType.Eigenschaft}
					displayComponent={KardinalitaetDisplay}
					editComponent={KardinalitaetEdit}
				/>
				<Renderer
					showForType={LiteNodeType.Eigenschaft}
					displayComponent={PresetValuesDisplay}
					editComponent={PresetValuesEdit}
				/>
				<Renderer
					showForType={LiteNodeType.Eigenschaft}
					displayComponent={CodelisteDisplay}
					editComponent={CodelisteEdit}
				/>
				<Renderer
					showForType={LiteNodeType.Baustein}
					showForKind={[LiteKind.Datentyp, LiteKind.CodeDatentyp]}
					displayComponent={DatentypTableDisplay}
					editComponent={DatentypTableEdit}
				/>
			</div>
		</RendererProvider>
	);
}
