import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import type { ReactNode } from "react";
import type { ExtendProps, PropsOf } from "../../utils/types";

type DataProps = { [K in `data-${string}`]?: string };

export type ConfirmDialogProps = ExtendProps<
	Omit<PropsOf<typeof Dialog>, "open">,
	{
		isOpen: boolean;
		onAccept?: () => void;
		onDismiss?: () => void;
		title?: ReactNode;
		description?: ReactNode;
		cancelText?: ReactNode;
		acceptText?: ReactNode;
		children?: ReactNode;
		componentsProps?: {
			titleProps?: PropsOf<typeof DialogTitle> & DataProps;
			contentProps?: PropsOf<typeof DialogContent> & DataProps;
			contentTextProps?: PropsOf<typeof DialogContentText> & DataProps;
			actionsProps?: PropsOf<typeof DialogActions> & DataProps;
			cancelProps?: PropsOf<typeof Button> & DataProps;
			acceptProps?: PropsOf<typeof Button> & DataProps;
		};
	}
>;

function ConfirmDialog({
	isOpen,
	onAccept,
	onDismiss,
	title,
	description,
	cancelText = "Abbrechen",
	acceptText = "Fortfahren",
	children,
	componentsProps: {
		titleProps = {},
		contentProps = {},
		contentTextProps = {},
		actionsProps = {},
		cancelProps = {},
		acceptProps = {},
	} = {},
	...props
}: ConfirmDialogProps): JSX.Element {
	return (
		<Dialog open={isOpen} onClose={onDismiss} {...props}>
			<DialogTitle {...titleProps}>{title}</DialogTitle>
			<DialogContent {...contentProps}>
				<DialogContentText {...contentTextProps}>
					{description}
				</DialogContentText>
				{children}
			</DialogContent>
			<DialogActions {...actionsProps}>
				<Button
					data-testid="confirmation-declined"
					variant="outlined"
					onClick={onDismiss}
					{...cancelProps}
				>
					{cancelText}
				</Button>
				<Button
					data-testid="confirmation-confirmed"
					variant="contained"
					onClick={onAccept}
					{...acceptProps}
				>
					{acceptText}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmDialog;
