import PlusIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import classNames from "classnames";
import Button from "@mui/material/Button";
import { useTreeContext } from "../TreeContext";
import { useTreeNodeContext } from "./TreeNodeContext";
import type { PathFragment } from "../types";
import "./TreeNodeToggle.scss";
import type { LiteId } from "../../AppActor/actors/modellierungModel/schemas";
import type { StandardProjekt } from "../../AppActor/actors/project/types";
import { useSendStoreEvent } from "../../AppActor/EventStore/hooks";

function TreeNodeToggle({
	fullPath,
	project,
}: {
	fullPath: LiteId[];
	project: StandardProjekt | null;
}): JSX.Element {
	const { isNodeExpandable } = useTreeContext<unknown, PathFragment>();
	const { node, path, isOpen } = useTreeNodeContext<unknown, PathFragment>();
	const isExpandable = isNodeExpandable(node, path);

	const sendStoreEvent = useSendStoreEvent();
	const createHandleAction =
		(type: "MODELLIERUNG_TREE.NODE.OPEN" | "MODELLIERUNG_TREE.NODE.CLOSE") =>
		() => {
			if (!fullPath || !project) return;
			const payload = { projektId: project.id, fullPath };
			sendStoreEvent({ type, payload });
		};

	const handleOpen = createHandleAction("MODELLIERUNG_TREE.NODE.OPEN");
	const handleClose = createHandleAction("MODELLIERUNG_TREE.NODE.CLOSE");

	const handleToggle = () => {
		setTimeout(() => {
			if (isOpen) {
				handleClose();
			} else {
				handleOpen();
			}
		});
	};

	return (
		<div
			className={classNames(
				"treenode-toggle",
				isOpen && "treenode-toggle--expanded",
			)}
		>
			{isExpandable && (
				<>
					<div
						className={classNames(
							"treenode-toggle__icon",
							"treenode-toggle__icon--minus",
							isOpen && "treenode-toggle__icon--active",
						)}
					>
						{/* TODO: fix button overlay padding  */}
						<Button onClick={handleToggle} variant="text">
							<MinusIcon className="treenode-toggle__svg" />
						</Button>
					</div>
					<div
						className={classNames(
							"treenode-toggle__icon",
							"treenode-toggle__icon--plus",
							!isOpen && "treenode-toggle__icon--active",
						)}
					>
						<Button onClick={handleToggle} variant="text">
							<PlusIcon className="treenode-toggle__svg" />
						</Button>
					</div>
				</>
			)}
		</div>
	);
}

export default TreeNodeToggle;
