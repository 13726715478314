import type { Location, NavLinkProps } from "react-router-dom";
import { createPath, useLocation, NavLink, useMatch } from "react-router-dom";
import { MenuItem } from "@xoev/ui";
import type { ExtendProps } from "../../utils/types";
import DynamicInfo from "../ui/DynamicInfo";

export type SubMenuItemProps = ExtendProps<
	NavLinkProps,
	{
		isActive?: (location: Location) => boolean;
		className?: string;
		isDisabled?: boolean;
	}
>;

function SubMenuItem({
	children,
	className,
	isActive,
	to,
	isDisabled = false,
	...props
}: SubMenuItemProps): JSX.Element {
	const location = useLocation();
	const match = useMatch({
		path: typeof to === "string" ? to : createPath(to),
		end: props.end,
		caseSensitive: props.caseSensitive,
	});
	const isActiveLink = !!isActive?.(location) || !!match;

	return (
		<MenuItem>
			{isDisabled ? (
				<MenuItem.Link
					as="span"
					className={className}
					role="link"
					aria-disabled
					tabIndex={0}
					isActive={false}
					data-is-active={false}
					disabled
				>
					{children}
					<DynamicInfo inline="end" infoKey="disabled" />
				</MenuItem.Link>
			) : (
				<MenuItem.Link
					as={NavLink}
					className={className}
					to={to}
					end
					isActive={isActiveLink}
					data-is-active={isActiveLink}
					{...props}
				>
					{children}
				</MenuItem.Link>
			)}
		</MenuItem>
	);
}

export default SubMenuItem;
