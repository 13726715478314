import type { SimpleAsyncGenerator } from "../../../../../../utils/generator";
import type { LiteId } from "../../schemas";

export enum Severity {
	Info = "info",
	Warning = "warning",
	Error = "error",
}

/**
 * Unique names for all input fields, that exist in the app and can be used in
 * the validation
 */
export enum ValidationTargetField {
	// Metadaten Standard
	NameKurz = "nameKurz",
	NameLang = "nameLang",
	NameTechnisch = "nameTechnisch",
	Kennung = "kennung",
	Beschreibung = "beschreibung",
	HerausgebernameLang = "herausgebernameLang",
	HerausgebernameKurz = "herausgebernameKurz",
	ExterneWebsite = "externeWebsite",

	// Metadaten Version
	Version = "version",
	VersionXoevHandbuch = "versionXoevHandbuch",
	VersionXGenerator = "versionXGenerator",
	VersionModellierungswerkzeug = "versionModellierungswerkzeug",
	Lizenz = "lizenz",
	AenderungZurVorversion = "aenderungZurVorversion",
	Bezugsort = "bezugsort",
	VersionBeschreibung = "versionBeschreibung",

	// Projekt Konfiguration
	FassungVom = "fassungVom",
	Namespace = "namespace",
	Prefix = "prefix",
	SchemaLocationBase = "schemaLocationBase",
	StandardEigenschaften = "standardEigenschaften",
	XSDNamedTypeNameSuffix = "xsdNamedTypeNameSuffix",

	// Shared node fields
	NodeName = "nodeName",
	NodeBeschreibung = "nodeBeschreibung",
	NodeUmsetzungshinweis = "nodeUmsetzungshinweis",

	// Schemapaket
	SchemaPrefix = "schemaPrefix",
	SchemaUri = "schemaUri",
	SchemaDateiname = "schemaDateiname",
}

export const metadatenStandardValidationTarget = [
	ValidationTargetField.NameKurz,
	ValidationTargetField.NameLang,
	ValidationTargetField.NameTechnisch,
	ValidationTargetField.Kennung,
	ValidationTargetField.Beschreibung,
	ValidationTargetField.HerausgebernameLang,
	ValidationTargetField.HerausgebernameKurz,
	ValidationTargetField.ExterneWebsite,
];
export const metadatenVersionValidationTarget = [
	ValidationTargetField.Version,
	ValidationTargetField.VersionXoevHandbuch,
	ValidationTargetField.VersionXGenerator,
	ValidationTargetField.VersionModellierungswerkzeug,
	ValidationTargetField.Lizenz,
	ValidationTargetField.AenderungZurVorversion,
	ValidationTargetField.Bezugsort,
	ValidationTargetField.VersionBeschreibung,
];
export const konfigurationValidationTarget = [
	ValidationTargetField.FassungVom,
	ValidationTargetField.Namespace,
	ValidationTargetField.Prefix,
	ValidationTargetField.SchemaLocationBase,
	ValidationTargetField.StandardEigenschaften,
	ValidationTargetField.XSDNamedTypeNameSuffix,
];

// Validation rule definitions
export interface RuleBase<Context, TargetField> {
	/**
	 * The target field is used to identify the input field in the gui, of which
	 * the value is checked by this rule. It is later used to display a helpful
	 * message in the validation dialog
	 */
	targetField: TargetField;
	/** A unique id that identifies the rule */
	id: string;
	/**
	 * The severity of the rule (`Error`, `Warning`, `Info`). When a rule with
	 * severity `Error` is present, the project cannot be saved.
	 * Validators should define a default severity, which is why the field is
	 * optional
	 */
	severity?: Severity;
	/**
	 * The function used to actually validate the input. Note that it is only
	 * responsible to check its specific rule. Should the validation not be
	 * possible because some other rule is violated, it should return `true`
	 * early, to skip this rule (for example a rule, that is supposed to check
	 * whether the input does not contain certain forbidden characters, it cannot
	 * run when the input is empty. In that case, the rule should be skiped,
	 * since, either empty values are allowed, or must be checked by another rule)
	 */
	isValid(context: Context): boolean;
	/**
	 * The error message, that is displayed when the rule fails. Can be a string
	 * or a function, returning the message
	 */
	message: string | ((context: Context) => string);
}

export type ValidationResult<TargetField> = {
	id: LiteId;
	idPath: LiteId[];
	severity: Severity;
	message: string;
	targetField: TargetField;
	ruleId: string;
};

export type Validator<
	ValidationResultT extends ValidationResult<ValidationTargetField>,
> = SimpleAsyncGenerator<ValidationResultT | null>;

export type Locator<TargetField> = Partial<{
	targetField: TargetField;
	nodeId: LiteId;
	path: LiteId[];
}>;

export type ResultType = ValidationResult<ValidationTargetField>;
