import type { ReactNode } from "react";
import { useMemo } from "react";
import SegmentTrie from "@xoev/segment-trie";
import { useAppSelector } from "../../../redux/hooks";
import { selectModellContainer } from "../../../redux/treeSlice";
import useActiveDataType from "../../../components/DatatypesView/useActiveDataType";
import useActiveRestriction from "../../../components/DatatypesView/useActiveRestriction";
import { useStateSelector } from "../../../components/EditorState";
import { selectStandard } from "../../../components/EditorState/selectors";
import { useValidationContext } from "../../../components/Validation/hooks";
import type {
	ProfileValidationResult,
	RestrictionProfileValidationResult,
	ValidationTargetField,
} from "../../../components/Validation/types";
import {
	ValidationResultGroup,
	Severity,
} from "../../../components/Validation/types";
import ProfilierungNodeValidationContext from "./ProfilierungNodeValidationContext";
import type { DatatypeSeverityMap, SeverityMap, SeverityTrie } from "./types";
import type { Nullish } from "../../../utils/types";
import type { LiteModellContainer } from "../../../components/AppActor/actors/modellierungModel/types";
import { createSelectDefinitionPathFromModell } from "../../../components/AppActor/actors/modellierungModel/selectors";

const createTrie = (): SeverityTrie => new SegmentTrie((path) => path);

function createEmptySeverityMap(): SeverityMap {
	return {
		[Severity.Info]: createTrie(),
		[Severity.Warning]: createTrie(),
		[Severity.Error]: createTrie(),
	};
}
function createEmptyDatatypeSeverityMap(): DatatypeSeverityMap {
	return {
		[Severity.Info]: new Set(),
		[Severity.Warning]: new Set(),
		[Severity.Error]: new Set(),
	};
}

function createSeverityMap(
	modell: Nullish<LiteModellContainer>,
	validationResults:
		| ProfileValidationResult<ValidationTargetField>[]
		| RestrictionProfileValidationResult<ValidationTargetField>[],
): SeverityMap {
	const severityMap = createEmptySeverityMap();
	if (!modell) return severityMap;
	for (const { severity, path } of validationResults) {
		severityMap[severity].insert(path, true);
	}
	return severityMap;
}

function filterRelevant<
	T extends { restrictionId: string; datatypeId: string },
>(
	list: T[],
	restrictionId: string | undefined,
	datatypeId: string | undefined,
): T[] {
	return list.filter(
		(r) => r.restrictionId === restrictionId && r.datatypeId === datatypeId,
	);
}

export default function ProfilierungNodeValidationProvider({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const activeRestriction = useActiveRestriction();
	const activeDataType = useActiveDataType();
	const { result } = useValidationContext();
	const standard = useStateSelector(selectStandard());
	const modell = useAppSelector(selectModellContainer(standard));

	const messageProfileSeverityMap = useMemo(
		() =>
			createSeverityMap(modell, result[ValidationResultGroup.MessageProfiles]),
		[modell, result],
	);

	const restrictionId = activeRestriction?.get("id");
	const datatypeId = activeDataType.activeDataType?.id;
	const restrictionMaps = useMemo(() => {
		const restrictionProfileResults = filterRelevant(
			result[ValidationResultGroup.RestrictionProfiles],
			restrictionId,
			datatypeId,
		);
		const restrictionResults = filterRelevant(
			result[ValidationResultGroup.Restrictions],
			restrictionId,
			datatypeId,
		);
		const restrictionProfileMap = createSeverityMap(
			modell,
			restrictionProfileResults,
		);
		for (const { datatypeId: dtId, severity } of restrictionResults) {
			const dtDefinitionPath = modell
				? createSelectDefinitionPathFromModell(modell)(dtId)
				: [];
			restrictionProfileMap[severity].insert(dtDefinitionPath, true);
		}
		const restrictionMap = createEmptyDatatypeSeverityMap();
		for (const { restrictionId: rsId, severity } of [
			...result[ValidationResultGroup.RestrictionProfiles],
			...result[ValidationResultGroup.Restrictions],
		]) {
			restrictionMap[severity].add(rsId);
		}
		return { restrictionProfileMap, restrictionMap };
	}, [datatypeId, modell, restrictionId, result]);

	const { restrictionMap, restrictionProfileMap } = restrictionMaps;

	const ctx = useMemo(
		() => ({
			messageProfiles: messageProfileSeverityMap,
			restrictions: restrictionMap,
			restrictionProfiles: restrictionProfileMap,
		}),
		[messageProfileSeverityMap, restrictionMap, restrictionProfileMap],
	);

	return (
		<ProfilierungNodeValidationContext.Provider value={ctx}>
			{children}
		</ProfilierungNodeValidationContext.Provider>
	);
}
