import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import type { ExtendProps, PropsOf } from "../../../utils/types";

type ButtonMenuItemProps = ExtendProps<
	PropsOf<typeof MenuItem>,
	{ onClick: () => void; title: string; children: React.ReactNode }
>;

const ButtonMenuItem = ({
	onClick,
	title,
	children,
	...props
}: ButtonMenuItemProps): JSX.Element => (
	<MenuItem onClick={onClick} {...props}>
		<ListItemIcon>{children}</ListItemIcon>
		<ListItemText>{title}</ListItemText>
	</MenuItem>
);
export default ButtonMenuItem;
