import type { ReactNode } from "react";
import { Link } from "react-router-dom";
import useDelayedValue from "../../../hooks/useDelayedValue";
import type { ExtendProps } from "../../../utils/types";
import { RequestStatus } from "../../../components/Api";
import { useEditorData } from "../../../components/EditorData";
import { useStateSelector } from "../../../components/EditorState";
import { selectHasActiveProject } from "../../../components/EditorState/selectors";
import InactivePageGuard from "../../../components/InactivePageGuard";
import type { InactivePageGuardProps } from "../../../components/InactivePageGuard/InactivePageGuard";

const DISPLAY_GUARD_DELAY = 300;

type ProfilierungPageGuardProps = ExtendProps<
	InactivePageGuardProps,
	{ children?: ReactNode }
>;

export default function ProfilierungPageGuard({
	children,
	...props
}: ProfilierungPageGuardProps): JSX.Element {
	const { initStatus } = useEditorData();
	const isLoadingOrIdle =
		initStatus === RequestStatus.Loading || initStatus === RequestStatus.Idle;
	// Delay the loading state slightly, so the ui does not flicker when the
	// state switches
	const delayedIsLoadingOrIdle = useDelayedValue(
		isLoadingOrIdle,
		DISPLAY_GUARD_DELAY,
	);
	const hasActiveProject = useStateSelector(selectHasActiveProject());
	const isLocked = !hasActiveProject && !delayedIsLoadingOrIdle;

	return (
		<InactivePageGuard
			{...props}
			isLocked={isLocked}
			heading="Aktuell ist kein Profilierungsprojekt aktiv"
			description={
				<>
					Wechseln Sie zur Startseite der Profilierung und legen Sie ein{" "}
					<Link to="/profilierung">neues&nbsp;Profilierungsprojekt</Link> an.
				</>
			}
		>
			{children}
		</InactivePageGuard>
	);
}
