import type { MessageProfileConfiguration } from "../../../EditorState/types";
import { Severity, ValidationTargetField } from "../../types";
import type {
	ProfileRule,
	ProfileValidationContext,
	AutoFixFn,
} from "../../types";
import type { DatatypeValidationKey, KeyToValidationField } from "../types";
import {
	selectQNameFromModell,
	selectReferencedDatatypeFromModell,
} from "../../../AppActor/actors/modellierungModel/selectors";
import type { ValidationDefinition } from "../../../AppActor/actors/modellierungModel/schemas";

function toList<T>(itemOrList: T | T[]): T[] {
	return Array.isArray(itemOrList) ? itemOrList : [itemOrList];
}

function getUsedType(context: ProfileValidationContext) {
	const { standardNode, modell } = context;
	return selectReferencedDatatypeFromModell(modell, standardNode);
}

function getTypeValidation(context: ProfileValidationContext) {
	const datatype = getUsedType(context);
	return datatype?.validierung ?? null;
}

function matchesPattern(
	validation: ValidationDefinition,
	value: string | string[],
) {
	if (!validation.pattern) return true;
	const regex = new RegExp(validation.pattern);
	return toList(value).every((v) => regex.test(v));
}

// Check the values against the regexes, defined in the datatypes
function createDatatypeRule(
	key: DatatypeValidationKey,
	label: string,
	targetField: ValidationTargetField,
): ProfileRule {
	return {
		targetField,
		id: `datatype-validation-regex-matches-${key}`,
		target: ["konfiguration"],
		isValid(context) {
			const { profile } = context;
			const validation = getTypeValidation(context);
			if (!validation) return true;
			const value = profile.getIn(["konfiguration", key]);
			// If there is no matching datatype definition or the value is empty,
			// ignore the rule. Otherwise, check if the regex matches
			if (!value) return true;
			return matchesPattern(validation, value);
		},
		message: (context) => {
			const type = getUsedType(context);
			const qname = type && selectQNameFromModell(context.modell, type);
			return (
				type?.validierung?.fehlermeldung ||
				`Der ${label} stimmt nicht mit dem im Standard definierten ` +
					`Wert überein. Erwartet wurde ein Wert vom Typ "${qname}", ` +
					`mit dem Pattern "${type?.validierung?.pattern}".`
				// TODO: Re-enable example value
				// `wie z.B. "${type?.beispielWert}".`
			);
		},
	};
}

function matchesBounds(
	value: string | string[],
	propertyValue: string,
	matchesBoundsCb: (value: number, propertyValue: number) => boolean,
) {
	return toList(value).every((v) => {
		const numericValue = Number(v);
		const numericPropertyValue = Number(propertyValue);
		if (Number.isNaN(numericValue) || Number.isNaN(numericPropertyValue)) {
			return true;
		}
		return matchesBoundsCb(numericValue, numericPropertyValue);
	});
}

// Check the values against the min and max values defined in the properties
function createBoundsRules(
	key: DatatypeValidationKey,
	label: string,
	targetField: ValidationTargetField,
): ProfileRule[] {
	const createRule = (
		property: "minValue" | "maxValue",
		matchesBoundsCb: (value: number, propertyValue: number) => boolean,
		createMessage: (propertyValue: string | null) => string,
	): ProfileRule => ({
		targetField,
		id: `datatype-validation-matches-${property}-${key}`,
		target: ["konfiguration"],
		isValid(context) {
			const { profile } = context;
			const validation = getTypeValidation(context);
			if (!validation) return true;
			const value = profile.getIn(["konfiguration", key]);
			// If there is no matching datatype definition or the value is empty,
			// ignore the rule
			if (!value) return true;
			// Return early if the property or value don't exist or are NaN
			const propertyValue = validation[property];
			if (!propertyValue) return true;
			// If the regex does not match, let that rule create the error message
			if (!matchesPattern(validation, value)) return true;
			return matchesBounds(value, propertyValue, matchesBoundsCb);
		},
		message: (context) => {
			const validation = getTypeValidation(context);
			return createMessage(validation?.[property] || null);
		},
	});
	return [
		createRule(
			"minValue",
			(value, minValue) => value >= minValue,
			(minValue) => `Der ${label} darf nicht kleiner als ${minValue} sein.`,
		),
		createRule(
			"maxValue",
			(value, maxValue) => value <= maxValue,
			(maxValue) => `Der ${label} darf nicht größer als ${maxValue} sein.`,
		),
	];
}
/* TODO: add to translations */

function createValueDiffersFromFixedValueProfileRule<
	Key extends Exclude<DatatypeValidationKey, "fixedWert">,
>(
	isValueEmpty: (value: MessageProfileConfiguration[Key]) => boolean,
	autoFix: AutoFixFn<KeyToValidationField[Key]>,
) {
	return (
		key: Key,
		label: string,
		targetField: KeyToValidationField[Key],
	): ProfileRule => {
		const profileRule: ProfileRule = {
			severity: Severity.Error,
			targetField,
			id: `${key}-differs-from-fixedWert`,
			target: ["konfiguration"],
			isValid({ profile }) {
				const fixedValue = profile.getIn(["konfiguration", "fixedWert"]);
				const value = profile.getIn(["konfiguration", key]);
				return !fixedValue || !isValueEmpty(value);
			},
			message: `Wenn ein "Fix-Wert" eingegeben ist, darf kein "${label}" vorhanden sein.`,
			autoFix,
			autoFixDescription: "Wert entfernen",
		};
		return profileRule;
	};
}

const createValueDifferesFromFixedValue =
	createValueDiffersFromFixedValueProfileRule<"defaultWert" | "beispielWert">(
		(value) => !!value,
		() => () => "",
	);

const createExampleValueMessageDifferesFormFixedValue =
	createValueDiffersFromFixedValueProfileRule<"beispielWertNachricht">(
		(values) => !!values && values.length > 0,
		() => () => [],
	);

const datatypeValidationRules = [
	createDatatypeRule("fixedWert", "Fix-Wert", ValidationTargetField.FixedValue),
	/* TODO: add to translations */
	createDatatypeRule(
		"defaultWert",
		"Standard-Wert",
		ValidationTargetField.DefaultValue,
	),
	createDatatypeRule(
		"beispielWert",
		"Beispiel-Wert",
		ValidationTargetField.ExampleValue,
	),
	createDatatypeRule(
		"beispielWertNachricht",
		"Wert für Beispielnachrichten",
		ValidationTargetField.ExampleValueMessage,
	),

	...createBoundsRules(
		"fixedWert",
		"Fix-Wert",
		ValidationTargetField.FixedValue,
	),
	...createBoundsRules(
		"defaultWert",
		"Standard-Wert",
		ValidationTargetField.DefaultValue,
	),
	...createBoundsRules(
		"beispielWert",
		"Beispiel-Wert",
		ValidationTargetField.ExampleValue,
	),
	...createBoundsRules(
		"beispielWertNachricht",
		"Wert für Beispielnachrichten",
		ValidationTargetField.ExampleValueMessage,
	),

	createValueDifferesFromFixedValue(
		"defaultWert",
		"Standard-Wert",
		ValidationTargetField.DefaultValue,
	),
	createValueDifferesFromFixedValue(
		"beispielWert",
		"Beispiel-Wert",
		ValidationTargetField.ExampleValue,
	),
	createExampleValueMessageDifferesFormFixedValue(
		"beispielWertNachricht",
		"Wert für Beispielnachrichten",
		ValidationTargetField.ExampleValueMessage,
	),
];

export default datatypeValidationRules;
