import Info from "../../../../../ui/Info/Info";
/* TODO: add to translations */

const ValueInfo = ({ label }: { label: string }) => (
	<Info>
		Wenn ein {`"Fix-Wert"`} eingegeben ist, ist {`"${label}"`} nicht
		bearbeitbar.
	</Info>
);

export default ValueInfo;
