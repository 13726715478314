import { useState, useEffect, useMemo } from "react";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, DialogContentText, Stack } from "@mui/material";
import { VisuallyHidden } from "../../../../components/ui";
import {
	RequestMethods,
	RequestStatus,
	useResource,
} from "../../../../components/Api";
import type SavedProfileData from "../../../../types/SavedProfileData";
import { useEditorData } from "../../../../components/EditorData";
import createProfileData from "../../utils/createProfileData";
import { EMPTY_EDITOR_STATE } from "../../../../components/EditorState/helpers";
import MetadataForm from "../../../../components/MetadataView/MetadataForm";
import type { MetadataMap } from "../../../../components/EditorState/types";
import ValidationContext from "../../../../components/Validation/ValidationContext";
import { createMetadataFormHelpers } from "../../../../components/MetadataView/MetadataEditForm/metadataFormHelpers";
import useValidators from "../../../../components/Validation/useValidators";
import validateMetadata from "../../../../components/Validation/validators/validateMetadata";
import { createEmptyMainValidators } from "../../../../components/Validation/useMainValidators";
import type { ValidationRunResult } from "../../../../components/Validation/types";
import {
	Severity,
	ValidationResultGroup,
} from "../../../../components/Validation/types";
import { useHtmlId, useSyncedRef } from "../../../../hooks";
import RequestErrorNotification from "../../../../components/RequestErrorNotification";
import { executeValidators } from "../../../../components/Validation/validationExecution";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import { generateKennung } from "../../../../utils/misc";
import { useGetStandardsQuery } from "../../../../redux/apiSlice";
import { useTranslateRequestMeta } from "../../../../utils/requestFormatters";
import ModalHeader from "../../../../components/ui/Modal/ModalHeader";
import "./SelectStandardModal.scss";
import { useAppDispatch } from "../../../../redux/hooks";

import {
	ValidationBase,
	setValidationBase,
} from "../../../../redux/messagesSlice";
import useReloadProfilierung from "../../hooks/useReloadProfilierung";
import type { ProjectPostResponse } from "../../types/types";
import type ProfilingMetadataValues from "../../../../types/ProflierungMetadataValues";

const EMPTY_METADATA = EMPTY_EDITOR_STATE.get("metadaten");

function createMetadataValidators(values: MetadataMap) {
	const mainValidators = createEmptyMainValidators();
	mainValidators[ValidationResultGroup.Metadata] = validateMetadata(values);
	return mainValidators;
}

function hasValidationErrors(validationResults: ValidationRunResult) {
	const results = validationResults[ValidationResultGroup.Metadata];
	return results.some((result) => result.severity === Severity.Error);
}

const SelectStandardModal = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}): JSX.Element => {
	const [values, setValues] = useState(EMPTY_METADATA);
	const [localState, setLocalState] = useState(values);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const validators = useMemo(() => createMetadataValidators(values), [values]);
	const validationCtx = useValidators(validators);
	const dispatch = useAppDispatch();

	const handleWrite = (name: string, value: string) => {
		if (value && isOpen) {
			setValues((prevValues) => {
				const kennung = generateKennung({
					standard: prevValues.get("standard"),
					herausgeber: prevValues.get("herausgeber"),
					nameKurz: prevValues.get("nameKurz"),
					version: prevValues.get("version"),
					// Overwrite `herausgeber`, `nameKurz` and `version` when they change
					[name]: value,
				});
				return prevValues
					.set(
						name as keyof ProfilingMetadataValues,
						value.trim().replace(/\s+/g, " "),
					)
					.set("kennung", kennung);
			});
		}
	};

	const handleChange = (e: { target: { name?: string; value: string } }) => {
		const { name, value } = e.target;
		if (name && value) {
			setLocalState((prevValues) => {
				return prevValues.set(
					name as keyof ProfilingMetadataValues,
					value as string,
				);
			});
		}
	};
	const { initContainer } = useEditorData();
	const reload = useReloadProfilierung();

	const {
		data: standardsData,
		error,
		isError,
		isFetching,
		isLoading,
		isSuccess,
	} = useGetStandardsQuery();

	const { status: standardsStatus, error: standardsError } =
		useTranslateRequestMeta({
			isError,
			isFetching,
			isLoading,
			isSuccess,
			error,
		});

	const {
		data: projectData,
		request: requestProjekt,
		error: projectError,
		status: projectStatus,
	} = useResource<ProjectPostResponse, RequestMethods.Post>();

	const onCloseRef = useSyncedRef(onClose);
	useEffect(() => {
		if (projectStatus === RequestStatus.Success) {
			onCloseRef.current();
		}
	}, [projectStatus, onCloseRef]);

	const { visibleFields, requiredFields } = useMemo(() => {
		const options = (standardsData || []).map((opt) => ({
			value: opt.kennung,
			label: `${opt.nameKurz} ${opt.version}`,
			"data-testid": "standard-select-option",
			"data-standard-value": opt.kennung,
		}));
		const helpers = createMetadataFormHelpers({
			type: "select",
			options,
			isRequired: true,
		});
		const requiredFormFields = [
			...helpers.visibleFields,
			...helpers.hiddenFields,
		]
			.filter((field) => field.isRequired)
			.map((field) => field.name);
		return { ...helpers, requiredFields: requiredFormFields };
	}, [standardsData]);

	const isRequiredDataComplete = requiredFields.every(
		(field) => !!localState.get(field),
	);

	const isEditDisabled =
		projectStatus === RequestStatus.Loading || isSubmitting;
	const isProjectLoading = isEditDisabled;
	const isSubmitDisabled = isEditDisabled || !isRequiredDataComplete;
	const isDialogOpen = isOpen || isProjectLoading;

	useEffect(() => {
		if (projectData) {
			const initState: SavedProfileData = createProfileData(projectData);
			initContainer(initState);
			dispatch(
				setValidationBase({
					validierungsBasis: ValidationBase.ActiveProfile,
				}),
			);
			reload();
		}
	}, [projectData, initContainer, reload, dispatch]);

	const isMountedRef = useIsMountedRef();
	// Run the validation manually on submit, so we can prevent the submit when
	// we still have invalid data. This way we don't need to synchronize the
	// validation state with clicked states. It also means that we have to run
	// the validation twice. Once on every state change, and once before submit
	const handleClick = () => {
		// Fill in all missing required values as an emtpy string, so we get all
		// `required data missing` validation errors
		let completeValues = values;
		requiredFields.forEach((field) => {
			if (!completeValues.get(field)) {
				completeValues = completeValues.set(field, "");
			}
		});
		// Also set the filled values to the state, so the component validation
		// also displays errors at the inputs
		setValues(completeValues);
		// Block every input while we're checking the data
		setIsSubmitting(true);
		// Run the validation, and don't chunk the validation, since we're not
		// validating lots of data
		executeValidators(createMetadataValidators(completeValues), {
			chunkSize: Infinity,
		})
			.then((results) => {
				if (!isMountedRef.current) return;
				if (isRequiredDataComplete && !hasValidationErrors(results)) {
					requestProjekt("/v1/projekt", {
						method: RequestMethods.Post,
						data: values.toJS(),
						headers: {
							"Content-Type": "application/json",
						},
					});
				}
			})
			.catch(() => {
				// We can ignore the failure case here, as we'll pick it up with the
				// useValidators hook
			})
			.finally(() => {
				if (!isMountedRef.current) return;
				// Unlock the form
				setIsSubmitting(false);
			});
	};

	const handleClose = () => {
		setValues(EMPTY_METADATA);
		setLocalState(EMPTY_METADATA);
		onClose();
	};

	const footnoteId = useHtmlId();

	return (
		<>
			<RequestErrorNotification
				id="select-standard-modal-project-request"
				error={projectError}
				status={projectStatus}
			>
				Fehler beim Anlegen des Projekts. Bitte versuchen Sie es erneut. Sollte
				der Fehler weiterhin auftreten, wenden Sie sich bitte an die
				Administrator:innen
			</RequestErrorNotification>
			<RequestErrorNotification
				id="select-standard-modal-standards-request"
				error={standardsError}
				status={standardsStatus}
			>
				Fehler beim Abrufen der verfügbaren Standards. Bitte versuchen Sie es
				erneut. Sollte der Fehler weiterhin auftreten, wenden Sie sich bitte an
				die Administrator:innen
			</RequestErrorNotification>
			<Dialog
				className="select-standard-modal"
				onClose={handleClose}
				open={isDialogOpen}
				maxWidth="lg"
			>
				<div
					data-testid="select-standard-modal-content"
					data-is-open={isDialogOpen}
				>
					<ModalHeader
						title="Profilierungsprojekt anlegen"
						handleClose={handleClose}
					/>
					<DialogContent>
						<Stack spacing={2}>
							<DialogContentText>
								Bitte wählen Sie einen Standard für die Profilierung aus und
								geben Sie die Metadaten der Profilierung an.
							</DialogContentText>
							<div data-testid="create-project-form">
								<ValidationContext.Provider value={validationCtx}>
									<VisuallyHidden>
										<span
											data-testid="create-project-validation-status"
											data-status={validationCtx.status}
											aria-hidden
										/>
									</VisuallyHidden>
									<MetadataForm
										className="select-standard-modal__form"
										values={values}
										onWrite={handleWrite}
										onChange={handleChange}
										disabled={isEditDisabled}
									>
										<MetadataForm.Fields fields={visibleFields} isSelectModal />
										<LoadingButton
											className="select-standard-modal__create-button"
											variant="contained"
											type="submit"
											onClick={handleClick}
											data-testid="select-standard-modal__open-button"
											disabled={isSubmitDisabled}
											loading={isProjectLoading}
											aria-describedby={footnoteId}
										>
											Anlegen
										</LoadingButton>
									</MetadataForm>
								</ValidationContext.Provider>
							</div>
							<small
								id={footnoteId}
								className="select-standard-modal__footnote"
							>
								<span aria-hidden>* </span>Das Anlegen eines neuen Projekts ist
								erst möglich, wenn alle mit * gekennzeichneten Pflichtfelder
								befüllt sind
							</small>
						</Stack>
					</DialogContent>
				</div>
			</Dialog>
		</>
	);
};

export default SelectStandardModal;
