import InfoIcon from "@mui/icons-material/InfoOutlined";
import HelpIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import type { ReactNode } from "react";
import VisuallyHidden from "../VisuallyHidden";
import getHelpPath from "../../../adocBase";
import { useAppInfo } from "../../AppInfoProvider";
import type {
	ExtendProps,
	ExtractOverlap,
	PropsOf,
} from "../../../utils/types";
import "./Info.scss";

export type InfoProps = ExtendProps<
	ExtractOverlap<PropsOf<"a">, PropsOf<"span">>,
	{
		children: ReactNode;
		className?: string;
		helpLink?: string;
		inline?: "start" | "end";
	}
>;

function Info({
	children,
	className,
	helpLink,
	inline,
	...props
}: InfoProps): JSX.Element {
	const { info } = useAppInfo();
	const basePath = info?.config?.["docs-plattform"];
	const classes = classNames(
		"xui-info",
		helpLink && "xui-info--link",
		inline && `xui-info--inline-${inline}`,
		className,
	);
	const Svg = helpLink ? HelpIcon : InfoIcon;
	const content = (
		<>
			<VisuallyHidden>Information: {children}</VisuallyHidden>
			<Tooltip title={children} aria-hidden>
				<Svg fontSize="inherit" color="info" className="xui-info__icon" />
			</Tooltip>
		</>
	);
	return helpLink ? (
		<a
			{...props}
			className={classes}
			href={getHelpPath(basePath, helpLink)}
			target="_blank"
			rel="noreferrer noopener"
		>
			{content}
		</a>
	) : (
		<span className={classes} {...props}>
			{content}
		</span>
	);
}

export default Info;
