import { createSvgIcon } from "@mui/material";

const ProfilIcon = createSvgIcon(
	<svg
		width="16"
		height="17"
		viewBox="0 0 16 17"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M8.49887 5.45486C9.77949 5.22019 10.75 4.09843 10.75 2.75C10.75 1.23122 9.51878 0 8 0C6.48122 0 5.25 1.23122 5.25 2.75C5.25 4.09843 6.22051 5.22019 7.50113 5.45486C7.50038 5.46607 7.5 5.47739 7.5 5.48879V8.28168L4.30876 11.4729C3.86574 11.1676 3.32875 10.9888 2.75 10.9888C1.23122 10.9888 0 12.22 0 13.7388C0 15.2576 1.23122 16.4888 2.75 16.4888C4.26878 16.4888 5.5 15.2576 5.5 13.7388C5.5 13.16 5.32122 12.623 5.01587 12.18L8 9.1959L10.9841 12.18C10.6788 12.623 10.5 13.16 10.5 13.7388C10.5 15.2576 11.7312 16.4888 13.25 16.4888C14.7688 16.4888 16 15.2576 16 13.7388C16 12.22 14.7688 10.9888 13.25 10.9888C12.6713 10.9888 12.1343 11.1676 11.6912 11.4729L8.5 8.28168V5.48879C8.5 5.47739 8.49962 5.46608 8.49887 5.45486Z" />
	</svg>,
	"Standard",
);

export default ProfilIcon;
