import { placeholder } from "../../../resources/textConstants";
import type { DatatypeMap, StateProfileMap } from "../../EditorState/types";
import cardinalityRules from "../rules/messageProfiling/cardinalityRules";
import datatypeValidationRules from "../rules/messageProfiling/datatypeValidationRules";
import recursionRules from "../rules/messageProfiling/recursionRules";
import propertyRules from "../rules/messageProfiling/attributeRules";
import type {
	ProfileRule,
	ProfileValidationContext,
	ProfileValidationResult,
	ValidationTargetField,
	Validator,
} from "../types";
import { ValidationResultGroup, Severity } from "../types";
import { isDeepEmpty } from "../../EditorState/helpers";
import codelistRules from "../rules/messageProfiling/codelistRules";
import type { RequestCodeListOptions } from "../../../redux/apiSlice";
import type { CodelistStateResult } from "../../../redux/codelistSlice";
import { ERROR_RESULT } from "../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/CodeListRestrictionRenderer/CodeListComboBox/useCodelistRequest";
import { parseQNamePath } from "../../AppActor/actors/modellierungModel/schemas";
import type { LiteModellContainer } from "../../AppActor/actors/modellierungModel/types";
import {
	subSelectCodelistIdentifier,
	subSelectCodelistVersion,
} from "../../EditorState/subSelectors";
import { selectCodelistInfoFromModell } from "../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/fieldRendererHelpers";
import {
	selectIdPathFromQNamePathFromModell,
	selectIsCodelisteFromModell,
	selectNodeFromModell,
} from "../../AppActor/actors/modellierungModel/selectors";
import { UnreachableError } from "../../../utils/error";

const profileRules: ProfileRule[] = [
	...cardinalityRules,
	...recursionRules,
	...datatypeValidationRules,
	...propertyRules,
	...codelistRules,
];

function createProfileValidationResult(
	failedRule: ProfileRule,
	validationContext: ProfileValidationContext,
): ProfileValidationResult<ValidationTargetField> {
	const validationMsg =
		typeof failedRule.message === "function"
			? failedRule.message(validationContext)
			: failedRule.message;
	// Create an id that uniquely identifies the result
	const resultId = `${failedRule.id}#${validationContext.id}`;
	const nodeName =
		validationContext.standardNode.name || placeholder.anonymousStructure;
	return {
		targetField: failedRule.targetField,
		type: ValidationResultGroup.MessageProfiles,
		id: resultId,
		// By default we'll assume that the severity is an error
		severity: failedRule.severity || Severity.Error,
		message: validationMsg,
		nodeId: validationContext.id,
		path: validationContext.path,
		ruleId: failedRule.id,
		nodeName,
		elementName: nodeName,
		elementNamePath: [nodeName],
		autoFix: failedRule.autoFix,
		autoFixDescription: failedRule.autoFixDescription,
		target: failedRule.target,
	};
}

/**
 * Validate message profiles entered by the user. This can be used for the
 * profiles from the message structure tree or for the profiles in the subtrees
 * in the datatypes views.
 *
 * @param profiles The profiles that should be validated
 * @param getStandardNode A getter for the nodes of the standard. We need these
 * nodes to compare the profiles to the data in the standard
 */
// Reduce cognitive complexity, when the function needs to be modified next
// eslint-disable-next-line sonarjs/cognitive-complexity
export default async function* validateProfiles({
	modell,
	profiles,
	datatypes,
	getCodelist,
}: {
	modell: LiteModellContainer;
	profiles: StateProfileMap;
	datatypes: DatatypeMap;
	getCodelist: (
		requestOptions: Partial<RequestCodeListOptions>,
	) => Promise<CodelistStateResult>;
}): Validator<ProfileValidationResult<ValidationTargetField>> {
	// Iterate through each message profile, so we can check all user entered data
	for (const [qnamePath, profile] of profiles) {
		if (profile.get("datentyp") && isDeepEmpty(profile.delete("datentyp"))) {
			continue;
		}
		const path = selectIdPathFromQNamePathFromModell(
			modell,
			parseQNamePath(qnamePath),
		);
		const nodeId = path.at(-1);
		if (!nodeId) {
			throw new Error(`No id could be extracted from xpath: "${qnamePath}"`);
		}
		const standardNode = selectNodeFromModell(modell, nodeId);
		// This should not be allowed to happen, but failing early here will help
		// us fix future errors
		if (!standardNode) {
			throw new UnreachableError(
				`Could not find node with id "${qnamePath}" in the tree. ` +
					`"Since a message profile exists for the ` +
					`node, there should also be a corresponding node in the standard ` +
					`tree, but none was found.`,
			);
		}

		let codelist = ERROR_RESULT as CodelistStateResult;
		if (selectIsCodelisteFromModell(modell, standardNode)) {
			const codeliste = selectCodelistInfoFromModell(modell, standardNode);
			const kennung = codeliste.kennung || subSelectCodelistIdentifier(profile);
			const version = codeliste.version || subSelectCodelistVersion(profile);
			if (kennung) {
				// eslint-disable-next-line no-await-in-loop
				codelist = await getCodelist({ kennung, version });
			}
		}

		// Now check each profile against the list of validation rules
		for (const rule of profileRules) {
			// Skip the validation if the profile does not contain at least one of
			// the target keys
			if (rule.target && !rule.target.some((key) => profile.has(key))) {
				continue;
			}
			const context: ProfileValidationContext = {
				modell,
				profile,
				profiles,
				datatypes,
				standardNode,
				id: nodeId,
				path,
				codelist,
			};
			if (!rule.isValid(context)) {
				// Yield the validation result here, so we can display it to the user
				yield createProfileValidationResult(rule, context);
			} else {
				// Yield nothing here, so we know that we've advanced the validation by
				// one check
				yield null;
			}
		}
	}
}
