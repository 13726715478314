import classNames from "classnames";
import { getDuplicateLabel } from "./helpers";
import type { ExtendProps, PropsOf } from "../../../../../../utils/types";
import type { LiteDatatypeEntry } from "../../../../../AppActor/actors/modellierungModel/selectors";
import "./ReferersName.scss";

export default function ReferersName({
	refEntry,
	className,
	datatypes,
	...props
}: ExtendProps<
	PropsOf<"span">,
	{ refEntry: LiteDatatypeEntry; datatypes: LiteDatatypeEntry[] }
>): JSX.Element {
	const duplicateLabel = getDuplicateLabel(datatypes, refEntry);
	return (
		<span className={classNames("lite-datatype-name", className)} {...props}>
			{refEntry.name}
			{duplicateLabel && (
				<span className="lite-datatype-name__path">{duplicateLabel}</span>
			)}
		</span>
	);
}
