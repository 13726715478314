import { useEffect } from "react";
import type { ExtendProps } from "../../../utils/types";
import { useIconInfos } from "../../IconInfoProvider";
import type { InfoProps } from "../Info";
import Info from "../Info";

export type DynamicInfoProps = ExtendProps<
	Omit<InfoProps, "helpLink" | "children">,
	{
		infoKey: string;
		inline?: "start" | "end";
	}
>;

const DynamicInfo = ({
	infoKey,
	inline,
	...props
}: DynamicInfoProps): JSX.Element => {
	const iconInfos = useIconInfos();
	if (process.env.BUILD_MODE !== "production") {
		// This call to `useEffect` does not actually violate the rules of
		// hooks, since `process.env.BUILD_MODE` is replaced by a constant string
		// during the build process. This way, we won't include the warning in the
		// production bundle
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (!(infoKey in iconInfos)) {
				// eslint-disable-next-line no-console
				console.warn(
					`Info key "${infoKey}" could not be found in the icon information.\n\n` +
						`Expected one of ${Object.keys(iconInfos).join(", ")}`,
				);
			}
		}, [iconInfos, infoKey]);
	}
	const { tooltip = infoKey, url } = iconInfos?.[infoKey] ?? {};
	return (
		<Info {...props} helpLink={url} inline={inline}>
			{tooltip}
		</Info>
	);
};

export default DynamicInfo;
