import { z } from "zod";
import { placeholder } from "../../../../resources/textConstants";
import type ValueOf from "../../../../types/ValueOf";
import { AssertionError } from "../../../../utils/error";
import { opaquePassthrough } from "../../../../utils/schemas";

export const RawLiteIdSchema = z.string({ description: "LiteId" });
export type RawLiteId = z.infer<typeof RawLiteIdSchema>;
export const LiteIdSchema = RawLiteIdSchema.brand("LiteId");
export type LiteId = z.infer<typeof LiteIdSchema>;
export const OptionalLiteIdSchema = LiteIdSchema.optional();
export type OptionalLiteId = z.infer<typeof OptionalLiteIdSchema>;
export const LiteIdListSchema = LiteIdSchema.array();
export type LiteIdList = z.infer<typeof LiteIdListSchema>;
export const QNameSchema = z
	.string()
	.refine((str) => !str.includes("/"), {
		message: "QName must not include '/' characters",
	})
	.brand("QName");
export type QName = z.infer<typeof QNameSchema>;
export const QNamePathSchema = z.string().brand("QNamePath");
export type QNamePath = z.infer<typeof QNamePathSchema>;

export const LitePathSchema = z.string().brand("LitePath");
/** A list of `LiteId`s seperated by `"/"` */
export type LitePath = z.infer<typeof LitePathSchema>;

export const ProjektIdSchema = z.string().brand("ProjektId");
export type ProjektId = z.infer<typeof ProjektIdSchema>;
export const ProjektDbIdSchema = z.number().brand("ProjektDbId");
export type ProjektDbId = z.infer<typeof ProjektDbIdSchema>;

export const LiteMultiplicitySchema = z.string().regex(/(\d+|\*)\.\.(\d+|\*)/);
export type LiteMultiplicity = z.infer<typeof LiteMultiplicitySchema>;

export enum LiteNodeType {
	Model = "MODELL",
	Paket = "PAKET",
	Baustein = "BAUSTEIN",
	Eigenschaft = "EIGENSCHAFT",
}
export const LiteNodeTypeSchema = z.nativeEnum(LiteNodeType);

export enum LiteBausteinType {
	Datentyp = "DATENTYP",
	CodeDatentyp = "CODE_DATENTYP",
	Nachricht = "NACHRICHT",
	GlobaleEigenschaft = "EIGENSCHAFT",
}
export const LiteBausteinTypeSchema = z.nativeEnum(LiteBausteinType);

export enum LiteGruppeType {
	Sequence = "SEQUENCE",
	Choice = "CHOICE",
	All = "ALL",
}
export const LiteGruppeTypeSchema = z.nativeEnum(LiteGruppeType);

export enum LitePaketType {
	Schema = "SCHEMA",
	Simple = "SIMPLE",
}
export const LitePaketTypeSchema = z.nativeEnum(LitePaketType);

export enum ProjektType {
	Modellierung = "MODELLIERUNG",
	Genericode = "GENERICODE",
	Profilierung = "PROFIL",
	Standard = "STANDARD",
}
export const ProjektTypeSchema = z.nativeEnum(ProjektType);
export const ApiProjektSchema = z.object({
	id: ProjektDbIdSchema,
	nameKurz: z.string(),
	kennung: z.string(),
	version: z.string().optional(),
	typ: ProjektTypeSchema,
});
export type ApiProjekt = z.infer<typeof ApiProjektSchema>;

export const ApiProjektListSchema = z.array(ApiProjektSchema);
export type ApiProjektList = z.infer<typeof ApiProjektListSchema>;

export const RawLiteNodeBaseSchema = opaquePassthrough(
	z.object({
		id: RawLiteIdSchema,
		name: z.string(),
		beschreibung: z.string().optional(),
		umsetzungshinweis: z.string().optional(),
		gruppeArt: LiteGruppeTypeSchema.optional(),
	}),
);

export const RawLiteEigenschaftBaseSchema = opaquePassthrough(
	RawLiteNodeBaseSchema.extend({
		typ: z.literal("EIGENSCHAFT"),
		name: z.string().optional(),
		datentyp: RawLiteIdSchema.nullish(),
		attribut: z.boolean(),
		multiplizitaet: LiteMultiplicitySchema.optional(),
		fixedWert: z.string().optional(),
		defaultWert: z.string().optional(),
	}),
);

export type RawLiteEigenschaft = z.infer<
	typeof RawLiteEigenschaftBaseSchema
> & {
	eigenschaften?: RawLiteEigenschaft[];
};

export const RawLiteEigenschaftSchema: z.ZodType<RawLiteEigenschaft> =
	opaquePassthrough(
		RawLiteEigenschaftBaseSchema.extend({
			eigenschaften: z
				.lazy(() => RawLiteEigenschaftSchema)
				.array()
				.optional(),
		}),
	);

export const ValidationDefinitionSchema = opaquePassthrough(
	z.object({
		pattern: z.string().optional(),
		minValue: z.string().optional(),
		maxValue: z.string().optional(),
		fehlermeldung: z.string().optional(),
	}),
);
export type ValidationDefinition = z.infer<typeof ValidationDefinitionSchema>;

export const RawLiteBausteinSchema = opaquePassthrough(
	RawLiteNodeBaseSchema.extend({
		typ: LiteBausteinTypeSchema,
		eigenschaften: RawLiteEigenschaftSchema.array().optional(),
		kennung: z.string().optional(),
		version: z.string().optional(),
		validierung: ValidationDefinitionSchema.optional(),
		restriction: z.boolean().optional(),
		basisDatentyp: RawLiteIdSchema.nullish(),
		datentyp: RawLiteIdSchema.nullish(),
	}),
);
export type RawLiteBaustein = z.infer<typeof RawLiteBausteinSchema>;

export const SchemaDefSchema = opaquePassthrough(
	z.object({
		namespace: z.string(),
		prefix: z.string(),
	}),
);
export type SchemaDef = z.infer<typeof SchemaDefSchema>;

export const SchemaPaketKonfigurationSchema = opaquePassthrough(
	z.object({
		nutztSchema: SchemaDefSchema.array().optional(),
		name: z.string().optional(),
		prefix: z.string().optional(),
		schemaFile: z.string().optional(),
		schemaLocation: z.string().optional(),
		version: z.string().optional(),
		elementFormDefault: z.string().optional(),
	}),
);
export type SchemaPaketKonfiguration = z.infer<
	typeof SchemaPaketKonfigurationSchema
>;

const RawLitePaketBaseSchema = opaquePassthrough(
	RawLiteNodeBaseSchema.extend({
		typ: LitePaketTypeSchema,
		bausteine: RawLiteBausteinSchema.array(),
		konfiguration: SchemaPaketKonfigurationSchema.optional(),
	}),
);

export type RawLitePaket = z.infer<typeof RawLitePaketBaseSchema> & {
	pakete: RawLitePaket[];
};

export const RawLitePaketSchema: z.ZodType<RawLitePaket> = opaquePassthrough(
	RawLitePaketBaseSchema.extend({
		pakete: z.lazy(() => RawLitePaketSchema).array(),
	}),
);

export const MetadatenStandardSchema = opaquePassthrough(
	z.object({
		nameLang: z.string(),
		nameKurz: z.string(),
		nameTechnisch: z.string(),
		kennung: z.string(),
		beschreibung: z.string().optional(),
		herausgebernameLang: z.string().optional(),
		herausgebernameKurz: z.string().optional(),
		externeWebsite: z.string().optional(),
	}),
);
export type MetadatenStandard = z.infer<typeof MetadatenStandardSchema>;

export const MetadatenVersionSchema = opaquePassthrough(
	z.object({
		version: z.string(),
		versionXoevHandbuch: z.string().optional(),
		versionXGenerator: z.string().optional(),
		versionModellierungswerkzeug: z.string().optional(),
		lizenz: z.string().optional(),
		aenderungZurVorversion: z.string().optional(),
		bezugsort: z.string().optional(),
		beschreibung: z.string().optional(),
	}),
);
export type MetadatenVersion = z.infer<typeof MetadatenVersionSchema>;

export type Metadaten = MetadatenVersion & MetadatenStandard;

export const ModelKonfigurationSchema = opaquePassthrough(
	z.object({
		fassungVom: z.string().optional(),
		namespace: z.string().optional(),
		prefix: z.string().optional(),
		schemaLocationBase: z.string().optional(),
		standardEigenschaften: z.string().optional(),
		xsdNamedTypeNameSuffix: z.string().optional(),
	}),
);
export type ModelKonfiguration = z.infer<typeof ModelKonfigurationSchema>;

const RawLiteModelBaseSchema = opaquePassthrough(
	RawLiteNodeBaseSchema.extend({
		kennung: z.string(),
		pakete: RawLitePaketSchema.array(),
		metadatenStandard: MetadatenStandardSchema,
		metadatenVersion: MetadatenVersionSchema,
		konfiguration: ModelKonfigurationSchema,
	}),
);

export type RawLiteModel = z.infer<typeof RawLiteModelBaseSchema> & {
	externeModelle: RawLiteModel[];
};

export const RawLiteModelSchema: z.ZodType<RawLiteModel> = opaquePassthrough(
	RawLiteModelBaseSchema.extend({
		externeModelle: z.lazy(() => RawLiteModelSchema).array(),
	}),
);

const RawLiteModelTypSchema = RawLiteModelBaseSchema.extend({
	typ: ProjektTypeSchema,
});
export const ApiUploadProjektSchema = z.object({
	id: ProjektDbIdSchema,
	modell: RawLiteModelTypSchema,
	name: z.string(),
});
export type ApiUploadProjekt = z.infer<typeof ApiUploadProjektSchema>;

const sharedNodeSubSchema = {
	id: LiteIdSchema,
	parent: LiteIdSchema.nullable(),
	children: LiteIdSchema.array(),
};

export const LiteEigenschaftSchema = opaquePassthrough(
	RawLiteEigenschaftBaseSchema.extend({
		...sharedNodeSubSchema,
		liteType: z.literal(LiteNodeType.Eigenschaft),
		basisDatentyp: LiteIdSchema.nullish(),
		datentyp: LiteIdSchema.nullish(),
		referenz: LiteIdSchema.nullish(),
	}),
);
export type LiteEigenschaft = z.infer<typeof LiteEigenschaftSchema>;

export const LiteBausteinSchema = opaquePassthrough(
	RawLiteBausteinSchema.omit({
		eigenschaften: true,
	}).extend({
		...sharedNodeSubSchema,
		liteType: z.literal(LiteNodeType.Baustein),
		basisDatentyp: LiteIdSchema.nullish(),
		datentyp: LiteIdSchema.nullish(),
		referenz: LiteIdSchema.nullish(),
	}),
);
export type LiteBaustein = z.infer<typeof LiteBausteinSchema>;

export const LitePaketSchema = opaquePassthrough(
	RawLitePaketBaseSchema.omit({
		bausteine: true,
	}).extend({
		...sharedNodeSubSchema,
		liteType: z.literal(LiteNodeType.Paket),
	}),
);
export type LitePaket = z.infer<typeof LitePaketSchema>;

export const LiteModelSchema = opaquePassthrough(
	RawLiteModelBaseSchema.omit({
		pakete: true,
	}).extend({
		...sharedNodeSubSchema,
		liteType: z.literal(LiteNodeType.Model),
	}),
);
export type LiteModel = z.infer<typeof LiteModelSchema>;

export type LiteNode = LiteModel | LitePaket | LiteBaustein | LiteEigenschaft;
export type LiteNodeKey = ValueOf<{
	[V in LiteNode as V["liteType"]]: keyof V;
}>;

export function isLiteModel(node: LiteNode): node is LiteModel {
	return node.liteType === LiteNodeType.Model;
}
export function isLitePaket(node: LiteNode): node is LitePaket {
	return node.liteType === LiteNodeType.Paket;
}
export function isLiteBaustein(node: LiteNode): node is LiteBaustein {
	return node.liteType === LiteNodeType.Baustein;
}
export function isLiteEigenschaft(node: LiteNode): node is LiteEigenschaft {
	return node.liteType === LiteNodeType.Eigenschaft;
}
export function isLiteSchema(node: LiteNode): node is LitePaket {
	return isLitePaket(node) && node.typ === LitePaketType.Schema;
}

export function isLiteDatatype(node: LiteNode): node is LiteBaustein {
	return (
		isLiteBaustein(node) &&
		(node.typ === LiteBausteinType.Datentyp ||
			node.typ === LiteBausteinType.CodeDatentyp)
	);
}
export function isLiteBasisDatentyp(node: LiteNode): node is LiteBaustein {
	return isLiteDatatype(node) && !!node.basisDatentyp;
}
export function isLiteNachricht(node: LiteNode): node is LiteBaustein {
	return isLiteBaustein(node) && node.typ === LiteBausteinType.Nachricht;
}

export function isLiteGlobaleEigenschaft(node: LiteNode): node is LiteBaustein {
	return (
		isLiteBaustein(node) && node.typ === LiteBausteinType.GlobaleEigenschaft
	);
}

export class NodeTypeAssertionError extends AssertionError {
	constructor(node: LiteNode, type: LiteNodeType) {
		super(
			`Expected node "${node.name || placeholder.anonymousStructure}" id: "${
				node.id
			}" to be of type ` +
				`"${type}", but found "${node.liteType}":\n` +
				`${JSON.stringify(node, null, 2)}`,
		);
	}
}
export function assertLiteModel(node: LiteNode): asserts node is LiteModel {
	if (!isLiteModel(node)) {
		throw new NodeTypeAssertionError(node, LiteNodeType.Model);
	}
}
export function assertLitePaket(node: LiteNode): asserts node is LitePaket {
	if (!isLitePaket(node)) {
		throw new NodeTypeAssertionError(node, LiteNodeType.Paket);
	}
}
export function assertLiteBaustein(
	node: LiteNode,
): asserts node is LiteBaustein {
	if (!isLiteBaustein(node)) {
		throw new NodeTypeAssertionError(node, LiteNodeType.Baustein);
	}
}
export function assertLiteEigenschaft(
	node: LiteNode,
): asserts node is LiteEigenschaft {
	if (!isLiteEigenschaft(node)) {
		throw new NodeTypeAssertionError(node, LiteNodeType.Eigenschaft);
	}
}

export function parseLitePath(path: LitePath): LiteId[] {
	return path.split("/").map((id) => LiteIdSchema.parse(id));
}

export function joinLitePath(path: LiteId[]): LitePath {
	return LitePathSchema.parse(path.join("/"));
}

export function parseQNamePath(path: QNamePath): QName[] {
	return path.split("/").map((id) => QNameSchema.parse(id));
}

export function joinQNamePath(path: QName[]): QNamePath {
	return QNamePathSchema.parse(path.join("/"));
}
