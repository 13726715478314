import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, fromPromise, setup } from "xstate";
import {
	sendToEventStore,
	translateStoreEvents,
} from "../../EventStore/helpers";
import { unknownErrorToString } from "../../../../utils/error";
import { getEffectApi } from "../effectApi/hooks";
import type {
	ProjektDbId,
	ProjektId,
	RawLiteModel,
} from "../modellierungModel/schemas";
import type { ExtractStoreEventPayload } from "../../EventStore/StoreEvent";
import type { ProjektMeta } from "./types";

// eslint-disable-next-line no-use-before-define
export type ProjectCommandActorRef = ActorRefFrom<typeof projectCommandMachine>;

export type ProjectCommandEvent =
	| { type: "ACTIVATE"; projektId: ProjektId; initialLocation?: string }
	| ({
			type: "ALERT_REQUEST_ERROR";
	  } & ExtractStoreEventPayload<"PROJECT.OPEN_EXISTING.FAILURE">);

const projectCommandMachine = setup({
	types: {
		events: {} as ProjectCommandEvent,
		context: {} as { projektMeta: ProjektMeta; initialLocation?: string },
		input: {} as ProjektMeta,
	},
	actors: {
		translateEvents: translateStoreEvents<ProjectCommandEvent>({
			"PROJECT.ACTIVATE": ({ payload }) => ({
				type: "ACTIVATE",
				projektId: payload.projektMeta.projektId,
				initialLocation: payload.initialLocation,
			}),
			"PROJECT.OPEN_EXISTING.FAILURE": ({ payload }) => ({
				type: "ALERT_REQUEST_ERROR",
				...payload,
			}),
		}),
		requestRawModel: fromPromise<RawLiteModel, ProjektDbId>(
			({ input: id, system }) => {
				const { requestProjectById } = getEffectApi(system);
				return requestProjectById(id);
			},
		),
	},
	actions: {
		signalRequest: ({ system, context }) =>
			sendToEventStore(system, {
				type: "PROJECT.OPEN_EXISTING.REQUEST",
				payload: context,
			}),
		alertRequestError: ({ event, system }) => {
			assertEvent(event, "ALERT_REQUEST_ERROR");
			sendToEventStore(system, {
				type: "NOTIFICATION.ALERT",
				payload: {
					severity: "error",
					text: `Die Projektdaten konnten nicht abgerufen werden.\n${event.error}`,
				},
			});
		},
		addInitialLocation: assign({
			initialLocation: ({ event }) => {
				assertEvent(event, "ACTIVATE");
				return event.initialLocation;
			},
		}),
	},
	guards: {
		isSelf: ({ event, context }) => {
			assertEvent(event, "ACTIVATE");
			return event.projektId === context.projektMeta.projektId;
		},
	},
}).createMachine({
	id: "project:command",
	invoke: { src: "translateEvents" },
	context: ({ input }) => ({ projektMeta: input }),
	initial: "Idle",
	states: {
		Idle: {
			on: {
				ACTIVATE: {
					guard: "isSelf",
					target: "Loading",
					actions: "addInitialLocation",
				},
			},
		},
		Loading: {
			entry: { type: "signalRequest" },
			invoke: {
				src: "requestRawModel",
				input: ({ context }) => context.projektMeta.dbId,
				onDone: {
					target: "Success",
					actions: ({ event, system, context }) =>
						sendToEventStore(system, {
							type: "PROJECT.OPEN_EXISTING.SUCCESS",
							payload: { rawModel: event.output, ...context },
						}),
				},
				onError: {
					target: "Errored",
					actions: ({ event, system, context }) =>
						sendToEventStore(system, {
							type: "PROJECT.OPEN_EXISTING.FAILURE",
							payload: { error: unknownErrorToString(event.error), ...context },
						}),
				},
			},
		},
		Success: {},
		Errored: {},
	},
	on: {
		ALERT_REQUEST_ERROR: { actions: "alertRequestError" },
	},
});

export default projectCommandMachine;
