import type { TreeNodeType } from "../../../../types/ProfilierungHome";
import useActiveDataType from "../../useActiveDataType";

/**
 * Access the currently active datatype node. Should the active datatype not
 * exist, the hook errors early. It should therefore only be used when we it
 * was checked before that the active datatype node actually exist.
 *
 * @param componentName The name of the component, that uses this hook. It is
 * used to create a more helpful error message
 */
export default function useCheckedActiveNode(
	componentName: string,
): TreeNodeType {
	const { activeDataType: activeNode } = useActiveDataType();

	// We're checking in the parent component, that activeDataType exists, so
	// this should not be possible
	if (!activeNode) {
		throw new Error(
			`<${componentName} /> No active datatype could be found. ` +
				`Please make sure to only render this component when an active ` +
				`datatype exists.`,
		);
	}

	return activeNode;
}
