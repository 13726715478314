import createImmutableMap from "@xoev/immutable-map";
import { memoize } from "@xoev/memo";
import { icons, placeholder } from "../../../../resources/textConstants";
import type {
	LiteBaustein,
	LiteId,
	LiteNode,
	LiteNodeKey,
	ProjektId,
	RawLiteModel,
	SchemaPaketKonfiguration,
} from "./schemas";
import {
	LiteBausteinSchema,
	LiteBausteinType,
	LiteEigenschaftSchema,
	LiteGruppeType,
	LiteIdSchema,
	LiteModelSchema,
	LiteNodeType,
	LitePaketSchema,
	LitePaketType,
	ProjektType,
	SchemaPaketKonfigurationSchema,
	assertLitePaket,
	isLiteBaustein,
	isLiteDatatype,
	isLiteEigenschaft,
	isLiteModel,
	isLitePaket,
} from "./schemas";
import {
	createSelectDirectChildren,
	createSelectExtensions,
	createSelectInfiniteChildren,
	createSelectNode,
	createSelectNodesByPath,
	createSelectReferences,
	selectNodeFromModell,
	selectRootModell,
} from "./selectors";
import type { ExtendProps, Nullish } from "../../../../utils/types";
import type {
	AddNodeShortcutEvent,
	DeleteNodeShortcutEvent,
} from "../../EventStore/StoreEvent";
import type {
	ImmutableRecord,
	LiteModellContainer,
	LiteProjectNodes,
	ModellierungPatch,
	NodeDeleteInstruction,
	NodeInsertInstruction,
	NodeInsertPosition,
	NodeTarget,
} from "./types";
import { LiteDatatypeKind } from "./types";
import type { ProjektMeta, StandardProjekt } from "../project/types";
import type { EventStoreLog } from "../../EventStore/eventStore.machine";
import { SerializableError } from "../../../../utils/error";
import { LiteDatentypenKind, LiteKind, getLiteNodeKind } from "./LiteKind";
import type { ModellNodeLists } from "../project/projectModel/createProjectModel";
import createProjectModel from "../project/projectModel/createProjectModel";
import type ValueOf from "../../../../types/ValueOf";
import { walkTreeEntries } from "../../../Tree/treeHelpers";
import { filterFalsy } from "../../../../utils/lists";
import iconTexts from "../../../../resources/textConstants/icons.json";

export const CARDINILITIES = ["0..1", "0..*", "1..1", "1..*"];
export const DEFAULT_CARDINALITY = "1..1";

export const DEFAULT_GROUPTYPE = LiteGruppeType.Sequence;

export const newKindTitleMap: { [K in LiteKind]: string } = {
	[LiteKind.Model]: "Neues Modell",
	[LiteKind.Paket]: "Neues Paket",
	[LiteKind.SchemaPaket]: "Neues Schemapaket",
	[LiteKind.CodeDatentyp]: "Neuer Codedatentyp",
	[LiteKind.Nachricht]: "Neue Nachricht",
	[LiteKind.Datentyp]: "Neuer Datentyp",
	[LiteKind.GlobaleEigenschaft]: "Neue Globale Eigenschaft",
	[LiteKind.UnknownBaustein]: "Neuer Baustein",
	[LiteKind.Attribut]: "Neues Attribut",
	[LiteKind.Eigenschaft]: "Neue Eigenschaft",
};

export const newKindValueMap: { [K in LiteKind]: string } = {
	[LiteKind.Model]: "NeuesModell",
	[LiteKind.Paket]: "NeuesPaket",
	[LiteKind.SchemaPaket]: "NeuesSchemapaket",
	[LiteKind.CodeDatentyp]: "NeuerCodedatentyp",
	[LiteKind.Nachricht]: "neueNachricht",
	[LiteKind.Datentyp]: "NeuerDatentyp",
	[LiteKind.GlobaleEigenschaft]: "neueGlobaleEigenschaft",
	[LiteKind.UnknownBaustein]: "NeuerBaustein",
	[LiteKind.Attribut]: "neuesAttribut",
	[LiteKind.Eigenschaft]: "neueEigenschaft",
};

export const kindTitleMap: { [K in LiteKind | LiteDatentypenKind]: string } = {
	[LiteKind.Model]: iconTexts.dropdown.model,
	[LiteKind.Paket]: iconTexts.dropdown.paket,
	[LiteKind.SchemaPaket]: iconTexts.dropdown.schemaPaket,
	[LiteKind.CodeDatentyp]: iconTexts.dropdown.codeDatentyp,
	[LiteKind.Nachricht]: iconTexts.dropdown.nachricht,
	[LiteKind.Datentyp]: iconTexts.dropdown.datentyp,
	[LiteKind.GlobaleEigenschaft]: iconTexts.dropdown.globaleEigenschaft,
	[LiteKind.UnknownBaustein]: iconTexts.dropdown.unknownBaustein,
	[LiteKind.Attribut]: iconTexts.dropdown.attribut,
	[LiteKind.Eigenschaft]: iconTexts.dropdown.eigenschaft,
	[LiteDatentypenKind.EinfacherDatentyp]: iconTexts.dropdown.einfacherDatentyp,
	[LiteDatentypenKind.KomplexerDatentyp]: iconTexts.dropdown.komplexerDatentyp,
};

export const kindTooltipMap: { [K in LiteKind | LiteDatentypenKind]: string } =
	{
		[LiteKind.Model]: icons.treeView.platzhalter.tooltip,
		[LiteKind.Paket]: icons.treeView.platzhalter.tooltip,
		[LiteKind.SchemaPaket]: icons.treeView.platzhalter.tooltip,
		[LiteKind.CodeDatentyp]: icons.treeView.codeDatentyp.tooltip,
		[LiteKind.Nachricht]: icons.treeView.platzhalter.tooltip,
		[LiteKind.Datentyp]: icons.treeView.einfacherDatatype.tooltip,
		[LiteKind.GlobaleEigenschaft]: icons.treeView.referenz.tooltip,
		[LiteKind.UnknownBaustein]: icons.treeView.komplexerDatentyp.tooltip,
		[LiteKind.Attribut]: icons.treeView.platzhalter.tooltip,
		[LiteKind.Eigenschaft]: icons.treeView.platzhalter.tooltip,
		[LiteDatentypenKind.EinfacherDatentyp]:
			icons.treeView.einfacherDatatype.tooltip,
		[LiteDatentypenKind.KomplexerDatentyp]:
			icons.treeView.komplexerDatentyp.tooltip,
	};
class NotDatatypeError extends SerializableError {
	constructor(node: LiteNode) {
		super({
			name: "NotDatatypeError",
			message:
				`Node "${node.name || placeholder.anonymousStructure}", id: "${
					node.id
				}" was expected to be a datatype, ` +
				`but had type "${node.liteType}" and kind "${getLiteNodeKind(node)}".`,
		});
	}

	static assert(
		node: LiteNode,
	): asserts node is ExtendProps<
		LiteBaustein,
		{ typ: LiteBausteinType.CodeDatentyp | LiteBausteinType.Datentyp }
	> {
		if (!isLiteDatatype(node)) {
			throw new NotDatatypeError(node);
		}
	}
}

/* TODO Merge with new Lite Datatype Kind */
export function getLiteDatatypeKind(node: LiteNode): LiteDatatypeKind {
	NotDatatypeError.assert(node);
	if (node.typ === LiteBausteinType.CodeDatentyp) {
		return LiteDatatypeKind.CodeList;
	}
	// TODO: Verify that children > 0 means complex datatype
	if (node.children.length > 0) {
		return LiteDatatypeKind.Complex;
	}
	return LiteDatatypeKind.Simple;
}

export function toImmutableMap<T extends { id: LiteId }>(
	list: T[],
): ImmutableRecord<LiteId, T> {
	const entries = list.map((item) => [item.id, item] as const);
	return createImmutableMap(entries as never);
}

function createLiteProjektNodes({
	modelList,
	paketList,
	bausteinList,
	eigenschaftList,
}: ModellNodeLists): LiteProjectNodes {
	return createImmutableMap({
		[LiteNodeType.Model]: toImmutableMap(modelList),
		[LiteNodeType.Paket]: toImmutableMap(paketList),
		[LiteNodeType.Baustein]: toImmutableMap(bausteinList),
		[LiteNodeType.Eigenschaft]: toImmutableMap(eigenschaftList),
	});
}

export function createStandardProjekt(
	rawModelId: string,
	projektMeta: ProjektMeta,
	name: string,
	nodeLists: ModellNodeLists,
): StandardProjekt {
	const rootModelId = LiteIdSchema.parse(rawModelId);
	const type = projektMeta.projektType;
	if (type !== ProjektType.Modellierung && type !== ProjektType.Standard) {
		throw new SerializableError({
			name: "UnsupportedProjektTypeError",
			message:
				"Unsupported projektType found in `createStandardProjekt`. " +
				`Expected ${ProjektType.Modellierung} or ${ProjektType.Standard}, ` +
				`got ${type}`,
		});
	}
	return {
		type,
		id: projektMeta.projektId,
		name,
		kennung: projektMeta.kennung,
		modell: { rootModelId, nodes: createLiteProjektNodes(nodeLists) },
	};
}

export function createLiteProject(
	rawModel: RawLiteModel,
	projektMeta: ProjektMeta,
	name: string,
): StandardProjekt {
	const nodeLists = createProjectModel(rawModel);
	return createStandardProjekt(rawModel.id, projektMeta, name, nodeLists);
}

export const createQueryId = (projektId: ProjektId) =>
	`modellierungModell:query:${projektId}`;
export const createCommandId = (projektId: ProjektId) =>
	`modellierungModell:command:${projektId}`;

export function assertHasProject(
	project: StandardProjekt | null,
): asserts project {
	if (!project) {
		throw new Error(
			"Expected project to exist, but it is `null`. Make sure to initialize the project correctly.",
		);
	}
}

function getNodeFromProject(project: StandardProjekt, nodeId: LiteId) {
	const node = createSelectNode(project)(nodeId);
	if (!node) {
		throw new Error(
			`Cannot write to node "${nodeId}". It could not be found in the project.`,
		);
	}
	return node;
}

function writeUpdateToNodes<TNode extends LiteNode, TKey extends keyof TNode>(
	project: StandardProjekt,
	node: TNode,
	name: TKey,
	value: TNode[TKey],
) {
	const nextNode = { ...node, [name]: value };
	return project.modell.nodes.setIn([node.liteType, node.id], nextNode);
}

function writeUpdateToRefNodes<
	TNode extends LiteNode,
	TKey extends keyof TNode,
>(project: StandardProjekt, node: TNode, oldKey: TKey, key: TKey) {
	const nextNode = { ...node, [key]: node[oldKey], [oldKey]: null };
	return project.modell.nodes.setIn([node.liteType, node.id], nextNode);
}

const schemas = {
	[LiteNodeType.Model]: LiteModelSchema,
	[LiteNodeType.Paket]: LitePaketSchema,
	[LiteNodeType.Baustein]: LiteBausteinSchema,
	[LiteNodeType.Eigenschaft]: LiteEigenschaftSchema,
};
export function safeUpdateNodes(
	project: StandardProjekt | null,
	nodeId: LiteId,
	name: LiteNodeKey,
	value: unknown,
) {
	assertHasProject(project);
	const node = getNodeFromProject(project, nodeId);
	const schema = schemas[node.liteType];
	const parsedName = schema.keyof().parse(name);
	const parsedValue =
		// Casting is safe here, since we know the keys exist and match the schema.
		// TS isn't quite smart enough to know that. The alternative would be to
		// manually switch over the `liteType`, but that would lead to a lot of
		// repitition
		schema.shape[parsedName as keyof typeof schema.shape].parse(value);
	return writeUpdateToNodes(
		project,
		node,
		parsedName as keyof typeof node,
		parsedValue,
	);
}

export function safeUpdateRefNodes(
	project: StandardProjekt | null,
	nodeId: LiteId,
	oldKey: LiteNodeKey,
	key: LiteNodeKey,
) {
	assertHasProject(project);
	const node = getNodeFromProject(project, nodeId);
	const schema = schemas[node.liteType];
	const parsedOldKey = schema.keyof().parse(oldKey);
	const parsedKey = schema.keyof().parse(key);

	return writeUpdateToRefNodes(
		project,
		node,
		parsedOldKey as keyof typeof node,
		parsedKey as keyof typeof node,
	);
}

export function safeUpdatePaketConfig(
	project: StandardProjekt | null,
	nodeId: LiteId,
	name: keyof SchemaPaketKonfiguration,
	value: ValueOf<SchemaPaketKonfiguration>,
) {
	assertHasProject(project);
	const node = getNodeFromProject(project, nodeId);
	assertLitePaket(node);
	const parsedName = SchemaPaketKonfigurationSchema.keyof().parse(name);
	const parsedValue =
		SchemaPaketKonfigurationSchema.shape[parsedName].parse(value);
	const nextConfig = { ...node.konfiguration, [parsedName]: parsedValue };
	return writeUpdateToNodes(project, node, "konfiguration", nextConfig);
}

const defaultNodes: {
	[TKind in LiteKind]: (id: LiteId, parent: LiteId | null) => LiteNode;
} = {
	[LiteKind.Paket]: (id, parent) => ({
		liteType: LiteNodeType.Paket,
		id,
		parent,
		name: newKindValueMap[LiteKind.Paket],
		children: [],
		typ: LitePaketType.Simple,
	}),
	[LiteKind.SchemaPaket]: (id, parent) => ({
		liteType: LiteNodeType.Paket,
		id,
		parent,
		name: newKindValueMap[LiteKind.SchemaPaket],
		children: [],
		typ: LitePaketType.Schema,
	}),
	[LiteKind.Datentyp]: (id, parent) => ({
		liteType: LiteNodeType.Baustein,
		id,
		parent,
		name: newKindValueMap[LiteKind.Datentyp],
		children: [],
		typ: LiteBausteinType.Datentyp,
	}),
	[LiteKind.CodeDatentyp]: (id, parent) => ({
		liteType: LiteNodeType.Baustein,
		id,
		parent,
		name: newKindValueMap[LiteKind.CodeDatentyp],
		children: [],
		typ: LiteBausteinType.CodeDatentyp,
	}),
	[LiteKind.Nachricht]: (id, parent) => ({
		liteType: LiteNodeType.Baustein,
		id,
		parent,
		name: newKindValueMap[LiteKind.Nachricht],
		children: [],
		typ: LiteBausteinType.Nachricht,
		datentyp: null,
	}),
	[LiteKind.GlobaleEigenschaft]: (id, parent) => ({
		liteType: LiteNodeType.Baustein,
		id,
		parent,
		name: newKindValueMap[LiteKind.GlobaleEigenschaft],
		children: [],
		typ: LiteBausteinType.GlobaleEigenschaft,
	}),
	[LiteKind.Eigenschaft]: (id, parent) => ({
		liteType: LiteNodeType.Eigenschaft,
		id,
		parent,
		name: newKindValueMap[LiteKind.Eigenschaft],
		typ: "EIGENSCHAFT",
		children: [],
		attribut: false,
		multiplizitaet: DEFAULT_CARDINALITY,
		datentyp: null,
		basisDatentyp: null,
	}),
	[LiteKind.Attribut]: (id, parent) => ({
		liteType: LiteNodeType.Eigenschaft,
		id,
		parent,
		name: newKindValueMap[LiteKind.Attribut],
		typ: "EIGENSCHAFT",
		children: [],
		attribut: true,
		multiplizitaet: DEFAULT_CARDINALITY,
		datentyp: null,
		basisDatentyp: null,
	}),
	[LiteKind.UnknownBaustein]: () => {
		throw new Error(
			`Creation of nodes of kind "${LiteKind.UnknownBaustein}" is not implemented yet.`,
		);
	},
	[LiteKind.Model]: () => {
		throw new Error(
			`Creation of nodes of kind "${LiteKind.Model}" is not implemented yet.`,
		);
	},
};

export function createNewNode(
	kind: LiteKind,
	nodeId: LiteId,
	parent: LiteId | null,
): LiteNode {
	return defaultNodes[kind](nodeId, parent);
}

export function getDebugPath(
	project: Nullish<StandardProjekt>,
	path: LiteId[],
) {
	return createSelectNodesByPath(project)(path)
		?.map((n) => n.name)
		.join("/");
}

function getNodeHierachy(projekt: Nullish<StandardProjekt>, path: LiteId[]) {
	const hierachy = createSelectNodesByPath(projekt)(path);
	if (!hierachy) {
		const debugPath = getDebugPath(projekt, path);
		throw new Error(
			`Insert hierachy could not be found for path "${debugPath}".`,
		);
	}
	const node = hierachy?.at(-1);
	if (!node) {
		const debugPath = getDebugPath(projekt, path);
		const debugName = debugPath?.split("/")?.at(-1);
		const nodeId = path.at(-1);
		throw new Error(
			`Node "${debugName}", id: "${nodeId}" could not be found for ` +
				`path ${debugPath}`,
		);
	}
	return { hierachy, node };
}

export function getInsertHierachy(
	projekt: Nullish<StandardProjekt>,
	path: LiteId[],
	position: NodeInsertPosition,
) {
	const insertRootPath = position === "child" ? path : path.slice(0, -1);
	const { hierachy, node } = getNodeHierachy(projekt, insertRootPath);
	return { hierachy, parent: node };
}

type InsertCheck = (
	hierachy: LiteNode[],
	parent: LiteNode,
	projekt?: Nullish<StandardProjekt>,
) => boolean;

const canInsertPaket: InsertCheck = (_, parent) =>
	isLiteModel(parent) || isLitePaket(parent);
const canInsertBaustein: InsertCheck = (hierachy, parent) => {
	return (
		isLitePaket(parent) &&
		hierachy.some((node) => getLiteNodeKind(node) === LiteKind.SchemaPaket)
	);
};

const isNodeCodeDataType = (
	projekt: Nullish<StandardProjekt>,
	nodeId?: LiteId | null,
) => {
	if (nodeId && projekt && projekt.modell) {
		const referencedDatatype = selectNodeFromModell(projekt.modell, nodeId);

		return (
			!!referencedDatatype &&
			getLiteNodeKind(referencedDatatype) === LiteKind.CodeDatentyp
		);
	}
	return false;
};
const canInsertEigenschaft: InsertCheck = (hierachy, parent, projekt) => {
	return (
		(isLiteBaustein(parent) ||
			(isLiteEigenschaft(parent) && !parent.attribut)) &&
		!parent.referenz &&
		!isNodeCodeDataType(projekt, parent.datentyp)
	);
};

const insertRules: { [TKind in LiteKind]: InsertCheck } = {
	[LiteKind.Model]: (_, parent) => isLiteModel(parent),
	[LiteKind.Paket]: canInsertPaket,
	[LiteKind.SchemaPaket]: (hierachy, parent) =>
		canInsertPaket(hierachy, parent) &&
		hierachy.every((node) => getLiteNodeKind(node) !== LiteKind.SchemaPaket),
	[LiteKind.Datentyp]: canInsertBaustein,
	[LiteKind.CodeDatentyp]: canInsertBaustein,
	[LiteKind.Nachricht]: canInsertBaustein,
	[LiteKind.GlobaleEigenschaft]: canInsertBaustein,
	[LiteKind.UnknownBaustein]: canInsertBaustein,
	[LiteKind.Eigenschaft]: canInsertEigenschaft,
	[LiteKind.Attribut]: canInsertEigenschaft,
};
export function canInsertNode(
	projekt: Nullish<StandardProjekt>,
	path: LiteId[],
	kind: LiteKind,
	position: NodeInsertPosition,
) {
	const { hierachy, parent } = getInsertHierachy(projekt, path, position);

	return insertRules[kind](hierachy, parent, projekt);
}

function getInsertIndex(
	project: Nullish<StandardProjekt>,
	path: LiteId[],
	position: NodeInsertPosition,
) {
	const { parent } = getInsertHierachy(project, path, position);
	if (position === "child") {
		return createSelectInfiniteChildren(project)(parent).length;
	}
	const currentIndex = parent.children.findIndex(
		(childId) => childId === path.at(-1),
	);
	if (currentIndex === -1) return parent.children.length;
	const offset = position === "above" ? 0 : 1;
	return currentIndex + offset;
}

export function insertNewNodeIntoNodes(
	project: StandardProjekt,
	path: LiteId[],
	node: LiteNode,
	position: NodeInsertPosition,
) {
	const kind = getLiteNodeKind(node);
	if (!canInsertNode(project, path, kind, position)) {
		const debugPath = getDebugPath(project, path);
		throw new Error(
			`New node of kind "${kind}" cannot be inserted at position ` +
				`"${position}" of "${debugPath}"`,
		);
	}
	const { parent } = getInsertHierachy(project, path, position);
	const insertIndex = getInsertIndex(project, path, position);
	const nextChildren = [...parent.children];
	nextChildren.splice(insertIndex, 0, node.id);
	const nextParent = { ...parent, children: nextChildren };
	return project.modell.nodes
		.setIn([parent.liteType, parent.id], nextParent)
		.setIn([node.liteType, node.id], node);
}

const addInstructionMap: {
	[TEvent in AddNodeShortcutEvent["type"]]: NodeInsertInstruction;
} = {
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.PAKET": {
		kind: LiteKind.Paket,
		position: "child",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.SCHEMA_PAKET": {
		kind: LiteKind.SchemaPaket,
		position: "child",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.DATENTYP": {
		kind: LiteKind.Datentyp,
		position: "child",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.CODE_DATENTYP": {
		kind: LiteKind.CodeDatentyp,
		position: "child",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.NACHRICHT": {
		kind: LiteKind.Nachricht,
		position: "child",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_CHILD.GLOBALE_EIGENSCHAFT": {
		kind: LiteKind.GlobaleEigenschaft,
		position: "child",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.PAKET": {
		kind: LiteKind.Paket,
		position: "above",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.SCHEMA_PAKET": {
		kind: LiteKind.SchemaPaket,
		position: "above",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.DATENTYP": {
		kind: LiteKind.Datentyp,
		position: "above",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.CODE_DATENTYP": {
		kind: LiteKind.CodeDatentyp,
		position: "above",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.NACHRICHT": {
		kind: LiteKind.Nachricht,
		position: "above",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_ABOVE.GLOBALE_EIGENSCHAFT": {
		kind: LiteKind.GlobaleEigenschaft,
		position: "above",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.PAKET": {
		kind: LiteKind.Paket,
		position: "below",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.SCHEMA_PAKET": {
		kind: LiteKind.SchemaPaket,
		position: "below",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.DATENTYP": {
		kind: LiteKind.Datentyp,
		position: "below",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.CODE_DATENTYP": {
		kind: LiteKind.CodeDatentyp,
		position: "below",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.NACHRICHT": {
		kind: LiteKind.Nachricht,
		position: "below",
		target: "baustein",
	},
	"SHORTCUT.BAUSTEIN_VIEW.ADD_BELOW.GLOBALE_EIGENSCHAFT": {
		kind: LiteKind.GlobaleEigenschaft,
		position: "below",
		target: "baustein",
	},

	"SHORTCUT.STRUCTURE_VIEW.ADD_CHILD.EIGENSCHAFT": {
		kind: LiteKind.Eigenschaft,
		position: "child",
		target: "structure",
	},
	"SHORTCUT.STRUCTURE_VIEW.ADD_CHILD.ATTRIBUT": {
		kind: LiteKind.Attribut,
		position: "child",
		target: "structure",
	},
	"SHORTCUT.STRUCTURE_VIEW.ADD_ABOVE.EIGENSCHAFT": {
		kind: LiteKind.Eigenschaft,
		position: "above",
		target: "structure",
	},
	"SHORTCUT.STRUCTURE_VIEW.ADD_ABOVE.ATTRIBUT": {
		kind: LiteKind.Attribut,
		position: "above",
		target: "structure",
	},
	"SHORTCUT.STRUCTURE_VIEW.ADD_BELOW.EIGENSCHAFT": {
		kind: LiteKind.Eigenschaft,
		position: "below",
		target: "structure",
	},
	"SHORTCUT.STRUCTURE_VIEW.ADD_BELOW.ATTRIBUT": {
		kind: LiteKind.Attribut,
		position: "below",
		target: "structure",
	},
};
export function getInsertInstructionsFromEvent(
	eventType: AddNodeShortcutEvent["type"],
): NodeInsertInstruction {
	return addInstructionMap[eventType];
}

type DeleteCheck = (
	projekt: StandardProjekt,
	hierachy: LiteNode[],
	node: LiteNode,
) => boolean;

const deleteRules: { [TKind in "baustein" | "structure"]: DeleteCheck } = {
	baustein: memoize(
		(projekt) => {
			// We can only delete nodes, when there is at least one paket node left in
			// the baustein tree. Otherwise we wouldn't be able to open a context menu
			// to add new nodes
			const root = selectRootModell(projekt);
			const children = createSelectDirectChildren(projekt)(root);
			const paketChildren = children.filter((node) => isLitePaket(node));
			return paketChildren.length > 1;
		},
		// Make sure we only use the projekt as cache key
		{ getCacheKeys: (projekt) => [projekt], cacheSize: 1 },
	),
	structure: (_, hierachy, node) =>
		// We only support deleting eigenschaft nodes for now
		isLiteEigenschaft(node) &&
		// Make sure, no parent has a `datentyp`, so we're actually allowed
		// to edit the node
		hierachy
			.slice(0, -1)
			.every((parent) => !isLiteEigenschaft(parent) || !parent.datentyp),
};

export function canDeleteNode(
	projekt: Nullish<StandardProjekt>,
	deletePath: LiteId[],
	target: NodeTarget,
): boolean {
	if (!projekt) return false;
	const { hierachy, node } = getNodeHierachy(projekt, deletePath);
	return deleteRules[target](projekt, hierachy, node);
}

type ReferenceEntry = { node: LiteNode; references: LiteNode[] };

function createReferenceEntry(
	modell: LiteModellContainer,
	node: LiteNode,
	ids: LiteId[],
): ReferenceEntry {
	const references = filterFalsy(
		ids.map((refId) => selectNodeFromModell(modell, refId)),
	);
	return { node, references };
}

export function listNodeReferences(
	projekt: Nullish<StandardProjekt>,
	node: LiteNode,
): ReferenceEntry[] {
	if (!projekt) return [];
	const entries = walkTreeEntries([node], createSelectDirectChildren(projekt));
	const referencedBausteinNodes: ReferenceEntry[] = [];
	for (const [, childNode] of entries) {
		if (isLiteEigenschaft(childNode)) continue;
		// Find references by datentyp
		const references = createSelectReferences(projekt)(childNode.id);
		if (references.length > 0) {
			referencedBausteinNodes.push(
				createReferenceEntry(projekt.modell, childNode, references),
			);
		}
		// Find references by basisdatentyp (extensions)
		const extensions = createSelectExtensions(projekt)(childNode.id);
		if (extensions.length > 0) {
			referencedBausteinNodes.push(
				createReferenceEntry(projekt.modell, childNode, extensions),
			);
		}
	}
	return referencedBausteinNodes;
}

export function listReferences(
	projekt: Nullish<StandardProjekt>,
	deletePath: LiteId[],
): ReferenceEntry[] {
	if (!projekt) return [];
	const { node } = getNodeHierachy(projekt, deletePath);
	return listNodeReferences(projekt, node);
}

export function stringifyReferences(references: ReferenceEntry[]): string {
	return references
		.map((ref) => ({
			...ref,
			references: ref.references
				.map((node) => `    - ${node.name || placeholder.anonymousStructure}`)
				.join("\n"),
		}))
		.map(
			(ref) =>
				`- ${ref.node.name || placeholder.anonymousStructure}\n${
					ref.references
				}`,
		)
		.join("\n\n");
}

/**
 * Check whether or not a datatype node, that is about to be deleted, is
 * referenced by any eigenschaft nodes. In that case a warning will be
 * displayed
 */
export function isDeletedNodeReferenced(
	projekt: Nullish<StandardProjekt>,
	deletePath: LiteId[],
): boolean {
	if (!projekt) return false;
	const { node } = getNodeHierachy(projekt, deletePath);
	return listNodeReferences(projekt, node).length > 0;
}

const deleteInstructionMap: {
	[TEvent in DeleteNodeShortcutEvent["type"]]: NodeDeleteInstruction;
} = {
	"SHORTCUT.BAUSTEIN_VIEW.DELETE": { target: "baustein" },
	"SHORTCUT.STRUCTURE_VIEW.DELETE": { target: "structure" },
};
export function getDeleteInstructionsFromEvent(
	eventType: DeleteNodeShortcutEvent["type"],
): NodeDeleteInstruction {
	return deleteInstructionMap[eventType];
}

/**
 * Assert function for narrowing events typed with discriminated unions
 */
export function assertPatch<
	TPatch extends ModellierungPatch,
	Type extends TPatch["type"],
>(patch: TPatch, type: Type): asserts patch is Extract<TPatch, { type: Type }> {
	if (patch.type !== type) {
		throw new Error(
			`Unexpected patch type "${patch.type}". Expected "${type}"`,
		);
	}
}

export function getUndoRedoNavigationTarget(
	eventLog: EventStoreLog,
	eventLogIndex: number | null,
): string | null {
	if (eventLogIndex === null) return null;
	const lastNavigateEvent = eventLog
		.slice(0, eventLogIndex)
		.findLast((entry) => entry.event.type === "NAVIGATE");
	if (!lastNavigateEvent || lastNavigateEvent.event.type !== "NAVIGATE") {
		return null;
	}
	return lastNavigateEvent.event.payload.location;
}

export function isChoice(node: LiteNode) {
	return "gruppeArt" in node && node.gruppeArt === LiteGruppeType.Choice;
}
export function isGroupTypeAll(node: LiteNode) {
	return "gruppeArt" in node && node.gruppeArt === LiteGruppeType.All;
}
