import { styled } from "@mui/material";
import { GridFilterPanel } from "@mui/x-data-grid";

const CustomGridFilterPanel = styled(GridFilterPanel)(() => ({
	"& .MuiDataGrid-filterForm": {
		padding: "16px",
		"& .MuiFormLabel-root": {
			paddingBottom: "6px",
			color: "#1D0F26",
			fontSize: "1rem",
			fontWeight: "600",
			lineHeight: "160%",
		},
		"& .MuiInputBase-root": {
			svg: {
				top: "calc(50% - 0.3em)",
			},
		},
		"&& .MuiInput-root:hover::before": {
			borderBottom: "none !important",
		},
		"& .MuiInput-root": {
			"&::before,&::after": {
				borderBottom: "none !important",
			},
		},
		"& .MuiDataGrid-filterFormDeleteIcon": {
			marginRight: "20px",
			justifyContent: "center",
		},
		"& .MuiNativeSelect-select, & .MuiInputBase-input": {
			marginTop: "6px",
			padding: "8px 12px",
			borderRadius: "5px",
			border: "1px solid #9BA0A6",
			background: "#FFF",
		},
		"& .MuiDataGrid-filterFormColumnInput": {
			"& .MuiNativeSelect-select": {
				color: "#212121",
				fontSize: "1rem",
				fontWeight: "400",
				lineHeight: "1.5rem",
			},
		},
		"& .MuiDataGrid-filterFormOperatorInput, & .MuiDataGrid-filterFormValueInput":
			{
				marginLeft: "16px",
			},
	},
}));

export default CustomGridFilterPanel;
