import { createSvgIcon } from "@mui/material";

const StandardInEntwicklungIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M16.4697 5.10392C13.3311 1.96536 8.24249 1.96536 5.10392 5.10392C1.96536 8.24249 1.96536 13.3311 5.10392 16.4697C5.39682 16.7626 5.39682 17.2374 5.10392 17.5303C4.81103 17.8232 4.33616 17.8232 4.04326 17.5303C0.318912 13.806 0.318912 7.76761 4.04326 4.04326C7.76761 0.318913 13.806 0.318912 17.5303 4.04326C17.5834 4.09629 17.6268 4.15529 17.6606 4.2181L19.1703 2.70837C19.4821 2.39665 20.0153 2.61155 20.0238 3.0523L20.1113 7.60183C20.1168 7.88546 19.8854 8.11681 19.6018 8.11135L15.0523 8.02383C14.6115 8.01535 14.3966 7.48208 14.7083 7.17037L16.6445 5.23421C16.5817 5.20038 16.5227 5.15696 16.4697 5.10392Z" />
		<path d="M17.0625 13.0014C17.0625 13.9394 16.3975 14.7266 15.5 14.9435V17.75L17.6983 19.3987C18.0433 19.1467 18.4724 18.9973 18.9375 18.9973C20.0766 18.9973 21 19.8933 21 20.9986C21 22.104 20.0766 23 18.9375 23C17.7984 23 16.875 22.104 16.875 20.9986C16.875 20.703 16.9411 20.4223 17.0597 20.1697L15 18.625L12.9404 20.1698C13.0589 20.4223 13.125 20.703 13.125 20.9986C13.125 22.104 12.2016 23 11.0625 23C9.92341 23 9 22.104 9 20.9986C9 19.8933 9.92341 18.9973 11.0625 18.9973C11.5276 18.9973 11.9568 19.1467 12.3018 19.3987L14.5 17.75V14.9435C13.6025 14.7267 12.9375 13.9394 12.9375 13.0014C12.9375 11.896 13.8609 11 15 11C16.1391 11 17.0625 11.896 17.0625 13.0014Z" />
	</svg>,
	"StandardInEntwickling",
);

export default StandardInEntwicklungIcon;
