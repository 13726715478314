import type {
	AnyResultMap,
	ValidationResultBase,
	ValidationTargetField,
	Validator,
	ValidatorExecResult,
	ValidatorMap,
} from "./types";
import {
	collectAsync,
	filterFalsyAsync,
	mapAsync,
	scanAsync,
	toAbortableAsync,
} from "../../utils/generator";
import { compose } from "../../utils/func";
import { objectEntries, objectFromEntries } from "../../utils/object";
import { toMutable } from "../../utils/types";

/**
 * Run the validation in chunks, so we don't wait after each validation steps
 * but instead after `CHUNK_SIZE` validation steps
 */
const DEFAULT_CHUNK_SIZE = 10;

// `requestIdleCallback` allow us to run code, when the browser thinks the cpu
// is not too busy. This way we can defer less important work until more
// important tasks, such as react's rendering has finished
const idleCallback: typeof window.requestIdleCallback =
	window.requestIdleCallback || ((cb: () => void) => setTimeout(cb, 0));
const isIdle = () =>
	new Promise<void>((res) => {
		idleCallback(() => res(), { timeout: 60 });
	});

/**
 * Execute a validator, preferably when the browser is not too busy.
 * @param validator The validator that should be executed
 * @param signal The abort signal, used to stop the validation run if necessary
 * @param chunkSize The number of validation steps to run in one validation tick
 * @returns The list of validation results
 */
async function deferValidation<
	ValidationResultT extends ValidationResultBase<ValidationTargetField>,
>(
	validator: Validator<ValidationResultT>,
	signal: AbortSignal,
	chunkSize: number,
): Promise<ValidationResultT[]> {
	const process = compose(
		(v: Validator<ValidationResultT>, s: AbortSignal) => toAbortableAsync(v, s),
		scanAsync<
			ValidationResultT | null,
			{ index: number; result: ValidationResultT | null }
		>((acc, result) => ({ index: acc.index + 1, result }), {
			index: 0,
			result: null,
		}),
		mapAsync(({ index, result }) =>
			index % chunkSize === 0
				? isIdle().then(() => result)
				: Promise.resolve(result),
		),
		filterFalsyAsync,
		collectAsync,
	);
	return process(validator, signal);
}

// We want to make the abort signal to `runValidation` optional, so we're able
// to run one validation pass to completion, without needing to create an
// unused `AbortController`. We'll want to do this before saving a project for
// example
const defaultSignal = new AbortController().signal;

/**
 * Run a validation pass against the provided validators. We defer the
 * execution of the validation to be run when the browser is not too busy.
 * We can do this, because validation is not required to run instantly to be
 * useful. It's enough if we know that it will run in the somewhat near future.
 * No one can type that fast that the slight delay will be a problem. Further
 * more, defering the validation helps us in cases, where we need to validate
 * a lot of data. It won't block the rest of the app from running, so the
 * experience will still be reasonably smooth.
 *
 * @param validatorMap The validators that will be executed
 * @param [options] Additional configuration for the validation run
 * @param [options.signal] An optional `AbortSignal`, so we can stop the validation early
 * @param [options.chunkSize] The number of validation steps to run in one validation tick
 * @returns The list of validation results
 */
export function executeValidators<ResultMap extends AnyResultMap>(
	validatorMap: ValidatorMap<ResultMap>,
	{
		signal = defaultSignal,
		chunkSize = DEFAULT_CHUNK_SIZE,
	}: { signal?: AbortSignal; chunkSize?: number } = {},
): Promise<ValidatorExecResult<ResultMap>> {
	const promises = objectEntries(validatorMap).map(([key, validator]) =>
		deferValidation(validator, signal, chunkSize).then((result) =>
			toMutable([key, result] as const),
		),
	);
	return Promise.all(promises).then(
		(entries) => objectFromEntries(entries) as ValidatorExecResult<ResultMap>,
	);
}

export function createEmptyValidationResults<ResultMap extends AnyResultMap>(
	validators: ValidatorMap<ResultMap>,
): ValidatorExecResult<ResultMap> {
	return Object.fromEntries(
		Object.keys(validators).map(
			(key) => [key, [] as ResultMap[keyof ResultMap][]] as const,
		),
	) as ValidatorExecResult<ResultMap>;
}

export async function* createEmptyValidator<
	ResultType extends ValidationResultBase<ValidationTargetField>,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
>(): Validator<ResultType> {
	// Do nothing
}
