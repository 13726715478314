import { useSelector } from "@xstate/react";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import { getFromSystem } from "../../../../../utils/machines";
import { useActiveProjectId } from "../../navigation/hooks";
import type { ConfirmationQueryActorRef } from "./confirmation.query.machine";
import { useSelectFromSystem } from "../../../hooks";

export function getConfirmationQueryFromSystem(
	system: AnyActorSystem,
): ConfirmationQueryActorRef {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const projectId = useActiveProjectId();
	return getFromSystem(system, `confirmation:query:${projectId}`);
}

export function useConfirmationQueryRef(): ConfirmationQueryActorRef {
	return useSelectFromSystem((system) =>
		getConfirmationQueryFromSystem(system),
	);
}

export function useConfirmationContext() {
	const actorRef = useConfirmationQueryRef();
	return useSelector(actorRef, ({ context }) => context.confirmation);
}
