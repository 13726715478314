import { useSendStoreEvent } from "../../../EventStore/hooks";
import { useConfirmationContext } from "./hooks";
import ConfirmDialog from "../../../../ConfirmDialog";

export default function Confirmation(): JSX.Element {
	const sendStoreEvent = useSendStoreEvent();
	const confirmation = useConfirmationContext();

	const createHandleConfirm = () => {
		sendStoreEvent({
			type: "CONFIRMATION.CONFIRMED",
			payload: confirmation,
		});
	};
	const createHandleDeclined = (_event?: object, reason?: string) => {
		if (reason && reason === "backdropClick" && "escapeKeyDown") {
			return;
		}

		sendStoreEvent({
			type: "CONFIRMATION.DECLINED",
			payload: confirmation,
		});
	};

	return confirmation.refString ? (
		<ConfirmDialog
			data-testid="confirmation"
			style={{ whiteSpace: "pre-line" }}
			isOpen={!!confirmation.refString}
			onClose={createHandleDeclined}
			title="Datentyp löschen"
			description={
				`Wollen Sie den Knoten wirklich löschen?\n` +
				`Dieser wird in folgenden Eigenschaften verwendet.\n\n` +
				`${confirmation.refString}`
			}
			onAccept={createHandleConfirm}
			acceptText="Löschen"
			onDismiss={createHandleDeclined}
		/>
	) : (
		<></>
	);
}
