import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
	selectFilterValue,
	setFilter,
	UiFilterGroup,
} from "../../../redux/uiSlice";
import { useIsDescendantProfiled } from "../../../utils/profilingHelpers";
import { encodeXPath } from "../../../utils/url";
import { useActiveNode } from "../useActiveNode";
import { useStateSelector } from "../../EditorState";
import { selectProfiles, selectStandard } from "../../EditorState/selectors";
import EditorSideBar from "../../EditorSideBar";
import FilterMenu from "../../../AtomicComponents/Organisms/ProfilierungFilterMenu";
import ProfiledOnlyFilter from "../../../AtomicComponents/Organisms/ProfilierungProfiledOnlyFilter";
import ProfilingTree from "../../../AtomicComponents/Organisms/ProfilierungTree";
import type { NodeEventArg } from "../../Tree";
import type {
	LiteId,
	LiteNode,
} from "../../AppActor/actors/modellierungModel/schemas";
import {
	selectModellContainer,
	selectRootNode,
} from "../../../redux/treeSlice";
import { useEventHandler } from "../../../hooks";
import { selectQNamePathFromModell } from "../../AppActor/actors/modellierungModel/selectors";

const FilterGroup = UiFilterGroup.MessageStructureTree as const;

const MessageProfilingList = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const standard = useStateSelector(selectStandard());
	const profiles = useStateSelector(selectProfiles());
	const rootNode = useAppSelector(selectRootNode(standard));
	const showProfiledOnly = useAppSelector(
		selectFilterValue(FilterGroup, "showProfiledOnly"),
	);
	const activeNodeState = useActiveNode();
	const isDescendantProfiled = useIsDescendantProfiled(profiles);
	const modell = useAppSelector(selectModellContainer(standard));

	const handleFilterModeChange = () => {
		dispatch(setFilter(FilterGroup, { showProfiledOnly: !showProfiledOnly }));
	};

	const getUrl = useEventHandler((event: NodeEventArg<LiteNode, LiteId>) => {
		if (!modell) return "/profilierung/nachrichtenstrukturen";
		const qnamePath = selectQNamePathFromModell(modell, event.path);
		const nodePath = encodeXPath(qnamePath);
		return `/profilierung/nachrichtenstrukturen/${nodePath}`;
	});

	return (
		<EditorSideBar>
			<EditorSideBar.Main>
				{rootNode && (
					<ProfilingTree
						activeNodeState={activeNodeState}
						getUrl={getUrl}
						profiles={profiles}
						rootId={rootNode.id}
						isFilterMatch={(node) =>
							!showProfiledOnly || isDescendantProfiled(node.id)
						}
						filterGroup={FilterGroup}
						standard={standard}
					/>
				)}
			</EditorSideBar.Main>
			<EditorSideBar.Controls>
				<FilterMenu filterGroup={FilterGroup}>
					<ProfiledOnlyFilter
						checked={showProfiledOnly}
						onChange={handleFilterModeChange}
					/>
				</FilterMenu>
			</EditorSideBar.Controls>
		</EditorSideBar>
	);
};

export default MessageProfilingList;
