import type { SvgIconComponent } from "@mui/icons-material";
import type { LiteNode } from "../../AppActor/actors/modellierungModel/schemas";
import { kindTitleMap } from "../../AppActor/actors/modellierungModel/helpers";
import LiteNodeIcon from "../LiteNodeIcon";
import { kindIconMap } from "../../../resources/iconMaps/icons";
import { getExtendedLiteNodeKind } from "../../AppActor/actors/modellierungModel/LiteKind";

function getLiteNodeIcon(node: LiteNode): SvgIconComponent {
	return kindIconMap[getExtendedLiteNodeKind(node)];
}

function getLiteNodeTitle(node: LiteNode): string {
	return kindTitleMap[getExtendedLiteNodeKind(node)];
}

export default function LiteNodeKindIcon({
	node,
	iconClassName,
	className,
}: {
	node: LiteNode;
	iconClassName?: string;
	className?: string;
}): JSX.Element {
	const Icon = getLiteNodeIcon(node);
	const title = getLiteNodeTitle(node);

	return (
		<LiteNodeIcon
			icon={Icon}
			title={title}
			className={className}
			iconClassName={iconClassName}
		/>
	);
}
