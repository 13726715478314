import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, fromPromise, raise, setup } from "xstate";
import type { ProjektMeta } from "../../project/types";
import type { ModellierungModelEvent } from "../../../EventStore/StoreEvent";
import {
	sendToEventStore,
	translateStoreEvents,
} from "../../../EventStore/helpers";
import type {
	ValidationResult,
	ValidationTargetField,
} from "./validators/types";
import { ProjektType } from "../schemas";
import type { ProjektId, RawLiteModel } from "../schemas";
import ValidationWorkerBridge from "./worker/ValidationWorkerBridge";
import type {
	EventStoreEntryMeta,
	EventStoreLog,
} from "../../../EventStore/eventStore.machine";
import { getEventLogFromSystem } from "../../../EventStore/hooks";
import { SerializableError } from "../../../../../utils/error";

export type ValidationCommandActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof validationCommandMachine
>;

export type ValidationCommandEvent =
	| {
			type: "FORWARD_EVENT";
			event: ModellierungModelEvent;
			meta: EventStoreEntryMeta;
	  }
	| {
			type: "VALIDATE";
			event: ModellierungModelEvent;
			meta: EventStoreEntryMeta;
	  };

const validationCommandMachine = setup({
	types: {
		events: {} as ValidationCommandEvent,
		context: {} as ProjektMeta & {
			rawModel: RawLiteModel;
			workerBridge: ValidationWorkerBridge | null;
		},
		input: {} as ProjektMeta & { rawModel: RawLiteModel },
	},
	actors: {
		initializeWorkerState: fromPromise<
			ValidationWorkerBridge,
			{ projektId: ProjektId; eventLog: EventStoreLog }
		>(({ input }) => {
			const { eventLog, projektId } = input;
			return ValidationWorkerBridge.fromEventLog(
				projektId,
				eventLog,
				() =>
					new Worker(new URL("./worker/validation.worker.ts", import.meta.url)),
			);
		}),
		sendEventToWorker: fromPromise<
			ValidationResult<ValidationTargetField>[],
			{ workerBridge: ValidationWorkerBridge | null }
		>(({ input }) => {
			const { workerBridge } = input;
			if (!workerBridge) return Promise.reject();
			return workerBridge.onEvent();
		}),
		forwardEvents: translateStoreEvents<ValidationCommandEvent>({
			"MODELLIERUNG.MODELL.APPLY": (event, meta) => ({
				type: "FORWARD_EVENT",
				event,
				meta,
			}),
			"MODELLIERUNG.MODELL.UNDO": (event, meta) => ({
				type: "FORWARD_EVENT",
				event,
				meta,
			}),
			"MODELLIERUNG.MODELL.REDO": (event, meta) => ({
				type: "FORWARD_EVENT",
				event,
				meta,
			}),
		}),
	},
	actions: {
		forwardEvent: ({ event, context }) => {
			assertEvent(event, "FORWARD_EVENT");
			if (!context.workerBridge) {
				throw new SerializableError({
					name: "ValidationWorkerNotInitializedError",
					message:
						"The validation worker was not initialized in projekt" +
						`"${context.kennung}", "${context.dbId}", "${context.projektId}", "${context.projektType}"`,
				});
			}
			context.workerBridge.worker.forward(event.event, event.meta);
		},
	},
	guards: {
		isReadOnly: ({ context }) => context.projektType === ProjektType.Standard,
	},
}).createMachine({
	id: "validation:command",
	context: ({ input }) => ({ ...input, workerBridge: null }),
	invoke: { src: "forwardEvents" },
	initial: "CheckingProjektType",
	states: {
		CheckingProjektType: {
			always: [
				{ target: "Inactive", guard: "isReadOnly" },
				{ target: "Unititialized" },
			],
		},
		Inactive: { type: "final" },
		Unititialized: {
			invoke: {
				src: "initializeWorkerState",
				input: ({ context: { projektId }, self }) => ({
					projektId,
					eventLog: getEventLogFromSystem(self.system),
				}),
				onDone: {
					actions: assign(({ event }) => ({ workerBridge: event.output })),
					target: "Validating",
				},
			},
		},
		Idle: {},
		Validating: {
			invoke: {
				src: "sendEventToWorker",
				input: ({ context }) => ({ workerBridge: context.workerBridge }),
				onDone: {
					actions: ({ event, system, context }) => {
						sendToEventStore(system, {
							type: "VALIDATION.SUCCESS",
							payload: {
								projektId: context.projektId,
								results: event.output,
							},
						});
					},
					target: "Idle",
				},
			},
		},
	},
	on: {
		FORWARD_EVENT: {
			actions: [
				"forwardEvent",
				raise(({ event }) => ({ ...event, type: "VALIDATE" })),
			],
		},
		VALIDATE: {
			target: ".Validating",
		},
	},
});

export default validationCommandMachine;
