import { createSvgIcon } from "@mui/material";

const ProfilInEntwicklungIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M16.4697 5.10392C13.3311 1.96536 8.24249 1.96536 5.10392 5.10392C1.96536 8.24249 1.96536 13.3311 5.10392 16.4697C5.39682 16.7626 5.39682 17.2374 5.10392 17.5303C4.81103 17.8232 4.33616 17.8232 4.04326 17.5303C0.318912 13.806 0.318912 7.76761 4.04326 4.04326C7.76761 0.318913 13.806 0.318912 17.5303 4.04326C17.5833 4.09627 17.6268 4.15524 17.6606 4.21802L19.1702 2.70837C19.4819 2.39665 20.0152 2.61155 20.0237 3.0523L20.1112 7.60183C20.1167 7.88546 19.8853 8.11681 19.6017 8.11135L15.0522 8.02383C14.6114 8.01535 14.3965 7.48208 14.7082 7.17037L16.6444 5.23417C16.5816 5.20035 16.5227 5.15693 16.4697 5.10392Z" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22 16.5C22 17.3221 21.8583 18.1111 21.5979 18.8439C21.5905 18.8649 21.5829 18.8859 21.5752 18.9068C21.106 20.1885 20.2726 21.2947 19.2004 22.1002C18.0304 22.9791 16.576 23.5 15 23.5C11.134 23.5 8 20.366 8 16.5C8 12.634 11.134 9.5 15 9.5C18.866 9.5 22 12.634 22 16.5ZM15 22.5C16.3331 22.5 17.5647 22.0652 18.5607 21.3297C18.4415 21.0766 18.375 20.7951 18.375 20.4986C18.375 20.203 18.4411 19.9223 18.5597 19.6697L16.5 18.125L14.4403 19.6697C14.5589 19.9223 14.625 20.203 14.625 20.4986C14.625 21.2915 14.1498 21.9767 13.4608 22.3007C13.952 22.4307 14.4679 22.5 15 22.5ZM20.4375 18.4973C20.5112 18.4973 20.584 18.501 20.6556 18.5083C20.8786 17.8804 21 17.2044 21 16.5C21 14.3904 19.9112 12.535 18.265 11.4653C18.4538 11.7675 18.5625 12.1221 18.5625 12.5014C18.5625 13.4394 17.8975 14.2267 17 14.4435V17.25L19.1982 18.8987C19.5433 18.6467 19.9724 18.4973 20.4375 18.4973ZM12.5625 18.4973C11.4336 18.4973 10.5166 19.3773 10.5002 20.469C9.56655 19.4113 9 18.0218 9 16.5C9 13.1863 11.6863 10.5 15 10.5C15.321 10.5 15.636 10.5252 15.9434 10.5737C15.0747 10.8094 14.4375 11.5832 14.4375 12.5014C14.4375 13.4394 15.1025 14.2267 16 14.4435V17.25L13.8018 18.8987C13.4567 18.6467 13.0276 18.4973 12.5625 18.4973Z"
		/>
	</svg>,
	"ProfilInEntwicklung",
);

export default ProfilInEntwicklungIcon;
