import { stripLeadingSlash, stripTrailingSlash } from "../../utils/url";
import type {
	DecodingResult,
	FetchResult,
	RequestOptions,
	RequestOptionsWithData,
	UnknownRequestOptions,
} from "./types";
import {
	RequestStatusCode,
	RequestStatus,
	RequestMethods,
	ResponseDecodingMode,
} from "./types";

// We want to be able to create a useful error message out of nearly everything
// so we really do want to accept `any` kind of input
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createErrorMessage(error: any): string {
	if (!error) {
		return "Beim Abrufen der Daten ist ein unbekannter Fehler aufgetreten";
	}
	if (typeof error === "string") return error;
	if (typeof error === "object") {
		if (typeof error.message === "string") {
			return error.message;
		}
		try {
			return JSON.stringify(error);
		} catch {
			return String(error);
		}
	}
	return String(error);
}

function createEndpoint(origin: string, base: string, endpoint: string) {
	const normalizedOrigin = stripTrailingSlash(origin);
	const normalizedBase = stripTrailingSlash(stripLeadingSlash(base));
	const normalizedEndpoint = stripLeadingSlash(endpoint);
	return `${normalizedOrigin}/${normalizedBase}/${normalizedEndpoint}`;
}

const defaultRequestOptions: RequestOptions = {
	method: RequestMethods.Get,
};

function optionsHasData<RequestData = unknown>(
	options: UnknownRequestOptions<RequestData>,
): options is RequestOptionsWithData<RequestData> {
	return (
		options.method === RequestMethods.Post ||
		options.method === RequestMethods.Put ||
		options.method === RequestMethods.Delete
	);
}

function createRequestBody<RequestData>(data: RequestData): FormData | string {
	return data instanceof FormData ? data : JSON.stringify(data);
}

function guessResponseDecodingMode(response: Response) {
	const { headers } = response;
	const contentType = headers.get("content-type");
	if (
		contentType &&
		(contentType.startsWith("application/json") ||
			contentType.startsWith("text/json"))
	) {
		return ResponseDecodingMode.Json;
	}
	if (
		contentType &&
		(contentType.startsWith("text/") ||
			contentType.startsWith("application/xml"))
	) {
		return ResponseDecodingMode.Text;
	}
	return ResponseDecodingMode.Blob;
}

async function decodeResponseDataUnsafe<ResponseData>(
	response: Response,
	expect: ResponseDecodingMode | undefined,
): Promise<ResponseData> {
	const contentType = response.headers.get("content-type");
	if (!contentType && !expect) {
		throw new Error(
			`Decoding response from request to "${response.url}" failed. ` +
				`No content type could be found. Either provide a content-type ` +
				`response header or set it explicitely using the "expect" property ` +
				`in the fetch options.`,
		);
	}
	const encoding = expect || guessResponseDecodingMode(response);
	if (encoding === ResponseDecodingMode.Json) {
		return response.json() as Promise<ResponseData>;
	}
	if (encoding === ResponseDecodingMode.Text) {
		return response.text() as unknown as Promise<ResponseData>;
	}
	return response.blob() as unknown as Promise<ResponseData>;
}

// Explicitely catch decoding errors, so we can be more precise with our error
// handling
async function decodeResponseData<ResponseData>(
	response: Response,
	expect: ResponseDecodingMode | undefined,
): Promise<DecodingResult<ResponseData>> {
	try {
		const responseData = await decodeResponseDataUnsafe<ResponseData>(
			response,
			expect,
		);
		return { data: responseData, status: null, error: null };
	} catch (error) {
		return { data: null, status: RequestStatusCode.DecodingError, error };
	}
}

// Simplify the creation of error responses
function createErrorResponse(
	status: number,
	message: string,
	headers: Record<string, string> | null,
) {
	return {
		data: null,
		error: { status, message },
		headers,
		status: RequestStatus.Failure,
	};
}

// Create a response from a successful response, (i.e. `response.ok === true`)
async function createResponse<ResponseData>(
	response: Response,
	expect: ResponseDecodingMode | undefined,
	headers: Record<string, string> | null,
) {
	const decodeResult = await decodeResponseData<ResponseData>(response, expect);
	if (decodeResult.data !== null) {
		return {
			data: decodeResult.data,
			error: null,
			headers,
			status: RequestStatus.Success,
		};
	}
	return createErrorResponse(
		decodeResult.status as number,
		createErrorMessage(decodeResult.error),
		headers,
	);
}

function createFetchOptions<
	Method extends RequestMethods = RequestMethods.Get,
	RequestData = unknown,
>(options?: RequestOptions<Method, RequestData>) {
	const fetchOptions = {
		...defaultRequestOptions,
		...options,
	} as RequestOptions<Method, RequestData>;
	const data = optionsHasData(fetchOptions)
		? createRequestBody(fetchOptions.data)
		: null;
	return {
		method: fetchOptions.method,
		headers: fetchOptions.headers,
		...(data && { body: data }),
	};
}

async function appFetch<
	ResponseData = unknown,
	Method extends RequestMethods = RequestMethods.Get,
	RequestData = unknown,
>(
	origin: string,
	base: string,
	endpoint: string,
	options?: RequestOptions<Method, RequestData>,
): Promise<FetchResult<ResponseData>> {
	try {
		const res = await globalThis.fetch(
			createEndpoint(origin, base, endpoint),
			createFetchOptions(options),
		);
		const headers = Object.fromEntries(res.headers);
		if (res.ok) {
			return createResponse(res, options?.expect, headers);
		}
		// If there is an error, keep the old data around, so we can still
		// display something
		const message = await res.text();
		return createErrorResponse(res.status, message, headers);
	} catch (e) {
		return createErrorResponse(
			RequestStatusCode.UnknownError,
			createErrorMessage(e),
			null,
		);
	}
}

export default appFetch;
