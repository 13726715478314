import { Button, Menu } from "@mui/material";
import classNames from "classnames";
import type { MouseEvent, ReactNode } from "react";
import { useMemo, useState } from "react";
import type { SvgIconComponent } from "@mui/icons-material";
import { useEventHandler, useHtmlId } from "../../../../hooks";
import type { PropsOf } from "../../../../utils/types";
import ToolbarMenuContext from "./ToolbarMenuContext";
import ToolbarMenuNested from "./ToolbarMenuNested";
import "./ToolbarMenu.scss";

export default function ToolbarMenu({
	children,
	icon: Icon,
	label,
	isActive,
	buttonProps,
	menuProps,
}: {
	children:
		| ReactNode
		| ((renderProps: { isOpen: boolean; closeMenu: () => void }) => ReactNode);
	isActive?: boolean;
	icon: SvgIconComponent;
	label: string;
	buttonProps?: Partial<PropsOf<typeof Button>>;
	menuProps?: Partial<PropsOf<typeof Menu>>;
}): JSX.Element {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = useEventHandler(() => {
		setAnchorEl(null);
	});

	const menuId = useHtmlId();
	const menuButtonId = useHtmlId();

	const ctx = useMemo(() => ({ isOpen: open }), [open]);

	return (
		<>
			<Button
				id={menuButtonId}
				aria-controls={open ? menuId : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				classes={{
					startIcon: classNames(
						"toolbar-menu__icon-wrapper",
						isActive && "toolbar-menu__icon-wrapper--active",
					),
				}}
				startIcon={<Icon className="toolbar-menu__icon" />}
				color={isActive ? "primary" : "inherit"}
				{...buttonProps}
			>
				{label}
			</Button>
			<Menu
				id={menuId}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{ "aria-labelledby": menuButtonId }}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "bottom", horizontal: "left" }}
				{...menuProps}
			>
				<ToolbarMenuContext.Provider value={ctx}>
					{typeof children === "function"
						? children({ isOpen: open, closeMenu: handleClose })
						: children}
				</ToolbarMenuContext.Provider>
			</Menu>
		</>
	);
}

ToolbarMenu.Nested = ToolbarMenuNested;
