import { useAppSelector } from "../../../../../../redux/hooks";
import { selectIsRekursionStart } from "../../../../../../redux/treeSlice";
import EditFormField from "../../../../../EditFormField";
import { useStateSelector } from "../../../../../EditorState";
import { selectStandard } from "../../../../../EditorState/selectors";
import {
	subSelectRecursionLimit,
	subSelectRecursionDesc,
	subSelectHasZeroCardinality,
} from "../../../../../EditorState/subSelectors";
import ProfilingValidationDisplay from "../../../../../../AtomicComponents/Organisms/ProfilierungValidationDisplay";
import DynamicInfo from "../../../../../ui/DynamicInfo";
import { ValidationTargetField } from "../../../../../Validation/types";
import DetailsComponent from "../../../DetailsComponent/DetailsComponent";
import type { FieldRendererComponentProps } from "../../types";
import "./RecursionRenderer.scss";

function RecursionRenderer({
	activeNode,
	profile,
	onBlur,
	onFix,
	definition,
}: FieldRendererComponentProps<"istRekursionsStart">): JSX.Element {
	const standard = useStateSelector(selectStandard());
	const isRekursionStart = useAppSelector(
		selectIsRekursionStart(standard, activeNode),
	);
	const value = subSelectRecursionLimit(profile) || "";
	const isZeroCardinality = subSelectHasZeroCardinality(profile);

	if (isRekursionStart) {
		return (
			<div className="recursion-renderer" data-testid="recursion-renderer">
				<EditFormField
					className="recursion-renderer__edit-field"
					labelClassName="recursion-renderer__hidden-label"
					definition={{
						readOnly: definition?.readOnly || isZeroCardinality,
						name: "limit",
						label: (
							<>
								Einschränkung der Rekursionstiefe{" "}
								{isZeroCardinality && <DynamicInfo infoKey="recursion" />}
							</>
						),
					}}
					value={value}
					onBlur={onBlur}
					placeholder="*"
					error={
						<ProfilingValidationDisplay
							targetField={ValidationTargetField.RecursionLimit}
							nodeId={activeNode.id}
							value={value}
							onFix={onFix}
						/>
					}
				/>
				<DetailsComponent
					label="Beschreibung der Einschränkung der Rekursionstiefe"
					name="beschreibungRekursion"
					value={subSelectRecursionDesc(profile) || ""}
					onBlur={onBlur}
				/>
			</div>
		);
	}

	return <></>;
}

export default RecursionRenderer;
