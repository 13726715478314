import ExportButton from "./ExportButton";
import RequestErrorNotification from "../../RequestErrorNotification";
import { useExportModellierungProjektMutation } from "../../../redux/apiSlice";
import {
	toRequestError,
	toRequestStatus,
} from "../../../utils/requestFormatters";
import {
	useModellierungModellSelector,
	useModellierungProjekt,
} from "../../AppActor/actors/modellierungModel/hooks";
import { selectRootModell } from "../../AppActor/actors/modellierungModel/selectors";
import createBackendModel from "../../AppActor/actors/project/projectModel/createBackendModel";

const ExportModellierungProject = (): JSX.Element => {
	const [exportModellierungProjekt, { error, isSuccess, isError, isLoading }] =
		useExportModellierungProjektMutation();

	const requesterror = toRequestError(error);
	const requesttatus = toRequestStatus({ isError, isLoading, isSuccess });

	const modell = useModellierungModellSelector((context) => context);
	const activeProject = useModellierungProjekt();

	const handleExportClick = () => {
		if (modell && activeProject) {
			const rootModell = selectRootModell(activeProject);
			const backendModel = createBackendModel(activeProject);
			exportModellierungProjekt({
				id: modell.dbId,
				name: rootModell.metadatenStandard.nameTechnisch,
				data: {
					id: modell.dbId,
					name: rootModell.metadatenStandard.nameKurz,
					modell: {
						typ: activeProject.type,
						...backendModel,
					},
				},
			});
		}
	};

	return (
		<>
			<RequestErrorNotification
				id="export-project-zip-error"
				error={requesterror}
				status={requesttatus}
			>
				Fehler beim Exportieren des Projekts
			</RequestErrorNotification>
			{modell && activeProject && (
				<ExportButton status={requesttatus} onClick={handleExportClick} />
			)}
		</>
	);
};

export default ExportModellierungProject;
