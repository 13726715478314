import type { Dispatch, SetStateAction } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useUploadProjektMutation } from "../../../../redux/apiSlice";
import { useSendStoreEvent } from "../../../../components/AppActor/EventStore/hooks";
import { Dropzone, useDropzone } from "../../../../components/ui";
import RequestErrorNotification from "../../../../components/RequestErrorNotification";
import ModalHeader from "../../../../components/ui/Modal/ModalHeader";
import {
	toRequestStatus,
	toRequestError,
} from "../../../../utils/requestFormatters";
import ErrorNotification from "./ErrorNotifaction";

const UploadDialog = ({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
	const { getProps, hasSelectedFiles, reset, rejectedError, selectedFiles } =
		useDropzone({
			accept: [".xml", ".zip"],
			multiple: false,
		});
	const sendStoreEvent = useSendStoreEvent();

	const [uploadProjekt, { error, isError, isLoading, isSuccess }] =
		useUploadProjektMutation();

	const status = toRequestStatus({ isError, isLoading, isSuccess });
	const requestError = toRequestError(error);

	const handleClick = () => {
		if (selectedFiles.length > 0) {
			const reqData = new FormData();
			for (const file of selectedFiles) {
				reqData.append("file", file);
			}

			uploadProjekt({ data: reqData })
				.unwrap()
				.then((data) => {
					setIsOpen(false);

					sendStoreEvent({
						type: "PROJECT.OPEN_EXISTING",
						payload: {
							projektMeta: {
								dbId: data.id,
								kennung: data.modell.kennung,
								projektType: data.modell.typ,
							},
						},
					});

					reset();
				});
		}
	};

	const handleClose = () => {
		if (isLoading) return;
		setIsOpen(false);
	};

	return (
		<>
			<RequestErrorNotification
				id="upload-project-request"
				status={status}
				error={requestError}
			>
				Hochladen des Projektes fehlgeschlagen
			</RequestErrorNotification>
			<Dialog
				open={isOpen || isLoading}
				onClose={handleClose}
				data-testid="project-upload-modal"
			>
				<ModalHeader title="Projekt hochladen" handleClose={handleClose} />
				<DialogContent
					data-testid="project-upload-modal-content"
					data-is-open={isOpen}
				>
					<Stack spacing={2}>
						<DialogContentText>
							Bitte wählen Sie nur ein Projekt im ZIP-Format oder XML-Format
							aus.
						</DialogContentText>
						<Dropzone {...getProps()}>
							<FileUploadIcon />
							{hasSelectedFiles ? (
								<ul>
									{selectedFiles.map((file) => (
										<li key={file.name}>{file.name}</li>
									))}
								</ul>
							) : (
								"Drag & Drop oder Feld klicken, um Explorer zu öffnen"
							)}
						</Dropzone>
					</Stack>
				</DialogContent>
				<ErrorNotification
					id="project-upload-message-drop"
					rejectedError={rejectedError}
				>
					Hochladen des Projektes fehlgeschlagen
				</ErrorNotification>
				<DialogActions>
					<Button onClick={handleClose} variant="outlined" disabled={isLoading}>
						Schließen
					</Button>
					<LoadingButton
						loading={isLoading}
						onClick={handleClick}
						variant="contained"
						data-testid="project-upload-button"
					>
						Hochladen
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UploadDialog;
