import { metadataFormHelpers } from "./metadataFormHelpers";
import {
	useApplyPatch,
	useEditorPatchCreators,
	useStateSelector,
} from "../../EditorState";
import { selectMetadata } from "../../EditorState/selectors";
import MetadataForm from "../MetadataForm";
import type ProfilingMetadataValues from "../../../types/ProflierungMetadataValues";
import { generateKennung } from "../../../utils/misc";
import "./MetadataEditForm.scss";

const { visibleFields } = metadataFormHelpers;

function MetadataEditForm(): JSX.Element {
	const applyPatch = useApplyPatch();
	const { setMetadata } = useEditorPatchCreators();
	const values = useStateSelector(selectMetadata());

	const handleWrite = (name: string, value: string) => {
		const kennung = generateKennung({
			standard: values.get("standard"),
			herausgeber: values.get("herausgeber"),
			nameKurz: values.get("nameKurz"),
			version: values.get("version"),
			// Overwrite `herausgeber`, `nameKurz` and `version` when they change
			[name]: value,
		});
		const nextValues = values
			.set(
				name as keyof ProfilingMetadataValues,
				value.trim().replace(/\s+/g, " "),
			)
			.set("kennung", kennung);
		applyPatch(setMetadata(nextValues));
	};

	return (
		<div data-testid="project-metadata-form">
			<MetadataForm
				className="metadata-edit-form"
				onWrite={handleWrite}
				values={values}
			>
				<MetadataForm.Fields fields={visibleFields} />
			</MetadataForm>
		</div>
	);
}

export default MetadataEditForm;
