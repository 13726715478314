import type { TreeNodeType } from "../../../types/ProfilierungHome";
import { ListDisplayState } from "./types";

// eslint-disable-next-line import/prefer-default-export
export function getListDisplayState({
	datatypes,
	isLoading,
}: {
	isLoading: boolean;
	datatypes: TreeNodeType[] | null;
}): ListDisplayState {
	if (!datatypes && !isLoading) return ListDisplayState.ShowFallback;
	if (!datatypes && isLoading) return ListDisplayState.ShowLoading;
	return ListDisplayState.ShowList;
}
