import type { AddNodeShortcutEvent } from "../../../AppActor/EventStore/StoreEvent";
import {
	canInsertNode,
	getInsertInstructionsFromEvent,
	newKindTitleMap,
} from "../../../AppActor/actors/modellierungModel/helpers";
import { useModellierungProjekt } from "../../../AppActor/actors/modellierungModel/hooks";
import { kindIconMap } from "../../../../resources/iconMaps/icons";
import ToolbarMenuItem from "../ToolbarMenuItem";
import { useTargetPath } from "./helpers";

export default function AddNodeButton({
	command,
	onClick,
}: {
	command: AddNodeShortcutEvent["type"];
	onClick?: () => void;
}): JSX.Element {
	const { kind, position, target } = getInsertInstructionsFromEvent(command);
	const insertPath = useTargetPath(target);
	const project = useModellierungProjekt();
	const isDisabled = !canInsertNode(project, insertPath, kind, position);
	const Icon = kindIconMap[kind];
	const label = newKindTitleMap[kind];
	const tooltip = `Ein Knoten vom typ ${kind} kann nicht an dieser Stelle erstellt werden`;

	return (
		<ToolbarMenuItem
			onClick={onClick}
			isDisabled={isDisabled}
			icon={Icon}
			command={command}
			{...(isDisabled ? { tooltip } : {})}
		>
			{label}
		</ToolbarMenuItem>
	);
}
