import classNames from "classnames";
import { useEffect, useState } from "react";
import type { TreeProps } from "../../../../../Tree";
import Tree from "../../../../../Tree";
import {
	INDEX_PROP,
	IS_EMPTY_PROP,
	IS_LEAF_PROP,
	NAME_PROP,
	NODE_COUNT_PROP,
	RENDERER_PROP,
	VALUE_PROP,
	extractChildNodeEntries,
	getMarkupNodeType,
	isMarkupNodeType,
} from "../../../helpers";
import type {
	Markup,
	MarkupNode,
	MarkupNodeTypeMap,
	RendererImplementation,
} from "../../../types";
import { MarkupNodeType } from "../../../types";
import type { RendererImplementationComponent } from "../../types";
import { useEventHandler, useSyncedRef } from "../../../../../../hooks";
import TreeRendererNode from "./TreeRendererNode";
import useIsMountedRef from "../../../../../../hooks/useIsMountedRef";
import { clamp } from "../../../../../../utils/math";
import "./TreeRenderer.scss";

const WIDTH_CUSTOM_PROP = "--tree-renderer-width";
const POSITION_CUSTOM_PROP = "--tree-renderer-divider-position";
const DEFAULT_DIVIDER_POSITION = 0.33;
const MIN_DIVIDER_POSITION = 0.1;
const MAX_DIVIDER_POSITION = 0.9;

function createLeaf(key: string, value: Markup | null) {
	if (value !== null) {
		return {
			[NAME_PROP]: key,
			[IS_LEAF_PROP]: "true",
			[VALUE_PROP]: value[VALUE_PROP],
			[INDEX_PROP]: value[INDEX_PROP],
		};
	}
	return { [NAME_PROP]: key, [IS_LEAF_PROP]: "true", [IS_EMPTY_PROP]: "true" };
}

const transformMap: {
	[Type in MarkupNodeType]: (
		key: string,
		value: MarkupNodeTypeMap[Type],
	) => MarkupNode | MarkupNode[];
} = {
	[MarkupNodeType.Node]: (key, value) => {
		// Add the key of the node to the node, so we can display it as the
		// node title later on
		if (VALUE_PROP in value && !(RENDERER_PROP in value)) {
			return createLeaf(key, value);
		}
		return { [NAME_PROP]: key, ...value };
	},
	[MarkupNodeType.NodeArray]: (key, value) => {
		// Each object is another markup node, so we need to add a name again
		return value.map((item) => ({ [NAME_PROP]: key, ...item }));
	},
	[MarkupNodeType.String]: (key, value) => {
		return { [NAME_PROP]: key, [IS_LEAF_PROP]: "true", [VALUE_PROP]: value };
	},
	[MarkupNodeType.StringArray]: (key, value) => {
		return {
			[NAME_PROP]: key,
			[IS_LEAF_PROP]: "true",
			[VALUE_PROP]: value.join(","),
		};
	},
	[MarkupNodeType.Empty]: (key) => createLeaf(key, null),
};

const getChildren = (node: MarkupNode): MarkupNode[] => {
	// If we only have strings or an array of strings, don't return any children.
	// They should be displayed in a dialog (see #126)
	if (
		!isMarkupNodeType(node, MarkupNodeType.Node) &&
		!isMarkupNodeType(node, MarkupNodeType.NodeArray)
	) {
		return [];
	}

	// Add a readable label to each value
	const children = extractChildNodeEntries(node).flatMap(([key, value]) => {
		const type = getMarkupNodeType(value);
		return transformMap[type](key, value as never);
	});
	return children as MarkupNode[];
};

function getTreeProps(
	maxDigits?: number,
): Pick<
	TreeProps<MarkupNode>,
	"getChildren" | "isNodeExpandable" | "renderNode" | "initialPath"
> {
	return {
		getChildren,
		isNodeExpandable: (node) => getChildren(node).length > 0,
		initialPath: [0],
		renderNode: ({ node, getProps, path }) => {
			return (
				<TreeRendererNode
					node={node}
					nodeProps={getProps()}
					level={path.length - 1}
					maxDigits={maxDigits}
				/>
			);
		},
	};
}

interface ResizeState {
	isActive: boolean;
	posX: number;
}

const INITIAL_RESIZE: ResizeState = {
	isActive: false,
	posX: DEFAULT_DIVIDER_POSITION,
};
const INITIAL_INDEX_SPACER_SIZE = "1rem";

const TreeRenderer: RendererImplementationComponent<
	RendererImplementation.Tree
> = ({ markup, renderer: Renderer }) => {
	const maxDigits = markup[NODE_COUNT_PROP]?.toString().length;
	const isMountedRef = useIsMountedRef();
	const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
	const [resizeState, setResizeState] = useState(INITIAL_RESIZE);
	const resizeStateRef = useSyncedRef(resizeState);
	const node = getChildren(markup);
	const [indexSpacerRef, setIndexSpacerRef] = useState<HTMLDivElement | null>(
		null,
	);
	const [indexSpacerSize, setIndexSpacerSize] = useState(
		INITIAL_INDEX_SPACER_SIZE,
	);

	const getWrapperWidth = useEventHandler(() => {
		return wrapperRef?.style.getPropertyValue(WIDTH_CUSTOM_PROP) || "100%";
	});

	const setWrapperWidthVar = useEventHandler((width: string) => {
		requestAnimationFrame(() => {
			if (!wrapperRef || !isMountedRef.current) return;
			wrapperRef.style.setProperty(WIDTH_CUSTOM_PROP, width);
		});
	});

	useEffect(() => {
		if (wrapperRef) {
			setWrapperWidthVar(getWrapperWidth());
		}
	}, [getWrapperWidth, setWrapperWidthVar, wrapperRef]);

	useEffect(() => {
		if (!wrapperRef) return;
		const ro = new ResizeObserver((entries) => {
			const entry = entries.at(0);
			if (!entry) return;
			const { width } = entry.contentRect;
			const computedWidth = `${width}px`;
			setWrapperWidthVar(computedWidth);
		});
		ro.observe(wrapperRef);
		// eslint-disable-next-line consistent-return
		return () => ro.disconnect();
	}, [setWrapperWidthVar, wrapperRef]);

	useEffect(() => {
		if (!indexSpacerRef) return;
		const ro = new ResizeObserver((entries) => {
			const entry = entries.at(0);
			if (!entry) return;
			const { width } = entry.contentRect;
			const computedWidth = `${width}px`;
			requestAnimationFrame(() => {
				setIndexSpacerSize(computedWidth);
			});
		});
		ro.observe(indexSpacerRef);
		// eslint-disable-next-line consistent-return
		return () => ro.disconnect();
	}, [indexSpacerRef]);

	const handleMouseDown = () => {
		setResizeState((prevState) => ({ ...prevState, isActive: true }));
	};

	useEffect(() => {
		const updateState = (currentX: number, isActive: boolean) => {
			if (!resizeStateRef.current.isActive || !wrapperRef) return;
			const { width, left } = wrapperRef.getBoundingClientRect();
			const offset = currentX - left;
			const posX = clamp(
				offset / width,
				MIN_DIVIDER_POSITION,
				MAX_DIVIDER_POSITION,
			);
			setResizeState({ isActive, posX });
		};
		const moveHandler = (e: MouseEvent) => {
			updateState(e.clientX, true);
		};
		const upHandler = (e: MouseEvent) => {
			updateState(e.clientX, false);
		};
		window.addEventListener("mousemove", moveHandler);
		window.addEventListener("mouseup", upHandler);
		return () => {
			window.removeEventListener("mousemove", moveHandler);
			window.removeEventListener("mouseup", upHandler);
		};
	}, [resizeStateRef, wrapperRef]);

	useEffect(() => {
		requestAnimationFrame(() => {
			if (!wrapperRef || !isMountedRef.current) return;
			wrapperRef.style.setProperty(POSITION_CUSTOM_PROP, `${resizeState.posX}`);
		});
	}, [isMountedRef, resizeState.posX, wrapperRef]);

	return (
		<>
			{markup.Header && <Renderer markup={markup.Header} />}
			<div
				className="tree-renderer"
				ref={setWrapperRef}
				style={{ ["--_tree-index-spacer-width" as string]: indexSpacerSize }}
			>
				<div className="tree-renderer__heading-group">
					<h3 className="tree-renderer__heading">Struktur der Nachricht</h3>
					<h3 className="tree-renderer__heading">Inhalte der Nachricht</h3>
				</div>
				<div className="tree-renderer__tree">
					<div
						style={{ opacity: 0, userSelect: "none", pointerEvents: "none" }}
						ref={setIndexSpacerRef}
						aria-hidden
					>
						{maxDigits && "0".padStart(maxDigits, "0")}
					</div>
					<Tree<MarkupNode> rootNodes={node} {...getTreeProps(maxDigits)} />
					{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
					<div
						className={classNames(
							"tree-renderer__divider",
							resizeState.isActive && "tree-renderer__divider--active",
						)}
						onMouseDown={handleMouseDown}
					/>
				</div>
			</div>
		</>
	);
};

export default TreeRenderer;
