import {
	GridToolbarQuickFilter,
	GridToolbarContainer,
	DataGrid,
	deDE,
} from "@mui/x-data-grid";
import type { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { Fragment, useMemo } from "react";
import type { Datatype } from "../../../../../AppActor/actors/modellierungModel/hooks";
import {
	useDatatypes,
	useModellierungProjekt,
} from "../../../../../AppActor/actors/modellierungModel/hooks";
import {
	joinQNamePath,
	parseQNamePath,
	type LiteBaustein,
} from "../../../../../AppActor/actors/modellierungModel/schemas";
import SelectionTableProvider from "../../../../../SelectionTableProvider";
import SelectionTableContainer from "../../../../../SelectionTableProvider/SelectionTableContainer";
import { GridCellExpand } from "../../../../../ui/mui";
import { createNodeTargetHref } from "../../../../../AppActor/actors/navigation/helpers";
import { selectIdPathFromQNamePathFromModell } from "../../../../../AppActor/actors/modellierungModel/selectors";
import type { StandardProjekt } from "../../../../../AppActor/actors/project/types";

type CellParams<ValueT = string> = GridRenderCellParams<ValueT, Datatype>;

function Toolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarQuickFilter
				inputProps={{ "data-testid": "datatype-ref-quick-filter" }}
			/>
		</GridToolbarContainer>
	);
}

function createRenderName(projekt: StandardProjekt) {
	return function NameCell(params: CellParams) {
		const { row, colDef } = params;
		const { name, path } = row;
		const href = createNodeTargetHref(projekt, path);
		const link = (
			<Link
				to={href}
				data-testid="element-name-link"
				data-node-qname={row.qnamePath}
			>
				{name}
			</Link>
		);
		return <GridCellExpand value={link} width={colDef.computedWidth} />;
	};
}

function createRenderPath(projekt: StandardProjekt) {
	return function PathCell(params: CellParams) {
		const { row, colDef } = params;
		const { qnamePath } = row;

		const qnames = parseQNamePath(qnamePath);
		const links = qnames.map((segment, i) => {
			const subPath = qnames.slice(0, i + 1);
			const idPath = selectIdPathFromQNamePathFromModell(
				projekt.modell,
				subPath,
			);
			const href = createNodeTargetHref(projekt, idPath);
			return (
				<Fragment key={joinQNamePath(subPath)}>
					{i > 0 && " / "}
					<Link data-testid="segment-link" data-segment={segment} to={href}>
						{segment}
					</Link>
				</Fragment>
			);
		});

		return <GridCellExpand value={links} width={colDef.computedWidth} />;
	};
}

export default function DatentypReferenceTable({
	activeNode,
}: {
	activeNode: LiteBaustein;
}): JSX.Element {
	const projekt = useModellierungProjekt();
	const references = useDatatypes(activeNode.id);
	const columns: GridColDef[] = useMemo(
		() =>
			projekt
				? [
						{
							field: "name",
							headerName: "Elementname",
							width: 200,
							hideable: false,
							filterable: false,
							type: "string",
							renderCell: createRenderName(projekt),
						},
						{
							field: "qnamePath",
							headerName: "Syntaxpfad",
							hideable: false,
							filterable: false,
							flex: 1,
							type: "string",
							renderCell: createRenderPath(projekt),
						},
				  ]
				: [],
		[projekt],
	);

	return (
		<SelectionTableContainer>
			<SelectionTableProvider>
				{({ getProps }) => (
					<DataGrid
						{...getProps()}
						components={{ Toolbar }}
						columns={columns}
						rows={references || []}
						disableSelectionOnClick
						columnVisibilityModel={{ type: false }}
						localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
						sx={{ minBlockSize: "var(--table-container-min-block-size)" }}
					/>
				)}
			</SelectionTableProvider>
		</SelectionTableContainer>
	);
}
