import { useState } from "react";
import { useGetProjekteQuery } from "../../../redux/apiSlice";
import { useTranslateRequestMeta } from "../../../utils/requestFormatters";
import { useSendStoreEvent } from "../../../components/AppActor/EventStore/hooks";
import type {
	ApiProjekt,
	ApiProjektList,
} from "../../../components/AppActor/actors/modellierungModel/schemas";

const EMPTY_DATA: ApiProjektList = [];

export default function useStartView() {
	const [pageSize, setPageSize] = useState(25);
	const handlePageSizeChange = (nextPageSize: number) => {
		setPageSize(nextPageSize);
	};
	const sendStoreEvent = useSendStoreEvent();

	const { data, error, isError, isFetching, isLoading, isSuccess } =
		useGetProjekteQuery();

	const { status, error: requestError } = useTranslateRequestMeta({
		isError,
		isFetching,
		isLoading,
		isSuccess,
		error,
	});

	const handleSelectProject = (projekt: ApiProjekt) => {
		// Id becomes Kennung so that the following functions are not affected by the change
		sendStoreEvent({
			type: "PROJECT.OPEN_EXISTING",
			payload: {
				projektMeta: {
					dbId: projekt.id,
					kennung: projekt.kennung,
					projektType: projekt.typ,
				},
			},
		});
	};

	return {
		pageSize,
		handlePageSizeChange,
		status,
		requestError,
		rows: data || EMPTY_DATA,
		handleSelectProject,
	};
}
