import { useModellierungProjekt } from "../../AppActor/actors/modellierungModel/hooks";
import {
	useActiveStructureNode,
	useActiveTreePath,
	useIsActiveNodeReadOnly,
} from "../../AppActor/actors/treeState/hooks";
import EditorContent from "../../EditorContent";
import DetailsDebug from "./DetailsDebug";
import DetailsRenderer from "./renderers/DetailsRenderer";

export default function DetailsView(): JSX.Element {
	const activeProjekt = useModellierungProjekt();
	const activeNode = useActiveStructureNode();
	const activePath = useActiveTreePath();
	const isReadOnly = useIsActiveNodeReadOnly();
	return (
		<EditorContent padTop>
			{activeProjekt && activeNode ? (
				<DetailsRenderer
					activeNode={activeNode}
					activePath={activePath}
					isReadOnly={isReadOnly}
					projekt={activeProjekt}
				/>
			) : (
				<>Kein Knoten gefunden</>
			)}
			<DetailsDebug />
		</EditorContent>
	);
}
