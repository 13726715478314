import { Tooltip } from "@mui/material";
import NodeIcon from "../../NodeIcon";
import { VisuallyHidden } from "../../ui";
import { useIconInfos } from "../../IconInfoProvider";
import GruppenartAlleIcon from "../../../resources/icons/GruppenartAlleIcon";

const AllIcon = (): JSX.Element => {
	const iconInfos = useIconInfos();
	const { tooltip = "Icon Alle" } = iconInfos?.groupTypeIsAll ?? {};

	return (
		<Tooltip title={tooltip}>
			<NodeIcon data-testid="all-icon">
				<VisuallyHidden>{tooltip}</VisuallyHidden>
				<GruppenartAlleIcon />
			</NodeIcon>
		</Tooltip>
	);
};

export default AllIcon;
