import type { KeyboardEventHandler } from "react";
import { forwardRef, useMemo, useContext } from "react";
import classNames from "classnames";
import { TabContext } from "./TabContexts";
import { getNextDescendantIndex } from "../util/descendants";
import type { TabListProps } from "./types";
import { callOrReturn } from "../../../utils/func";

const createArrowEntries = (
	nextIndex: number,
	prevIndex: number,
	orientation: "horizontal" | "vertical",
) => {
	const nextName = orientation === "vertical" ? "Down" : "Right";
	const prevName = orientation === "vertical" ? "Up" : "Left";
	return {
		[`Arrow${prevName}`]: prevIndex,
		[prevName]: prevIndex,
		[`Arrow${nextName}`]: nextIndex,
		[nextName]: nextIndex,
	};
};

const TabList = forwardRef<HTMLDivElement, TabListProps>(function TabList(
	{ className = "", children, ...props },
	ref,
) {
	const { activeIndex, setActiveIndex, tabs, orientation, activeTab } =
		useContext(TabContext);

	const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
		const indexMap: { [key: string]: number } = {
			...createArrowEntries(
				getNextDescendantIndex(tabs, tabs[activeIndex]?.id, 1),
				getNextDescendantIndex(tabs, tabs[activeIndex]?.id, -1),
				orientation,
			),
			Home: 0,
			End: tabs.length - 1,
		};
		if (e.key in indexMap) {
			e.preventDefault();
			setActiveIndex(indexMap[e.key]);
		}
	};

	const activeId = activeTab?.id || null;
	const renderProps = useMemo(
		() => ({ activeIndex, activeId }),
		[activeIndex, activeId],
	);

	return (
		<div
			ref={ref}
			className={classNames(
				"xui-tab-list",
				`xui-tab-list--orientation-${orientation}`,
				className,
			)}
			role="tablist"
			tabIndex={-1}
			aria-orientation={orientation}
			onKeyDown={handleKeyDown}
			data-xui-component="tabs"
			data-xui-element="tab-list"
			{...props}
		>
			{callOrReturn(children, renderProps)}
		</div>
	);
});

export default TabList;
