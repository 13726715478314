import type { ReactNode } from "react";
import classNames from "classnames";
import styles from "./TopMenu.module.scss";

function TopMenu({
	children,
	className,
}: {
	children?: ReactNode;
	className?: string;
}): JSX.Element {
	return (
		<nav
			className={classNames(styles["xoev-header-navigation--top"], className)}
		>
			<ul>{children}</ul>
		</nav>
	);
}

export default TopMenu;
