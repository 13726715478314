import { useSelector } from "@xstate/react";
import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import type { SnapshotFrom } from "xstate";
import { getFromSystem } from "../../../../utils/machines";
import { useSelectFromSystem } from "../../hooks";
import type { EffectApiActorRef, EffectApiContext } from "./effectApi.machine";

export function getEffectApiFromSystem(
	system: AnyActorSystem,
): EffectApiActorRef {
	return getFromSystem(system, "effectApi");
}

export function selectEffectApiFn<FnKey extends keyof EffectApiContext>(
	fnKey: FnKey,
): (snapshot: SnapshotFrom<EffectApiActorRef>) => EffectApiContext[FnKey] {
	return (snapshot) => snapshot.context[fnKey];
}

export function getEffectApi(system: AnyActorSystem): EffectApiContext {
	return getEffectApiFromSystem(system).getSnapshot().context;
}

export function selectEffectApiFnFromSystem<
	FnKey extends keyof EffectApiContext,
>(system: AnyActorSystem, fnKey: FnKey): EffectApiContext[FnKey] {
	return selectEffectApiFn(fnKey)(getEffectApiFromSystem(system).getSnapshot());
}

export function useEffectApiRef(): EffectApiActorRef {
	return useSelectFromSystem((system) => getEffectApiFromSystem(system));
}

export function useEffectApiFn<FnKey extends keyof EffectApiContext>(
	fnKey: FnKey,
): EffectApiContext[FnKey] {
	const effectApiRef = useEffectApiRef();
	return useSelector(effectApiRef, selectEffectApiFn(fnKey));
}
