import classNames from "classnames";
import { useEffect, useMemo, useRef } from "react";
import TreeNode from "./TreeNode";
import type { LiteNode } from "../AppActor/actors/modellierungModel/schemas";
import type { NodeEventArg, PathFragment, TreeProps } from "./types";
import { getTreeContext } from "./TreeContext";
import TreeNodeToggle from "./TreeNode/TreeNodeToggle";
import TreeNodeToggleClick from "./TreeNode/TreeNodeToggleClick";
import { useExpansionState } from "./ExpansionState";
import { useEventHandler } from "../../hooks";
import { defaultGetNodeId } from "./treeHelpers";
import type { WithRequired } from "../../utils/types";
import "./Tree.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
const defaultInitialPath: unknown[] = [];

function Tree<TNode = unknown>(
	props: Omit<TreeProps<TNode, number>, "getNodeId">,
): JSX.Element;
function Tree<TNode, TNodeId extends PathFragment>(
	props: WithRequired<TreeProps<TNode, TNodeId>, "getNodeId">,
): JSX.Element;
function Tree<TNode extends LiteNode, TNodeId extends PathFragment>({
	rootNodes,
	onOpen = noop,
	onClose = noop,
	onActivate = noop,
	getChildren,
	isNodeExpandable,
	renderNode,
	expansionState,
	className,
	getNodeId = defaultGetNodeId as Required<
		TreeProps<TNode, TNodeId>
	>["getNodeId"],
	initialPath = defaultInitialPath as TNodeId[],
	activeFilters,
}: TreeProps<TNode, TNodeId>): JSX.Element {
	const TreeContext = getTreeContext<TNode, TNodeId>();

	// The warning will be removed from the production build
	if (process.env.NODE_ENV === "development") {
		// The above condition will be resolved at build time, so the
		// hook is NOT actually conditionally rendered...
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const prevExpansionStateRef = useRef(expansionState);
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (!!prevExpansionStateRef.current !== !!expansionState) {
				const fromText = expansionState ? "an uncontrolled" : "a controlled";
				const toText = !expansionState ? "controlled" : "uncontrolled";
				// eslint-disable-next-line no-console
				console.warn(
					`Warning: A <Tree /> component is changing from ${fromText} component ` +
						`to be ${toText}. This is caused by the \`expansionState\` prop ` +
						`changing from undefined to a defined value, which should not happen. ` +
						"Decide between using a controlled or uncontrolled input element " +
						"for the lifetime of the component.",
				);
			}
			prevExpansionStateRef.current = expansionState;
		}, [expansionState]);
	}

	const {
		closePath,
		openPath,
		expansionState: ownExpansionState,
	} = useExpansionState<TNodeId>(initialPath);

	const handleOpen = useEventHandler((event: NodeEventArg<TNode, TNodeId>) => {
		if (!expansionState && event.path) {
			openPath(event.path);
		}
		onOpen(event);
	});
	const handleClose = useEventHandler((event: NodeEventArg<TNode, TNodeId>) => {
		if (!expansionState && event.path) {
			closePath(event.path);
		}
		onClose(event);
	});

	const ctx = useMemo(
		() => ({
			onActivate,
			onOpen: handleOpen,
			onClose: handleClose,
			getChildren,
			isNodeExpandable,
			renderNode,
			expansionState: expansionState || ownExpansionState,
			getNodeId,
		}),
		[
			onActivate,
			handleOpen,
			handleClose,
			getChildren,
			getNodeId,
			isNodeExpandable,
			renderNode,
			expansionState,
			ownExpansionState,
		],
	);

	return (
		<TreeContext.Provider value={ctx}>
			<ul
				className={classNames("treelist", className)}
				data-testid="tree__list"
			>
				{rootNodes.map((root, i) => {
					const id = getNodeId(root, i);
					const path = [id];
					return (
						<TreeNode<TNode, TNodeId>
							key={id}
							node={root}
							path={path}
							activeFilters={activeFilters}
						/>
					);
				})}
			</ul>
		</TreeContext.Provider>
	);
}

Tree.NodeToggle = TreeNodeToggle;
Tree.NodeToggleClick = TreeNodeToggleClick;

export default Tree;
