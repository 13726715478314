import type { AnyActorSystem } from "xstate/dist/declarations/src/system";
import type { SnapshotFrom } from "xstate";
import type { ProjectQueryActorRef } from "./project.query.machine";
import { createQueryId } from "./helpers";
import { getFromSystem, useActorRefSelector } from "../../../../utils/machines";
import { useSelectFromSystem } from "../../hooks";
import { useActiveProjectId } from "../navigation/hooks";
import { ProjektType, type ProjektId } from "../modellierungModel/schemas";

export function getProjectQueryRef(
	system: AnyActorSystem,
	projektId: ProjektId | null | undefined,
): ProjectQueryActorRef | null {
	if (!projektId) return null;
	return getFromSystem(system, createQueryId(projektId));
}

export function useProjectQueryRef(
	projektId: ProjektId | null | undefined,
): ProjectQueryActorRef | null {
	return useSelectFromSystem((system) => getProjectQueryRef(system, projektId));
}

export function useProjectSnapshotSelectorById<T>(
	projektId: ProjektId | null | undefined,
	selector: (snapshot: SnapshotFrom<ProjectQueryActorRef>) => T,
): T | null {
	const ref = useProjectQueryRef(projektId);
	return useActorRefSelector(ref, selector);
}

export function useProjectSnapshotSelector<T>(
	selector: (snapshot: SnapshotFrom<ProjectQueryActorRef>) => T,
): T | null {
	const projektId = useActiveProjectId();
	return useProjectSnapshotSelectorById(projektId, selector);
}

export function useProjectKennung(
	projektId: ProjektId | null | undefined,
): string | null {
	return useProjectSnapshotSelectorById(
		projektId,
		(snapshot) => snapshot.context.kennung,
	);
}

export function useProjektType() {
	return useProjectSnapshotSelector((snapshot) => snapshot.context.projektType);
}

export function useIsProjektDisplayOnly() {
	return (
		useProjectSnapshotSelector(
			(snapshot) => snapshot.context.projektType !== ProjektType.Modellierung,
		) ?? true
	);
}
