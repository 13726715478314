import { ListItemText, MenuItem, Switch, Divider } from "@mui/material";
import { LiteBausteinType } from "../../../AppActor/actors/modellierungModel/schemas";
import {
	type BausteinSchemaFilters,
	LiteBausteinFilter,
} from "../../../Tree/types";
import { bottomMenu } from "../../../../resources/textConstants/menus.json";
import NachrichtIcon from "../../../../resources/icons/NachrichtIcon";
import KomplexerDatentypIcon from "../../../../resources/icons/KomplexerDatentypIcon";
import CodeDatentypIcon from "../../../../resources/icons/CodeDatentypIcon";
import { GlobaleEigenschaftIcon } from "../../../../resources/iconMaps/icons";

export default function FilterMenuItems({
	activeFilters,
	toggleFilter,
}: {
	activeFilters: BausteinSchemaFilters;
	toggleFilter: (...filters: BausteinSchemaFilters) => void;
}): JSX.Element {
	return (
		<>
			<MenuItem onClick={() => toggleFilter(LiteBausteinType.Datentyp)}>
				<Switch checked={activeFilters.includes(LiteBausteinType.Datentyp)} />
				<ListItemText>
					<KomplexerDatentypIcon /> {bottomMenu.filters.datentyp}
				</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => toggleFilter(LiteBausteinType.CodeDatentyp)}>
				<Switch
					checked={activeFilters.includes(LiteBausteinType.CodeDatentyp)}
				/>
				<ListItemText>
					<CodeDatentypIcon />
					{bottomMenu.filters.codedatentyp}
				</ListItemText>
			</MenuItem>
			<MenuItem onClick={() => toggleFilter(LiteBausteinType.Nachricht)}>
				<Switch checked={activeFilters.includes(LiteBausteinType.Nachricht)} />
				<ListItemText>
					<NachrichtIcon /> {bottomMenu.filters.nachricht}
				</ListItemText>
			</MenuItem>
			<MenuItem
				onClick={() => toggleFilter(LiteBausteinType.GlobaleEigenschaft)}
			>
				<Switch
					checked={activeFilters.includes(LiteBausteinType.GlobaleEigenschaft)}
				/>
				<ListItemText>
					<GlobaleEigenschaftIcon /> {bottomMenu.filters.globaleEigenschaften}
				</ListItemText>
			</MenuItem>
			<Divider />
			<MenuItem onClick={() => toggleFilter(LiteBausteinFilter.Leer)}>
				<Switch checked={!activeFilters.includes(LiteBausteinFilter.Leer)} />
				<ListItemText>{bottomMenu.filters.leerePaketeAnzeigen}</ListItemText>
			</MenuItem>
		</>
	);
}
