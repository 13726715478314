import { useEditorData } from "../../../components/EditorData";
import StructureTree from "../../../components/StructureDisplay/StructureTree";
import type { PropsOf } from "../../../utils/types";

const ProfilierungTree = (
	props: Omit<PropsOf<typeof StructureTree>, "parentStatus">,
): JSX.Element => {
	const { initStatus: projectStatus } = useEditorData();
	return <StructureTree parentStatus={projectStatus} {...props} />;
};

export default ProfilierungTree;
