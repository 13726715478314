import { useMemo } from "react";
import { useEventHandler } from "../../../../../../../hooks";
import useIsMountedRef from "../../../../../../../hooks/useIsMountedRef";
import type { RequestCodeListOptions } from "../../../../../../../redux/apiSlice";
import { useLazyGetCodeListQuery } from "../../../../../../../redux/apiSlice";
import type {
	CodelistRequestResult,
	CodelistStateResult,
} from "../../../../../../../redux/codelistSlice";
import {
	addCodelist,
	CodelistResultStatus,
	selectCodelist,
	selectIsCodelistAvailabe,
} from "../../../../../../../redux/codelistSlice";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import {
	toRequestError,
	useTranslateRequestMeta,
} from "../../../../../../../utils/requestFormatters";
import type { RequestError, RequestStatus } from "../../../../../../Api";
import { store } from "../../../../../../../redux/store";

const transformCodelistResult = (
	requestResult: CodelistRequestResult,
): CodelistStateResult => {
	if (requestResult.status !== CodelistResultStatus.Existing) {
		return requestResult;
	}
	const suggestedList = requestResult.data.at(0);
	const otherLists = requestResult.data.slice(1).flatMap((list) => list);

	if (!suggestedList) {
		throw new Error(
			"Codelists always need to have at least one column with the recommended list",
		);
	}
	return {
		status: requestResult.status,
		data: { suggestedList, otherLists },
	};
};

export const ERROR_RESULT = {
	status: CodelistResultStatus.Error,
	data: null,
} as const;

type ListTrigger = Awaited<
	ReturnType<ReturnType<typeof useLazyGetCodeListQuery>[0]>
>;
function toResult(listTrigger: ListTrigger): CodelistRequestResult {
	if (listTrigger.isSuccess) {
		return {
			status: CodelistResultStatus.Existing,
			data: listTrigger.data,
		};
	}
	if (
		listTrigger.isError &&
		toRequestError(listTrigger.error)?.status === 404
	) {
		return {
			status: CodelistResultStatus.NonExisting,
			data: null,
		};
	}

	return ERROR_RESULT;
}

export default function useCodelistRequest(): {
	request: (
		requestOptions: Partial<RequestCodeListOptions>,
	) => Promise<CodelistStateResult>;
	status: RequestStatus;
	error: RequestError | null;
} {
	const [trigger, { isError, isFetching, isLoading, isSuccess, error }] =
		useLazyGetCodeListQuery();
	const dispatch = useAppDispatch();
	const { status, error: requestError } = useTranslateRequestMeta({
		isError,
		isFetching,
		isLoading,
		isSuccess,
		error,
	});

	const isMountedRef = useIsMountedRef();
	const request = useEventHandler(
		async ({
			kennung,
			version,
		}: Partial<RequestCodeListOptions>): Promise<CodelistStateResult> => {
			if (kennung) {
				if (selectIsCodelistAvailabe(kennung, version)(store.getState())) {
					return selectCodelist(kennung, version)(store.getState());
				}
				try {
					const listTrigger = await trigger({ kennung, version });
					const result = toResult(listTrigger);
					const transformedResult = transformCodelistResult(result);
					if (!isMountedRef.current) return ERROR_RESULT;
					dispatch(
						addCodelist({ kennung, version, result: transformedResult }),
					);
					return transformedResult;
				} catch {
					return ERROR_RESULT;
				}
			}
			return ERROR_RESULT;
		},
	);

	return useMemo(
		() => ({ request, status, error: requestError }),
		[request, requestError, status],
	);
}
