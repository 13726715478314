import { isLiteGlobaleEigenschaft } from "../../../../../AppActor/actors/modellierungModel/schemas";
import { placeholder } from "../../../../../../resources/textConstants";
import InfoRendererDisplay from "../../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/renderers/InfoRenderer/InfoRendererDisplay";
import { useReferencingEntries } from "../../referers/shared/helpers";
import type { DisplayRendererProps } from "../../types";

export default function CommonMetadataDisplay({
	activeNode,
}: DisplayRendererProps): JSX.Element {
	const { currentReferer } = useReferencingEntries(activeNode);

	const getDisplayName = () => {
		if (isLiteGlobaleEigenschaft(activeNode) && currentReferer) {
			return currentReferer.name;
		}

		return activeNode.name;
	};

	return (
		<>
			<InfoRendererDisplay
				label="Name"
				value={getDisplayName()}
				fallback={placeholder.anonymousStructure}
			/>
			<InfoRendererDisplay
				label="Gruppierungsart"
				value={activeNode.gruppeArt}
				fallback="Keine Gruppierungsart vorhanden"
			/>
			<InfoRendererDisplay
				label="Beschreibung"
				value={activeNode.beschreibung}
				fallback="Keine Beschreibung vorhanden"
			/>
			<InfoRendererDisplay
				label="Umsetzungshinweis"
				value={activeNode.umsetzungshinweis}
				fallback="Kein Umsetzungshinweis vorhanden"
			/>
		</>
	);
}
