import { TreeDisplayState } from "./types";
import {
	LiteNodeType,
	isLiteBaustein,
	LiteBausteinType,
	isLiteModel,
	isLitePaket,
} from "../../AppActor/actors/modellierungModel/schemas";
import type {
	LiteId,
	LiteNode,
} from "../../AppActor/actors/modellierungModel/schemas";
import {
	createSelectChildrenFromModell,
	selectNodeId,
} from "../../AppActor/actors/modellierungModel/selectors";
import { walkTreeEntries } from "../../Tree/treeHelpers";
import type { LiteModellContainer } from "../../AppActor/actors/modellierungModel/types";
import { memoize } from "../../../utils/memoization";

export function getTreeDisplayState({
	isFallback,
	isLoading,
}: {
	isLoading: boolean;
	isFallback: boolean;
}): TreeDisplayState {
	if (isFallback && !isLoading) return TreeDisplayState.ShowFallback;
	if (isFallback && isLoading) return TreeDisplayState.ShowLoading;
	return TreeDisplayState.ShowTree;
}

function shouldFilterModellNode(modell: LiteModellContainer, node: LiteNode) {
	return node.liteType === LiteNodeType.Model && node.id !== modell.rootModelId;
}

const shouldFilterNachrichtParentNode = memoize(
	(modell: LiteModellContainer, rootId: LiteId, node: LiteNode) => {
		if (!isLiteModel(node) && !isLitePaket(node)) return false;
		if (node.children.includes(rootId)) return false;

		let hasNachricht = false;
		const entries = walkTreeEntries(
			[node as LiteNode],
			(child, path) => {
				if (isLiteBaustein(child)) return [];
				return createSelectChildrenFromModell(modell)(child, path);
			},
			selectNodeId,
		);
		for (const [, child] of entries) {
			if (isLiteBaustein(child) && child.typ === LiteBausteinType.Nachricht) {
				hasNachricht = true;
			}
		}

		return !hasNachricht;
	},
	{ cacheSize: 64 },
);

export function shouldFilterNode(
	modell: LiteModellContainer,
	rootId: LiteId,
	node: LiteNode,
) {
	return (
		shouldFilterModellNode(modell, node) ||
		shouldFilterNachrichtParentNode(modell, rootId, node)
	);
}
