import { createContext, useContext, type ReactNode, useMemo } from "react";
import type { IconInfoContextType } from "./types";
import { iconInfosSchema } from "../../utils/schemas";
import iconsData from "../../resources/textConstants/infoIcons.json";

export const IconInfoContext = createContext<IconInfoContextType>(
	null as never,
);

export function useIconInfos(): IconInfoContextType {
	const ctx = useContext(IconInfoContext);
	return ctx;
}

function validateIconInfos(data: unknown) {
	return iconInfosSchema.parse(data);
}

function IconInfoProvider({ children }: { children: ReactNode }): JSX.Element {
	const iconsInfo = validateIconInfos(iconsData);
	const ctx = useMemo(() => iconsInfo, [iconsInfo]);
	return (
		<IconInfoContext.Provider value={ctx}>{children}</IconInfoContext.Provider>
	);
}

export default IconInfoProvider;
