import { useEffect } from "react";
import type { TreeNodeType } from "../../../types/ProfilierungHome";
import DatatypeNode from "./DatatypeNode";
import { useStableNavigate, useSyncedRef } from "../../../hooks";
import EditorSideBar from "../../EditorSideBar";
import NoSearchResultsInfo from "../StructureTree/NoSearchResultsInfo";
import type { BaseDatatypesListProps } from "./types";
import { ListDisplayState } from "./types";
import BaseDatatypesListSearch from "./BaseDatatypesListSearch";
import useLoaderAttributes from "../../../hooks/useLoaderAttributes";
import useDelayedValue from "../../../hooks/useDelayedValue";
import StructureLoadingInfo from "../StructureInlineInfo/StructureLoadingInfo";
import StructureNoDataInfo from "../StructureInlineInfo/StructureNoDataInfo";
import { getListDisplayState } from "./helpers";
import { STRUCTURE_LOADING_SMOOTHING_DELAY } from "../helpers";
import "./BaseDatatypesList.scss";

const getEmptyProps = () => ({});

function BaseDatatypesList({
	getNodeProps = getEmptyProps,
	activeDataType,
	datatypes,
	getUrl,
	isLoading: isLoadingProp = false,
	...props
}: BaseDatatypesListProps): JSX.Element {
	const navigate = useStableNavigate();
	const getUrlRef = useSyncedRef(getUrl);

	const handleActivate = (node: TreeNodeType) =>
		navigate(getUrl(node), { replace: true });

	useEffect(() => {
		if (datatypes && !activeDataType) {
			const firstNode = datatypes.at(0);
			if (!firstNode) return;
			navigate(getUrlRef.current(firstNode), { replace: true });
		}
	}, [activeDataType, datatypes, getUrlRef, navigate]);

	// Delay the disapearing of the loading indicator slightly, so the ui does
	// not flicker while calculating its next version
	const delayedIsLoading = useDelayedValue(
		isLoadingProp,
		STRUCTURE_LOADING_SMOOTHING_DELAY,
	);
	const isLoading = isLoadingProp || delayedIsLoading;
	const displayState = getListDisplayState({ isLoading, datatypes });
	// Mark the wrapping element as a loading indicator for screen readers
	const treeLoaderProps = useLoaderAttributes({
		isLoading,
		description: "Die Datentypen werden geladen.",
	});

	return (
		<EditorSideBar.List
			{...props}
			className="base-datatypes-list"
			{...treeLoaderProps}
		>
			{datatypes &&
				displayState === ListDisplayState.ShowList &&
				datatypes.map((datatype) => (
					<DatatypeNode
						key={datatype.id}
						datatype={datatype}
						onActivate={handleActivate}
						isActive={activeDataType?.id === datatype.id}
						{...getNodeProps(datatype)}
					/>
				))}
			{datatypes &&
				displayState === ListDisplayState.ShowList &&
				datatypes.length === 0 && (
					<NoSearchResultsInfo infoKey="noSearchResultsList" />
				)}
			<StructureNoDataInfo
				isActive={displayState === ListDisplayState.ShowFallback}
			>
				Keine Datentypen verfügbar
			</StructureNoDataInfo>
			<StructureLoadingInfo
				isActive={displayState === ListDisplayState.ShowLoading}
			>
				Datentypen werden geladen...
			</StructureLoadingInfo>
		</EditorSideBar.List>
	);
}

BaseDatatypesList.Search = BaseDatatypesListSearch;

export default BaseDatatypesList;
