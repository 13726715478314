import { Table } from "@mui/material";
import classNames from "classnames";
import type { ReactNode } from "react";
import type { ExtendProps, PropsOf } from "../../../../utils/types";

type DatatypeDetailsTableProps = ExtendProps<
	PropsOf<typeof Table>,
	{ children: ReactNode; className?: string; title: string }
>;

export default function DatatypeDetailsTable({
	children,
	className,
	title,
	...props
}: DatatypeDetailsTableProps): JSX.Element {
	return (
		<div className={classNames("datatype-details-table", className)}>
			<h4 className="datatype-details-table__label">{title}</h4>
			<Table {...props}>{children}</Table>
		</div>
	);
}
