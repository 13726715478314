import { Button } from "@mui/material";
import PlusIcon from "../../../../resources/icons/PlusIcon";
import "./UploadButton.scss";

const UploadButton = ({ onClick }: { onClick: () => void }): JSX.Element => {
	return (
		<Button
			variant="contained"
			data-testid="upload-project"
			className="startview-project__upload-button"
			onClick={onClick}
			startIcon={
				<PlusIcon className="startview-project__upload-button__icon" />
			}
		>
			hochladen
		</Button>
	);
};

export default UploadButton;
