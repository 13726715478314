import { styled } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

const CustomGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(() => ({
	display: "flex",
	flex: "1 0 0",
	height: "40px",
	border: "1px solid #8a8a8a !important",
	borderRadius: "29px",
	paddingTop: "4px",
	paddingBottom: "0",
	"&& .MuiInput-root:hover::before": {
		borderBottom: "none",
	},
	"& .MuiInput-root": {
		"&::before,&::after": {
			borderBottom: "none",
		},
		"& > .MuiSvgIcon-root": {
			marginLeft: "13px",
		},
		"& > .MuiInput-input": {
			padding: "0 5px 0 10px",
			background: "#fff",
		},
		"& > .MuiIconButton-root": {
			marginRight: "6px",
		},
	},
}));

export default CustomGridToolbarQuickFilter;
