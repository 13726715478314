import createImmutableMap from "@xoev/immutable-map";
import type { MemoizeOptions } from "@xoev/memo";
import { memoize } from "@xoev/memo";
import { placeholder } from "../../../../resources/textConstants";
import {
	Direction,
	createStringSorter,
	filterFalsy,
} from "../../../../utils/lists";
import { weakMemoize } from "../../../../utils/memoization";
import { pipe } from "../../../../utils/func";
import * as Gen from "../../../../utils/generator";
import type {
	LiteBausteinMap,
	LiteEigenschaftMap,
	LiteModelMap,
	LiteModellContainer,
	LitePaketMap,
	LiteProjectNodes,
} from "./types";
import type {
	LiteBaustein,
	LiteEigenschaft,
	LiteId,
	LiteModel,
	LiteNode,
	QName,
	QNamePath,
} from "./schemas";
import {
	LiteBausteinType,
	LiteIdSchema,
	LiteNodeType,
	QNamePathSchema,
	QNameSchema,
	isLiteBaustein,
	isLiteDatatype,
	isLiteEigenschaft,
	isLiteGlobaleEigenschaft,
	isLiteModel,
	isLiteNachricht,
	isLitePaket,
	joinLitePath,
	joinQNamePath,
	parseQNamePath,
} from "./schemas";
import { walkTreeEntries } from "../../../Tree/treeHelpers";
import type { Nullable, Nullish } from "../../../../utils/types";
import type { StandardProjekt } from "../project/types";
import { AssertionError, SerializableError } from "../../../../utils/error";
import { LiteKind, getLiteNodeKind } from "./LiteKind";

export interface LiteDatatypeEntry {
	name: string;
	id: LiteId;
	parentPath: string;
	node: LiteNode;
}

export type RefererSelection = LiteBausteinType[];

const EMPTY_PROJECT_NODES: LiteProjectNodes = createImmutableMap({
	[LiteNodeType.Model]: createImmutableMap() satisfies LiteModelMap,
	[LiteNodeType.Paket]: createImmutableMap() satisfies LitePaketMap,
	[LiteNodeType.Baustein]: createImmutableMap() satisfies LiteBausteinMap,
	[LiteNodeType.Eigenschaft]: createImmutableMap() satisfies LiteEigenschaftMap,
});
export const EMPTY_MODELL: LiteModellContainer = {
	nodes: EMPTY_PROJECT_NODES,
	rootModelId: LiteIdSchema.parse("__root__"),
};
const EMPTY_CHILDREN: LiteNode[] = [];

function getModell(projekt: Nullish<StandardProjekt>) {
	return projekt?.modell || EMPTY_MODELL;
}

type RestArgs<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	TSelector extends (modell: LiteModellContainer, ...args: any[]) => any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
> = TSelector extends (modell: LiteModellContainer, ...args: infer TArgs) => any
	? TArgs
	: never;

function createProjektSelector<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	TSelector extends (modell: LiteModellContainer, ...args: any[]) => any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	CacheKeys extends any[] = Parameters<TSelector>,
>(
	modellSelector: TSelector,
	options?: {
		memoize?:
			| boolean
			| MemoizeOptions<
					(...args: RestArgs<TSelector>) => ReturnType<TSelector>,
					CacheKeys
			  >;
	},
): (
	projekt: Nullish<StandardProjekt>,
) => (...args: RestArgs<TSelector>) => ReturnType<TSelector> {
	type TResultFn = (...args: RestArgs<TSelector>) => ReturnType<TSelector>;
	const shouldMemo = options?.memoize !== false;
	const memoOptions =
		typeof options?.memoize === "object" ? options.memoize : { cacheSize: 64 };
	return memoize(
		(projekt) => {
			const selector: TResultFn = (...args) =>
				modellSelector(getModell(projekt), ...args);
			return shouldMemo
				? memoize<TResultFn, CacheKeys>(selector, memoOptions)
				: selector;
		},
		{ cacheSize: 8 },
	);
}

export function selectNodeId(node: LiteNode): LiteId {
	return node.id;
}

export function selectNodeFromModell(
	modell: LiteModellContainer,
	nodeId: LiteId,
): LiteNode | null {
	const modelMap = modell.nodes.get(LiteNodeType.Model);
	const paketMap = modell.nodes.get(LiteNodeType.Paket);
	const bausteinMap = modell.nodes.get(LiteNodeType.Baustein);
	const eigenschaftMap = modell.nodes.get(LiteNodeType.Eigenschaft);
	return (
		modelMap.get(nodeId) ||
		paketMap.get(nodeId) ||
		bausteinMap.get(nodeId) ||
		eigenschaftMap.get(nodeId) ||
		null
	);
}
export const createSelectNode = createProjektSelector(selectNodeFromModell, {
	memoize: false,
});

function hasExtension(node: LiteNode): node is LiteBaustein | LiteEigenschaft {
	return (
		((isLiteDatatype(node) ||
			isLiteNachricht(node) ||
			isLiteGlobaleEigenschaft(node)) &&
			!!node.basisDatentyp &&
			!node.restriction) ||
		(isLiteNachricht(node) && !!node.datentyp) ||
		(isLiteEigenschaft(node) && !!node.basisDatentyp) ||
		(isLiteEigenschaft(node) && !!node.referenz)
	);
}

function getExtensionBase(node: LiteNode): LiteId | null {
	return (
		(hasExtension(node) &&
			(node.basisDatentyp || node.datentyp || node.referenz)) ||
		null
	);
}

function checkedResolveInheritedChildren(
	modell: LiteModellContainer,
	node: LiteBaustein | LiteEigenschaft,
	visited: LiteNode[],
): LiteId[] {
	if (visited.some((n) => n.id === node.id)) {
		const chain = [...visited, node]
			.map((n) => `${n.name} (${n.id})`)
			.join("\n");
		throw new SerializableError({
			name: "CircularExtensionError",
			message:
				`Circular extension detected. Node "${
					node.name || placeholder.anonymousStructure
				}" id: "${node.id}" ` +
				`references itself in its extension chain. Reference chain:\n${chain}`,
		});
	}
	visited.push(node);
	const baseId = getExtensionBase(node);
	if (!baseId) return [];
	const baseDt = selectNodeFromModell(modell, baseId);
	if (!baseDt || !(isLiteDatatype(baseDt) || isLiteGlobaleEigenschaft(baseDt)))
		return [];
	return [
		...checkedResolveInheritedChildren(modell, baseDt, visited),
		...baseDt.children,
	];
}

function resolveInheritedChildren(
	modell: LiteModellContainer,
	node: LiteBaustein | LiteEigenschaft,
): LiteId[] {
	return checkedResolveInheritedChildren(modell, node, []);
}

export const createSelectDirectChildrenFromModell = weakMemoize(
	(modell: LiteModellContainer) => {
		return weakMemoize((node: LiteNode): LiteNode[] => {
			const childIds = hasExtension(node)
				? [...resolveInheritedChildren(modell, node), ...node.children]
				: node.children;
			const children = childIds.map((childId) =>
				selectNodeFromModell(modell, childId),
			);
			return filterFalsy(children);
		});
	},
);
export const createSelectDirectChildren = (projekt: Nullish<StandardProjekt>) =>
	createSelectDirectChildrenFromModell(getModell(projekt));

const memoizedCreateSelectEigenschaftChildren = weakMemoize(
	(modell: LiteModellContainer) => {
		return weakMemoize((node: LiteEigenschaft) => {
			const selectDirectChildren = createSelectDirectChildrenFromModell(modell);
			if (node.datentyp) {
				const bausteinMap = modell.nodes.get(LiteNodeType.Baustein);
				const ref = bausteinMap.get(node.datentyp);
				if (!ref) {
					throw new Error(`Datatype "${node.datentyp}" could not be found.`);
				}
				return selectDirectChildren(ref);
			}
			return selectDirectChildren(node);
		});
	},
);

export const createSelectInfiniteChildrenFromModell = weakMemoize(
	(modell: LiteModellContainer): ((node: LiteNode) => LiteNode[]) => {
		return weakMemoize((node) => {
			if (!node) return [];
			if (isLiteEigenschaft(node) && !node.basisDatentyp && !node.referenz) {
				return memoizedCreateSelectEigenschaftChildren(modell)(node);
			}
			return createSelectDirectChildrenFromModell(modell)(node);
		});
	},
);

export const createSelectInfiniteChildren = (
	project: StandardProjekt | null | undefined,
) => createSelectInfiniteChildrenFromModell(getModell(project));

export const createSelectNodesByPathFromModell = memoize(
	(modell: LiteModellContainer) =>
		memoize(
			(path: LiteId[]) => {
				return path.map((nodeId) => {
					const node = selectNodeFromModell(modell, nodeId);
					if (!node) {
						throw new Error(
							`Expected node "${nodeId}" to exist in the project, but it could not be found`,
						);
					}
					return node;
				});
			},
			{ cacheSize: 128, getCacheKeys: (path) => [joinLitePath(path)] },
		),
);
const noopSelectNodes = () => null;
export const createSelectNodesByPath = (
	project: Nullish<StandardProjekt>,
): ((path: LiteId[]) => LiteNode[] | null) => {
	if (!project) return noopSelectNodes;
	return createSelectNodesByPathFromModell(project.modell);
};

function isSecondRecursion(
	modell: LiteModellContainer,
	node: LiteNode,
	path: LiteId[],
): boolean {
	if (!(isLiteEigenschaft(node) && node.datentyp)) return false;
	const parents = createSelectNodesByPathFromModell(modell)(path.slice(0, -1));
	if (!parents) {
		throw new Error(
			`Node parents of "${node.name || placeholder.anonymousStructure}" id: "${
				node.id
			}" could not be found`,
		);
	}
	return parents.some((parent) => {
		return (
			(isLiteEigenschaft(parent) && parent.datentyp === node.datentyp) ||
			parent.id === node.datentyp
		);
	});
}

export const createSelectChildrenFromModell = memoize(
	(modell: LiteModellContainer) => {
		const selectChildren = createSelectInfiniteChildrenFromModell(modell);
		return (node: LiteNode, path: LiteId[]): LiteNode[] => {
			if (isSecondRecursion(modell, node, path)) {
				return EMPTY_CHILDREN;
			}
			return selectChildren(node);
		};
	},
);
export const createSelectChildren = (project: Nullish<StandardProjekt>) =>
	createSelectChildrenFromModell(getModell(project));

function isDatatypeRecursive(modell: LiteModellContainer, node: LiteBaustein) {
	// Only datatype nodes can be recursive
	if (node.typ !== LiteBausteinType.Datentyp) return false;
	const selectInfiniteChildren = createSelectInfiniteChildrenFromModell(modell);
	const rootNodes = selectInfiniteChildren(node);
	const selectChildren = createSelectChildrenFromModell(modell);
	const entries = walkTreeEntries(rootNodes, selectChildren, selectNodeId);
	for (const [, child] of entries) {
		if (isLiteEigenschaft(child) && child.datentyp === node.id) {
			return true;
		}
	}
	return false;
}
function selectDatatypeOrRefFromNode(
	modell: LiteModellContainer,
	node: LiteNode,
): LiteBaustein | null {
	if (isLiteBaustein(node) && node.typ === LiteBausteinType.Datentyp) {
		return node;
	}
	if (isLiteEigenschaft(node) && node.datentyp) {
		return modell.nodes.getIn([LiteNodeType.Baustein, node.datentyp]) || null;
	}
	return null;
}
export const createSelectIsRecursiveFromModell = weakMemoize(
	(modell: LiteModellContainer) =>
		weakMemoize((node: LiteNode): boolean => {
			const datatypeNode = selectDatatypeOrRefFromNode(modell, node);
			if (!datatypeNode) return false;
			return isDatatypeRecursive(modell, datatypeNode);
		}),
);
export const createSelectIsRecursive = (project: Nullish<StandardProjekt>) =>
	createSelectIsRecursiveFromModell(getModell(project));

export const createSelectDefinitionPathFromModell = weakMemoize(
	(modell: LiteModellContainer) =>
		memoize((nodeId: LiteId): LiteId[] => {
			let node = selectNodeFromModell(modell, nodeId);
			const path: LiteId[] = [];
			if (node) {
				path.unshift(node?.id);
			}
			while (node && node.parent) {
				path.unshift(node.parent);
				node = selectNodeFromModell(modell, node.parent);
			}
			return path;
		}),
);
export const createSelectDefinitionPath = (projekt: Nullish<StandardProjekt>) =>
	createSelectDefinitionPathFromModell(getModell(projekt));

const memoizedCreateSelectModelOrPaketChildren = weakMemoize(
	(modell: LiteModellContainer): ((node: LiteNode) => LiteNode[]) => {
		return weakMemoize((node) => {
			if ((node && isLiteModel(node)) || isLitePaket(node)) {
				const selectChildren = createSelectInfiniteChildrenFromModell(modell);
				return selectChildren(node);
			}
			return [];
		});
	},
);
export const walkModelOrPaketNodes = (
	modell: LiteModellContainer,
): Gen.SimpleGenerator<LiteNode> => {
	const selectModelOrPaketChildren =
		memoizedCreateSelectModelOrPaketChildren(modell);
	const rootModel = selectNodeFromModell(modell, modell.rootModelId);
	if (!rootModel) return Gen.empty();
	const root = [rootModel];
	const entries = walkTreeEntries(
		root,
		selectModelOrPaketChildren,
		selectNodeId,
	);
	return pipe(entries).through(Gen.map(([, node]) => node));
};

export const selectDatatypesFromModell = memoize(
	(modell: LiteModellContainer): LiteNode[] => {
		const nodes = walkModelOrPaketNodes(modell);
		return pipe(nodes).through(
			Gen.filter((node) => isLiteDatatype(node)),
			Gen.collect,
			(list) => list.sort(createStringSorter("name", Direction.Asc)),
		);
	},
);

export const selectDatatypesAndGlobalsFromModell = memoize(
	(
		modell: LiteModellContainer,
		datatypesToSelect: RefererSelection,
	): LiteNode[] => {
		const nodes = walkModelOrPaketNodes(modell);
		return pipe(nodes).through(
			Gen.filter((node) => {
				return (
					node && isLiteBaustein(node) && datatypesToSelect.includes(node.typ)
				);
			}),
			Gen.collect,
			(list) => list.sort(createStringSorter("name", Direction.Asc)),
		);
	},
);
export const EMPTY_NODES: LiteNode[] = [];
export const selectDatatypes = (project: Nullish<StandardProjekt>) => {
	if (!project) return EMPTY_NODES;
	return selectDatatypesFromModell(project.modell);
};
export const selectOwnDatatypesFromModell = memoize(
	(modell: LiteModellContainer): LiteNode[] => {
		const selectNodesByPath = createSelectNodesByPathFromModell(modell);
		const selectDefinitionPath = createSelectDefinitionPathFromModell(modell);
		const nodes = walkModelOrPaketNodes(modell);
		return pipe(nodes).through(
			Gen.filter((node) => {
				if (!isLiteDatatype(node)) return false;
				const definitionPath = selectDefinitionPath(node.id);
				const parentPath = selectNodesByPath(definitionPath.slice());
				return parentPath.every(
					(parent) => !isLiteModel(parent) || parent.id === modell.rootModelId,
				);
			}),
			Gen.collect,
			(list) => list.sort(createStringSorter("name", Direction.Asc)),
		);
	},
);
export const selectOwnDatatypes = (project: Nullish<StandardProjekt>) => {
	if (!project) return EMPTY_NODES;
	return selectDatatypesFromModell(project.modell);
};

export const selectModellierungDatatypesAndGlobalsFromModell = memoize(
	(projekt: StandardProjekt, datatypesToSelect: RefererSelection) => {
		const selectNodesByPath = createSelectNodesByPathFromModell(projekt.modell);
		const selectDefinitionPath = createSelectDefinitionPathFromModell(
			projekt.modell,
		);
		return selectDatatypesAndGlobalsFromModell(
			projekt.modell,
			datatypesToSelect,
		)
			.sort((a) => {
				const pattern =
					"[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";
				if (a.id.match(pattern)) {
					return 1;
				}
				if (!a.id.match(pattern)) {
					return -1;
				}
				return 0;
			})
			.map((node) => {
				const definitionPath = selectDefinitionPath(node.id);
				const parentPath = selectNodesByPath(definitionPath.slice(0, -1))
					.map((ancestor) => ancestor.name)
					.join("/");
				return {
					name: node.name || node.id,
					id: node.id,
					parentPath,
					node,
				};
			});
	},
);
export const EMPTY_DATATYPES: LiteDatatypeEntry[] = [];
export const selectModellierungDatatypesAndGlobals = (
	projekt: Nullish<StandardProjekt>,
	datatypesToSelect: RefererSelection,
) => {
	if (!projekt) return EMPTY_DATATYPES;
	return selectModellierungDatatypesAndGlobalsFromModell(
		projekt,
		datatypesToSelect,
	);
};

export const selectNachrichtenFromModell = memoize(
	(modell: LiteModellContainer): LiteNode[] => {
		const nodes = walkModelOrPaketNodes(modell);
		return pipe(nodes).through(
			Gen.filter((node) => isLiteNachricht(node)),
			Gen.collect,
			(list) => list.sort(createStringSorter("name", Direction.Asc)),
		);
	},
);
export const selectNachrichten = (project: Nullish<StandardProjekt>) => {
	if (!project) return EMPTY_NODES;
	return selectNachrichtenFromModell(project.modell);
};

export const selectBausteinRootNode = (
	projekt: Nullish<StandardProjekt>,
): LiteNode | null => {
	if (!projekt) return null;
	const rootModel = createSelectNode(projekt)(projekt.modell.rootModelId);
	if (!rootModel) return null;
	const rootNode = createSelectInfiniteChildren(projekt)(rootModel).at(0);
	return rootNode || null;
};

export const createSelectReferencesFromModell = weakMemoize(
	(modell: LiteModellContainer) =>
		memoize(
			(nodeId: LiteId): LiteId[] => {
				const node = selectNodeFromModell(modell, nodeId);
				if (!node || !isLiteDatatype(node)) return [];
				return modell.nodes
					.get(LiteNodeType.Eigenschaft)
					.valueSeq()
					.filter(
						(eigenschaft) =>
							!!eigenschaft?.datentyp && eigenschaft.datentyp === node.id,
					)
					.map((eigenschaft) => LiteIdSchema.parse(eigenschaft?.id))
					.toArray();
			},
			{ cacheSize: 64 },
		),
);
/**
 * Find all ids of eigenschaft nodes, that reference a given datatype node
 */
export const createSelectReferences = (projekt: Nullish<StandardProjekt>) =>
	createSelectReferencesFromModell(getModell(projekt));

export const createSelectExtensionsFromModell = weakMemoize(
	(modell: LiteModellContainer) =>
		memoize(
			(nodeId: LiteId): LiteId[] => {
				const node = selectNodeFromModell(modell, nodeId);
				if (!node || !isLiteDatatype(node)) return [];
				return modell.nodes
					.get(LiteNodeType.Baustein)
					.valueSeq()
					.filter(
						(baustein) =>
							!!baustein?.basisDatentyp && baustein.basisDatentyp === node.id,
					)
					.map((baustein) => LiteIdSchema.parse(baustein?.id))
					.toArray();
			},
			{ cacheSize: 64 },
		),
);
/**
 * Find all ids of datatype nodes, that extend a given datatype node
 */
export const createSelectExtensions = (projekt: Nullish<StandardProjekt>) =>
	createSelectExtensionsFromModell(getModell(projekt));

export function selectRootModellFromModell(
	modell: LiteModellContainer,
): LiteModel {
	const node = selectNodeFromModell(modell, modell.rootModelId);

	if (node === null) {
		throw new Error("Project needs to have a root model");
	} else if (!isLiteModel(node)) {
		throw new Error("Node is not a lite model");
	}

	return node;
}
export function selectRootModell(project: StandardProjekt) {
	return selectRootModellFromModell(project.modell);
}

export const selectQNameFromModell = memoize(
	(modell: LiteModellContainer, node: LiteNode): QName => {
		const hierachy = createSelectDefinitionPathFromModell(modell)(node.id);
		const parentWithPrefix = filterFalsy(
			hierachy.map((parentId) => selectNodeFromModell(modell, parentId)),
		).findLast((parent) => {
			return (
				(isLitePaket(parent) || isLiteModel(parent)) &&
				parent.konfiguration?.prefix
			);
		});
		const prefix =
			(parentWithPrefix &&
				"konfiguration" in parentWithPrefix &&
				parentWithPrefix.konfiguration?.prefix) ||
			"";
		if (!prefix)
			return QNameSchema.parse(node.name || placeholder.anonymousStructure);
		return QNameSchema.parse(`${prefix}:${node.name}`);
	},
);

export function selectQNamePathFromModell(
	modell: LiteModellContainer,
	path: LiteId[],
) {
	if (path.length < 1 || !path.at(0)) return QNamePathSchema.parse("");
	const hierachy = createSelectNodesByPathFromModell(modell)(path);

	const bausteinIndex = hierachy.findIndex((node) => isLiteBaustein(node));
	const nodes = bausteinIndex === -1 ? hierachy : hierachy.slice(bausteinIndex);
	return joinQNamePath(
		nodes.map((node) => selectQNameFromModell(modell, node)),
	);
}

export const selectBausteinQNameMapFromModell = memoize(
	(modell: LiteModellContainer) => {
		return modell.nodes.get(LiteNodeType.Baustein).mapEntries(([, node]) => {
			AssertionError.notNullish(node);
			return [selectQNameFromModell(modell, node), node] as const;
		});
	},
	{ cacheSize: 8 },
);

export const selectIdPathFromQNamePathFromModell = memoize(
	(modell: Nullable<LiteModellContainer>, qnames: QName[]): LiteId[] => {
		const rootQName = qnames.at(0);
		if (!rootQName || !modell) return [];
		const bausteinQNameMap = selectBausteinQNameMapFromModell(modell);
		const rootBaustein = bausteinQNameMap.get(rootQName);
		const rootBausteinId = rootBaustein?.id;
		const definition = rootBausteinId
			? createSelectDefinitionPathFromModell(modell)(rootBausteinId)
			: [];
		const rootNodeId = rootBausteinId || modell.rootModelId;
		const rootNode = selectNodeFromModell(modell, rootNodeId);
		const root = rootNode ? [rootNode] : [];
		const entries = walkTreeEntries(
			root,
			(node, path) => {
				const index = path.length - 1;
				const segment = qnames[index];
				if (selectQNameFromModell(modell, node) !== segment) return [];
				return createSelectChildrenFromModell(modell)(node, path);
			},
			selectNodeId,
		);
		for (const [path, node] of entries) {
			const index = path.length - 1;
			const segment = qnames[index];
			if (
				path.length === qnames.length &&
				selectQNameFromModell(modell, node) === segment
			) {
				return definition.slice(0, -1).concat(path);
			}
		}
		return [modell.rootModelId];
	},
	{
		cacheSize: 32,
		getCacheKeys: (modell, qnames) => [modell, joinQNamePath(qnames)],
	},
);

export const selectParentQNamePathsFromModell = memoize(
	(modell: LiteModellContainer, qnamePath: QNamePath) => {
		const idPath = selectIdPathFromQNamePathFromModell(
			modell,
			parseQNamePath(qnamePath),
		);
		return idPath.map((_segment, i) => {
			const subPath = idPath.slice(0, i + 1);
			return selectQNamePathFromModell(modell, subPath);
		});
	},
	{ cacheSize: 32 },
);

export const selectAffectedIdPathsFromModell = memoize(
	(modell: LiteModellContainer, qnamePath: QNamePath) => {
		const idPath = selectIdPathFromQNamePathFromModell(
			modell,
			parseQNamePath(qnamePath),
		);
		return idPath.map((_segment, i) => {
			const subPath = idPath.slice(0, i + 1);
			return joinLitePath(subPath);
		});
	},
	{ cacheSize: 32 },
);

export const selectReferencedDatatypeFromModell = (
	modell: LiteModellContainer,
	node: Nullish<LiteNode>,
): LiteBaustein | null => {
	const datatypeRef =
		(node && isLiteEigenschaft(node) && node.datentyp) || null;
	if (!datatypeRef) return null;
	const datatype = selectNodeFromModell(modell, datatypeRef);
	if (!datatype || !isLiteDatatype(datatype)) return null;
	return datatype;
};

export const selectDatatypeOrReferenceFromModell = (
	modell: LiteModellContainer,
	node: Nullish<LiteNode>,
): LiteBaustein | null => {
	if (node && isLiteDatatype(node)) return node;
	return selectReferencedDatatypeFromModell(modell, node);
};

export const selectIsCodelisteFromModell = (
	modell: LiteModellContainer,
	node: Nullish<LiteNode>,
): boolean => {
	const datatype = selectReferencedDatatypeFromModell(modell, node);
	return (
		(!!node && getLiteNodeKind(node) === LiteKind.CodeDatentyp) ||
		(!!datatype && getLiteNodeKind(datatype) === LiteKind.CodeDatentyp)
	);
};

export const selectIsDefiniedInStandard = (
	modell: LiteModellContainer,
	datatypeId: Nullish<LiteId>,
) => {
	if (!datatypeId) return false;
	const definitionPath =
		createSelectDefinitionPathFromModell(modell)(datatypeId);
	const hierachy = createSelectNodesByPathFromModell(modell)(definitionPath);
	const definitionModell = hierachy.findLast((node) => isLiteModel(node));
	if (!definitionModell) return false;
	return definitionModell.id === modell.rootModelId;
};
