import { createSvgIcon } from "@mui/material";

const KomplexerDatentypIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.25 4C2.25 3.0335 3.0335 2.25 4 2.25H14C14.9665 2.25 15.75 3.0335 15.75 4V6.84C15.75 7.25421 15.4142 7.59 15 7.59C14.5858 7.59 14.25 7.25421 14.25 6.84V4C14.25 3.86193 14.1381 3.75 14 3.75H4C3.86193 3.75 3.75 3.86193 3.75 4V14C3.75 14.1381 3.86193 14.25 4 14.25H6.5C6.91421 14.25 7.25 14.5858 7.25 15C7.25 15.4142 6.91421 15.75 6.5 15.75H4C3.0335 15.75 2.25 14.9665 2.25 14V4Z"
		/>
		<path d="M12.937 13.5C12.715 14.3626 11.9319 15 11 15C9.89543 15 9 14.1046 9 13C9 11.8954 9.89543 11 11 11C11.9319 11 12.715 11.6374 12.937 12.5H18.063C18.285 11.6374 19.0681 11 20 11C21.1046 11 22 11.8954 22 13C22 14.1046 21.1046 15 20 15C19.0681 15 18.285 14.3626 18.063 13.5H16V19.5H18C18.021 19.5 18.0417 19.5013 18.062 19.5038C18.2827 18.6393 19.0667 18 20 18C21.1046 18 22 18.8954 22 20C22 21.1046 21.1046 22 20 22C19.0667 22 18.2827 21.3607 18.062 20.4962C18.0417 20.4987 18.021 20.5 18 20.5H15.5C15.2239 20.5 15 20.2761 15 20V13.5H12.937Z" />
	</svg>,
	"KomplexerDatentyp",
);

export default KomplexerDatentypIcon;
