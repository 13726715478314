import type { ImmutableMap } from "@xoev/immutable-map";
import createImmutableMap from "@xoev/immutable-map";
import type ProfilingMetadataValues from "../../types/ProflierungMetadataValues";
import type SavedProfileData from "../../types/SavedProfileData";
import type {
	SavedPropertyProfile,
	SavedCodeList,
	SavedDatatypeProfile,
	SavedMessageProfileConfiguration,
	SavedMessageProfileRecursion,
	SavedProfile,
	SavedProfileDocumentation,
} from "../../types/SavedProfileData";
import { toMutable } from "../../utils/types";
import {
	type MessageProfileConfiguration,
	type Datatype,
	type RestrictionProfileValues,
	type MessageProfileValues,
	type InnerEditorState,
	type DatatypeProfileValues,
	type Recursion,
	type PropertyProfile,
	type PropertyProfileMap,
	RestrictionIdSchema,
} from "./types";

type DocumentationEntries = [string, ImmutableMap<SavedProfileDocumentation>][];

// We'll convert the type of `recursion.limit` from number to string, since that
// is what our input fields will yield in the editor
function convertRecursionType(
	recursion: SavedMessageProfileRecursion | undefined,
) {
	const { limit, ...restRecursion } = recursion || {};
	const converted: Recursion = restRecursion;
	if (limit || limit === 0) {
		converted.limit = limit.toString();
	}
	return converted;
}

function convertPropertyType(
	properties: SavedPropertyProfile[] | undefined,
): PropertyProfileMap {
	const entries =
		properties?.map(
			({ definition: { nameTechnisch }, value }) =>
				[
					nameTechnisch,
					createImmutableMap<PropertyProfile>({ name: nameTechnisch, value }),
				] as const,
		) || [];
	return createImmutableMap(Object.fromEntries(entries));
}

function createProfileConfiguration(
	config: SavedMessageProfileConfiguration | undefined,
): ImmutableMap<MessageProfileConfiguration> {
	const { codeliste, ...restConfig } = config || {};
	const clPartial = codeliste && {
		codeliste: createImmutableMap<Partial<SavedCodeList>>(codeliste),
	};
	return createImmutableMap<MessageProfileConfiguration>({
		...restConfig,
		...clPartial,
	});
}

// We create an object with the same keys as in `MessageProfileValues` here,
// because this way we can enforce, that every key of `MessageProfileValues`
// also appears in this object. This way we'll get a compiler error when we
// forget to add a key here after `MessageProfileValues` was modified. Simply
// using a list of keys cannot give us that safety unfortunately since it is
// not (yet) possible to convert a union type to a tuple type
const allowedProfileKeyMap: {
	[K in keyof Required<MessageProfileValues>]: 0;
} = {
	beschreibung: 0,
	umsetzungshinweis: 0,
	lowerBound: 0,
	upperBound: 0,
	errorMessageRecursion: 0,
	istRekursionsStart: 0,
	konfiguration: 0,
	datentyp: 0,
	eigenschaften: 0,
	rekursion: 0,
};
// We'll use this map to remove all unknown keys we receive from the backend,
// so the ui only works with the keys it knows
const allowedProfileKeys = new Set<string>(Object.keys(allowedProfileKeyMap));

/**
 * Removes all unknown keys from a `MessageProfileValues` object. The backend
 * might supply additional data about a profile that is unused or unknown in
 * the frontend. To allow the ui to make correct assumptions about the shape
 * of the profile data, we remove all keys, that are not in
 * `MessageProfileValues`.
 *
 * @param profile The `MessageProfileValues` that may contain unknown keys
 * @returns The same profile with the unknown keys removed
 */
function sanitizeProfile(
	profile: Partial<MessageProfileValues>,
): Partial<MessageProfileValues> {
	return Object.fromEntries(
		Object.entries(profile).filter(([key]) => allowedProfileKeys.has(key)),
	);
}

function createProfile(
	savedProfile: SavedProfile,
): Partial<MessageProfileValues> {
	const {
		konfiguration,
		datentyp,
		lowerBound,
		upperBound,
		eigenschaften,
		rekursion,
		...restProfile
	} = savedProfile;

	const config = createProfileConfiguration(konfiguration);
	const configPartial = !config.isEmpty() && { konfiguration: config };
	const datatypeRefPartial = datentyp && {
		datentyp: createImmutableMap<Datatype>(datentyp),
	};
	const lowerBoundPartial = lowerBound !== null &&
		lowerBound !== undefined && { lowerBound: lowerBound.toString() };
	const upperBoundPartial = upperBound !== null &&
		upperBound !== undefined && { upperBound: upperBound.toString() };
	const propertiesMap = convertPropertyType(eigenschaften);
	const propertyPartial = eigenschaften &&
		propertiesMap.size && { eigenschaften: propertiesMap };
	const recursionPartial = rekursion && {
		rekursion: createImmutableMap(convertRecursionType(rekursion)),
	};

	return sanitizeProfile({
		...restProfile,
		...configPartial,
		...datatypeRefPartial,
		...lowerBoundPartial,
		...upperBoundPartial,
		...propertyPartial,
		...recursionPartial,
	});
}

function createProfileEntry(
	savedProfile: SavedProfile,
): [string, ImmutableMap<Partial<MessageProfileValues>>] {
	const profile = createProfile(savedProfile);
	return [savedProfile.syntaxPfad, createImmutableMap(profile)];
}

function createDatatypeEntry(
	standardDatatype: string,
	initialDatatypes: SavedDatatypeProfile[],
): [string, ImmutableMap<Partial<DatatypeProfileValues>>] {
	const restrictions = initialDatatypes.filter(
		(init) => init.datentyp === standardDatatype,
	);

	const restrictionsRestructured = Object.fromEntries(
		restrictions.map((restriction) => {
			const profilesRestructured = Object.fromEntries(
				restriction.profile.map((profileEntry) => {
					const profile = createProfile(profileEntry);
					return [
						profileEntry.syntaxPfad,
						createImmutableMap<MessageProfileValues>(profile),
					];
				}),
			);
			return [
				restriction.id,
				createImmutableMap<RestrictionProfileValues>({
					id: RestrictionIdSchema.parse(restriction.id),
					beschreibung: restriction.beschreibung,
					umsetzungshinweis: restriction.umsetzungshinweis,
					name: restriction.name,
					profile: createImmutableMap(profilesRestructured),
				}),
			];
		}),
	);

	const datatypeProfile: Partial<DatatypeProfileValues> = {
		name: standardDatatype,
		restrictions: createImmutableMap<{
			[restrictionId: string]: ImmutableMap<RestrictionProfileValues>;
		}>(restrictionsRestructured),
	};

	return [standardDatatype, createImmutableMap(datatypeProfile)];
}

function createDocumentationEntry(
	doc: SavedProfileDocumentation,
	index: number | null,
) {
	const indexed = index !== null ? { index } : {};
	const normalizedDoc = { ...indexed, ...doc };
	// We don't want to keep the generated content in the state, since we don't
	// want to mess with state history entries when something outside of the
	// state container changes (in this case, the generated documentation, when
	// the configuration changes). We'll just ignore the contents of the
	// generated docs and fetch them as needed
	if (normalizedDoc.generiert) {
		normalizedDoc.inhalt = "";
	}
	return toMutable([
		doc.name,
		createImmutableMap<SavedProfileDocumentation>(normalizedDoc),
	] as const);
}
function createDocumentation(docs: SavedProfileDocumentation[]) {
	const hiddenDocumentation: DocumentationEntries = docs
		.filter((doc) => doc.versteckt)
		.map((doc) => createDocumentationEntry(doc, null));
	const shownDocumentation: DocumentationEntries = docs
		.filter((doc) => !doc.versteckt)
		.map((doc, index) => createDocumentationEntry(doc, index));
	return Object.fromEntries([...hiddenDocumentation, ...shownDocumentation]);
}

function createEditorState(initialState: SavedProfileData): InnerEditorState {
	// Convert the profile list that the backend sends to a map of profile ids
	// to profile values
	const profile: {
		[nodeIdKey: string]: ImmutableMap<Partial<MessageProfileValues>>;
	} = Object.fromEntries(
		initialState.profile.map((savedProfile) =>
			createProfileEntry(savedProfile),
		),
	);

	const dokumentation = createDocumentation(initialState.dokumentation);

	const standardDatatypes = [
		...new Set(initialState.datentypen.map((entry) => entry.datentyp)),
	];

	const datentypen: {
		[nodeIdKey: string]: ImmutableMap<Partial<DatatypeProfileValues>>;
	} = Object.fromEntries(
		standardDatatypes.map((standardDatatype) =>
			createDatatypeEntry(standardDatatype, initialState.datentypen),
		),
	);

	return {
		metadaten: createImmutableMap<Partial<ProfilingMetadataValues>>(
			initialState.metadaten,
		),
		profile: createImmutableMap(profile),
		dokumentation: createImmutableMap(dokumentation),
		datentypen: createImmutableMap(datentypen),
		konfiguration: createImmutableMap({
			profilierung: createImmutableMap(
				initialState.konfiguration.profilierung || {},
			),
		}),
	};
}

export default createEditorState;
