import { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import type { DropEvent, FileError } from "react-dropzone";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import type SavedProfileData from "../../../../types/SavedProfileData";
import {
	RequestMethods,
	RequestStatus,
	useResource,
} from "../../../../components/Api";
import { Dropzone } from "../../../../components/ui";
import { useEditorData } from "../../../../components/EditorData";
import createProfileData from "../../utils/createProfileData";
import { useSyncedRef } from "../../../../hooks";
import RequestErrorNotification from "../../../../components/RequestErrorNotification";
import ModalHeader from "../../../../components/ui/Modal/ModalHeader";
import { useAppDispatch } from "../../../../redux/hooks";
import {
	ValidationBase,
	setValidationBase,
} from "../../../../redux/messagesSlice";
import type { ProjectPostResponse } from "../../types/types";
import useReloadProfilierung from "../../hooks/useReloadProfilierung";

const OpenProjectModal = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}): JSX.Element => {
	const [selectedFile, setSelectedFile] = useState<File | null>();
	const {
		data: projectData,
		request: requestProjekt,
		error: projectError,
		status: projectStatus,
	} = useResource<ProjectPostResponse, RequestMethods.Post, FormData>();
	const { initContainer } = useEditorData();
	const reload = useReloadProfilierung();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (projectData) {
			const initState: SavedProfileData = createProfileData(projectData);
			initContainer(initState);
			reload();
		}
	}, [projectData, initContainer, reload]);

	const onCloseRef = useSyncedRef(onClose);
	useEffect(() => {
		if (projectStatus === RequestStatus.Success) {
			setSelectedFile(null);
			dispatch(
				setValidationBase({ validierungsBasis: ValidationBase.ActiveProfile }),
			);
			onCloseRef.current();
		}
	}, [projectStatus, onCloseRef, dispatch]);

	const handleClick = () => {
		if (selectedFile) {
			const reqData = new FormData();
			reqData.append("file", selectedFile);

			requestProjekt("/v1/projekt", {
				method: RequestMethods.Post,
				data: reqData,
			});
		}
	};

	const handleDrop = (
		accepted: File[],
		fileRejections: File[],
		error: FileError[],
		event: DropEvent,
	) => {
		event.preventDefault();
		setSelectedFile(accepted[0]);
	};

	const handleClose = () => {
		setSelectedFile(null);
		onClose();
	};

	return (
		<>
			<RequestErrorNotification
				id="open-project-modal-load-project-request"
				error={projectError}
				status={projectStatus}
			>
				Fehler beim Laden des Projekts. Bitte versuchen Sie es erneut. Sollte
				der Fehler weiterhin auftreten, wenden Sie sich bitte an die
				Administrator:innen
			</RequestErrorNotification>
			<Dialog open={isOpen} onClose={handleClose}>
				<div data-testid="open-project-modal-content" data-is-open={isOpen}>
					<ModalHeader
						title="Profilierungsprojekt öffnen"
						handleClose={handleClose}
					/>
					<DialogContent>
						<Stack spacing={2}>
							<DialogContentText>
								Bitte wählen Sie nur Profilierungsprojektdateien (im Format zip)
								aus.
							</DialogContentText>
							<Dropzone
								accept=".zip"
								multiple={false}
								onDrop={handleDrop}
								hasSelectedFiles={!!selectedFile}
							>
								<FileUploadIcon />
								{selectedFile
									? selectedFile.name
									: "Drag & Drop oder Feld klicken, um Explorer zu öffnen"}
							</Dropzone>
						</Stack>
					</DialogContent>
					<DialogActions>
						<LoadingButton
							variant="contained"
							onClick={handleClick}
							disabled={!selectedFile}
							data-testid="open-project-button"
							loading={projectStatus === RequestStatus.Loading}
						>
							Öffnen
						</LoadingButton>
					</DialogActions>
				</div>
			</Dialog>
		</>
	);
};

export default OpenProjectModal;
