import type { ActorRefFrom } from "xstate";
import { assertEvent, assign, setup } from "xstate";
import type { ExtractStoreEventPayload } from "../../../EventStore/StoreEvent";
import type { LiteId, ProjektId } from "../schemas";
import type { NodeTarget } from "../types";
import {
	sendToEventStore,
	translateStoreEvents,
	type EventTranslationMap,
} from "../../../EventStore/helpers";

export type ConfirmationQueryActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof confirmationQueryMachine
>;

// eslint-disable-next-line no-use-before-define
export type ConfirmationQueryContext = {
	refString: string;
	projektId: ProjektId;
	deletePath: LiteId[];
	target: NodeTarget;
};

export type ConfirmationQueryEvent =
	| ({ type: "OPEN" } & ExtractStoreEventPayload<"CONFIRMATION.OPEN">)
	| ({ type: "CONFIRMED" } & ExtractStoreEventPayload<"CONFIRMATION.CONFIRMED">)
	| ({ type: "DECLINED" } & ExtractStoreEventPayload<"CONFIRMATION.DECLINED">)
	| ({ type: "CLOSE" } & ExtractStoreEventPayload<"CONFIRMATION.CLOSE">);

export const translateMap: EventTranslationMap<ConfirmationQueryEvent> = {
	"CONFIRMATION.OPEN": ({ payload }) => ({
		type: "OPEN",
		...payload,
	}),
	"CONFIRMATION.CONFIRMED": ({ payload }) => ({
		type: "CONFIRMED",
		...payload,
	}),
	"CONFIRMATION.DECLINED": ({ payload }) => ({
		type: "DECLINED",
		...payload,
	}),
	"CONFIRMATION.CLOSE": ({ payload }) => ({
		type: "CLOSE",
		...payload,
	}),
};

const confirmationQueryMachine = setup({
	types: {
		events: {} as ConfirmationQueryEvent,
		context: { confirmation: {} as ConfirmationQueryContext },
	},
	actors: {
		translateEvents: translateStoreEvents<ConfirmationQueryEvent>(
			translateMap,
			{ replayEventLog: true },
		),
	},
	actions: {
		open: assign({
			confirmation: ({ event }) => {
				const { deletePath, projektId, refString, target } = event;
				const payload = { deletePath, projektId, refString, target };
				assertEvent(event, "OPEN");

				return payload;
			},
		}),
		declined: assign({
			confirmation: ({ event, system }) => {
				const { deletePath, projektId, refString, target } = event;
				const payload = { deletePath, projektId, refString, target };
				assertEvent(event, "DECLINED");

				sendToEventStore(system, {
					type: "MODELLIERUNG.NODE.DELETE.DECLINED",
					payload,
				});

				return {} as ConfirmationQueryContext;
			},
		}),
		confirmed: assign({
			confirmation: ({ event, system }) => {
				const { deletePath, projektId, refString, target } = event;
				const payload = { deletePath, projektId, refString, target };
				assertEvent(event, "CONFIRMED");

				sendToEventStore(system, {
					type: "MODELLIERUNG.NODE.DELETE.CONFIRMED",
					payload,
				});

				return {} as ConfirmationQueryContext;
			},
		}),
	},
	guards: {
		hasText: ({ event }) => {
			return !!event.refString;
		},
		isSelf: ({ context, event }) => {
			return context.confirmation.projektId === event.projektId;
		},
	},
}).createMachine({
	id: "confirmation:query",
	context: { confirmation: {} as ConfirmationQueryContext },
	invoke: { src: "translateEvents" },
	on: {
		OPEN: { guard: "hasText", actions: "open" },
		DECLINED: { guard: "isSelf", actions: "declined" },
		CONFIRMED: { guard: "isSelf", actions: "confirmed" },
	},
});

export default confirmationQueryMachine;
