import MenuIcon from "@mui/icons-material/Menu";
import FilterIcon from "@mui/icons-material/FilterList";
import ToolbarMenu from "../../Toolbar/ToolbarMenu";
import BausteinNodeMenuItems from "./BausteinNodeMenuItems";
import FilterMenuItems from "./FilterMenuItems";
import type { BausteinSchemaFilters } from "../../../Tree/types";

export default function BausteinToolbar({
	activeFilters,
	isDisplayOnly,
	toggleFilter,
}: {
	activeFilters: BausteinSchemaFilters;
	isDisplayOnly: boolean;
	toggleFilter: (...filters: BausteinSchemaFilters) => void;
}): JSX.Element {
	return (
		<>
			{!isDisplayOnly && (
				<ToolbarMenu icon={MenuIcon} label="Menü">
					{({ closeMenu, isOpen }) => (
						<BausteinNodeMenuItems closeMenu={closeMenu} isOpen={isOpen} />
					)}
				</ToolbarMenu>
			)}
			<ToolbarMenu icon={FilterIcon} label="Filter">
				{() => (
					<FilterMenuItems
						activeFilters={activeFilters}
						toggleFilter={toggleFilter}
					/>
				)}
			</ToolbarMenu>
		</>
	);
}
