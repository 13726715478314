import classNames from "classnames";
import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { useMemo } from "react";
import type { ExtendProps } from "../../utils/types";
import EditorSideBarSearch from "./EditorSideBarSearch";
import { useHtmlId } from "../../hooks";
import EditorSideBarContext from "./EditorSideBarContext";
import "./EditorSideBar.scss";

type AsideProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
type DivProps = DetailedHTMLProps<
	HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
>;
type UlProps = DetailedHTMLProps<
	HTMLAttributes<HTMLUListElement>,
	HTMLUListElement
>;

function createWrapperComponent<BaseElementProps>(
	displayName: string,
	baseClassName: string,
	tag: string,
) {
	type PropType = ExtendProps<
		BaseElementProps,
		{
			children?: ReactNode;
			className?: string;
		}
	>;
	const Tag = tag;
	function Component({ children, className, ...props }: PropType): JSX.Element {
		return (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			<Tag {...(props as any)} className={classNames(baseClassName, className)}>
				{children ?? null}
			</Tag>
		);
	}
	Component.displayName = displayName;
	return Component;
}

const EditorSideBarComponent = createWrapperComponent<AsideProps>(
	"EditorSideBar",
	"editor-sidebar",
	"aside",
);
const EditorSideBarMain = createWrapperComponent<DivProps>(
	"EditorSideBarMain",
	"editor-sidebar__main",
	"div",
);
const EditorSideBarList = createWrapperComponent<UlProps>(
	"EditorSideBarList",
	"editor-sidebar__list",
	"ul",
);
const EditorSideBarControls = createWrapperComponent<DivProps>(
	"EditorSideBarControls",
	"editor-sidebar__controls",
	"div",
);
const EditorSideBarScroller = createWrapperComponent<DivProps>(
	"EditorSideBarScroller",
	"editor-sidebar__scroller",
	"div",
);

type EditorSideBarType = typeof EditorSideBarComponent & {
	Main: typeof EditorSideBarMain;
	List: typeof EditorSideBarList;
	Controls: typeof EditorSideBarControls;
	Search: typeof EditorSideBarSearch;
	Scroller: typeof EditorSideBarScroller;
};

const EditorSideBar: EditorSideBarType = ({ children, ...props }) => {
	const searchInputId = useHtmlId();
	const ctx = useMemo(() => ({ searchInputId }), [searchInputId]);
	return (
		<EditorSideBarContext.Provider value={ctx}>
			<EditorSideBarComponent {...props}>{children}</EditorSideBarComponent>
		</EditorSideBarContext.Provider>
	);
};

EditorSideBar.displayName = "EditorSideBar";

EditorSideBar.Main = EditorSideBarMain;
EditorSideBar.List = EditorSideBarList;
EditorSideBar.Controls = EditorSideBarControls;
EditorSideBar.Search = EditorSideBarSearch;
EditorSideBar.Scroller = EditorSideBarScroller;

export default EditorSideBar;
