import { memoize } from "@xoev/memo";
import type { Nullish } from "../../../../../../utils/types";
import { getNodeByPath } from "../../../../../Tree/treeHelpers";
import type { LiteId } from "../../schemas";
import { isLiteDatatype } from "../../schemas";
import {
	createSelectChildrenFromModell,
	createSelectDefinitionPathFromModell,
	createSelectReferencesFromModell,
	selectNodeFromModell,
	selectNodeId,
	selectRootModellFromModell,
} from "../../selectors";
import type { LiteModellContainer } from "../../types";

interface QueueEntry {
	nodeId: LiteId;
	pathPostfix: LiteId[];
}

function getOccurrence(
	modell: LiteModellContainer,
	definitionPath: LiteId[],
	pathPostfix: LiteId[],
) {
	const root = selectRootModellFromModell(modell);
	const selectChildren = createSelectChildrenFromModell(modell);
	const occurrencePath = definitionPath.concat(pathPostfix);
	const containsOccurrence = !!getNodeByPath(
		[root],
		occurrencePath,
		selectChildren,
		selectNodeId,
	);
	return containsOccurrence ? occurrencePath : null;
}

function getParentDatatype(
	modell: LiteModellContainer,
	definitionPath: LiteId[],
) {
	const parentDatatypeIndex = definitionPath
		.slice(0, -1)
		.findLastIndex((parentId) => {
			const parent = selectNodeFromModell(modell, parentId);
			return !!parent && isLiteDatatype(parent);
		});
	const parentDatatypeId =
		(parentDatatypeIndex !== -1 && definitionPath.at(parentDatatypeIndex)) ||
		null;
	if (!parentDatatypeId) return null;
	return { parentDatatypeId, parentDatatypeIndex };
}

function assertEntry(entry: Nullish<QueueEntry>): asserts entry {
	if (!entry) {
		throw new Error(
			"Queue.shift must return an entry, while the queues length is greater than 0",
		);
	}
}

function markVisited(
	visited: Map<LiteId, Set<LiteId>>,
	nodeId: LiteId,
	parentDatatypeId: LiteId,
) {
	const nodeSet = visited.get(parentDatatypeId) || new Set();
	nodeSet.add(nodeId);
	visited.set(parentDatatypeId, nodeSet);
}

function hasVisited(
	visited: Map<LiteId, Set<LiteId>>,
	nodeId: LiteId,
	parentDatatypeId: LiteId,
) {
	return !!visited.get(parentDatatypeId)?.has(nodeId);
}

/**
 * Find all occurrence paths of a number of nodes in the resolved modell tree.
 *
 * @param projekt The project against which the node occurrences will be resolved
 * @param nodeIds The list of node ids, which will be resolved
 * @returns A list of paths, that point to each occurrence of the nodes provided
 *
 * @example
 * ```ts
 * resolveOccurrences(suiteEntwicklungProjekt, ["zubereitung"]);
 * // -> [
 * //   "suite-entwicklung 2.5/Nachrichten/Wettbewerbsmeldungen/Nahrung/zubereitung",
 * //   "suite-entwicklung 2.5/Nachrichten/Wettbewerbsmeldungen/wettbewerbsmeldungen.anmeldung.0001/benoetigteNahrung/zubereitung",
 * // ]
 * ```
 */
export default function resolveOccurrences(
	modell: LiteModellContainer,
	nodeIds: LiteId[],
): LiteId[][] {
	const selectDefinitionPath = createSelectDefinitionPathFromModell(modell);
	const selectReferences = createSelectReferencesFromModell(modell);

	// Store all occurrences of the node ids we come across
	const occurrences: LiteId[][] = [];
	// Add the node ids to the initial queue. We will later add other places
	// these nodes are referenced
	const queue: QueueEntry[] = nodeIds.map((nodeId) => ({
		nodeId,
		// The path postfix is the segment of the nodes path, that leads up to the
		// datatype, where it was defined. For example the eigenschaft node
		// `xdomea_3.0.0/Baukasten/AkteType/Akteninhalt/Dokument` is defined in
		// `AkteType`, so its path postfix is `Akteninhalt/Dokument`. This fragment
		// will later be rebased on other paths, where the parent datatype
		// `AkteType` is referenced, such as
		// `xdomea_3.0.0/Nachrichten/AbgabeDurchfuehren/Abgabe.Abgabe.0401/Schriftgutobjekt/Akte`.
		// We now know that there must be a occurrence of `Dokument` at
		// `xdomea_3.0.0/Nachrichten/AbgabeDurchfuehren/Abgabe.Abgabe.0401/Schriftgutobjekt/Akte/Akteninhalt/Dokument`.
		// We keep on traversing the paths, check for datatypes and if we find any,
		// add them to the queue until we've resolved all references
		pathPostfix: [],
	}));
	// Store which parent/child combinations we've come across already, so we can
	// skip them, should they occur multiple times
	const visited = new Map<LiteId, Set<LiteId>>();

	// Check the current node, as long as the queue contains more nodes
	while (queue.length > 0) {
		const entry = queue.shift();
		assertEntry(entry);
		const { nodeId, pathPostfix } = entry;
		const definitionPath = selectDefinitionPath(nodeId);

		// Join the definition with the path postfix, to get the occurrence path
		const occurrence = getOccurrence(modell, definitionPath, pathPostfix);
		// If the occurrence could not be found, we can skip the entry
		if (!occurrence) continue;
		occurrences.push(occurrence);

		// If there is no parent datatype in the node's path, we've reached the end
		// of the reference chain and we can stop resolving it further
		const parentDatatype = getParentDatatype(modell, definitionPath);
		if (!parentDatatype) continue;

		const { parentDatatypeId, parentDatatypeIndex } = parentDatatype;
		// Skip parent/node pairs we've already encountered
		if (hasVisited(visited, nodeId, parentDatatypeId)) continue;
		markVisited(visited, nodeId, parentDatatypeId);

		// Get the updated path postfix and add it alongside all references to the
		// queue
		const definitionPostfix = definitionPath.slice(parentDatatypeIndex + 1);
		const references = selectReferences(parentDatatypeId);
		queue.push(
			...references.map((refId) => ({
				nodeId: refId,
				pathPostfix: definitionPostfix.concat(pathPostfix),
			})),
		);
	}

	return occurrences;
}

export const selectUsedByFromModell = memoize(
	(modell: LiteModellContainer, nodeId: LiteId) => {
		const references = createSelectReferencesFromModell(modell)(nodeId);
		return resolveOccurrences(modell, references);
	},
	{ cacheSize: 32 },
);
