import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import {
	IconButton,
	TableCell,
	TableRow,
	TextField,
	Tooltip,
} from "@mui/material";
import type { ChangeEventHandler, KeyboardEventHandler } from "react";
import { useEffect, useRef, useState } from "react";
import type { PropertyValues } from "../../../EditorState/types";
import KeyboardShortcut from "../../../KeyboardShortcut";
import "./PropertyEditRow.scss";
import { normalizeIdentifier } from "../../../../utils/misc";

export enum PropertyEditMode {
	Edit = "Edit",
	Create = "Create",
}

const emptyState = { name: "", nameTechnisch: "", beschreibung: "" };

function checkConfirmState(
	values: PropertyValues,
	properties: PropertyValues[],
	mode: PropertyEditMode,
) {
	const verb = mode === PropertyEditMode.Create ? "erstellt" : "gespeichert";
	const hasRequiredData = Object.values(values).every(
		(value) => !!value.trim(),
	);
	if (!hasRequiredData) {
		return {
			isDisabled: true as const,
			reason:
				`Die Eigenschaft kann nicht ${verb} werden, da nicht alle notwendigen ` +
				"Daten vorhanden sind.",
		};
	}
	const hasDuplicateKey = properties.some(
		(prop) => prop.nameTechnisch === values.nameTechnisch,
	);
	if (hasDuplicateKey) {
		return {
			isDisabled: true as const,
			reason:
				`Die Eigenschaft kann nicht ${verb} werden, da der Name (technisch) ` +
				"der Eigenschaft nicht eindeutig ist.",
		};
	}
	return { isDisabled: false as const, reason: null };
}

export default function PropertyEditRow({
	onConfirm,
	onCancel,
	properties,
	mode,
	initialState = emptyState,
	autoFocus = false,
}: {
	onConfirm: (values: PropertyValues) => void;
	onCancel?: () => void;
	properties: PropertyValues[];
	mode: PropertyEditMode;
	initialState?: PropertyValues;
	autoFocus?: boolean;
}) {
	const [values, setValues] = useState(initialState);
	const firstInputRef = useRef<HTMLInputElement>();

	useEffect(() => {
		setValues(initialState);
	}, [initialState]);

	const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		const { value, name } = e.target;
		if (name === "name") {
			const nameTechnisch = normalizeIdentifier(value);
			setValues((prevValues) => ({
				...prevValues,
				nameTechnisch,
				name: value,
			}));
		} else {
			setValues((prevValues) => ({ ...prevValues, [name]: value }));
		}
	};

	const { isDisabled: isConfirmDisabled, reason } = checkConfirmState(
		values,
		properties,
		mode,
	);

	const handleConfirm = () => {
		if (isConfirmDisabled) return;
		onConfirm(values);
		setValues(initialState);
		firstInputRef.current?.focus();
	};

	const handleCancel = () => {
		onCancel?.();
	};

	const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
		if (e.ctrlKey && e.key === "Enter") {
			handleConfirm();
		}
	};

	const confirmVerb =
		mode === PropertyEditMode.Create ? "hinzufügen" : "speichern";
	return (
		<TableRow data-testid="attribute-edit-row" data-mode={mode}>
			<TableCell>
				<TextField
					autoFocus={autoFocus}
					fullWidth
					placeholder="Name"
					name="name"
					value={values.name}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					data-testid="attribute-name-input"
				/>
			</TableCell>
			<TableCell>
				<TextField
					fullWidth
					placeholder="Beschreibung"
					multiline
					rows={3}
					name="beschreibung"
					value={values.beschreibung}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					data-testid="attribute-desc-input"
				/>
			</TableCell>
			<TableCell>
				<span className="attribute-edit-row__actions">
					<Tooltip
						title={isConfirmDisabled ? reason : `Eigenschaft ${confirmVerb}`}
					>
						<span>
							<IconButton
								disabled={isConfirmDisabled}
								onClick={handleConfirm}
								data-testid="confirm-button"
							>
								{mode === PropertyEditMode.Create ? <AddIcon /> : <SaveIcon />}
							</IconButton>
						</span>
					</Tooltip>
					{mode === PropertyEditMode.Edit && (
						<>
							<Tooltip title="Bearbeitung abbrechen">
								<span>
									<IconButton
										onClick={handleCancel}
										data-testid="cancel-button"
									>
										<CancelIcon />
									</IconButton>
								</span>
							</Tooltip>
							<KeyboardShortcut
								shortcut={{ key: "Escape" }}
								onActivate={handleCancel}
							/>
						</>
					)}
				</span>
			</TableCell>
		</TableRow>
	);
}
