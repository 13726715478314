import { createSvgIcon } from "@mui/material";

const EinfacherDatentypIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.25 6C4.25 5.0335 5.0335 4.25 6 4.25H18C18.9665 4.25 19.75 5.0335 19.75 6V18C19.75 18.9665 18.9665 19.75 18 19.75H6C5.0335 19.75 4.25 18.9665 4.25 18V6ZM6 5.75C5.86193 5.75 5.75 5.86193 5.75 6V18C5.75 18.1381 5.86193 18.25 6 18.25H18C18.1381 18.25 18.25 18.1381 18.25 18V6C18.25 5.86193 18.1381 5.75 18 5.75H6Z"
		/>
	</svg>,
	"EinfacherDatentyp",
);

export default EinfacherDatentypIcon;
