import { Outlet, Route, Routes } from "react-router-dom";
import DatatypesNavBar from "./DatatypesNavBar";
import DatatypesListView from "./DatatypesListView";
import RestrictionView from "./DetailsView/RestrictionView";
import StandardTypeView from "./DetailsView/StandardTypeView";
import EditorPage, { EditorSection } from "../EditorPage";
import ProfilingNodeValidationProvider from "../../AtomicComponents/Organisms/ProfilierungNodeValidationProvider";

function DatatypesView(): JSX.Element {
	return (
		<EditorPage data-testid="datatypes-view" section={EditorSection.Datatypes}>
			<ProfilingNodeValidationProvider>
				<Routes>
					<Route path="/" element={<DatatypesNavBar />}>
						<Route index element={<Outlet />} />
						<Route path=":nodeId/*" element={<Outlet />} />
						<Route path="*" element={<Outlet />} />
					</Route>
				</Routes>
				<Routes>
					<Route path="/" element={<DatatypesListView />}>
						<Route index element={<Outlet />} />
						<Route path=":nodeId" element={<Outlet />} />
					</Route>
					<Route
						path=":nodeId/profil/:restrictionId"
						element={<RestrictionView />}
					>
						<Route index element={<Outlet />} />
						<Route path=":subNodeId" element={<Outlet />} />
					</Route>
					<Route path=":nodeId/standard" element={<StandardTypeView />}>
						<Route index element={<Outlet />} />
						<Route path=":subNodeId" element={<Outlet />} />
					</Route>
				</Routes>
			</ProfilingNodeValidationProvider>
		</EditorPage>
	);
}

export default DatatypesView;
