import { useHtmlId } from "../../../../../../hooks";
import type { CodeListValues } from "../../../../../../types/InfoNodeValues";
import type { FieldDefinition } from "../../../../../EditFormField";
import EditFormField from "../../../../../EditFormField";
import { subSelectCodelistIdentifier } from "../../../../../EditorState/subSelectors";
import { useProfilierungValidationResult } from "../../../../../../AtomicComponents/Organisms/ProfilierungValidationDisplay";
import DynamicInfo from "../../../../../ui/DynamicInfo";
import { ValidationTargetField } from "../../../../../Validation/types";
import ValidationFormatter from "../../../../../Validation/ValidationFormatter";
import {
	getCodeListIconKey,
	useCodeliste,
	useReferenceProfile,
} from "../../fieldRendererHelpers";
import type { FieldRendererComponentProps } from "../../types";
import InfoRenderer from "../InfoRenderer";

const KennungRenderer = ({
	fields,
	isStandardRenderer,
	definition: customDefinition,
	onBlur,
	profile,
	activeNode,
}: FieldRendererComponentProps) => {
	const [definition] = fields;

	const validationResultId = useHtmlId();
	const validationResults = useProfilierungValidationResult({
		nodeId: activeNode.id,
		targetField: ValidationTargetField.CodelistIdentifier,
	});

	const { nutzungsArt, kennung } = useCodeliste(activeNode);
	const readOnlyByKind =
		nutzungsArt === "1" || nutzungsArt === "2" || nutzungsArt === "3";
	const referenceProfile = useReferenceProfile(profile, activeNode);
	const kennungState = referenceProfile
		? subSelectCodelistIdentifier(referenceProfile)
		: subSelectCodelistIdentifier(profile);
	const value = (readOnlyByKind ? kennung : kennungState) ?? "";
	const infoKey = getCodeListIconKey("kennung", nutzungsArt);
	return (
		<>
			{!isStandardRenderer && (
				<EditFormField
					definition={
						{
							...customDefinition,
							...definition,
							readOnly:
								readOnlyByKind || definition?.readOnly || isStandardRenderer,
							label: (
								<>
									{definition.label}{" "}
									{infoKey && <DynamicInfo infoKey={infoKey} />}
								</>
							),
						} as FieldDefinition<CodeListValues>
					}
					onBlur={onBlur}
					value={value}
					aria-describedby={validationResultId}
				/>
			)}

			<InfoRenderer
				label="Codelistenkennung"
				value={kennung}
				fallback="Keine Angabe im Standard"
			/>

			{validationResults.length > 0 && (
				<div id={validationResultId}>
					<ValidationFormatter results={validationResults} />
				</div>
			)}
		</>
	);
};

export default KennungRenderer;
