import classNames from "classnames";
import { CircularProgress, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import type {
	ChangeEvent,
	DetailedHTMLProps,
	InputHTMLAttributes,
	KeyboardEventHandler,
} from "react";
import { useRef, useEffect } from "react";
import type { ExtendProps } from "../../../utils/types";
import { useEditorSideBar } from "../EditorSideBarContext";
import { VisuallyHidden } from "../../ui";
import "./EditorSideBarSearch.scss";

type InputProps = DetailedHTMLProps<
	InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
>;
type EditorSideBarSearchProps = ExtendProps<
	InputProps,
	{
		onChange?: (newValue: string) => void;
		onClear?: () => void;
		onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
		className?: string;
		inputClassName?: string;
		value?: string;
		label: string;
		loading?: boolean;
	}
>;

function EditorSideBarSearch({
	onChange,
	onClear,
	onKeyDown,
	className,
	inputClassName,
	value,
	label,
	loading,
	...props
}: EditorSideBarSearchProps): JSX.Element {
	const ref = useRef<HTMLInputElement>(null);
	const { searchInputId } = useEditorSideBar();

	const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
		onKeyDown?.(e);
		if (e.key === "Escape") {
			onClear?.();
		}
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange?.(e.target.value);
	};

	useEffect(() => {
		const handler = (e: KeyboardEvent) => {
			if (e.key === "F" && e.ctrlKey && e.shiftKey) {
				e.preventDefault();
				ref.current?.focus();
			}
		};
		window.addEventListener("keydown", handler);
		return () => window.removeEventListener("keydown", handler);
	}, []);

	return (
		<div
			className={classNames("editor-sidebar-search", className)}
			data-testid="editor-sidebar-search"
		>
			<div className="editor-sidebar-search__wrapper">
				<div className="editor-sidebar-search__icon-wrapper editor-sidebar-search__icon-wrapper--search">
					{loading ? <CircularProgress size="1em" /> : <SearchIcon />}
				</div>
				<label className="editor-sidebar-search__label" htmlFor={searchInputId}>
					<VisuallyHidden>{label}</VisuallyHidden>
					<input
						type="text"
						data-testid="sidebar-search-input"
						placeholder="Suche"
						onChange={handleChange}
						onKeyDown={handleKeyDown}
						aria-busy={loading}
						value={value}
						{...props}
						className={classNames(
							"editor-sidebar-search__input",
							inputClassName,
						)}
						id={searchInputId}
						ref={ref}
					/>
				</label>
				<IconButton
					className="editor-sidebar-search__icon-wrapper editor-sidebar-search__icon-wrapper--reset"
					data-testid="sidebar-search-reset"
					onClick={onClear}
					disableRipple
				>
					<ClearIcon />
				</IconButton>
			</div>
		</div>
	);
}

export default EditorSideBarSearch;
