import {
	useLocation,
	Route,
	Routes,
	Outlet,
	createPath,
	Link,
} from "react-router-dom";
import { Footer } from "@xoev/ui";
import { DocumentTitle, VisuallyHidden } from "./components/ui";
import GoogleSearch from "./components/GoogleSearch";
import Menu from "./components/Menu";
import ProfilingSubMenu from "./components/Menu/ProfilingSubMenu";
import EnvBanner from "./components/EnvBanner/EnvBanner";
import MessageView from "./components/MessageView";
import DatatypesView from "./components/DatatypesView";
import MessageProfilingView from "./components/MessageProfilingView";
import DocumentationView from "./components/DocumentationView";
import MetadataView from "./components/MetadataView";
import ProfilingHome from "./features/ProfilierungHome/components/ProfilierungHome";
import EditorProvider from "./components/Editor";
import NotFound from "./components/NotFound";
import MaintenanceBanner from "./components/MaintenanceBanner";
import AppInfoProvider from "./components/AppInfoProvider";
import {
	SystemBannerOutlet,
	SystemBannerProvider,
} from "./components/SystemBanner";
import Styleguide from "./components/Styleguide";
import HomeView from "./components/HomeView";
import TreeStructureProvider from "./components/TreeStructureProvider";
import CacheProvider from "./components/CacheProvider";
import GlobalSnackbar from "./components/GlobalSnackbar";
import IconInfoProvider from "./components/IconInfoProvider";
import MetadataViewLite from "./components/MetadataViewLite/MetadataViewLite";
import ProjectSubMenu from "./components/Menu/ProjectSubMenu";
import UnderConstruction from "./components/Projects/UnderConstruction";
import NavigationListener from "./components/NavigationListener";
import LiteModelView from "./components/LiteModelView";
import EffectApi from "./components/AppActor/actors/effectApi/EffectApi";
import NotificationCenter from "./components/AppActor/actors/notification/NotificationCenter";
import AppDebug from "./components/AppActor/AppDebug";
import ConfigurationView from "./components/ConfigurationView";
import { LiteBasePath } from "./components/AppActor/actors/navigation/helpers";
import "./AppView.scss";
import StartView from "./features/StartView/components/StartView";

enum AppSection {
	Profiling = "profilierung",
}

const mainPaths = ["/profilierung"];

const AppView = (): JSX.Element => {
	const location = useLocation();

	const tabContent = (
		<>
			<Route
				path="metadaten/*"
				element={
					<DocumentTitle title="Projekt">
						<MetadataViewLite />
					</DocumentTitle>
				}
			/>
			<Route
				path="konfiguration"
				element={
					<DocumentTitle title="Konfiguration">
						<ConfigurationView />
					</DocumentTitle>
				}
			/>
			<Route
				path="modell/*"
				element={
					<DocumentTitle title="Modell">
						<LiteModelView />
					</DocumentTitle>
				}
			/>
			<Route
				path="*"
				element={
					<DocumentTitle title="Under Construction">
						<UnderConstruction />
					</DocumentTitle>
				}
			/>
		</>
	);

	return (
		<GlobalSnackbar>
			{(import.meta.env.DEV || process.env.BUILD_MODE !== "production") && (
				<AppDebug />
			)}
			<EffectApi />
			<NotificationCenter />
			<NavigationListener />
			<DocumentTitle title="XÖV-Suite">
				<VisuallyHidden>
					<span
						aria-hidden="true"
						data-testid="app-location"
						data-path={createPath(location)}
					/>
				</VisuallyHidden>
				<AppInfoProvider>
					<GoogleSearch />
					<IconInfoProvider>
						<TreeStructureProvider>
							<EditorProvider>
								<SystemBannerProvider>
									<SystemBannerOutlet />
									<EnvBanner />
									<MaintenanceBanner />
								</SystemBannerProvider>
								<Menu />
								<div className="app-view">
									<Routes>
										<Route
											path="/"
											element={
												<DocumentTitle title="Startseite">
													<StartView />
												</DocumentTitle>
											}
										/>
										<Route
											path={`${LiteBasePath.Modellierung}/:projektId`}
											element={
												<DocumentTitle title="Projekt">
													<ProjectSubMenu />
													<Outlet />
												</DocumentTitle>
											}
										>
											{tabContent}
										</Route>
										<Route
											path={`${LiteBasePath.Standard}/:projektId`}
											element={
												<DocumentTitle title="Projekt">
													<ProjectSubMenu />
													<Outlet />
												</DocumentTitle>
											}
										>
											{tabContent}
										</Route>
										<Route
											path="profilierung"
											element={
												<DocumentTitle title="Profilierung">
													<CacheProvider
														mainPaths={mainPaths}
														section={AppSection.Profiling}
													>
														<ProfilingSubMenu />
														<Outlet />
													</CacheProvider>
												</DocumentTitle>
											}
										>
											<Route index element={<ProfilingHome />} />
											<Route
												path="projekt/*"
												element={
													<DocumentTitle title="Projekt">
														<MetadataView />
													</DocumentTitle>
												}
											/>
											<Route
												path="datentypen/*"
												element={
													<DocumentTitle title="Datentypen">
														<DatatypesView />
													</DocumentTitle>
												}
											/>
											<Route
												path="nachrichtenstrukturen/*"
												element={
													<DocumentTitle title="Nachrichtenstrukturen">
														<MessageProfilingView />
													</DocumentTitle>
												}
											/>
											<Route
												path="dokumentation/*"
												element={
													<DocumentTitle title="Dokumentation">
														<DocumentationView />
													</DocumentTitle>
												}
											/>
										</Route>
										<Route
											path="anzeige/*"
											element={
												<DocumentTitle title="Anzeige">
													<h2 className="app-view__route-heading">Anzeige</h2>
												</DocumentTitle>
											}
										/>
										<Route
											path="nachrichten"
											element={
												<DocumentTitle title="Nachrichtenübersicht">
													<MessageView />
												</DocumentTitle>
											}
										/>
										<Route
											path="impressum/*"
											element={
												<DocumentTitle title="Impressum">
													<HomeView doc="impressum.adoc" />
												</DocumentTitle>
											}
										/>
										<Route
											path="datenschutzerklaerung/*"
											element={
												<DocumentTitle title="Datenschutzerklärung">
													<HomeView doc="datenschutzerklaerung.adoc" />
												</DocumentTitle>
											}
										/>
										<Route
											path="barrierefreiheit/*"
											element={
												<DocumentTitle title="Barrierefreiheit">
													<HomeView doc="barrierefreiheit.adoc" />
												</DocumentTitle>
											}
										/>
										<Route
											path="styleguide"
											element={
												<DocumentTitle title="StyleGuide">
													<Styleguide />
												</DocumentTitle>
											}
										/>
										<Route path="*" element={<NotFound />} />
									</Routes>
								</div>
								<Footer>
									<ul>
										<li>
											<Link to="/impressum" data-testid="impressum-link">
												Impressum
											</Link>
										</li>
										<li>
											<Link
												to="/datenschutzerklaerung"
												data-testid="datenschutz-link"
											>
												Datenschutzerklärung
											</Link>
										</li>
									</ul>
								</Footer>
							</EditorProvider>
						</TreeStructureProvider>
					</IconInfoProvider>
				</AppInfoProvider>
			</DocumentTitle>
		</GlobalSnackbar>
	);
};

export default AppView;
