import SubMenu from "../SubMenu";

function HomeSubMenu(): JSX.Element {
	return (
		<SubMenu label="Unternavigation der Startseite">
			<></>
		</SubMenu>
	);
}

export default HomeSubMenu;
