import type { ActorRefFrom } from "xstate";
import { setup } from "xstate";
import type { ConfirmationQueryActorRef } from "./confirmation.query.machine";
import confirmationQueryMachine from "./confirmation.query.machine";
import type { ProjektId, RawLiteModel } from "../schemas";
import type { ProjektMeta } from "../../project/types";

export type ConfirmationActorRef = ActorRefFrom<
	// eslint-disable-next-line no-use-before-define
	typeof confirmationMachine
>;

export const createConfirmationQueryId = (projektId: ProjektId) =>
	`confirmation:query:${projektId}`;

const confirmationMachine = setup({
	types: {
		context: {} as {
			query: ConfirmationQueryActorRef;
		},
		input: {} as ProjektMeta & { rawModel: RawLiteModel },
	},
	actors: {
		query: confirmationQueryMachine,
	},
}).createMachine({
	id: "confirmation",
	context: ({ input, spawn }) => ({
		query: spawn("query", {
			systemId: createConfirmationQueryId(input.projektId),
			id: createConfirmationQueryId(input.projektId),
		}),
	}),
});

export default confirmationMachine;
