import { Tooltip } from "@mui/material";
import NodeIcon from "../../NodeIcon";
import { VisuallyHidden } from "../../ui";
import CodelisteIcon from "../../../resources/icons/CodelisteIcon";

const CodeListIcon = ({ isCodeList }: { isCodeList: boolean }): JSX.Element => {
	return (
		<>
			{isCodeList && (
				<Tooltip title="Das Element ist eine Codeliste">
					<NodeIcon>
						<VisuallyHidden>
							Information: Das Element ist eine Codeliste
						</VisuallyHidden>
						<CodelisteIcon />
					</NodeIcon>
				</Tooltip>
			)}
		</>
	);
};

export default CodeListIcon;
