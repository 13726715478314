import { createSvgIcon } from "@mui/material";

const GruppenartAuswahlIcon = createSvgIcon(
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5 12C10.5 12.8284 11.1716 13.5 12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12ZM9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5 20C10.5 20.8284 11.1716 21.5 12 21.5C12.8284 21.5 13.5 20.8284 13.5 20C13.5 19.1716 12.8284 18.5 12 18.5C11.1716 18.5 10.5 19.1716 10.5 20ZM9 20C9 21.6569 10.3431 23 12 23C13.6569 23 15 21.6569 15 20C15 18.3431 13.6569 17 12 17C10.3431 17 9 18.3431 9 20Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.09305 3.25572C9.06256 3.25194 9.03151 3.25 9 3.25H5C4.58579 3.25 4.25 3.58579 4.25 4C4.25 4.41421 4.58579 4.75 5 4.75H9C9.03151 4.75 9.06256 4.74806 9.09305 4.74428C9.42409 6.0411 10.6001 7 12 7C13.3999 7 14.5759 6.0411 14.907 4.74428C14.9374 4.74805 14.9685 4.75 15 4.75H19C19.4142 4.75 19.75 4.41421 19.75 4C19.75 3.58579 19.4142 3.25 19 3.25H15C14.9685 3.25 14.9374 3.25195 14.907 3.25572C14.5759 1.9589 13.3999 1 12 1C10.6001 1 9.42409 1.9589 9.09305 3.25572ZM12 5.5C11.1716 5.5 10.5 4.82843 10.5 4C10.5 3.17157 11.1716 2.5 12 2.5C12.8284 2.5 13.5 3.17157 13.5 4C13.5 4.82843 12.8284 5.5 12 5.5Z"
		/>
	</svg>,
	"GruppenartAuswahlIcon",
);

export default GruppenartAuswahlIcon;
