import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import type {
	LiteBaustein,
	LiteEigenschaft,
} from "../../../../../AppActor/actors/modellierungModel/schemas";
import EditFormFieldLabel from "../../../../../EditFormField/EditFormFieldLabel";
import { getOptionLabel } from "./helpers";
import ReferersName from "./ReferersName";
import "../ReferersEdit.scss";
import { useHtmlId } from "../../../../../../hooks";
import { useModellierungDatatypesAndGlobals } from "../../../../../AppActor/actors/modellierungModel/hooks";
import LiteNodeKindIcon from "../../../../LiteNodeKindIcon";
import type { ReferersDropdownProps } from "../../types";

export default function ReferersDropdown({
	onChange,
	dropdownOptions,
	activeNode,
	value,
	isDataAnonymous,
	isReadOnly,
	labelText,
	clearTextHint,
	placeholderText,
}: ReferersDropdownProps<LiteEigenschaft | LiteBaustein>): JSX.Element {
	const inputId = useHtmlId();
	const datatypes = useModellierungDatatypesAndGlobals();

	return (
		<>
			<EditFormFieldLabel
				text={<span>{labelText}</span>}
				htmlFor={inputId}
				data-testid="referers-select-label"
				data-is-anonymous={isDataAnonymous}
			>
				<Autocomplete
					filterOptions={createFilterOptions({
						matchFrom: "start",
						stringify: (option) => option.name,
					})}
					key={activeNode.id}
					disabled={isReadOnly}
					disablePortal
					options={dropdownOptions}
					value={value}
					onChange={onChange}
					fullWidth
					clearText={clearTextHint}
					isOptionEqualToValue={(option, currentValue) =>
						option.id === currentValue.id
					}
					componentsProps={{
						clearIndicator: {
							[String("data-testid")]: `clear-referers`,
						},
					}}
					getOptionKey={(option) => option.id}
					getOptionLabel={(option) => getOptionLabel(dropdownOptions, option)}
					renderOption={(params, option) => (
						<span
							{...params}
							data-testid="referers-option"
							data-dt-name={option.name}
							data-dt-id={option.id}
						>
							<LiteNodeKindIcon
								node={option.node}
								className="lite-referers-select__icon-container"
								iconClassName="lite-referers-select__icon"
							/>

							<ReferersName
								className="lite-referers-select__option"
								title={getOptionLabel(dropdownOptions, option)}
								refEntry={option}
								datatypes={datatypes}
							/>
						</span>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={placeholderText}
							data-testid="referers-input"
						/>
					)}
				/>
			</EditFormFieldLabel>
		</>
	);
}
