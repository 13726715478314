import classNames from "classnames";
import createImmutableMap from "@xoev/immutable-map";
import { memoize } from "@xoev/memo";
import { placeholder } from "../../../../../resources/textConstants";
import FormFieldRenderer from "../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/FormFieldRenderer";
import type { TreeNodeType } from "../../../../../types/ProfilierungHome";
import { fields } from "../../../../InfoNodeEditView/InfoNodeEditForm/infoNodeFormHelpers";
import useActiveDataType from "../../../useActiveDataType";
import EditorContent from "../../../../EditorContent";
import { useActiveStandardNode } from "../../../../MessageProfilingView/useActiveNode";
import SelectionTable from "../../RestrictionView/RestrictionEditView/RestrictionEditForm/SelectionTable";
import {
	selectStandard,
	selectStandardSelectionModel,
} from "../../../../EditorState/selectors";
import type { MessageProfileValues } from "../../../../EditorState/types";
import type { LiteModellContainer } from "../../../../AppActor/actors/modellierungModel/types";
import type { RootState } from "../../../../../redux/store";
import {
	selectModellContainer,
	selectQName,
} from "../../../../../redux/treeSlice";
import type { Nullish } from "../../../../../utils/types";
import { useAppSelector } from "../../../../../redux/hooks";
import { useStateSelector } from "../../../../EditorState";
import {
	isLiteEigenschaft,
	type LiteNode,
} from "../../../../AppActor/actors/modellierungModel/schemas";
import type { FieldRendererDefinition } from "../../../../InfoNodeEditView/InfoNodeEditForm/FormFieldRenderer/types";
import {
	LiteKind,
	getLiteNodeKind,
} from "../../../../AppActor/actors/modellierungModel/LiteKind";
import "./StandardTypeDetailsView.scss";
import { selectDatatypeOrReferenceFromModell } from "../../../../AppActor/actors/modellierungModel/selectors";

const EMPTY_FIELDS: FieldRendererDefinition[] = [];

const selectFieldsFromModell = memoize(
	(modell: LiteModellContainer, activeSubnode: LiteNode) => {
		const datatype = selectDatatypeOrReferenceFromModell(modell, activeSubnode);
		const isCodeliste =
			!!datatype && getLiteNodeKind(datatype) === LiteKind.CodeDatentyp;
		return fields.filter(
			(field) =>
				field.name in activeSubnode ||
				field.name === "defaultWert" ||
				field.name === "fixedWert" ||
				(isLiteEigenschaft(activeSubnode) &&
					(field.name === "lowerBound" || field.name === "upperBound")) ||
				(isCodeliste &&
					(field.name === "nutzungsArt" ||
						field.name === "version" ||
						field.name === "kennung")),
		);
	},
);

const selectFields =
	(standard: Nullish<string>, activeSubnode: TreeNodeType) =>
	(state: RootState) => {
		const modell = selectModellContainer(standard)(state);
		if (!modell) return EMPTY_FIELDS;
		return selectFieldsFromModell(modell, activeSubnode);
	};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const StandardTypeDetailsView = (): JSX.Element => {
	const { activeDataType: optionalActiveDataType } = useActiveDataType();
	// We check for the existance of the activeDataType in the parent component
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const activeDataType = optionalActiveDataType!;
	const standard = useStateSelector(selectStandard());
	const qname = useAppSelector(selectQName(standard, activeDataType));
	const modelSelector = selectStandardSelectionModel(qname);
	const { activeNode, activePath } = useActiveStandardNode(activeDataType.id);
	const standardFields = useAppSelector(selectFields(standard, activeNode));
	return (
		<EditorContent>
			<div
				className={classNames(
					activeNode.id === activeDataType.id && "standard-view",
				)}
			>
				<h3 data-testid="standard-dt-name">
					{activeNode.name || placeholder.anonymousStructure}
				</h3>
				<FormFieldRenderer
					fields={standardFields}
					profile={createImmutableMap(activeNode as MessageProfileValues)}
					definition={{
						readOnly: true,
					}}
					onBlur={noop}
					activeNode={activeNode}
					activePath={activePath}
					isStandardRenderer
				/>
				{activeNode.id === activeDataType.id && (
					<div className="standard-view__table">
						<SelectionTable modelSelector={modelSelector} isStandardView />
					</div>
				)}
			</div>
		</EditorContent>
	);
};

export default StandardTypeDetailsView;
