import { Tooltip } from "@mui/material";
import classNames from "classnames";
import type { ReactNode } from "react";
import NodeIcon from "../../NodeIcon";
import { VisuallyHidden } from "../../ui";
import { validationIcons, validationLabels } from "../helpers";
import type { Severity } from "../types";
import "./ValidationLabel.scss";

function ValidationLabel({
	children,
	severity,
}: {
	children?: ReactNode;
	severity?: Severity | null;
}): JSX.Element {
	return (
		<>
			<span
				className={classNames(
					"validation-label__text",
					severity && `validation-label__text--has-result`,
					severity && `validation-label__text--severity-${severity}`,
				)}
				data-severity={severity}
				title={typeof children === "string" ? children : undefined}
			>
				{children}
			</span>
			{severity && (
				<>
					<Tooltip aria-hidden title={validationLabels[severity]}>
						<NodeIcon
							className={classNames(
								"validation-label__icon",
								severity && `validation-label__icon--severity-${severity}`,
							)}
						>
							{validationIcons[severity]}
						</NodeIcon>
					</Tooltip>
					<VisuallyHidden>{validationLabels[severity]}</VisuallyHidden>
				</>
			)}
		</>
	);
}

export default ValidationLabel;
